import React, { MouseEvent, RefObject, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Fade from './Fade';
import {
  StyledError,
  StyledOptionContainerCSSProp,
  StyledOutsideClickWatcher,
  StyledSelect,
  StyledSelectLabel,
} from './Select.styles';
import IconTooltip, { Props } from '@molecules/IconTooltip/IconTooltip';

interface SelectProps {
  options: OptionType[];
  optionComponent: React.FC<OptionComponentProps>;
  selectBoxComponent: React.FC<{ currentSelection: OptionType; isOpen: boolean }>;
  className?: string;
  onChange?: (e: OptionType) => void;
  selectedOptionIndex?: number;
  avoidViewport: boolean;
  isAddToList?: boolean;
  testId?: string;
  label?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  tooltip?: Props;
  disabled?: boolean;
}

const ADJUST_TOP = 120;

const Select = ({
  options,
  optionComponent,
  selectBoxComponent,
  className,
  onChange,
  selectedOptionIndex = 0,
  avoidViewport = true,
  isAddToList = false,
  testId,
  label,
  required = false,
  size = 'small',
  tooltip,
  disabled,
}: SelectProps) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<OptionType>(options[selectedOptionIndex]);
  const [adjustedTopPosition, setAdjustedTopPosition] = useState(0);
  const { t } = useTranslation('common');
  const onSelectFocus = (e: MouseEvent) => {
    if (e.target !== e.currentTarget || disabled) return;
    setIsOpen(!isOpen);
  };

  const onSelectBlur = () => {
    setIsOpen(false);
  };

  const onItemClickHandler = (option: OptionType) => {
    setIsTouched(true);
    setCurrentSelection(option);
    if (onChange) {
      onChange(option);
    }
    onSelectBlur();
  };

  useEffect(() => {
    // Recalculate current selection when options change
    setCurrentSelection(options.filter((option) => option.selected)[0] || options[0]);
  }, [options]);

  const isElementInViewport = (el: HTMLDivElement) => {
    const rect = el.getBoundingClientRect();
    return rect ? rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) : true;
  };

  const onFadeStart = (_show: boolean, ref: RefObject<HTMLDivElement | undefined>) => {
    // reset between renders
    setAdjustedTopPosition(0);

    if (avoidViewport && isOpen && ref.current) {
      const isInViewport = isElementInViewport(ref.current);
      if (!isInViewport) {
        setAdjustedTopPosition(ADJUST_TOP);
      } else {
        setAdjustedTopPosition(0);
      }
    }
  };

  return (
    <StyledOutsideClickWatcher className={className} clickHandler={onSelectBlur}>
      {label && (
        <StyledSelectLabel>
          {label}
          {tooltip?.content && <IconTooltip {...tooltip} />}
        </StyledSelectLabel>
      )}
      <StyledSelect
        onClick={onSelectFocus}
        data-testid={testId}
        role="listbox"
        tabIndex={0}
        hasError={required ? !currentSelection.value && isTouched : false}
        size={size}
        aria-label={label}
        disabled={disabled}
      >
        {selectBoxComponent({ currentSelection, isOpen })}
      </StyledSelect>
      {required && !currentSelection.value && isTouched && <StyledError>{t('input->invalidRequired')}</StyledError>}

      <Fade
        as="ul"
        show={isOpen}
        onStart={onFadeStart}
        role="menu"
        extraCss={StyledOptionContainerCSSProp(adjustedTopPosition, isAddToList)}
      >
        {options.map((option) => {
          return optionComponent({
            onItemClickHandler,
            option,
            isSelected: !!option.selected,
          });
        })}
      </Fade>
    </StyledOutsideClickWatcher>
  );
};

export default Select;
