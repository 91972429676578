import styled, { css } from 'styled-components';
import Config from '@config';
import variables from '@styles/variables';
import { rem } from 'polished';

export const StyledHeaderContainer = styled.div<{ hasHeader: boolean }>`
  ${({ hasHeader }) =>
    hasHeader &&
    css`
      min-height: ${rem(60)};
    `}

  ${Config.MEDIA.BELOW_DESKTOP} {
    position: sticky;
    top: 0;
    z-index: ${variables.zIndex.toolbar + 1};
  }
`;
