import useSWR, { preload } from 'swr';
import { getCustomer } from '@api/interfaces/customerApi';
import type { OverriddenCustomerData } from '@api/interfaces/customerApi';

type OnSuccessHandler = (data: OverriddenCustomerData) => void;

const endpoint = '/rest/customer';

if (typeof window !== 'undefined') {
  preload(endpoint, getCustomer);
}
const useCustomer = (onSuccess?: OnSuccessHandler) => {
  const {
    data: customer,
    isValidating,
    mutate: refreshCustomer,
    isLoading,
  } = useSWR(endpoint, getCustomer, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    revalidateOnFocus: true,
  });

  return {
    customer,
    refreshCustomer,
    isValidating,
    isLoading,
  };
};

export default useCustomer;
