import { useState } from 'react';
import Grid from '@atoms/Grid/Grid';
import VoucherCard from '@molecules/VoucherCard/VoucherCard';
import ProductsWithTracking from '@molecules/ProductsWithTracking/ProductsWithTracking';
import type { AxfoodExternalVoucher, AxfoodBasicProductViewModel } from '@occ/api-client';

type Variant = 'product' | 'mixmatch';

type Vouchers = {
  used: AxfoodExternalVoucher[];
  available: AxfoodExternalVoucher[];
};

interface Props {
  title: string;
  products: Array<AxfoodBasicProductViewModel | ImageGridComponentType>;
  category?: string;
  vouchers?: Vouchers;
  productType: OnlineOfflineProductType;
  offline?: boolean;
  variant?: Variant;
  hideAddToList?: boolean;
  limit?: number;
  numberOfGridCols?: number;
}

const ProductContainer = ({
  title,
  products = [],
  category = title,
  vouchers,
  productType,
  offline,
  variant = 'product',
  hideAddToList = false,
  limit = products?.length || 0,
  numberOfGridCols,
}: Props) => {
  const [currentMixMatchBeam, setCurrentMixMatchBeam] = useState<string>('');
  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const setOpenMixMatchBeam = (code: string) => setCurrentMixMatchBeam(code);
  const handleRef = (ref: HTMLDivElement | null) => setGridRef(ref);

  const getProductKey = (product: AxfoodBasicProductViewModel | ImageGridComponentType) => {
    if ('typeCode' in product) {
      return product?.code;
    }
    return `${productType}-${product?.code || product.potentialPromotions[0]?.code}`;
  };

  return (
    <Grid passRef={handleRef}>
      {vouchers?.available?.map((voucher) => (
        <VoucherCard voucher={voucher} key={voucher.voucherId} type={productType} />
      ))}

      {vouchers?.used?.map((voucher) => (
        <VoucherCard voucher={voucher} key={voucher.voucherId} type={productType} />
      ))}

      {products && products.length > 0 && (
        <ProductsWithTracking
          products={products}
          variant={variant}
          getKey={getProductKey}
          category={category}
          offline={offline}
          openMixMatchBeam={currentMixMatchBeam}
          setOpenMixMatchBeam={setOpenMixMatchBeam}
          containerRef={gridRef}
          hideAddToList={hideAddToList}
          limit={limit}
          numberOfGridCols={numberOfGridCols}
          numberOfVouchers={(vouchers?.used?.length || 0) + (vouchers?.available?.length || 0)}
        />
      )}
    </Grid>
  );
};

export default ProductContainer;
