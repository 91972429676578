import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

interface Props {
  progress: number;
  total: number;
}

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: ${rem(2)};
  background-color: ${variables.colors.mediumGray};
`;

const Progress = styled.div<{ width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${rem(2)};
  background-color: ${variables.colors.primary};
  width: ${(props) => props.width}%;
`;

const ProgressBar = ({ progress, total }: Props) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(Math.round((progress / total) * 100));
  }, [progress, total]);

  return (
    <Bar data-testid="progressbar-bar">
      {progress > 0 && total > 0 && <Progress data-testid="progressbar-progress" width={width} />}
    </Bar>
  );
};

export default ProgressBar;
