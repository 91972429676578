import { AxfoodCartViewModel } from '@occ/api-client';
import { useMemo } from 'react';

interface Props {
  cart?: AxfoodCartViewModel;
  quantityInRefOrder?: number;
  currentQuantity: number;
  inCartUnit: string;
  unit: string;
  averageWeight?: number;
}

const useProductQuantityHelper = ({
  cart,
  quantityInRefOrder,
  currentQuantity,
  inCartUnit,
  unit,
  averageWeight,
}: Props) => {
  const shouldEnableWhenOpenOrder = !!(
    cart?.orderReference &&
    quantityInRefOrder !== undefined &&
    quantityInRefOrder > 0
  );

  const hasReachedMaxQuantity = (quantity?: number) => {
    if (shouldEnableWhenOpenOrder) {
      const quantityToUse = quantity ?? currentQuantity;

      const comparisonQuantity =
        inCartUnit === 'kilogram' && unit === 'pieces' && averageWeight
          ? quantityToUse * 1000 * averageWeight
          : quantityToUse;
      return comparisonQuantity >= quantityInRefOrder;
    }
    return false;
  };

  const preventIncrementDueToLowStock = useMemo(hasReachedMaxQuantity, [
    averageWeight,
    currentQuantity,
    inCartUnit,
    quantityInRefOrder,
    shouldEnableWhenOpenOrder,
    unit,
  ]);

  return {
    shouldEnableWhenOpenOrder,
    preventIncrementDueToLowStock,
    hasReachedMaxQuantity,
  };
};

export default useProductQuantityHelper;
