import { ReactNode } from 'react';
import StyledGridCol from './GridCol.styles';

export type SizeType = {
  startRow?: { s?: number; m?: number; l?: number };
  startCol?: { s?: number; m?: number; l?: number };
  spanRow?: { s?: number; m?: number; l?: number };
  spanCol?: { s?: number; m?: number; l?: number };
};

export interface GridColProps {
  size: SizeType;
  children: ReactNode;
  className?: string;
}
const GridCol = ({ size, children, className }: GridColProps) => (
  <StyledGridCol size={size} className={className}>
    {children}
  </StyledGridCol>
);

export default GridCol;
