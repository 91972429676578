import Config from '@config';
import { CmsApi } from '@occ/api-client';

const api = new CmsApi({
  baseUrl: '/axfoodcommercewebservices/v2',
});

export const findComponentsByIds = async (componentIds: Array<string>, storeId?: string) => {
  const res = await api.findComponentsByIds(Config.SITE_NAME, {
    componentIds,
    storeId,
  });
  return res.data;
};
