import styled from 'styled-components';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';

export const StyledMenuButton = styled(Button)`
  padding: 0;
  min-height: ${rem(44)};
  min-width: ${rem(44)};
  max-height: ${rem(44)};

  ${Config.BREAKPOINTS.FROM_DESKTOP} {
    padding: 0 ${rem(12)};
  }
`;

export const StyledMenuText = styled.span`
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(10)};
  text-transform: uppercase;

  ${Config.BREAKPOINTS.FROM_DESKTOP} {
    font-size: ${rem(13)};
    margin-left: ${rem(5)};
  }
`;

export const StyledMenuButtonWrapper = styled.div`
  position: relative;
`;
