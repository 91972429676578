import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackButtonClick = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'login_prompt',
    action: 'continued_to_login_modal',
    label: '',
    value: 0,
  });
};

export const trackCloseModal = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'login_prompt',
    action: 'window_closed',
    label: '',
    value: 0,
  });
};

export const trackShowModal = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'login_prompt',
    action: 'login_prompt_shown',
    label: '',
    value: 0,
  });
};
