export default {
  PAGE: {
    PROMOTION: {
      SIZE: {
        MOBILE: 20,
        TABLET: 20,
        DESKTOP: 20,
      },
    },
    CATEGORY: {
      SIZE: {
        MOBILE: 10,
        TABLET: 20,
        DESKTOP: 30,
      },
    },
    SEARCH_RESULT: {
      SIZE: {
        MOBILE: 10,
        TABLET: 20,
        DESKTOP: 30,
      },
    },
  },
};
