import variables from '@styles/variables';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamBook};

  td {
    &:last-child {
      text-align: right;
    }
  }

  thead {
    tr {
      td {
        padding: ${rem(5)} 0;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${variables.colors.lighterGray};
      }
      td {
        padding: ${rem(10)} ${rem(15)};
      }
    }
  }
`;
