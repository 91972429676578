import useSWRImmutable from 'swr/immutable';

type Config = {
  API_URL: string;
  GTM_YYYYYY: string;
  SYMPLIFY_WEBSITE_ID: string;
  RECAPTCHA_KEY: string;
  IMBOX_URL: string;
  MAPBOX_ACCESS_TOKEN: string;
  GOOGLE_MAPS_API_KEY: string;
};
const endpoint = '/api/config';
const fetcher = async () => {
  const response = await fetch(endpoint);
  return (await response.json()) as Config;
};

const useConfig = () => {
  const { data } = useSWRImmutable(endpoint, fetcher);

  return {
    config: data,
  };
};
export default useConfig;
