import { ReactNode } from 'react';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import NavigationBreadcrumbComponent from '@molecules/NavigationBreadcrumbComponent/NavigationBreadcrumbComponent';
import GridContainer from '@organisms/GridContainer/GridContainer';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import CmsContentSlot from '@atoms/CmsContentSlot/CmsContentSlot';
import collectSlots from '@api/interfaces/collectSlots';
import useSmartEdit from '@hooks/useSmartEdit';
import type { PageWithSlots } from '@api/interfaces/pageApi';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  homePageSlots: string[];
}

const WillysHomepageTemplate = ({ cmsPage, children, homePageSlots }: Props) => {
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const cmsSlots = collectSlots(cmsPage.slots, homePageSlots);
  const { Position1, Position3, Position5, Position7 } = cmsSlots;
  const ContentUspWillysHomepage = cmsSlots['Content-usp-Willys-Homepage'];
  const { breadcrumbs = [] } = cmsPage;
  const breadCrumbDataArray = Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs];
  const { isSmartEditEnabled } = useSmartEdit();
  return (
    <>
      {(isSmartEditEnabled || ContentUspWillysHomepage?.component?.length > 0) && (
        <CmsContentSlot
          slotData={ContentUspWillysHomepage}
          id="Content-usp-Willys-Homepage"
          data-testid="Content-usp-Willys-Homepage"
        >
          {breadCrumbDataArray.length > 0 && <NavigationBreadcrumbComponent data={breadCrumbDataArray} />}
          {ContentUspWillysHomepage?.component?.map((child, index) => (
            <CmsComponent
              key={child.uuid}
              component={child}
              context={{ slotName: 'Content-usp-Willys-Homepage', slotPosition: index + 1 }}
            />
          ))}
        </CmsContentSlot>
      )}

      {(isSmartEditEnabled || Position1?.component?.length > 0) && (
        <CmsContentSlot slotData={Position1} id="position-1-slot" data-testid="position-1-slot">
          {Position1?.component?.map((child, index) => (
            <CmsComponent
              key={child.uuid}
              component={child}
              context={{ slotName: 'Position1', slotPosition: index + 1 }}
            />
          ))}
        </CmsContentSlot>
      )}

      <GridContainer addPadding={sideMenuIsOpen || isCartPreviewOpen}>
        {(isSmartEditEnabled || Position3?.component?.length > 0) && (
          <CmsContentSlot slotData={Position3} id="position-3-slot" data-testid="position-3-slot">
            {Position3?.component?.map((child, index) => (
              <CmsComponent
                key={child.uuid}
                component={child}
                context={{ slotName: 'Position3', slotPosition: index + 1 }}
              />
            ))}
          </CmsContentSlot>
        )}

        {(isSmartEditEnabled || Position5?.component?.length > 0) && (
          <CmsContentSlot slotData={Position5} id="position-5-slot" data-testid="position-5-slot">
            {Position5?.component?.map((child, index) => (
              <CmsComponent
                key={child.uuid}
                component={child}
                context={{ slotName: 'Position5', slotPosition: index + 1 }}
              />
            ))}
          </CmsContentSlot>
        )}
      </GridContainer>

      {(isSmartEditEnabled || Position7?.component?.length > 0) && (
        <CmsContentSlot slotData={Position7} id="position-7-slot" data-testid="position-7-slot">
          {Position7?.component?.map((child, index) => (
            <CmsComponent
              key={child.uuid}
              component={child}
              context={{ slotName: 'Position7', slotPosition: index + 1 }}
            />
          ))}
        </CmsContentSlot>
      )}

      {/* This is only used from pages/index.tsx where we pass a hardcoded component */}
      {children && children}
    </>
  );
};

export default WillysHomepageTemplate;
