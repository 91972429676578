import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import { ParsedUrlQuery } from 'querystring';
import UserAgent from '@helpers/useragent';

type urlType = string | UrlObject;
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  nativeAppMethodOverride?: 'replace' | 'push';
}

export const reserveQueries = (url: urlType, currentRouterQuery: ParsedUrlQuery) => {
  if (!currentRouterQuery?.viewconfig) return url;

  const persistQuery = { viewconfig: currentRouterQuery.viewconfig };

  if (typeof url === 'object') {
    return { pathname: url.pathname, query: { ...(url.query as ParsedUrlQuery), ...persistQuery } };
  }

  const connector = url.indexOf('?') > -1 && url.indexOf('=') > -1 ? '&' : '?';
  return `${url}${connector}${new URLSearchParams(persistQuery as any).toString()}`;
};

const useCustomRouter = () => {
  const router = useRouter();

  const getLink = (url: urlType, as?: urlType) => {
    const newUrlObject = reserveQueries(url, router.query);
    const asArg = as ? reserveQueries(as, router.query) : as;

    return { newUrlObject, asArg };
  };

  const overrideMethod = (url: urlType, as?: urlType, options?: TransitionOptions) => {
    const { nativeAppMethodOverride } = options || { nativeAppMethodOverride: 'push' };

    if (nativeAppMethodOverride && options) {
      const { nativeAppMethodOverride: x, ...originalOptions } = options;
      router[nativeAppMethodOverride](url, as, originalOptions);
    }
  };

  const push = (url: urlType, as?: urlType, options?: TransitionOptions) => {
    const { newUrlObject, asArg } = getLink(url, as);

    // Allow overriding replace/push functionality in native app environment
    const willOverrideMethod = UserAgent.isNativeApp() && options?.nativeAppMethodOverride;

    willOverrideMethod ? overrideMethod(newUrlObject, asArg, options) : router.push(newUrlObject, asArg, options);
  };

  const replace = (url: urlType, as?: urlType, options?: TransitionOptions) => {
    const { newUrlObject, asArg } = getLink(url, as);

    // Allow overriding replace/push functionality in native app environment
    const willOverrideMethod = UserAgent.isNativeApp() && options?.nativeAppMethodOverride;

    willOverrideMethod ? overrideMethod(newUrlObject, asArg, options) : router.replace(newUrlObject, asArg, options);
  };

  const customRouter = {
    ...router,
    push,
    replace,
  };

  return customRouter;
};

export default useCustomRouter;
