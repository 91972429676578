import { useEffect } from 'react';

const unFreezeScroll = (preservePosition?: boolean) => {
  const body = document.getElementsByTagName('body')[0];

  if (body.style.getPropertyValue('overflow')) {
    const scrollY = body.style.top;
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('left');
    body.style.removeProperty('right');

    let number = parseInt(scrollY || '0') * -1;
    if (preservePosition) {
      // Scroll back to the original position
      window.scrollTo(0, number);
    }
  }
};

const freezeScroll = (preservePosition?: boolean) => {
  const body = document.getElementsByTagName('body')[0];
  if (preservePosition) {
    body.style.top = `-${window.scrollY}px`;
  }
  body.style.overflow = 'hidden';
  body.style.position = 'fixed';
  body.style.left = '0';
  body.style.right = '0';
};

const useFreezeBodyScroll = (shouldFreeze: boolean, preservePosition?: boolean) => {
  useEffect(() => {
    if (shouldFreeze) {
      freezeScroll(preservePosition);
    } else {
      unFreezeScroll(preservePosition);
    }
    return unFreezeScroll;
  }, [shouldFreeze, preservePosition]);
};

export default useFreezeBodyScroll;
