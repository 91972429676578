import { StyledKlarnaContainer } from '@molecules/CheckoutPaymentOption/CheckoutPaymentOption.styles';
import useCart from '@hooks/useCart';

const KlarnaOption = () => {
  const { cart } = useCart();
  const isReopened = Boolean(cart?.orderReference);

  return <StyledKlarnaContainer hideBorder={isReopened} data-testid="klarna-pay-option" />;
};

export default KlarnaOption;
