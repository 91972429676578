import { rem } from 'polished';

const size = {
  containerPadding: 20,
  containerPaddingNegative: -20,
  toolbarHeight: 60,
  cartPreviewWidth: 316,
  sidenavWidth: 260,
  sidenavWidthSmall: 210,
  takeoverInputfield: 60,
  checkoutBoxWidthTablet: 698,
  checkoutBoxWidthDesktop: 1032,
  cartPageMobileTotalsHeight: 120,
};

const colors = {
  // Colors - main
  primary: '#E60019',
  cherryRed: '#A80718',
  error: '#F49E00',
  plus: '#FFE500',
  eko: '#42A62A',
  highlight: '#0077B8',
  black: '#000000',
  lighterBlack: '#181818',
  darkerGray: '#767676',
  darkGray: '#949494',
  mediumGray: '#E2E0E2',
  lighterGray: '#F6F6F6',
  lightGray: '#EDEDED',
  paleGrey: '#f1f1f1',
  white: '#FFFFFF',
  highlightBg: '#F2F9FD',

  // Colors - states
  primaryDark: '#D20017',
  primaryInactive: '#FA969D',
  highlightHover: '#005D8D',
};

const fonts = {
  fontGothamBook: 'var(--font-gotham-book)',
  fontGothamMedium: 'var(--font-gotham-medium)',
  fontGothamSerifPrice: 'var(--font-gothamSerif-price)',
  fontGothamSerifBold: 'var(--font-gothamSerif-bold)',
};

const zIndex = {
  0: 0,
  1: 1,
  2: 2,
  scrollTop: 10,
  cart: 20,
  toolbar: 30,
  deliveryPicker: 40,
  mobileMenu: 50,
  backdrop: 60,
  sideNav: 70,
  globalMessage: 80,
  modal: 90,
};

const animation = {
  normal: '250ms',
  slow: '1s',
  layout: '250ms',
};

const container = {
  xs: `calc(${rem(233)} * 3 + ${rem(size.containerPadding)} * 2)`,
  sm: `calc(${rem(233)} * 4 + ${rem(size.containerPadding)} * 2)`,
  md: `calc(${rem(204)} * 5 + ${rem(size.containerPadding)} * 2 - ${rem(15)})`,
  lg: `calc(${rem(204)} * 6 + ${rem(size.containerPadding)} * 2)`,
  xl: `calc(${rem(204)} * 7 + ${rem(size.containerPadding)} * 2)`,
};

export default {
  colors,
  fonts,
  zIndex,
  animation,
  size,
  container,
};
