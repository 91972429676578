import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface DeliveryPickerState {
  isOpen: boolean;
  proceedToCheckout: boolean;
}

const initialState: DeliveryPickerState = {
  isOpen: false,
  proceedToCheckout: false,
};

export const deliveryPickerSlice = createAppSlice({
  name: 'deliveryPicker',
  initialState,
  reducers: (create) => ({
    setDeliveryPickerIsOpen: create.reducer(
      (state, action: PayloadAction<{ isOpen: boolean; proceedToCheckout?: boolean }>) => {
        state.isOpen = action.payload.isOpen;
        state.proceedToCheckout = action.payload.proceedToCheckout || false;
      }
    ),
  }),
  selectors: {
    selectDeliveryPicker: (deliveryPicker) => deliveryPicker,
    selectDeliveryPickerProceedToCheckout: (deliveryPicker) => deliveryPicker.proceedToCheckout,
  },
});

// Actions
export const { setDeliveryPickerIsOpen } = deliveryPickerSlice.actions;

// Selectors
export const { selectDeliveryPicker, selectDeliveryPickerProceedToCheckout } = deliveryPickerSlice.selectors;
