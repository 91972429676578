import { Action, combineSlices, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { conflictingModalSlice } from '@slices/conflictingModalSlice';
import { toolbarSlice } from '@slices/toolbarSlice';
import { modalSlice } from '@slices/modalSlice';
import { deliveryPickerSlice } from '@slices/deliveryPickerSlice';
import { cartUpdatingSlice } from '@slices/cartUpdatingSlice';
import { miniCartSlice } from '@slices/miniCartSlice';
import { menuSlice } from '@slices/menuSlice';
import { checkoutSlice } from '@slices/checkoutSlice';
import { loginSlice } from '@slices/loginSlice';
import { slideInSectionSlice } from '@slices/slideInSectionSlice';

const rootReducer = combineSlices(
  toolbarSlice,
  modalSlice,
  conflictingModalSlice,
  deliveryPickerSlice,
  cartUpdatingSlice,
  miniCartSlice,
  menuSlice,
  checkoutSlice,
  loginSlice,
  slideInSectionSlice
);

export type RootState = ReturnType<typeof rootReducer>;

export const makeStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

// Infer the return type of `makeStore`
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, Action>;
