/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ArticleContentPageData } from '../dto';
// @ts-ignore
import { AxfoodNavigationNodeViewModel } from '../dto';
// @ts-ignore
import { CategoryNavigationNodeData } from '../dto';
// @ts-ignore
import { NewsItemData } from '../dto';
/**
 * ComponentApi - axios parameter creator
 * @export
 */
export const ComponentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get articles
         * @param {string} type type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesUsingGET: async (type: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getArticlesUsingGET', 'type', type)
            const localVarPath = `/axfood/rest/articles/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryNavigationNodeDataUsingGET: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getCategoryNavigationNodeDataUsingGET', 'componentId', componentId)
            const localVarPath = `/leftMenu/categories`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryNavigationNodeDataUsingGET1: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getCategoryNavigationNodeDataUsingGET1', 'componentId', componentId)
            const localVarPath = `/leftMenu/categories/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category navigation tree
         * @param {string} [storeId] storeId
         * @param {'ANDROID' | 'IOS' | 'OTHER'} [deviceType] deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryNavigationTreeUsingGET: async (storeId?: string, deviceType?: 'ANDROID' | 'IOS' | 'OTHER', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leftMenu/categorytree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['deviceType'] = deviceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get items for year
         * @param {string} yearUid yearUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsForYearUsingGET: async (yearUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearUid' is not null or undefined
            assertParamExists('getItemsForYearUsingGET', 'yearUid', yearUid)
            const localVarPath = `/axfood/rest/news/year/{yearUid}/items`
                .replace(`{${"yearUid"}}`, encodeURIComponent(String(yearUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get links navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinksNavigationNodeDataUsingGET: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getLinksNavigationNodeDataUsingGET', 'componentId', componentId)
            const localVarPath = `/leftMenu/links`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get links navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinksNavigationNodeDataUsingGET1: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getLinksNavigationNodeDataUsingGET1', 'componentId', componentId)
            const localVarPath = `/leftMenu/links/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get navigation data for the given component and page
         * @param {string} componentId componentId
         * @param {string} pageLabel pageLabel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationDataUsingGET: async (componentId: string, pageLabel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getNavigationDataUsingGET', 'componentId', componentId)
            // verify required parameter 'pageLabel' is not null or undefined
            assertParamExists('getNavigationDataUsingGET', 'pageLabel', pageLabel)
            const localVarPath = `/articleNavigationMenuComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (pageLabel !== undefined) {
                localVarQueryParameter['pageLabel'] = pageLabel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get packages for product banner
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagesForProductBannerUsingGET: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getPackagesForProductBannerUsingGET', 'componentId', componentId)
            const localVarPath = `/productBannerComponent/packages/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get packages for product banner
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagesForProductBannerUsingGET1: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getPackagesForProductBannerUsingGET1', 'componentId', componentId)
            const localVarPath = `/productCarouselComponent/packages/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pages for page banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagesForPagesBannerUsingGET: async (componentId: string, page?: string, size?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getPagesForPagesBannerUsingGET', 'componentId', componentId)
            const localVarPath = `/pageBannerListComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pages for page banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagesForPagesBannerUsingGET1: async (componentId: string, page?: string, size?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getPagesForPagesBannerUsingGET1', 'componentId', componentId)
            const localVarPath = `/genericPageBannerListComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for a buy all component
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForBuyAllBannerUsingGET: async (componentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getProductsForBuyAllBannerUsingGET', 'componentId', componentId)
            const localVarPath = `/buyAllProductsComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {string} [promotionStoreUid] promotionStoreUid
         * @param {string} [q] q
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProductBannerUsingGET: async (componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getProductsForProductBannerUsingGET', 'componentId', componentId)
            const localVarPath = `/productBannerComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (promotionStoreUid !== undefined) {
                localVarQueryParameter['promotionStoreUid'] = promotionStoreUid;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {string} [promotionStoreUid] promotionStoreUid
         * @param {string} [q] q
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProductBannerUsingGET1: async (componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getProductsForProductBannerUsingGET1', 'componentId', componentId)
            const localVarPath = `/productCarouselComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (promotionStoreUid !== undefined) {
                localVarQueryParameter['promotionStoreUid'] = promotionStoreUid;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProductBannerUsingGET2: async (componentId: string, page?: string, size?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getProductsForProductBannerUsingGET2', 'componentId', componentId)
            const localVarPath = `/twoTilesButtonAndProductBannerComponent/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComponentApi - functional programming interface
 * @export
 */
export const ComponentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get articles
         * @param {string} type type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticlesUsingGET(type: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticleContentPageData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticlesUsingGET(type, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get category navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryNavigationNodeDataUsingGET(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryNavigationNodeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryNavigationNodeDataUsingGET(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get category navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryNavigationNodeDataUsingGET1(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryNavigationNodeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryNavigationNodeDataUsingGET1(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get category navigation tree
         * @param {string} [storeId] storeId
         * @param {'ANDROID' | 'IOS' | 'OTHER'} [deviceType] deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryNavigationTreeUsingGET(storeId?: string, deviceType?: 'ANDROID' | 'IOS' | 'OTHER', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryNavigationTreeUsingGET(storeId, deviceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get items for year
         * @param {string} yearUid yearUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemsForYearUsingGET(yearUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsItemData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsForYearUsingGET(yearUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get links navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinksNavigationNodeDataUsingGET(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodNavigationNodeViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinksNavigationNodeDataUsingGET(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get links navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinksNavigationNodeDataUsingGET1(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodNavigationNodeViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinksNavigationNodeDataUsingGET1(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get navigation data for the given component and page
         * @param {string} componentId componentId
         * @param {string} pageLabel pageLabel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNavigationDataUsingGET(componentId: string, pageLabel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodNavigationNodeViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNavigationDataUsingGET(componentId, pageLabel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get packages for product banner
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagesForProductBannerUsingGET(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagesForProductBannerUsingGET(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get packages for product banner
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagesForProductBannerUsingGET1(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagesForProductBannerUsingGET1(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pages for page banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPagesForPagesBannerUsingGET(componentId: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPagesForPagesBannerUsingGET(componentId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pages for page banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPagesForPagesBannerUsingGET1(componentId: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPagesForPagesBannerUsingGET1(componentId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for a buy all component
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForBuyAllBannerUsingGET(componentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForBuyAllBannerUsingGET(componentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {string} [promotionStoreUid] promotionStoreUid
         * @param {string} [q] q
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForProductBannerUsingGET(componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForProductBannerUsingGET(componentId, page, size, promotionStoreUid, q, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {string} [promotionStoreUid] promotionStoreUid
         * @param {string} [q] q
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForProductBannerUsingGET1(componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForProductBannerUsingGET1(componentId, page, size, promotionStoreUid, q, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForProductBannerUsingGET2(componentId: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForProductBannerUsingGET2(componentId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComponentApi - factory interface
 * @export
 */
export const ComponentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComponentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get articles
         * @param {string} type type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesUsingGET(type: string, page?: number, size?: number, options?: any): AxiosPromise<Array<ArticleContentPageData>> {
            return localVarFp.getArticlesUsingGET(type, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get category navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryNavigationNodeDataUsingGET(componentId: string, options?: any): AxiosPromise<Array<CategoryNavigationNodeData>> {
            return localVarFp.getCategoryNavigationNodeDataUsingGET(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get category navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryNavigationNodeDataUsingGET1(componentId: string, options?: any): AxiosPromise<Array<CategoryNavigationNodeData>> {
            return localVarFp.getCategoryNavigationNodeDataUsingGET1(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get category navigation tree
         * @param {string} [storeId] storeId
         * @param {'ANDROID' | 'IOS' | 'OTHER'} [deviceType] deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryNavigationTreeUsingGET(storeId?: string, deviceType?: 'ANDROID' | 'IOS' | 'OTHER', options?: any): AxiosPromise<string> {
            return localVarFp.getCategoryNavigationTreeUsingGET(storeId, deviceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get items for year
         * @param {string} yearUid yearUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsForYearUsingGET(yearUid: string, options?: any): AxiosPromise<Array<NewsItemData>> {
            return localVarFp.getItemsForYearUsingGET(yearUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get links navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinksNavigationNodeDataUsingGET(componentId: string, options?: any): AxiosPromise<AxfoodNavigationNodeViewModel> {
            return localVarFp.getLinksNavigationNodeDataUsingGET(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get links navigation node data
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinksNavigationNodeDataUsingGET1(componentId: string, options?: any): AxiosPromise<AxfoodNavigationNodeViewModel> {
            return localVarFp.getLinksNavigationNodeDataUsingGET1(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get navigation data for the given component and page
         * @param {string} componentId componentId
         * @param {string} pageLabel pageLabel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationDataUsingGET(componentId: string, pageLabel: string, options?: any): AxiosPromise<AxfoodNavigationNodeViewModel> {
            return localVarFp.getNavigationDataUsingGET(componentId, pageLabel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get packages for product banner
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagesForProductBannerUsingGET(componentId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPackagesForProductBannerUsingGET(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get packages for product banner
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagesForProductBannerUsingGET1(componentId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPackagesForProductBannerUsingGET1(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pages for page banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagesForPagesBannerUsingGET(componentId: string, page?: string, size?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPagesForPagesBannerUsingGET(componentId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pages for page banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagesForPagesBannerUsingGET1(componentId: string, page?: string, size?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPagesForPagesBannerUsingGET1(componentId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for a buy all component
         * @param {string} componentId componentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForBuyAllBannerUsingGET(componentId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProductsForBuyAllBannerUsingGET(componentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {string} [promotionStoreUid] promotionStoreUid
         * @param {string} [q] q
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProductBannerUsingGET(componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProductsForProductBannerUsingGET(componentId, page, size, promotionStoreUid, q, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {string} [promotionStoreUid] promotionStoreUid
         * @param {string} [q] q
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProductBannerUsingGET1(componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProductsForProductBannerUsingGET1(componentId, page, size, promotionStoreUid, q, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for product banner
         * @param {string} componentId componentId
         * @param {string} [page] page
         * @param {string} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProductBannerUsingGET2(componentId: string, page?: string, size?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getProductsForProductBannerUsingGET2(componentId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComponentApi - object-oriented interface
 * @export
 * @class ComponentApi
 * @extends {BaseAPI}
 */
export class ComponentApi extends BaseAPI {
    /**
     * 
     * @summary Get articles
     * @param {string} type type
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getArticlesUsingGET(type: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getArticlesUsingGET(type, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get category navigation node data
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getCategoryNavigationNodeDataUsingGET(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getCategoryNavigationNodeDataUsingGET(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get category navigation node data
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getCategoryNavigationNodeDataUsingGET1(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getCategoryNavigationNodeDataUsingGET1(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get category navigation tree
     * @param {string} [storeId] storeId
     * @param {'ANDROID' | 'IOS' | 'OTHER'} [deviceType] deviceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getCategoryNavigationTreeUsingGET(storeId?: string, deviceType?: 'ANDROID' | 'IOS' | 'OTHER', options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getCategoryNavigationTreeUsingGET(storeId, deviceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get items for year
     * @param {string} yearUid yearUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getItemsForYearUsingGET(yearUid: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getItemsForYearUsingGET(yearUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get links navigation node data
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getLinksNavigationNodeDataUsingGET(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getLinksNavigationNodeDataUsingGET(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get links navigation node data
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getLinksNavigationNodeDataUsingGET1(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getLinksNavigationNodeDataUsingGET1(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get navigation data for the given component and page
     * @param {string} componentId componentId
     * @param {string} pageLabel pageLabel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getNavigationDataUsingGET(componentId: string, pageLabel: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getNavigationDataUsingGET(componentId, pageLabel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get packages for product banner
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getPackagesForProductBannerUsingGET(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getPackagesForProductBannerUsingGET(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get packages for product banner
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getPackagesForProductBannerUsingGET1(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getPackagesForProductBannerUsingGET1(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pages for page banner
     * @param {string} componentId componentId
     * @param {string} [page] page
     * @param {string} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getPagesForPagesBannerUsingGET(componentId: string, page?: string, size?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getPagesForPagesBannerUsingGET(componentId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pages for page banner
     * @param {string} componentId componentId
     * @param {string} [page] page
     * @param {string} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getPagesForPagesBannerUsingGET1(componentId: string, page?: string, size?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getPagesForPagesBannerUsingGET1(componentId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for a buy all component
     * @param {string} componentId componentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getProductsForBuyAllBannerUsingGET(componentId: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getProductsForBuyAllBannerUsingGET(componentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for product banner
     * @param {string} componentId componentId
     * @param {string} [page] page
     * @param {string} [size] size
     * @param {string} [promotionStoreUid] promotionStoreUid
     * @param {string} [q] q
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getProductsForProductBannerUsingGET(componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getProductsForProductBannerUsingGET(componentId, page, size, promotionStoreUid, q, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for product banner
     * @param {string} componentId componentId
     * @param {string} [page] page
     * @param {string} [size] size
     * @param {string} [promotionStoreUid] promotionStoreUid
     * @param {string} [q] q
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getProductsForProductBannerUsingGET1(componentId: string, page?: string, size?: string, promotionStoreUid?: string, q?: string, sort?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getProductsForProductBannerUsingGET1(componentId, page, size, promotionStoreUid, q, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for product banner
     * @param {string} componentId componentId
     * @param {string} [page] page
     * @param {string} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getProductsForProductBannerUsingGET2(componentId: string, page?: string, size?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getProductsForProductBannerUsingGET2(componentId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}
