import styled, { css } from 'styled-components';
import RoundButton from '@atoms/RoundButton/RoundButton';
import variables from '@styles/variables';
import { rem } from 'polished';
import InputField from '@atoms/InputField/InputField';
import Config from '@config';
import { saytToolbarPaddings } from '@organisms/SearchAsYouType/SearchAsYouTypeResults/SearchAsYouTypeResults.styles';
import Icon from '@atoms/Icon/Icon';
import OutsideClickWatcher from '@utility/OutsideClickWatcher/OutsideClickWatcher';

export const StyledSearchAsYouType = styled(OutsideClickWatcher)<{ adjustForToolbar: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  caret-color: ${variables.colors.highlight};
  position: relative;
  margin-right: auto;

  // Widths + margin on the input field (20 px)
  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(900 + saytToolbarPaddings.horizontal * 2)};
  }

  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    max-width: ${rem(1190 + saytToolbarPaddings.horizontal * 2)};
  }

  ${(props) =>
    props.adjustForToolbar &&
    css`
      padding: ${rem(8)} ${rem(20)};
    `}
`;

export const StyledSearchForm = styled.form`
  position: relative;
  flex: 1;
`;

export const StyledSearchButton = styled(RoundButton)<{ isSearchOpen: boolean }>`
  z-index: ${variables.zIndex[1]};

  > span {
    pointer-events: none;
  }

  ${(props) =>
    props.isSearchOpen &&
    css`
      margin-left: ${rem(10)};
    `}
`;

export const StyledSearchButtonIcon = styled(Icon)<{ isSearchOpen: boolean }>`
  ${(props) =>
    props.isSearchOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const StyledSearchContainer = styled.div<{ resultsVisible: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${variables.colors.white};
  border-radius: ${rem(22)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    ${(props) =>
      props.resultsVisible &&
      css`
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      `};
  }
`;

export const StyledSearchButtonSubmit = styled(RoundButton)`
  position: absolute;
  right: ${rem(4)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  > span {
    pointer-events: none;
  }
`;

export const StyledSearchField = styled(InputField)`
  height: ${rem(44)} !important;
  border: 0 none;
  padding-left: ${rem(20)};
  background-color: transparent;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    border-radius: 0 !important;
  }
`;

export const StyledSearchClearButton = styled.button<{ visible: boolean; shiftLeft: boolean }>`
  position: absolute;
  right: ${rem(9)};
  top: ${rem(8)};
  padding: ${rem(5)};
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-left: ${rem(-66)};
  z-index: 1;

  transform: scale(0);
  transition: transform 250ms ease-in-out;

  ${(props) =>
    props.shiftLeft &&
    css`
      right: ${rem(52)};
      top: ${rem(8)};
    `}

  ${(props) =>
    props.visible &&
    css`
      transform: scale(1);
    `}
`;
