/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddRecipeReplacementProducts } from '../dto';
// @ts-ignore
import { AddRecipeToCartRequestBody } from '../dto';
// @ts-ignore
import { AxfoodCartViewModel } from '../dto';
// @ts-ignore
import { AxfoodPurchasableRecipeInfoViewModel } from '../dto';
// @ts-ignore
import { AxfoodRecipeBasicInfoViewModel } from '../dto';
// @ts-ignore
import { ChangePortionsRequest } from '../dto';
// @ts-ignore
import { RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel } from '../dto';
// @ts-ignore
import { RemoveRecipeFromCartRequestBody } from '../dto';
/**
 * RecipeApi - axios parameter creator
 * @export
 */
export const RecipeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add entry to recipe cart
         * @param {string} productCode productCode
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEntryToRecipeCartUsingPOST: async (productCode: string, recipeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('addEntryToRecipeCartUsingPOST', 'productCode', productCode)
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('addEntryToRecipeCartUsingPOST', 'recipeId', recipeId)
            const localVarPath = `/axfood/rest/recipe/addEntryToRecipeCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (recipeId !== undefined) {
                localVarQueryParameter['recipeId'] = recipeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add recipe to cart
         * @param {AddRecipeToCartRequestBody} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipeToCartUsingPOST: async (body: AddRecipeToCartRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addRecipeToCartUsingPOST', 'body', body)
            const localVarPath = `/axfood/rest/recipe/addToCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add replacement products
         * @param {AddRecipeReplacementProducts} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReplacementProductUsingPOST: async (body: AddRecipeReplacementProducts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addReplacementProductUsingPOST', 'body', body)
            const localVarPath = `/axfood/rest/recipe/addReplacementProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change portions to recipe
         * @param {ChangePortionsRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePortionsUsingPATCH: async (request: ChangePortionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('changePortionsUsingPATCH', 'request', request)
            const localVarPath = `/axfood/rest/recipe/changePortions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find recipe replacement products
         * @param {string} recipeId recipeId
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRecipeReplacementProductsUsingGET: async (recipeId: string, productCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('findRecipeReplacementProductsUsingGET', 'recipeId', recipeId)
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('findRecipeReplacementProductsUsingGET', 'productCode', productCode)
            const localVarPath = `/axfood/rest/recipe/findRecipeReplacementProducts/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeUsingGET: async (recipeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('getRecipeUsingGET', 'recipeId', recipeId)
            const localVarPath = `/axfood/rest/recipe/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeUsingGET1: async (recipeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('getRecipeUsingGET1', 'recipeId', recipeId)
            const localVarPath = `/axfood/rest/recipe/preview/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get related recipes
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedRecipesUsingGET: async (recipeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('getRelatedRecipesUsingGET', 'recipeId', recipeId)
            const localVarPath = `/axfood/rest/recipe/getRelatedRecipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for recipes
         * @param {string} q The search query
         * @param {string} sort Field to use for sorting the results
         * @param {number} [page] Current page index. Used for pagination
         * @param {number} [size] Size of a single page of results.
         * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET: async (q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getSearchResultsUsingGET', 'q', q)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('getSearchResultsUsingGET', 'sort', sort)
            const localVarPath = `/search/recipe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove entry from recipe cart
         * @param {string} productCode productCode
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntryFromRecipeCartUsingPOST: async (productCode: string, recipeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('removeEntryFromRecipeCartUsingPOST', 'productCode', productCode)
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('removeEntryFromRecipeCartUsingPOST', 'recipeId', recipeId)
            const localVarPath = `/axfood/rest/recipe/removeEntryFromRecipeCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (recipeId !== undefined) {
                localVarQueryParameter['recipeId'] = recipeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove recipe from cart
         * @param {RemoveRecipeFromCartRequestBody} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRecipeFromCartUsingPOST: async (body: RemoveRecipeFromCartRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('removeRecipeFromCartUsingPOST', 'body', body)
            const localVarPath = `/axfood/rest/recipe/removeFromCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipeApi - functional programming interface
 * @export
 */
export const RecipeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add entry to recipe cart
         * @param {string} productCode productCode
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEntryToRecipeCartUsingPOST(productCode: string, recipeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPurchasableRecipeInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEntryToRecipeCartUsingPOST(productCode, recipeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add recipe to cart
         * @param {AddRecipeToCartRequestBody} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecipeToCartUsingPOST(body: AddRecipeToCartRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecipeToCartUsingPOST(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add replacement products
         * @param {AddRecipeReplacementProducts} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReplacementProductUsingPOST(body: AddRecipeReplacementProducts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPurchasableRecipeInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReplacementProductUsingPOST(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change portions to recipe
         * @param {ChangePortionsRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePortionsUsingPATCH(request: ChangePortionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPurchasableRecipeInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePortionsUsingPATCH(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find recipe replacement products
         * @param {string} recipeId recipeId
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRecipeReplacementProductsUsingGET(recipeId: string, productCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRecipeReplacementProductsUsingGET(recipeId, productCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeUsingGET(recipeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPurchasableRecipeInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeUsingGET(recipeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeUsingGET1(recipeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPurchasableRecipeInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeUsingGET1(recipeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get related recipes
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedRecipesUsingGET(recipeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodRecipeBasicInfoViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedRecipesUsingGET(recipeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for recipes
         * @param {string} q The search query
         * @param {string} sort Field to use for sorting the results
         * @param {number} [page] Current page index. Used for pagination
         * @param {number} [size] Size of a single page of results.
         * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchResultsUsingGET(q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchResultsUsingGET(q, sort, page, size, show, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove entry from recipe cart
         * @param {string} productCode productCode
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEntryFromRecipeCartUsingPOST(productCode: string, recipeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPurchasableRecipeInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEntryFromRecipeCartUsingPOST(productCode, recipeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove recipe from cart
         * @param {RemoveRecipeFromCartRequestBody} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRecipeFromCartUsingPOST(body: RemoveRecipeFromCartRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRecipeFromCartUsingPOST(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipeApi - factory interface
 * @export
 */
export const RecipeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipeApiFp(configuration)
    return {
        /**
         * 
         * @summary Add entry to recipe cart
         * @param {string} productCode productCode
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEntryToRecipeCartUsingPOST(productCode: string, recipeId: string, options?: any): AxiosPromise<AxfoodPurchasableRecipeInfoViewModel> {
            return localVarFp.addEntryToRecipeCartUsingPOST(productCode, recipeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add recipe to cart
         * @param {AddRecipeToCartRequestBody} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipeToCartUsingPOST(body: AddRecipeToCartRequestBody, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.addRecipeToCartUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add replacement products
         * @param {AddRecipeReplacementProducts} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReplacementProductUsingPOST(body: AddRecipeReplacementProducts, options?: any): AxiosPromise<AxfoodPurchasableRecipeInfoViewModel> {
            return localVarFp.addReplacementProductUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change portions to recipe
         * @param {ChangePortionsRequest} request request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePortionsUsingPATCH(request: ChangePortionsRequest, options?: any): AxiosPromise<AxfoodPurchasableRecipeInfoViewModel> {
            return localVarFp.changePortionsUsingPATCH(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find recipe replacement products
         * @param {string} recipeId recipeId
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRecipeReplacementProductsUsingGET(recipeId: string, productCode: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.findRecipeReplacementProductsUsingGET(recipeId, productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeUsingGET(recipeId: string, options?: any): AxiosPromise<AxfoodPurchasableRecipeInfoViewModel> {
            return localVarFp.getRecipeUsingGET(recipeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeUsingGET1(recipeId: string, options?: any): AxiosPromise<AxfoodPurchasableRecipeInfoViewModel> {
            return localVarFp.getRecipeUsingGET1(recipeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get related recipes
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedRecipesUsingGET(recipeId: string, options?: any): AxiosPromise<Array<AxfoodRecipeBasicInfoViewModel>> {
            return localVarFp.getRelatedRecipesUsingGET(recipeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for recipes
         * @param {string} q The search query
         * @param {string} sort Field to use for sorting the results
         * @param {number} [page] Current page index. Used for pagination
         * @param {number} [size] Size of a single page of results.
         * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET(q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', options?: any): AxiosPromise<RecipeSearchPageDataOfSearchStateDataAndAxfoodRecipeBasicInfoViewModel> {
            return localVarFp.getSearchResultsUsingGET(q, sort, page, size, show, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove entry from recipe cart
         * @param {string} productCode productCode
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntryFromRecipeCartUsingPOST(productCode: string, recipeId: string, options?: any): AxiosPromise<AxfoodPurchasableRecipeInfoViewModel> {
            return localVarFp.removeEntryFromRecipeCartUsingPOST(productCode, recipeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove recipe from cart
         * @param {RemoveRecipeFromCartRequestBody} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRecipeFromCartUsingPOST(body: RemoveRecipeFromCartRequestBody, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.removeRecipeFromCartUsingPOST(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipeApi - object-oriented interface
 * @export
 * @class RecipeApi
 * @extends {BaseAPI}
 */
export class RecipeApi extends BaseAPI {
    /**
     * 
     * @summary Add entry to recipe cart
     * @param {string} productCode productCode
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public addEntryToRecipeCartUsingPOST(productCode: string, recipeId: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).addEntryToRecipeCartUsingPOST(productCode, recipeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add recipe to cart
     * @param {AddRecipeToCartRequestBody} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public addRecipeToCartUsingPOST(body: AddRecipeToCartRequestBody, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).addRecipeToCartUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add replacement products
     * @param {AddRecipeReplacementProducts} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public addReplacementProductUsingPOST(body: AddRecipeReplacementProducts, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).addReplacementProductUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change portions to recipe
     * @param {ChangePortionsRequest} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public changePortionsUsingPATCH(request: ChangePortionsRequest, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).changePortionsUsingPATCH(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find recipe replacement products
     * @param {string} recipeId recipeId
     * @param {string} productCode productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public findRecipeReplacementProductsUsingGET(recipeId: string, productCode: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).findRecipeReplacementProductsUsingGET(recipeId, productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeUsingGET(recipeId: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeUsingGET(recipeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeUsingGET1(recipeId: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeUsingGET1(recipeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get related recipes
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRelatedRecipesUsingGET(recipeId: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRelatedRecipesUsingGET(recipeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for recipes
     * @param {string} q The search query
     * @param {string} sort Field to use for sorting the results
     * @param {number} [page] Current page index. Used for pagination
     * @param {number} [size] Size of a single page of results.
     * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getSearchResultsUsingGET(q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getSearchResultsUsingGET(q, sort, page, size, show, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove entry from recipe cart
     * @param {string} productCode productCode
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public removeEntryFromRecipeCartUsingPOST(productCode: string, recipeId: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).removeEntryFromRecipeCartUsingPOST(productCode, recipeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove recipe from cart
     * @param {RemoveRecipeFromCartRequestBody} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public removeRecipeFromCartUsingPOST(body: RemoveRecipeFromCartRequestBody, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).removeRecipeFromCartUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }
}
