import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledBackdrop = styled.div<{
  isOpen: boolean;
  hasChildren: boolean;
  mobileOnly: boolean;
  transparent: boolean;
  zIndex?: number;
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${(props) => (props.transparent ? 'transparent' : rgba(variables.colors.lighterBlack, 0.75))};
  opacity: ${(props) => (props.isOpen ? 0.75 : 0)};
  z-index: ${variables.zIndex.backdrop};

  ${(props) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
  transition: opacity ${variables.animation.normal};

  ${(props) =>
    props.hasChildren &&
    css`
      opacity: 1 !important;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    `}

  ${(props) =>
    props.mobileOnly &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        display: none;
      }
    `}
`;
