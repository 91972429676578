import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledAutoComplete = styled.div<{
  rounded?: boolean;
  scrollable?: boolean;
  showAsCards?: boolean;
}>`
  width: 100%;
  background-color: ${variables.colors.white};
  overflow: hidden;

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    box-shadow: 0 ${rem(8)} ${rem(20)} 0 rgba(0, 0, 0, 0.13);
  }

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 0 0 ${rem(22)} ${rem(22)};
    `}

  ${(props) =>
    props.scrollable &&
    css`
      overflow-y: auto;
    `}

  ${(props) =>
    props.showAsCards &&
    css`
      padding: 0 ${rem(20)};
    `}
`;

export const StyledAutoCompleteList = styled.ul`
  margin: 0;
  padding: ${rem(1)};
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
`;

export const StyledAutoCompleteListItem = styled.ul<{
  hoverable: boolean;
  focusable: boolean;
  showAsCards?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0 ${rem(16)};
  transition: background-color 0.15s linear;
  position: relative;

  ${(props) =>
    props.showAsCards &&
    css`
      border-radius: ${rem(4)};
      border: ${rem(1)} solid ${variables.colors.mediumGray};
    `}

  ${(props) =>
    !props.isDisabled &&
    (props.hoverable || props.focusable) &&
    css`
      cursor: pointer;

      &:hover,
      &:focus,
      &:focus-within,
      &[aria-selected='true'] {
        border: ${rem(2)} solid ${variables.colors.highlight};
        margin: ${rem(-1)};
        z-index: 1;
      }
    `}
`;
