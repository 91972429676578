import { ReactNode, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  selector: string;
  children: ReactNode;
}

const Portal = ({ children, selector }: Props) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById(selector);
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, ref.current as Element) : null;
};
export default Portal;
