import { useEffect, useState } from 'react';
import { getNavigationData } from '@api/interfaces/componentApi';
import { useRouter } from 'next/router';
import ArticleNavigationNode from '@organisms/ArticleNavigationMenu/ArticleNavigationNode';
import { StyledNavigationMenu } from './ArticleNavigationMenu.styles';
import useResponsive from '@hooks/useResponsive';
import { isAxiosError } from 'axios';
import logger from '@logger';

const article = '/artikel';

interface Props {
  articleUid: string;
}

const ArticleNavigationMenu = ({ articleUid }: Props) => {
  const router = useRouter();
  const [navigationData, setNavigationData] = useState<ArticleCMSNode>({} as ArticleCMSNode);
  const [activeNode, setActiveNode] = useState<ActiveNodeType>({ id: '', parents: [] });
  const [expandedNodes, setExpandedNodes] = useState<Array<NavigationNode>>([]);
  const [expandedNavigationNodes, setExpandedNavigationNodes] = useState<Array<NavigationNode>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { fromDesktop } = useResponsive();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navigation: any = await getNavigationData(articleUid, router.asPath.replace(article, ''));
        setNavigationData(navigation.data);
        setIsLoading(false);
      } catch (error) {
        if (isAxiosError(error)) {
          logger.error({ error: 'Failed to fetch article navigation menu', message: error.message });
        }
      }
    };
    if (Object.keys(navigationData).length === 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let continueExec = true;
  const getActiveNavigationNode = (node: ArticleCMSNode, parentNodes: NavigationNode[]) => {
    if (continueExec) {
      const currentHierarchy = parentNodes.concat({ id: node.title });

      const activeLink = node.links?.find((link: CMSLink) => {
        return link.url === router.asPath.split('?')[0];
      });

      if (activeLink) {
        continueExec = false;
        setActiveNode({ id: activeLink.title, parents: parentNodes });
        setExpandedNodes(currentHierarchy);
      } else {
        node?.children?.forEach((child) => getActiveNavigationNode(child, currentHierarchy));
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (navigationData?.children?.length > 0) {
        getActiveNavigationNode(navigationData, [] as NavigationNode[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, isLoading]);

  return fromDesktop ? (
    <StyledNavigationMenu data-testid="article-navigation-menu">
      {navigationData?.children?.length > 0 &&
        navigationData.children.map((child: ArticleCMSNode, index) => (
          <ArticleNavigationNode
            node={child}
            activeNode={activeNode}
            setActiveNode={setActiveNode}
            navigationNodes={expandedNavigationNodes}
            setNavigationNodes={setExpandedNavigationNodes}
            parentNodes={[]}
            setExpandedNodes={setExpandedNodes}
            expandedNodes={expandedNodes}
            key={`${child.title}-${index}`}
          />
        ))}
    </StyledNavigationMenu>
  ) : null;
};

export default ArticleNavigationMenu;
