import { convertProductToAnalyticsEcommerceItem } from './analyticsProduct';
import { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import type { AxfoodCartProductViewModel } from '@occ/api-client';

const trackCartModification = (
  value: number,
  previousQuantity: number,
  product: AxfoodCartProductViewModel,
  list: string
) => {
  const remove = previousQuantity > value;
  const parsedValue = +parseFloat(String(value)).toFixed(1);
  const quantity = remove
    ? previousQuantity - parsedValue
    : previousQuantity
    ? parsedValue - previousQuantity
    : parsedValue;
  const isPromotionPage =
    list === 'personal-campaigns-online' ||
    list === 'personal-campaigns-offline' ||
    list === 'generic-campaigns-online' ||
    list === 'generic-campaigns-offline';

  const listArr = isPromotionPage ? list.split('-') : [];
  const listName = isPromotionPage
    ? `promotion_page | ${listArr[2] === 'online' ? 'ecommerce' : 'store'} | ${listArr[0]}`
    : list;

  pushGTMEcommerceEvent({
    event: remove ? EcommerceEvent.REMOVE_FROM_CART : EcommerceEvent.ADD_TO_CART,
    ecommerce: { items: [convertProductToAnalyticsEcommerceItem(product, quantity, listName)] },
  });
};

export default trackCartModification;
