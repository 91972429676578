import styled from 'styled-components';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';

export const StyledCancelRemoveItem = styled(Button)`
  display: flex;
  align-items: center;
  gap: ${rem(6)};
  font-size: ${rem(13)};
`;
