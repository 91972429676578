/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodBasicStatusResponseViewModel } from '../dto';
// @ts-ignore
import { AxfoodRegisterB2BCustomerViewModel } from '../dto';
// @ts-ignore
import { AxfoodVerifyPhoneResponseViewModel } from '../dto';
// @ts-ignore
import { BonusBenefitInfoData } from '../dto';
// @ts-ignore
import { CompanySearchViewModel } from '../dto';
// @ts-ignore
import { CompleteMemberEmailFlowForm } from '../dto';
// @ts-ignore
import { CompleteMemberPhoneFlowForm } from '../dto';
// @ts-ignore
import { CustomerData } from '../dto';
// @ts-ignore
import { RegisterB2bCustomerForm } from '../dto';
// @ts-ignore
import { RegisterCustomerForm } from '../dto';
// @ts-ignore
import { SearchCompanyForm } from '../dto';
// @ts-ignore
import { SustainableData } from '../dto';
// @ts-ignore
import { VerifyMembersEmailFlowForm } from '../dto';
// @ts-ignore
import { VerifyMembersPhoneFlowForm } from '../dto';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary App react eligible
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appReactEligibleUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/app-react-eligible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register complete customer info
         * @param {CompleteMemberEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeCustomerInfoUsingPOST: async (form: CompleteMemberEmailFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('completeCustomerInfoUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/register/complete-customer-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register complete membership
         * @param {CompleteMemberPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMembershipUsingPOST: async (form: CompleteMemberPhoneFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('completeMembershipUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/register/complete-membership`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete agreement
         * @param {string} agreementId agreementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgreementUsingDELETE: async (agreementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('deleteAgreementUsingDELETE', 'agreementId', agreementId)
            const localVarPath = `/axfood/rest/customer/savedcard/{agreementId}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Data matrix code for customer ssn
         * @param {number} [width] width
         * @param {number} [height] height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDataMatrixCodeForCustomerSSNUsingGET: async (width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/data-matrix-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bonus benefit info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusBenefitInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/bonusBenefitInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session information for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionInformationUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sustainables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSustainablesUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/sustainables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup b2b customer
         * @param {SearchCompanyForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupB2BCustomerUsingPOST: async (form: SearchCompanyForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('lookupB2BCustomerUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/b2bcustomers/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Customer new program enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomerProgramEnabledUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/customer-newprogram-enabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Customer header footer design enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newHeaderFooterRedesignEnabledUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/customer-headerfooterredesign-enabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register additional subaccount
         * @param {string} email 
         * @param {string} socialSecurityNumber 
         * @param {string} [socialSecurityNumberKey] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAdditionalUsingPOST: async (email: string, socialSecurityNumber: string, socialSecurityNumberKey?: string, phone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('registerAdditionalUsingPOST', 'email', email)
            // verify required parameter 'socialSecurityNumber' is not null or undefined
            assertParamExists('registerAdditionalUsingPOST', 'socialSecurityNumber', socialSecurityNumber)
            const localVarPath = `/customers/subaccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (socialSecurityNumberKey !== undefined) {
                localVarQueryParameter['socialSecurityNumberKey'] = socialSecurityNumberKey;
            }


            if (phone !== undefined) { 
                localVarFormParams.set('phone', phone as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (socialSecurityNumber !== undefined) { 
                localVarFormParams.set('socialSecurityNumber', socialSecurityNumber as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register b2b customer
         * @param {RegisterB2bCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerB2BCustomerUsingPOST: async (form: RegisterB2bCustomerForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('registerB2BCustomerUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/b2bcustomers/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register customer
         * @param {RegisterCustomerForm} form form
         * @param {boolean} [autoLogin] autoLogin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCustomerUsingPOST: async (form: RegisterCustomerForm, autoLogin?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('registerCustomerUsingPOST', 'form', form)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (autoLogin !== undefined) {
                localVarQueryParameter['autoLogin'] = autoLogin;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register willys plus account
         * @param {string} [email] 
         * @param {string} [socialSecurityNumber] 
         * @param {string} [socialSecurityNumberKey] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWillysPlusAccountUsingPOST: async (email?: string, socialSecurityNumber?: string, socialSecurityNumberKey?: string, phone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/willysplus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (socialSecurityNumber !== undefined) {
                localVarQueryParameter['socialSecurityNumber'] = socialSecurityNumber;
            }

            if (socialSecurityNumberKey !== undefined) {
                localVarQueryParameter['socialSecurityNumberKey'] = socialSecurityNumberKey;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Scan an go eligible
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanAndGoEligibleUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer/scan-and-go-eligible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify email
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailUsingPOST: async (form: VerifyMembersEmailFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyEmailUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/lost-password/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify email
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailUsingPOST1: async (form: VerifyMembersEmailFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyEmailUsingPOST1', 'form', form)
            const localVarPath = `/axfood/rest/register/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify id in source system
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyIdInSourceSystemUsingPOST: async (form: VerifyMembersEmailFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyIdInSourceSystemUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/register/verify-membership-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify membership number
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMCNUsingPOST: async (form: VerifyMembersEmailFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyMCNUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/lost-password/verify-membership-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify phone
         * @param {VerifyMembersPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneUsingPOST: async (form: VerifyMembersPhoneFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyPhoneUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/lost-password/verify-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify phone
         * @param {VerifyMembersPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneUsingPOST1: async (form: VerifyMembersPhoneFlowForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyPhoneUsingPOST1', 'form', form)
            const localVarPath = `/axfood/rest/register/verify-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary App react eligible
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appReactEligibleUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appReactEligibleUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register complete customer info
         * @param {CompleteMemberEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeCustomerInfoUsingPOST(form: CompleteMemberEmailFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeCustomerInfoUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register complete membership
         * @param {CompleteMemberPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeMembershipUsingPOST(form: CompleteMemberPhoneFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeMembershipUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete agreement
         * @param {string} agreementId agreementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgreementUsingDELETE(agreementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgreementUsingDELETE(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Data matrix code for customer ssn
         * @param {number} [width] width
         * @param {number} [height] height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDataMatrixCodeForCustomerSSNUsingGET(width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDataMatrixCodeForCustomerSSNUsingGET(width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bonus benefit info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusBenefitInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BonusBenefitInfoData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusBenefitInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get session information for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionInformationUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionInformationUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sustainables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSustainablesUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SustainableData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSustainablesUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lookup b2b customer
         * @param {SearchCompanyForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupB2BCustomerUsingPOST(form: SearchCompanyForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySearchViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupB2BCustomerUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Customer new program enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newCustomerProgramEnabledUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newCustomerProgramEnabledUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Customer header footer design enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newHeaderFooterRedesignEnabledUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newHeaderFooterRedesignEnabledUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register additional subaccount
         * @param {string} email 
         * @param {string} socialSecurityNumber 
         * @param {string} [socialSecurityNumberKey] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAdditionalUsingPOST(email: string, socialSecurityNumber: string, socialSecurityNumberKey?: string, phone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAdditionalUsingPOST(email, socialSecurityNumber, socialSecurityNumberKey, phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register b2b customer
         * @param {RegisterB2bCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerB2BCustomerUsingPOST(form: RegisterB2bCustomerForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodRegisterB2BCustomerViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerB2BCustomerUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register customer
         * @param {RegisterCustomerForm} form form
         * @param {boolean} [autoLogin] autoLogin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCustomerUsingPOST(form: RegisterCustomerForm, autoLogin?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCustomerUsingPOST(form, autoLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register willys plus account
         * @param {string} [email] 
         * @param {string} [socialSecurityNumber] 
         * @param {string} [socialSecurityNumberKey] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerWillysPlusAccountUsingPOST(email?: string, socialSecurityNumber?: string, socialSecurityNumberKey?: string, phone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerWillysPlusAccountUsingPOST(email, socialSecurityNumber, socialSecurityNumberKey, phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Scan an go eligible
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanAndGoEligibleUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanAndGoEligibleUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify email
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmailUsingPOST(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodBasicStatusResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmailUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify email
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmailUsingPOST1(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmailUsingPOST1(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify id in source system
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyIdInSourceSystemUsingPOST(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyIdInSourceSystemUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify membership number
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyMCNUsingPOST(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodBasicStatusResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyMCNUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify phone
         * @param {VerifyMembersPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPhoneUsingPOST(form: VerifyMembersPhoneFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodVerifyPhoneResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPhoneUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify phone
         * @param {VerifyMembersPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPhoneUsingPOST1(form: VerifyMembersPhoneFlowForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodVerifyPhoneResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPhoneUsingPOST1(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary App react eligible
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appReactEligibleUsingGET(options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.appReactEligibleUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register complete customer info
         * @param {CompleteMemberEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeCustomerInfoUsingPOST(form: CompleteMemberEmailFlowForm, options?: any): AxiosPromise<object> {
            return localVarFp.completeCustomerInfoUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register complete membership
         * @param {CompleteMemberPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMembershipUsingPOST(form: CompleteMemberPhoneFlowForm, options?: any): AxiosPromise<object> {
            return localVarFp.completeMembershipUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete agreement
         * @param {string} agreementId agreementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgreementUsingDELETE(agreementId: string, options?: any): AxiosPromise<CustomerData> {
            return localVarFp.deleteAgreementUsingDELETE(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Data matrix code for customer ssn
         * @param {number} [width] width
         * @param {number} [height] height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDataMatrixCodeForCustomerSSNUsingGET(width?: number, height?: number, options?: any): AxiosPromise<string> {
            return localVarFp.generateDataMatrixCodeForCustomerSSNUsingGET(width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bonus benefit info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusBenefitInfoUsingGET(options?: any): AxiosPromise<Array<BonusBenefitInfoData>> {
            return localVarFp.getBonusBenefitInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get session information for customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionInformationUsingGET(options?: any): AxiosPromise<CustomerData> {
            return localVarFp.getSessionInformationUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sustainables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSustainablesUsingGET(options?: any): AxiosPromise<Array<SustainableData>> {
            return localVarFp.getSustainablesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup b2b customer
         * @param {SearchCompanyForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupB2BCustomerUsingPOST(form: SearchCompanyForm, options?: any): AxiosPromise<CompanySearchViewModel> {
            return localVarFp.lookupB2BCustomerUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Customer new program enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomerProgramEnabledUsingGET(options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.newCustomerProgramEnabledUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Customer header footer design enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newHeaderFooterRedesignEnabledUsingGET(options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.newHeaderFooterRedesignEnabledUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register additional subaccount
         * @param {string} email 
         * @param {string} socialSecurityNumber 
         * @param {string} [socialSecurityNumberKey] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAdditionalUsingPOST(email: string, socialSecurityNumber: string, socialSecurityNumberKey?: string, phone?: string, options?: any): AxiosPromise<object> {
            return localVarFp.registerAdditionalUsingPOST(email, socialSecurityNumber, socialSecurityNumberKey, phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register b2b customer
         * @param {RegisterB2bCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerB2BCustomerUsingPOST(form: RegisterB2bCustomerForm, options?: any): AxiosPromise<AxfoodRegisterB2BCustomerViewModel> {
            return localVarFp.registerB2BCustomerUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register customer
         * @param {RegisterCustomerForm} form form
         * @param {boolean} [autoLogin] autoLogin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCustomerUsingPOST(form: RegisterCustomerForm, autoLogin?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.registerCustomerUsingPOST(form, autoLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register willys plus account
         * @param {string} [email] 
         * @param {string} [socialSecurityNumber] 
         * @param {string} [socialSecurityNumberKey] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWillysPlusAccountUsingPOST(email?: string, socialSecurityNumber?: string, socialSecurityNumberKey?: string, phone?: string, options?: any): AxiosPromise<object> {
            return localVarFp.registerWillysPlusAccountUsingPOST(email, socialSecurityNumber, socialSecurityNumberKey, phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Scan an go eligible
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanAndGoEligibleUsingGET(options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.scanAndGoEligibleUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify email
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailUsingPOST(form: VerifyMembersEmailFlowForm, options?: any): AxiosPromise<AxfoodBasicStatusResponseViewModel> {
            return localVarFp.verifyEmailUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify email
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailUsingPOST1(form: VerifyMembersEmailFlowForm, options?: any): AxiosPromise<object> {
            return localVarFp.verifyEmailUsingPOST1(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify id in source system
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyIdInSourceSystemUsingPOST(form: VerifyMembersEmailFlowForm, options?: any): AxiosPromise<object> {
            return localVarFp.verifyIdInSourceSystemUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify membership number
         * @param {VerifyMembersEmailFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMCNUsingPOST(form: VerifyMembersEmailFlowForm, options?: any): AxiosPromise<AxfoodBasicStatusResponseViewModel> {
            return localVarFp.verifyMCNUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify phone
         * @param {VerifyMembersPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneUsingPOST(form: VerifyMembersPhoneFlowForm, options?: any): AxiosPromise<AxfoodVerifyPhoneResponseViewModel> {
            return localVarFp.verifyPhoneUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify phone
         * @param {VerifyMembersPhoneFlowForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPhoneUsingPOST1(form: VerifyMembersPhoneFlowForm, options?: any): AxiosPromise<AxfoodVerifyPhoneResponseViewModel> {
            return localVarFp.verifyPhoneUsingPOST1(form, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary App react eligible
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public appReactEligibleUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).appReactEligibleUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register complete customer info
     * @param {CompleteMemberEmailFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public completeCustomerInfoUsingPOST(form: CompleteMemberEmailFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).completeCustomerInfoUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register complete membership
     * @param {CompleteMemberPhoneFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public completeMembershipUsingPOST(form: CompleteMemberPhoneFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).completeMembershipUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete agreement
     * @param {string} agreementId agreementId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteAgreementUsingDELETE(agreementId: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteAgreementUsingDELETE(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Data matrix code for customer ssn
     * @param {number} [width] width
     * @param {number} [height] height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public generateDataMatrixCodeForCustomerSSNUsingGET(width?: number, height?: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).generateDataMatrixCodeForCustomerSSNUsingGET(width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bonus benefit info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getBonusBenefitInfoUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getBonusBenefitInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get session information for customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getSessionInformationUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getSessionInformationUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sustainables
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getSustainablesUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getSustainablesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup b2b customer
     * @param {SearchCompanyForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public lookupB2BCustomerUsingPOST(form: SearchCompanyForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).lookupB2BCustomerUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Customer new program enabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public newCustomerProgramEnabledUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).newCustomerProgramEnabledUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Customer header footer design enabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public newHeaderFooterRedesignEnabledUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).newHeaderFooterRedesignEnabledUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register additional subaccount
     * @param {string} email 
     * @param {string} socialSecurityNumber 
     * @param {string} [socialSecurityNumberKey] 
     * @param {string} [phone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public registerAdditionalUsingPOST(email: string, socialSecurityNumber: string, socialSecurityNumberKey?: string, phone?: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).registerAdditionalUsingPOST(email, socialSecurityNumber, socialSecurityNumberKey, phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register b2b customer
     * @param {RegisterB2bCustomerForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public registerB2BCustomerUsingPOST(form: RegisterB2bCustomerForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).registerB2BCustomerUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register customer
     * @param {RegisterCustomerForm} form form
     * @param {boolean} [autoLogin] autoLogin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public registerCustomerUsingPOST(form: RegisterCustomerForm, autoLogin?: boolean, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).registerCustomerUsingPOST(form, autoLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register willys plus account
     * @param {string} [email] 
     * @param {string} [socialSecurityNumber] 
     * @param {string} [socialSecurityNumberKey] 
     * @param {string} [phone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public registerWillysPlusAccountUsingPOST(email?: string, socialSecurityNumber?: string, socialSecurityNumberKey?: string, phone?: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).registerWillysPlusAccountUsingPOST(email, socialSecurityNumber, socialSecurityNumberKey, phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Scan an go eligible
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public scanAndGoEligibleUsingGET(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).scanAndGoEligibleUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify email
     * @param {VerifyMembersEmailFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public verifyEmailUsingPOST(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).verifyEmailUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify email
     * @param {VerifyMembersEmailFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public verifyEmailUsingPOST1(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).verifyEmailUsingPOST1(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify id in source system
     * @param {VerifyMembersEmailFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public verifyIdInSourceSystemUsingPOST(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).verifyIdInSourceSystemUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify membership number
     * @param {VerifyMembersEmailFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public verifyMCNUsingPOST(form: VerifyMembersEmailFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).verifyMCNUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify phone
     * @param {VerifyMembersPhoneFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public verifyPhoneUsingPOST(form: VerifyMembersPhoneFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).verifyPhoneUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify phone
     * @param {VerifyMembersPhoneFlowForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public verifyPhoneUsingPOST1(form: VerifyMembersPhoneFlowForm, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).verifyPhoneUsingPOST1(form, options).then((request) => request(this.axios, this.basePath));
    }
}
