import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css, keyframes } from 'styled-components';

const show = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: ${rem(999)};
  }
`;

const hide = keyframes`
  from {
    max-height: ${rem(999)};
  }
  to {
    max-height: 0;
  }
`;

export const StyledMixMatchBeam = styled.div<{ show: boolean; hide: boolean }>`
  margin: ${rem(20)} 0 ${rem(40)} 0;
  position: relative;
  width: calc(100% - ${rem(1)});
  background-color: ${variables.colors.white};

  ${(props) =>
    props.show &&
    css`
      animation: ${show} ease-out 0.7s forwards;
    `}

  ${(props) =>
    props.hide &&
    css`
      animation: ${hide} ease-in 0.7s forwards;
    `}
`;

export const StyledMixMatchBeamArrowContainer = styled.div`
  position: relative;
`;

export const StyledMixMatchBeamArrow = styled.span`
  width: ${rem(20)};
  height: ${rem(20)};
  background-color: ${variables.colors.white};
  border-left: ${rem(1)} solid ${variables.colors.mediumGray};
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  position: absolute;
  top: ${rem(-10)};
  transform: translateX(-50%) rotate(45deg);
`;

export const StyledProductDummy = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${variables.colors.white};
  width: calc(100% - ${rem(1)});
`;
