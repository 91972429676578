import Config from '../config';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import TagManager from 'react-gtm-module';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, title: string) => {
  pushGTMEvent({
    event: 'Track',
    category: '',
    action: 'page_view',
    label: '',
    value: 0,
    page_title: title,
    page_path: url,
  });
};

export const initializeGTM = (gtmId: string) => {
  TagManager.initialize({ gtmId });
};

export default `window.dataLayer = window.dataLayer || []; function gtag() { window.dataLayer.push(arguments); }`;
