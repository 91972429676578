import Button from '@atoms/Button/Button';
import Config from '@config';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import AnimateHeight from 'react-animate-height';
import styled, { css } from 'styled-components';

export const StyledExandableContainer = styled(AnimateHeight)<{ $faded: boolean }>`
  position: relative;
  padding: 0 ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(0)};
  }

  ${(props) =>
    props.$faded &&
    css`
      &::after {
        width: 100%;
        height: 60%;
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        background: linear-gradient(to bottom, ${rgba(variables.colors.white, 0)} 0%, ${variables.colors.white} 100%);
      }
    `}
`;

export const StyledExandableContainerButton = styled(Button)`
  font-size: ${rem(15)};
  margin-top: ${rem(25)};
  padding: 0 ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(0)};
  }
`;
