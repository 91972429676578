import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import variables from '@styles/variables';
import CmsContentSlot from '@atoms/CmsContentSlot/CmsContentSlot';

export const StyledContentWrapper = styled.div<{ stackElements: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  margin-bottom: ${rem(30)};

  ${Config.MEDIA.FROM_DESKTOP} {
    flex-direction: row;
    gap: ${rem(120)};
    justify-content: center;
    margin-bottom: ${rem(70)};
  }

  ${(props) =>
    props.stackElements &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        flex-direction: column;
        gap: 0;
        justify-content: flex-start;
      }

      ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
        justify-content: center;
      }
    `}
`;

export const StyledRightContentWrapper = styled.div<{ menuCartOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(40)};
  width: 100%;

  ${(props) =>
    !props.menuCartOpen &&
    css`
      ${Config.MEDIA.FROM_DESKTOP_LARGE} {
        min-width: ${rem(250)};
        max-width: ${rem(250)};
        width: auto;
        padding-left: ${rem(40)};
        border-left: ${rem(1)} solid ${variables.colors.mediumGray};
      }
    `}
`;

export const StyledMainContentWrapper = styled.div<{ hasRightContent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(40)};

  ${(props) =>
    props.hasRightContent &&
    css`
      ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
        max-width: ${rem(720)};
      }
    `}
`;

export const StyledSlot = styled(CmsContentSlot)`
  width: 100%;

  img {
    max-width: 100%;
  }
`;
