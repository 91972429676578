import HorizontalBannerComponentSkeleton from '@molecules/HorizontalBannerComponent/HorizontalBannerComponent.skeleton';
import ProductBeamSkeleton from '@molecules/ProductBeam/ProductBeam.skeleton';
import TwoTilesComponentSkeleton from '@molecules/TwoTilesComponent/TwoTilesComponent.skeleton';

interface Props {
  template: string;
}
const SkeletonComponent = ({ template }: Props) => {
  const skeletonTemplates = {
    HorizontalBannerComponent: <HorizontalBannerComponentSkeleton />,
    AxfoodProductBannerComponent: <ProductBeamSkeleton />,
    TwoTilesButtonAndProductBannerComponent: <TwoTilesComponentSkeleton />,
  };

  return skeletonTemplates[template as keyof typeof skeletonTemplates] || <HorizontalBannerComponentSkeleton />;
};

export default SkeletonComponent;
