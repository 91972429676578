import { StyledHotJarStyleChanger } from './HotJarStyleChanger.styles';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import useResponsive from '@hooks/useResponsive';
import { selectSlideInSectionIsOpen } from '@slices/slideInSectionSlice';

const HotJarStyleChanger = () => {
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSlideInSectionOpen = useAppSelector(selectSlideInSectionIsOpen);
  const { fromDesktop } = useResponsive();

  return <StyledHotJarStyleChanger hide={fromDesktop && (isCartPreviewOpen || isSlideInSectionOpen)} />;
};

export default HotJarStyleChanger;
