import useSWR from 'swr';
import { getOnlineAndOfflineVouchers } from '@api/interfaces/voucherApi';
import { mergeVouchers } from '@helpers/mergeVouchers';
import useCustomer from '@hooks/useCustomer';

/**
 * Fetcher function for SWR
 */
const fetcher = async () => {
  const data = await getOnlineAndOfflineVouchers();

  const onlineVouchers = mergeVouchers(data?.online);
  const offlineVouchers = mergeVouchers(data?.offline);

  return { onlineVouchers, offlineVouchers };
};

/**
 * SWR hook for vouchers
 */
const useVouchers = (isPreviewMode?: boolean) => {
  const { customer } = useCustomer();
  const shouldFetch = Boolean(customer) && !isPreviewMode;
  const key = isPreviewMode ? null : { endpoint: '/axfood/rest/allexternalvouchers', customerId: customer?.uid };
  const { data, isLoading, mutate: refreshVouchers } = useSWR(shouldFetch ? key : null, fetcher);

  return {
    onlineVouchers: data?.onlineVouchers,
    offlineVouchers: data?.offlineVouchers,
    isLoading,
    refreshVouchers,
  };
};

export default useVouchers;
