const paddingFromCms = (data: any): Padding => {
  const getNumberFromPaddingValue = (where: 'paddingTop' | 'paddingRight' | 'paddingBottom' | 'paddingLeft') =>
    data?.[where]?.split('_')[1];
  return {
    top: getNumberFromPaddingValue('paddingTop'),
    right: getNumberFromPaddingValue('paddingRight'),
    bottom: getNumberFromPaddingValue('paddingBottom'),
    left: getNumberFromPaddingValue('paddingLeft'),
  };
};

export default paddingFromCms;
