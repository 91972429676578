import { StyledButton, StyledNoResultsText } from './SearchAsYouTypeEditorialSuggestion.styles';
import { SearchSuggestionData } from '@occ/api-client';
import Link from 'next/link';
import { useEffect } from 'react';
import { trackEditorialSuggestion, trackEditorialSuggestionClick } from '@helpers/analyticsHelpers/trackSearchAsYouType';

export type SearchAsYouTypeEditorialSuggestionProps = {
  suggestion: SearchSuggestionData;
  searchTerm: string;
};

const SearchAsYouTypeEditorialSuggestion = ({ suggestion, searchTerm }: SearchAsYouTypeEditorialSuggestionProps) => {
  const trackClickedSuggestion = () => {
    if (searchTerm) {
      trackEditorialSuggestionClick(searchTerm);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      trackEditorialSuggestion(searchTerm);
    }
  }, [searchTerm]);

  return (
    <>
      <StyledNoResultsText type="body" align="center">
        {suggestion.text}
      </StyledNoResultsText>

      {suggestion.linkUrl && suggestion.linkText && (
        <Link href={suggestion.linkUrl || ''}>
          <StyledButton onClick={() => trackClickedSuggestion()}>{suggestion.linkText}</StyledButton>
        </Link>
      )}
    </>
  );
};

export default SearchAsYouTypeEditorialSuggestion;
