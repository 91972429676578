import type { AxfoodBasicProductViewModel } from '@occ/api-client';
import { useMemo, useState } from 'react';

const IMAGE_PATH = '/images/NO-PRODUCT-IMAGE.svg';

export const useProductImage = (product?: Pick<AxfoodBasicProductViewModel, 'image'>) => {
  const [imageLoadingError, setImageLoadingError] = useState(false);

  const productImageURL = useMemo(() => {
    if (imageLoadingError || !product?.image?.url) {
      return IMAGE_PATH;
    }

    return product.image.url;
  }, [product, imageLoadingError]);

  const productImageAltText = useMemo(() => {
    if (!product?.image?.altText) {
      return '';
    }

    return product.image.altText;
  }, [product]);

  const onImageLoadError = () => {
    setImageLoadingError(true);
  };

  return { productImageURL, productImageAltText, onImageLoadError };
};
