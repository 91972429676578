/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeatureToggle } from '../dto';
/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/feature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get feature toggle
         * @param {string} featureName featureName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureToggleUsingGET: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('getFeatureToggleUsingGET', 'featureName', featureName)
            const localVarPath = `/axfood/rest/feature/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get features for a service
         * @param {string} serviceName serviceName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesForServiceUsingGET: async (serviceName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('getFeaturesForServiceUsingGET', 'serviceName', serviceName)
            const localVarPath = `/axfood/rest/feature/service/{serviceName}`
                .replace(`{${"serviceName"}}`, encodeURIComponent(String(serviceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featuresUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureToggle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featuresUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get feature toggle
         * @param {string} featureName featureName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureToggleUsingGET(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureToggle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureToggleUsingGET(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get features for a service
         * @param {string} serviceName serviceName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturesForServiceUsingGET(serviceName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureToggle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesForServiceUsingGET(serviceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * 
         * @summary Get features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresUsingGET(options?: any): AxiosPromise<Array<FeatureToggle>> {
            return localVarFp.featuresUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get feature toggle
         * @param {string} featureName featureName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureToggleUsingGET(featureName: string, options?: any): AxiosPromise<FeatureToggle> {
            return localVarFp.getFeatureToggleUsingGET(featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get features for a service
         * @param {string} serviceName serviceName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesForServiceUsingGET(serviceName: string, options?: any): AxiosPromise<Array<FeatureToggle>> {
            return localVarFp.getFeaturesForServiceUsingGET(serviceName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI {
    /**
     * 
     * @summary Get features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public featuresUsingGET(options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).featuresUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get feature toggle
     * @param {string} featureName featureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureToggleUsingGET(featureName: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeatureToggleUsingGET(featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get features for a service
     * @param {string} serviceName serviceName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeaturesForServiceUsingGET(serviceName: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeaturesForServiceUsingGET(serviceName, options).then((request) => request(this.axios, this.basePath));
    }
}
