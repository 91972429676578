import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Head from 'next/head';
import type { PageWithSlots } from '@api/interfaces/pageApi';
import paths from '@constants/paths';
import { useRouter } from 'next/router';

interface Props {
  cmsPage?: PageWithSlots;
}

// Skip rendering title and description for these paths
// They have their own title and description
const skipRenderPaths = [paths.CATEGORY, paths.PDP];

const CMSHead = ({ cmsPage }: Props) => {
  const { t } = useTranslation('metadata');
  const title = `${cmsPage?.title ? cmsPage.title : t('metadata->title')} ${t('metadata->titleSuffix')}`;
  const description = `${cmsPage?.description ? cmsPage.description : t('metadata->description')}`;
  const router = useRouter();

  // Do not render title and description if current path starts with any of the paths in skipRenderPaths
  if (skipRenderPaths.some((path) => router.pathname.startsWith(path))) {
    return null;
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Head>
  );
};

export default CMSHead;
