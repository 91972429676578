import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackPersonalElementsOrderOnClick = (status: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'personal_widget',
    action: `${status}_order`,
    label: '',
    value: 0,
  });
};

export const trackPersonalElementsReceiptOnClick = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'personal_widget',
    action: 'see_latest_receipt',
    label: '',
    value: 0,
  });
};

export const trackPersonalElementsVouchersOnClick = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'personal_widget',
    action: 'show_voucher_code',
    label: '',
    value: 0,
  });
};
