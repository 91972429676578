import useSWRImmutable from 'swr/immutable';
import { getAvailableStores } from '@api/interfaces/storeApi';

function useAvailableStores() {
  const { data, isLoading, error } = useSWRImmutable('rest/store', () => getAvailableStores());

  return {
    allStores: data,
  };
}

export default useAvailableStores;
