import styled from 'styled-components';
import Link from '@atoms/Link/Link';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledBreadcrumb = styled(Link)`
  &:not(a) {
    color: ${variables.colors.lighterBlack};
  }

  display: inline-flex;
  font-size: ${rem(13)};
  gap: ${rem(8)};
  line-height: ${rem(20)};
  align-items: center;
  flex: 1 auto;
  margin-right: ${rem(8)};
`;
