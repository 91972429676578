import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import { StyledSpinner } from './Spinner.styles';
import { useEffect, useState } from 'react';
import LottiePlayer from '@molecules/LottiePlayer/LottiePlayer';

export type SpinnerSizeType = 32 | 20 | 16;
type SpinnerColorType = 'black' | 'primary' | 'white';

interface Props {
  color?: SpinnerColorType;
  className?: string;
  title?: string;
  size?: SpinnerSizeType;
}

const Spinner = ({ color = 'black', className, title, size = 20 }: Props) => {
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    (async () => {
      const fileName = `loader_${size}px_${color}.json`;
      const loadedAnimation = await import(`./animations/${fileName}`);
      setAnimation(loadedAnimation);
    })();
  }, [size, color]);

  return (
    <>
      <StyledSpinner data-testid="spinner" className={className} size={size}>
        {animation && <LottiePlayer animationData={animation} data-testid="spinner-icon" />}
      </StyledSpinner>

      {title && <ScreenReaderMessage>{title}</ScreenReaderMessage>}
    </>
  );
};

export default Spinner;
