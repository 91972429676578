import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledGrid = styled.div<{ isPdp: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 ${rem(variables.size.containerPaddingNegative)} ${rem(16)};
  max-width: calc(100% + ${rem(2 * variables.size.containerPadding)});

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto ${rem(26)};
    max-width: 100%;
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(204)}, 1fr));
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(202)}, 1fr));
  }

  ${(props) =>
    props.isPdp === true &&
    css`
      margin: 0 0 ${rem(26)} 0;

      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        grid-template-columns: repeat(4, 1fr);
      }
    `}
`;
