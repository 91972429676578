import styled, { css } from 'styled-components';
import Config from '@config';
import variables from '@styles/variables';
import { rem } from 'polished';
import { ProductProps } from '@components/molecules/Product/Product';

type StyledProductProps = Pick<ProductProps, 'variant' | 'offline'>;
export const StyledProduct = styled.div<StyledProductProps>`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${variables.colors.white};
  max-width: calc(100% - ${rem(1)});
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  margin: ${rem(-1)} 0 0 ${rem(-1)};
  box-sizing: content-box;

  ${(props) =>
    (props.variant === 'mixmatch' || props.variant === 'multisearch') &&
    css`
      margin-top: 0;
    `}

  ${(props) =>
    props.variant === 'multisearch' &&
    css`
      &:first-child {
        margin-left: 0;
      }
    `}
`;

export const StyledProductLinkWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledProductHeader = styled.div`
  position: relative;
  padding: ${rem(12)} ${rem(20)} ${rem(12)} ${rem(28)};

  ${Config.MEDIA.IS_MOBILE_SMALL} {
    padding: ${rem(28)} ${rem(20)} ${rem(28)} ${rem(28)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(32)} ${rem(24)} ${rem(32)} ${rem(28)};
  }
`;

export const StyledLowestHistoricalPrice = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  color: ${variables.colors.darkerGray};
`;

export const StyledProductFooter = styled.div`
  font-size: ${rem(13)};
  justify-content: space-between;
  width: 100%;
  padding: ${rem(12)} ${rem(12)} ${rem(12)} 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(20)} ${rem(16)} ${rem(20)} 0;
  }
`;

export const StyledProductFooterBottomSection = styled.div`
  color: ${variables.colors.darkerGray};
  font-size: ${rem(11)};
  line-height: ${rem(14)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${Config.MEDIA.IS_MOBILE_SMALL} {
    align-self: flex-start;
  }
`;

export const StyledProductName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  line-height: ${rem(20)};
  font-size: ${rem(13)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    font-size: ${rem(15)};
  }
`;

export const StyledProductManufacturerVolumeWrapper = styled.div`
  display: table;
  width: 100%;
  line-height: ${rem(16)};
  font-size: ${rem(11)};
  text-transform: uppercase;
  color: ${variables.colors.darkerGray};
  flex-grow: 1;

  &:before {
    display: table-column;
    width: 100%;
    content: '';
  }
`;

export const StyledProductManufacturerBrand = styled.span`
  display: table-cell;
  max-width: ${rem(1)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledProductPriceWrapper = styled.div`
  display: block;
  position: absolute;
  right: ${rem(12)};
  top: ${rem(12)};

  ${Config.MEDIA.FROM_DESKTOP} {
    transform: scale(0.8);
    transform-origin: right;
  }
`;

export const StyledProductSavePrice = styled.div`
  background: ${variables.colors.lightGray};
  border-radius: ${rem(2)};
  direction: ltr;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(8)};
  line-height: ${rem(8)};
  min-width: 100%;
  margin-bottom: ${rem(2)};
  padding: ${rem(2)};
  position: static;
  text-align: center;
  text-transform: uppercase;
  top: ${rem(0)};
  unicode-bidi: embed;
  white-space: nowrap;

  ${Config.MEDIA.FROM_DESKTOP} {
    font-size: ${rem(10)};
    line-height: ${rem(10)};
  }
`;

export const StyledProductPromotionPriceInfo = styled.div`
  color: ${variables.colors.primary};
`;

export const StyledProductOriginalPrice = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  color: ${variables.colors.darkerGray};
`;

export const StyledProductComparePrice = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  color: ${variables.colors.darkerGray};
`;

export const StyledFooterCTAWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${Config.MEDIA.IS_MOBILE_SMALL} {
    flex-direction: column;
    gap: ${rem(4)};
    margin-top: ${rem(4)};
  }
`;
