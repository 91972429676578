import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';
import Link from '@atoms/Link/Link';

export const StyledSnackBar = styled.div<{ slideIn: boolean | null; bottomPosition?: number }>`
  background-color: ${variables.colors.black};
  color: ${variables.colors.white};
  padding: ${rem(20)};
  height: ${rem(62)};
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  transition: transform 0.5s ease;
  z-index: ${variables.zIndex.modal + 1};
  box-shadow: 0 ${rem(8)} ${rem(28)} 0 rgba(0, 0, 0, 0.5);
  bottom: ${(props) => (props.bottomPosition ? rem(props.bottomPosition) : rem(20))};
  right: ${rem(20)};
  transform: translateX(calc(100% + ${rem(20)}));
  width: calc(100% - ${rem(40)});

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    bottom: ${rem(24)};
    right: ${rem(24)};
    transform: translateX(calc(100% + ${rem(24)}));
    width: auto;
  }

  ${(props) =>
    props.slideIn &&
    css`
      transform: translateX(0) !important;
    `}
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
`;

export const StyledLink = styled(Link)`
  font-size: ${rem(13)};
  color: ${variables.colors.white} !important;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-left: ${rem(105)};
  }
`;
