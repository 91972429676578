import { css } from 'styled-components';
import { SizeTypes, ThemeTypes } from '@molecules/AddToCart/AddToCart';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const CommonWrapperStyle = css<{
  disabled: boolean;
  hasItems: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
}>`
  display: flex;
  align-items: center;
  border-radius: ${rem(22)};
  padding: ${rem(4)};

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
`;

export const PrimaryWrapperStyle = css<{
  disabled: boolean;
  hasItems: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
}>`
  ${CommonWrapperStyle};
  background-color: ${variables.colors.lighterGray};
  ${(props) =>
    props.hasItems &&
    css`
      background-color: ${variables.colors.primary};
      color: ${variables.colors.white};

      ${props.disabled &&
      css`
        background-color: ${variables.colors.primary};
      `}
    `}
`;

export const SecondaryWrapperStyle = css<{
  disabled: boolean;
  hasItems: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
}>`
  ${CommonWrapperStyle};
  min-width: ${rem(104)};
  width: ${rem(104)};
  padding: 0;
  ${(props) =>
    props.size !== 'small' &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        min-width: ${rem(114)};
        width: ${rem(114)};
      }
    `}
`;

export const WishlistWrapperStyle = css<{
  disabled: boolean;
  hasItems: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
}>`
  ${CommonWrapperStyle};
  min-width: ${rem(106)};
  width: ${rem(106)};
  padding: 0;
  ${(props) =>
    props.size !== 'small' &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        min-width: ${rem(114)};
        width: ${rem(114)};
      }
    `}
`;
