import { useEffect, useState } from 'react';
import paths from '@constants/paths';
import { updateCanonicalLink } from '@helpers/analyticsHelpers/canonicalUrlBuilder';
import useCustomRouter from '@hooks/useCustomRouter';

interface Props {
  setCanonicalLink: (value: string) => void;
  setIsCheckout: (value: boolean) => void;
  setIsSharedWishList: (value: boolean) => void;
}
const useProductDetailsRouting = ({ setCanonicalLink, setIsSharedWishList, setIsCheckout }: Props) => {
  const [productDetailVisibility, setProductDetailVisibility] = useState(false);
  const router = useCustomRouter();

  useEffect(() => {
    if (
      (router.asPath.startsWith(paths.PDP) ||
        (router.asPath.startsWith(paths.PDP_OFFLINE) && router.query?.name) ||
        (router.asPath.startsWith(paths.PDP_PREVIEW) && router.query?.name) ||
        (router.asPath.startsWith(paths.PDP_ONLINE) && router.query?.name)) &&
      router.query.showInModal === 'true'
    ) {
      setProductDetailVisibility(true);
    } else if (productDetailVisibility) {
      setProductDetailVisibility(false);
    }

    setCanonicalLink(updateCanonicalLink(window.location.origin, router.asPath));

    setIsCheckout(
      ((router?.query?.from === paths.CHECKOUT || document.referrer.includes(paths.CHECKOUT)) &&
        router.asPath.startsWith(paths.USER_LOGIN)) ||
        router.asPath.startsWith(paths.CHECKOUT)
    );
    setIsSharedWishList(router.asPath.startsWith(paths.SHARED_LIST));
  }, [router.asPath]);

  return { productDetailVisibility };
};

export default useProductDetailsRouting;
