import React from 'react';
import Skeleton from '@components/atoms/Skeleton/Skeleton';
import { SkeletonWrapper } from './HorizontalBannerComponent.skeleton.styles';

const HorizontalBannerComponentSkeleton = () => {
  return (
    <SkeletonWrapper data-testid="horizontalbanner-skeleton">
      <Skeleton type="rect" height={310} />
    </SkeletonWrapper>
  );
};

export default HorizontalBannerComponentSkeleton;
