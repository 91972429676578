import { useImpressionTracker } from 'impression-tracker-react-hook';

interface Props {
  trackingObject: { logImpression: () => void; handleError: (error: Error) => void; contentId: string };
}

const ClientsideTrackingComponent = ({ trackingObject }: Props) => {
  const { logImpression, handleError, contentId } = trackingObject;

  const [ref] = useImpressionTracker({ logImpression, handleError, contentId });

  return <div ref={ref} />;
};

export default ClientsideTrackingComponent;
