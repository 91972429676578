import { useEffect } from 'react';
import Alert from '@molecules/Alert/Alert';
import { IconColorType } from '@atoms/Icon/Icon';
import { trackGlobalMessage } from '@helpers/analyticsHelpers/trackGlobalMessage';
import { setGlobalMessageDisplayState } from '@helpers/localStorageFacade';
import useUserAgent from '@hooks/useUserAgent';

interface Props {
  data: GlobalMessageType;
}

const GlobalMessage = ({ data }: Props) => {
  const { isNativeApp } = useUserAgent();

  const onClose = () => {
    setGlobalMessageDisplayState(data.uuid);
    trackGlobalMessage('global_notification_closed', data.message);
  };

  useEffect(() => {
    trackGlobalMessage('global_notification_shown', data.message);
  }, [data.message]);

  return (!isNativeApp && data.hideInWeb !== 'true') || (isNativeApp && data.hideInApp !== 'true') ? (
    <Alert
      id={data.uid}
      callback={onClose}
      fontColor={data.fontColor}
      backgroundColor={data.backgroundColor}
      buttonColor={data.fontColor as IconColorType}
      hasCloseButton={data.hasCloseIcon === 'true'}
    >
      {data.message}
    </Alert>
  ) : null;
};

export default GlobalMessage;
