import RoundButton from '@atoms/RoundButton/RoundButton';
import Config from '@config';
import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css, keyframes } from 'styled-components';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  } 
`;

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const StyledFaqWidget = styled(RoundButton)<{
  cartIsOpen: boolean;
  show: boolean;
}>`
  position: fixed;
  opacity: 0;
  visibility: hidden;
  background-color: ${variables.colors.primary};
  transition: margin-right 0.25s;
  z-index: ${variables.zIndex.scrollTop};
  align-self: flex-end;
  right: ${rem(15)};
  bottom: ${rem(15)};
  pointer-events: auto;
  box-shadow: 0 ${rem(4)} ${rem(6)} rgba(0, 0, 0, 0.06), 0 ${rem(2)} ${rem(16)} rgba(0, 0, 0, 0.16);

  :active {
    transform: scale(0.8);
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    right: ${rem(30)};
    bottom: ${rem(30)};
  }

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
      animation: ${show} 0.25s;
    `}

  ${(props) =>
    !props.show &&
    css`
      opacity: 0;
      visibility: hidden;
      animation: ${hide} 0.25s;
    `}

  ${(props) =>
    props.cartIsOpen &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        transition: right 0.25s;
        right: calc(${rem(variables.size.cartPreviewWidth)} + ${rem(15)});
      }
    `}
`;
