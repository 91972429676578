import { convertProductsToAnalyticsEcommerceItems } from './analyticsProduct';
import pushGTMEvent, { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import type { AxfoodCartProductViewModel } from '@occ/api-client';

const trackRemoveFromCart = (products: AxfoodCartProductViewModel[], listName: string) => {
  const items = convertProductsToAnalyticsEcommerceItems(products, undefined, listName);

  pushGTMEcommerceEvent({
    event: EcommerceEvent.REMOVE_FROM_CART,
    ecommerce: {
      items,
    },
  });
};

export const trackCancelRemoveFromCart = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'cart',
    action: 'removal_of_products_undone',
    label: '',
    value: 0,
  });
};

export const trackCancelRemoveFromCartShown = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'cart',
    action: 'undo_removal_message_shown',
    label: '',
    value: 0,
  });
};

export default trackRemoveFromCart;
