const Geolocation = () => {
  const geo = navigator?.geolocation;

  const getMyPosition = (success: any, error: any) => {
    if (!geo) {
      return error();
    }

    geo.getCurrentPosition((position) => {
      success(position.coords.latitude, position.coords.longitude);
    }, error);
  };

  const compareDistance = (lat: number, lng: number, myLoc: any) => {
    const toRad = (delta: number) => {
      return (delta * Math.PI) / 180;
    };

    const R = 6371; // Radius of the earth in km
    const dLat = toRad(lat - myLoc.latitude); // Javascript functions in radians
    const dLon = toRad(lng - myLoc.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(myLoc.latitude)) * Math.cos(toRad(lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Distance in km
    return R * c;
  };

  return {
    available: 'geolocation' in navigator,
    compareDistance,
    getMyPosition,
  };
};

export default Geolocation;
