import React from 'react';
import { SkeletonRow, SkeletonRect, SkeletonCircle, SkeletonHalfCircle, SkeletonButton } from './Skeleton.styles';

declare type Type = 'text' | 'rect' | 'circle' | 'halfCircle' | 'button';

interface Props {
  count?: number | string;
  width?: number | string;
  height?: number | string;
  type?: Type;
  animation?: boolean;
  className?: string;
}

const Skeleton = ({
  count = 1,
  width = '100%',
  height = '100%',
  type = 'text',
  animation = true,
  className = '',
}: Props) => {
  const skeletons: Array<React.ReactNode> = [];

  for (let i = 0; i < count; i += 1) {
    let currentSkeleton;
    switch (type) {
      case 'circle':
        currentSkeleton = (
          <SkeletonCircle
            className={className}
            width={width}
            height={height}
            animation={animation}
            key={i}
            data-testid="skeleton-circle"
          />
        );
        break;
      case 'rect':
        currentSkeleton = (
          <SkeletonRect
            className={className}
            width={width}
            height={height}
            animation={animation}
            key={i}
            data-testid="skeleton-rect"
          />
        );
        break;
      case 'halfCircle':
        currentSkeleton = (
          <SkeletonHalfCircle
            className={className}
            width={width}
            height={height}
            animation={animation}
            key={i}
            data-testid="skeleton-halfCircle"
          />
        );
        break;
      case 'button':
        currentSkeleton = (
          <SkeletonButton
            className={className}
            width={width}
            height={height}
            animation={animation}
            key={i}
            data-testid="skeleton-button"
          />
        );
        break;
      default:
        currentSkeleton = (
          <SkeletonRow
            className={className}
            width={width}
            height={height}
            animation={animation}
            key={i}
            data-testid="skeleton-default"
          />
        );
        break;
    }

    skeletons.push(currentSkeleton);
  }

  return (
    <>
      {skeletons.map((el) => {
        return el;
      })}
    </>
  );
};

export default Skeleton;
