import { AriaAttributes, ReactNode, useEffect, useRef } from 'react';
import IconTooltip, { Props as IconTooltipProps } from '@components/molecules/IconTooltip/IconTooltip';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import { StyledCheckboxInput, StyledCheckboxLabel, StyledCheckMarkIcon, StyledContainer } from './Checkbox.styles';
import Checkmark from '@icons/checkmark.svg';
import Info from '@icons/info.svg';
interface Props extends AriaAttributes {
  bold?: boolean;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (checked: boolean) => void;
  id: string;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  tabIndex?: number;
  labelTooltip?: string;
  labelTooltipProps?: Partial<IconTooltipProps>;
  testId?: string;
  required?: boolean;
  name?: string;
}

const Checkbox = ({
  bold = false,
  checked,
  indeterminate,
  disabled,
  onChange,
  id,
  children,
  className,
  tabIndex,
  labelTooltip,
  labelTooltipProps,
  testId = 'checkbox',
  required = false,
  name,
}: Props) => {
  const checkRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkRef?.current) {
      // @ts-ignore
      checkRef.current.indeterminate = indeterminate;
    }
  }, [checked, indeterminate]);

  return (
    <StyledContainer className={className}>
      <StyledCheckboxInput
        name={name}
        required={required}
        ref={checkRef}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
        data-testid={testId}
        onChange={(e) => {
          !disabled && onChange(!checked);
        }}
        tabIndex={tabIndex}
      />

      <StyledCheckboxLabel htmlFor={id} bold={bold} checked={checked}>
        {/* Wrapping span is required, do not remove: https://github.com/facebook/react/issues/11538#issuecomment-390386520 */}
        <span>{checked && <StyledCheckMarkIcon svg={Checkmark} size={12} color="primary" />}</span>
        {children}
        {labelTooltip && <ScreenReaderMessage>. {labelTooltip}</ScreenReaderMessage>}
      </StyledCheckboxLabel>

      {labelTooltip && (
        <IconTooltip
          content={labelTooltip}
          iconProps={{ svg: Info, size: 16 }}
          align="center"
          position="top"
          color="black"
          {...labelTooltipProps}
        />
      )}
    </StyledContainer>
  );
};

export default Checkbox;
