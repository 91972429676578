/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodCombinedOrderHistoryDatesViewModel } from '../dto';
// @ts-ignore
import { AxfoodExternalOrderHistoryForMonthViewModel } from '../dto';
// @ts-ignore
import { AxfoodExternalOrderHistoryViewModel } from '../dto';
// @ts-ignore
import { AxfoodLoyaltyTransactionHistoryViewModel } from '../dto';
// @ts-ignore
import { AxfoodOrderHistoryViewModel } from '../dto';
// @ts-ignore
import { CustomerData } from '../dto';
// @ts-ignore
import { RemoveLinkedCardForm } from '../dto';
// @ts-ignore
import { UpdateCommunicationPreferencesUsingPOSTRequest } from '../dto';
// @ts-ignore
import { UpdateEmailPhoneAndDigitalReceiptForm } from '../dto';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update email and phone number And Digital Receipt
         * @param {UpdateEmailPhoneAndDigitalReceiptForm} updateEmailPhoneAndDigitalReceiptForm updateEmailPhoneAndDigitalReceiptForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailAndPhoneAndDigitalReceiptUsingPOST: async (updateEmailPhoneAndDigitalReceiptForm: UpdateEmailPhoneAndDigitalReceiptForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEmailPhoneAndDigitalReceiptForm' is not null or undefined
            assertParamExists('emailAndPhoneAndDigitalReceiptUsingPOST', 'updateEmailPhoneAndDigitalReceiptForm', updateEmailPhoneAndDigitalReceiptForm)
            const localVarPath = `/axfood/rest/account/emailAndPhoneAndDigitalReceipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailPhoneAndDigitalReceiptForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bonus points history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusPointsHistoryUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/bonusPointsHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get internal, external order history and bonus for month to date
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryAndBonusUsingGET: async (toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/orderBonusCombined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get external order history and bonus for month
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryOfflineAndBonusUsingGET: async (toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/orderBonus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paged internal, external order history and bonus for month to date
         * @param {string} [fromDate] fromDate
         * @param {string} [toDate] toDate
         * @param {number} [currentPage] currentPage
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedOrderHistoryAndBonusUsingGET: async (fromDate?: string, toDate?: string, currentPage?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/pagedOrderBonusCombined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paged internal, external order history and bonus for month to date
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentCombinedOrderHistoryDatesUsingGET: async (toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/recentCombinedOrderHistoryDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if activated offer is in cart re-calc or not
         * @param {string} offerNumber offerNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isActivatedOfferInCartUsingPOST: async (offerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerNumber' is not null or undefined
            assertParamExists('isActivatedOfferInCartUsingPOST', 'offerNumber', offerNumber)
            const localVarPath = `/axfood/rest/account/isActivatedOfferInCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (offerNumber !== undefined) {
                localVarQueryParameter['offerNumber'] = offerNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove connection to willys+ or Hemköp Club of a Card
         * @param {RemoveLinkedCardForm} removeLinkedCardForm removeLinkedCardForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLinkedCardUsingPOST: async (removeLinkedCardForm: RemoveLinkedCardForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeLinkedCardForm' is not null or undefined
            assertParamExists('removeLinkedCardUsingPOST', 'removeLinkedCardForm', removeLinkedCardForm)
            const localVarPath = `/axfood/rest/account/removeLinkedCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeLinkedCardForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set latest login auto login false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetLatestLoginIsAutoLoginUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/resetLatestLoginIsAutoLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update phone
         * @param {string} phoneNumber phoneNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCellPhoneUsingPOST: async (phoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('updateCellPhoneUsingPOST', 'phoneNumber', phoneNumber)
            const localVarPath = `/axfood/rest/account/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update charity
         * @param {boolean} charity charity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCharityUsingPOST: async (charity: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'charity' is not null or undefined
            assertParamExists('updateCharityUsingPOST', 'charity', charity)
            const localVarPath = `/axfood/rest/account/charity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (charity !== undefined) {
                localVarQueryParameter['charity'] = charity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update multiple communication options at once
         * @param {UpdateCommunicationPreferencesUsingPOSTRequest} [updateCommunicationPreferencesUsingPOSTRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommunicationPreferencesUsingPOST: async (updateCommunicationPreferencesUsingPOSTRequest?: UpdateCommunicationPreferencesUsingPOSTRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/communicationPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommunicationPreferencesUsingPOSTRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update digital receipt email comm channel
         * @param {boolean} digitalReceiptEmailCommChannel digitalReceiptEmailCommChannel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDigitalReceiptEmailCommChannelUsingPOST: async (digitalReceiptEmailCommChannel: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'digitalReceiptEmailCommChannel' is not null or undefined
            assertParamExists('updateDigitalReceiptEmailCommChannelUsingPOST', 'digitalReceiptEmailCommChannel', digitalReceiptEmailCommChannel)
            const localVarPath = `/axfood/rest/account/digitalReceiptEmailCommChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (digitalReceiptEmailCommChannel !== undefined) {
                localVarQueryParameter['digitalReceiptEmailCommChannel'] = digitalReceiptEmailCommChannel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email and phone number
         * @param {string} [email] 
         * @param {string} [chkEmail] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailAndPhoneUsingPOST: async (email?: string, chkEmail?: string, phone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/emailAndPhone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (chkEmail !== undefined) {
                localVarQueryParameter['chkEmail'] = chkEmail;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email news
         * @param {boolean} emailNews emailNews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailNewsUsingPOST: async (emailNews: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailNews' is not null or undefined
            assertParamExists('updateEmailNewsUsingPOST', 'emailNews', emailNews)
            const localVarPath = `/axfood/rest/account/emailNews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (emailNews !== undefined) {
                localVarQueryParameter['emailNews'] = emailNews;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email
         * @param {string} [email] 
         * @param {string} [chkEmail] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailUsingPOST: async (email?: string, chkEmail?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/account/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (chkEmail !== undefined) {
                localVarQueryParameter['chkEmail'] = chkEmail;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update monthly communication
         * @param {boolean} postalOffer postalOffer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMonthlyCommunicationUsingPOST: async (postalOffer: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalOffer' is not null or undefined
            assertParamExists('updateMonthlyCommunicationUsingPOST', 'postalOffer', postalOffer)
            const localVarPath = `/axfood/rest/account/monthlyCommunication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (postalOffer !== undefined) {
                localVarQueryParameter['postalOffer'] = postalOffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update offer status
         * @param {string} offerNumber offerNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfferStatusUsingPOST: async (offerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerNumber' is not null or undefined
            assertParamExists('updateOfferStatusUsingPOST', 'offerNumber', offerNumber)
            const localVarPath = `/axfood/rest/account/activateOffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (offerNumber !== undefined) {
                localVarQueryParameter['offerNumber'] = offerNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update password
         * @param {string} currentPasswordKey 
         * @param {string} newPasswordKey 
         * @param {string} checkNewPasswordKey 
         * @param {string} currentPassword 
         * @param {string} newPassword 
         * @param {string} checkNewPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePasswordUsingPOST: async (currentPasswordKey: string, newPasswordKey: string, checkNewPasswordKey: string, currentPassword: string, newPassword: string, checkNewPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currentPasswordKey' is not null or undefined
            assertParamExists('updatePasswordUsingPOST', 'currentPasswordKey', currentPasswordKey)
            // verify required parameter 'newPasswordKey' is not null or undefined
            assertParamExists('updatePasswordUsingPOST', 'newPasswordKey', newPasswordKey)
            // verify required parameter 'checkNewPasswordKey' is not null or undefined
            assertParamExists('updatePasswordUsingPOST', 'checkNewPasswordKey', checkNewPasswordKey)
            // verify required parameter 'currentPassword' is not null or undefined
            assertParamExists('updatePasswordUsingPOST', 'currentPassword', currentPassword)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('updatePasswordUsingPOST', 'newPassword', newPassword)
            // verify required parameter 'checkNewPassword' is not null or undefined
            assertParamExists('updatePasswordUsingPOST', 'checkNewPassword', checkNewPassword)
            const localVarPath = `/axfood/rest/account/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


            if (currentPasswordKey !== undefined) { 
                localVarFormParams.set('currentPasswordKey', currentPasswordKey as any);
            }
    
            if (newPasswordKey !== undefined) { 
                localVarFormParams.set('newPasswordKey', newPasswordKey as any);
            }
    
            if (checkNewPasswordKey !== undefined) { 
                localVarFormParams.set('checkNewPasswordKey', checkNewPasswordKey as any);
            }
    
            if (currentPassword !== undefined) { 
                localVarFormParams.set('currentPassword', currentPassword as any);
            }
    
            if (newPassword !== undefined) { 
                localVarFormParams.set('newPassword', newPassword as any);
            }
    
            if (checkNewPassword !== undefined) { 
                localVarFormParams.set('checkNewPassword', checkNewPassword as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sms news
         * @param {boolean} smsNews smsNews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSMSNewsUsingPOST: async (smsNews: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsNews' is not null or undefined
            assertParamExists('updateSMSNewsUsingPOST', 'smsNews', smsNews)
            const localVarPath = `/axfood/rest/account/smsNews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (smsNews !== undefined) {
                localVarQueryParameter['smsNews'] = smsNews;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update digital receipt
         * @param {boolean} digitalReceipt digitalReceipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSendDigitalReceiptUsingPOST: async (digitalReceipt: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'digitalReceipt' is not null or undefined
            assertParamExists('updateSendDigitalReceiptUsingPOST', 'digitalReceipt', digitalReceipt)
            const localVarPath = `/axfood/rest/account/digitalReceipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (digitalReceipt !== undefined) {
                localVarQueryParameter['digitalReceipt'] = digitalReceipt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update email and phone number And Digital Receipt
         * @param {UpdateEmailPhoneAndDigitalReceiptForm} updateEmailPhoneAndDigitalReceiptForm updateEmailPhoneAndDigitalReceiptForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailAndPhoneAndDigitalReceiptUsingPOST(updateEmailPhoneAndDigitalReceiptForm: UpdateEmailPhoneAndDigitalReceiptForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailAndPhoneAndDigitalReceiptUsingPOST(updateEmailPhoneAndDigitalReceiptForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bonus points history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusPointsHistoryUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodLoyaltyTransactionHistoryViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusPointsHistoryUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get internal, external order history and bonus for month to date
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryAndBonusUsingGET(toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodExternalOrderHistoryForMonthViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryAndBonusUsingGET(toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get external order history and bonus for month
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryOfflineAndBonusUsingGET(toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodExternalOrderHistoryForMonthViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryOfflineAndBonusUsingGET(toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodOrderHistoryViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paged internal, external order history and bonus for month to date
         * @param {string} [fromDate] fromDate
         * @param {string} [toDate] toDate
         * @param {number} [currentPage] currentPage
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPagedOrderHistoryAndBonusUsingGET(fromDate?: string, toDate?: string, currentPage?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodExternalOrderHistoryViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPagedOrderHistoryAndBonusUsingGET(fromDate, toDate, currentPage, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paged internal, external order history and bonus for month to date
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentCombinedOrderHistoryDatesUsingGET(toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCombinedOrderHistoryDatesViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentCombinedOrderHistoryDatesUsingGET(toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if activated offer is in cart re-calc or not
         * @param {string} offerNumber offerNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isActivatedOfferInCartUsingPOST(offerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isActivatedOfferInCartUsingPOST(offerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove connection to willys+ or Hemköp Club of a Card
         * @param {RemoveLinkedCardForm} removeLinkedCardForm removeLinkedCardForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLinkedCardUsingPOST(removeLinkedCardForm: RemoveLinkedCardForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeLinkedCardUsingPOST(removeLinkedCardForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set latest login auto login false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetLatestLoginIsAutoLoginUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetLatestLoginIsAutoLoginUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update phone
         * @param {string} phoneNumber phoneNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCellPhoneUsingPOST(phoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCellPhoneUsingPOST(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update charity
         * @param {boolean} charity charity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCharityUsingPOST(charity: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCharityUsingPOST(charity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update multiple communication options at once
         * @param {UpdateCommunicationPreferencesUsingPOSTRequest} [updateCommunicationPreferencesUsingPOSTRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommunicationPreferencesUsingPOST(updateCommunicationPreferencesUsingPOSTRequest?: UpdateCommunicationPreferencesUsingPOSTRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommunicationPreferencesUsingPOST(updateCommunicationPreferencesUsingPOSTRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update digital receipt email comm channel
         * @param {boolean} digitalReceiptEmailCommChannel digitalReceiptEmailCommChannel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDigitalReceiptEmailCommChannelUsingPOST(digitalReceiptEmailCommChannel: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDigitalReceiptEmailCommChannelUsingPOST(digitalReceiptEmailCommChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email and phone number
         * @param {string} [email] 
         * @param {string} [chkEmail] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailAndPhoneUsingPOST(email?: string, chkEmail?: string, phone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailAndPhoneUsingPOST(email, chkEmail, phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email news
         * @param {boolean} emailNews emailNews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailNewsUsingPOST(emailNews: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailNewsUsingPOST(emailNews, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email
         * @param {string} [email] 
         * @param {string} [chkEmail] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailUsingPOST(email?: string, chkEmail?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailUsingPOST(email, chkEmail, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update monthly communication
         * @param {boolean} postalOffer postalOffer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMonthlyCommunicationUsingPOST(postalOffer: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMonthlyCommunicationUsingPOST(postalOffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update offer status
         * @param {string} offerNumber offerNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfferStatusUsingPOST(offerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfferStatusUsingPOST(offerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update password
         * @param {string} currentPasswordKey 
         * @param {string} newPasswordKey 
         * @param {string} checkNewPasswordKey 
         * @param {string} currentPassword 
         * @param {string} newPassword 
         * @param {string} checkNewPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePasswordUsingPOST(currentPasswordKey: string, newPasswordKey: string, checkNewPasswordKey: string, currentPassword: string, newPassword: string, checkNewPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePasswordUsingPOST(currentPasswordKey, newPasswordKey, checkNewPasswordKey, currentPassword, newPassword, checkNewPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update sms news
         * @param {boolean} smsNews smsNews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSMSNewsUsingPOST(smsNews: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSMSNewsUsingPOST(smsNews, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update digital receipt
         * @param {boolean} digitalReceipt digitalReceipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSendDigitalReceiptUsingPOST(digitalReceipt: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSendDigitalReceiptUsingPOST(digitalReceipt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Update email and phone number And Digital Receipt
         * @param {UpdateEmailPhoneAndDigitalReceiptForm} updateEmailPhoneAndDigitalReceiptForm updateEmailPhoneAndDigitalReceiptForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailAndPhoneAndDigitalReceiptUsingPOST(updateEmailPhoneAndDigitalReceiptForm: UpdateEmailPhoneAndDigitalReceiptForm, options?: any): AxiosPromise<void> {
            return localVarFp.emailAndPhoneAndDigitalReceiptUsingPOST(updateEmailPhoneAndDigitalReceiptForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfoUsingGET(options?: any): AxiosPromise<CustomerData> {
            return localVarFp.getAccountInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bonus points history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusPointsHistoryUsingGET(options?: any): AxiosPromise<AxfoodLoyaltyTransactionHistoryViewModel> {
            return localVarFp.getBonusPointsHistoryUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get internal, external order history and bonus for month to date
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryAndBonusUsingGET(toDate?: string, options?: any): AxiosPromise<AxfoodExternalOrderHistoryForMonthViewModel> {
            return localVarFp.getOrderHistoryAndBonusUsingGET(toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get external order history and bonus for month
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryOfflineAndBonusUsingGET(toDate?: string, options?: any): AxiosPromise<AxfoodExternalOrderHistoryForMonthViewModel> {
            return localVarFp.getOrderHistoryOfflineAndBonusUsingGET(toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryUsingGET(options?: any): AxiosPromise<Array<AxfoodOrderHistoryViewModel>> {
            return localVarFp.getOrderHistoryUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paged internal, external order history and bonus for month to date
         * @param {string} [fromDate] fromDate
         * @param {string} [toDate] toDate
         * @param {number} [currentPage] currentPage
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedOrderHistoryAndBonusUsingGET(fromDate?: string, toDate?: string, currentPage?: number, pageSize?: number, options?: any): AxiosPromise<AxfoodExternalOrderHistoryViewModel> {
            return localVarFp.getPagedOrderHistoryAndBonusUsingGET(fromDate, toDate, currentPage, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paged internal, external order history and bonus for month to date
         * @param {string} [toDate] toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentCombinedOrderHistoryDatesUsingGET(toDate?: string, options?: any): AxiosPromise<AxfoodCombinedOrderHistoryDatesViewModel> {
            return localVarFp.getRecentCombinedOrderHistoryDatesUsingGET(toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if activated offer is in cart re-calc or not
         * @param {string} offerNumber offerNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isActivatedOfferInCartUsingPOST(offerNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.isActivatedOfferInCartUsingPOST(offerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove connection to willys+ or Hemköp Club of a Card
         * @param {RemoveLinkedCardForm} removeLinkedCardForm removeLinkedCardForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLinkedCardUsingPOST(removeLinkedCardForm: RemoveLinkedCardForm, options?: any): AxiosPromise<CustomerData> {
            return localVarFp.removeLinkedCardUsingPOST(removeLinkedCardForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set latest login auto login false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetLatestLoginIsAutoLoginUsingPOST(options?: any): AxiosPromise<void> {
            return localVarFp.resetLatestLoginIsAutoLoginUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update phone
         * @param {string} phoneNumber phoneNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCellPhoneUsingPOST(phoneNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateCellPhoneUsingPOST(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update charity
         * @param {boolean} charity charity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCharityUsingPOST(charity: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateCharityUsingPOST(charity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update multiple communication options at once
         * @param {UpdateCommunicationPreferencesUsingPOSTRequest} [updateCommunicationPreferencesUsingPOSTRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommunicationPreferencesUsingPOST(updateCommunicationPreferencesUsingPOSTRequest?: UpdateCommunicationPreferencesUsingPOSTRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCommunicationPreferencesUsingPOST(updateCommunicationPreferencesUsingPOSTRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update digital receipt email comm channel
         * @param {boolean} digitalReceiptEmailCommChannel digitalReceiptEmailCommChannel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDigitalReceiptEmailCommChannelUsingPOST(digitalReceiptEmailCommChannel: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateDigitalReceiptEmailCommChannelUsingPOST(digitalReceiptEmailCommChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email and phone number
         * @param {string} [email] 
         * @param {string} [chkEmail] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailAndPhoneUsingPOST(email?: string, chkEmail?: string, phone?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmailAndPhoneUsingPOST(email, chkEmail, phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email news
         * @param {boolean} emailNews emailNews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailNewsUsingPOST(emailNews: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmailNewsUsingPOST(emailNews, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email
         * @param {string} [email] 
         * @param {string} [chkEmail] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailUsingPOST(email?: string, chkEmail?: string, password?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmailUsingPOST(email, chkEmail, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update monthly communication
         * @param {boolean} postalOffer postalOffer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMonthlyCommunicationUsingPOST(postalOffer: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateMonthlyCommunicationUsingPOST(postalOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update offer status
         * @param {string} offerNumber offerNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfferStatusUsingPOST(offerNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateOfferStatusUsingPOST(offerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update password
         * @param {string} currentPasswordKey 
         * @param {string} newPasswordKey 
         * @param {string} checkNewPasswordKey 
         * @param {string} currentPassword 
         * @param {string} newPassword 
         * @param {string} checkNewPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePasswordUsingPOST(currentPasswordKey: string, newPasswordKey: string, checkNewPasswordKey: string, currentPassword: string, newPassword: string, checkNewPassword: string, options?: any): AxiosPromise<void> {
            return localVarFp.updatePasswordUsingPOST(currentPasswordKey, newPasswordKey, checkNewPasswordKey, currentPassword, newPassword, checkNewPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sms news
         * @param {boolean} smsNews smsNews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSMSNewsUsingPOST(smsNews: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateSMSNewsUsingPOST(smsNews, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update digital receipt
         * @param {boolean} digitalReceipt digitalReceipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSendDigitalReceiptUsingPOST(digitalReceipt: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateSendDigitalReceiptUsingPOST(digitalReceipt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Update email and phone number And Digital Receipt
     * @param {UpdateEmailPhoneAndDigitalReceiptForm} updateEmailPhoneAndDigitalReceiptForm updateEmailPhoneAndDigitalReceiptForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public emailAndPhoneAndDigitalReceiptUsingPOST(updateEmailPhoneAndDigitalReceiptForm: UpdateEmailPhoneAndDigitalReceiptForm, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).emailAndPhoneAndDigitalReceiptUsingPOST(updateEmailPhoneAndDigitalReceiptForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountInfoUsingGET(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccountInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bonus points history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getBonusPointsHistoryUsingGET(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getBonusPointsHistoryUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get internal, external order history and bonus for month to date
     * @param {string} [toDate] toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getOrderHistoryAndBonusUsingGET(toDate?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getOrderHistoryAndBonusUsingGET(toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get external order history and bonus for month
     * @param {string} [toDate] toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getOrderHistoryOfflineAndBonusUsingGET(toDate?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getOrderHistoryOfflineAndBonusUsingGET(toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getOrderHistoryUsingGET(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getOrderHistoryUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paged internal, external order history and bonus for month to date
     * @param {string} [fromDate] fromDate
     * @param {string} [toDate] toDate
     * @param {number} [currentPage] currentPage
     * @param {number} [pageSize] pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getPagedOrderHistoryAndBonusUsingGET(fromDate?: string, toDate?: string, currentPage?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getPagedOrderHistoryAndBonusUsingGET(fromDate, toDate, currentPage, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paged internal, external order history and bonus for month to date
     * @param {string} [toDate] toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getRecentCombinedOrderHistoryDatesUsingGET(toDate?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getRecentCombinedOrderHistoryDatesUsingGET(toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if activated offer is in cart re-calc or not
     * @param {string} offerNumber offerNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public isActivatedOfferInCartUsingPOST(offerNumber: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).isActivatedOfferInCartUsingPOST(offerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove connection to willys+ or Hemköp Club of a Card
     * @param {RemoveLinkedCardForm} removeLinkedCardForm removeLinkedCardForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public removeLinkedCardUsingPOST(removeLinkedCardForm: RemoveLinkedCardForm, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).removeLinkedCardUsingPOST(removeLinkedCardForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set latest login auto login false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resetLatestLoginIsAutoLoginUsingPOST(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).resetLatestLoginIsAutoLoginUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update phone
     * @param {string} phoneNumber phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateCellPhoneUsingPOST(phoneNumber: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateCellPhoneUsingPOST(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update charity
     * @param {boolean} charity charity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateCharityUsingPOST(charity: boolean, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateCharityUsingPOST(charity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update multiple communication options at once
     * @param {UpdateCommunicationPreferencesUsingPOSTRequest} [updateCommunicationPreferencesUsingPOSTRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateCommunicationPreferencesUsingPOST(updateCommunicationPreferencesUsingPOSTRequest?: UpdateCommunicationPreferencesUsingPOSTRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateCommunicationPreferencesUsingPOST(updateCommunicationPreferencesUsingPOSTRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update digital receipt email comm channel
     * @param {boolean} digitalReceiptEmailCommChannel digitalReceiptEmailCommChannel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateDigitalReceiptEmailCommChannelUsingPOST(digitalReceiptEmailCommChannel: boolean, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateDigitalReceiptEmailCommChannelUsingPOST(digitalReceiptEmailCommChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email and phone number
     * @param {string} [email] 
     * @param {string} [chkEmail] 
     * @param {string} [phone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateEmailAndPhoneUsingPOST(email?: string, chkEmail?: string, phone?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateEmailAndPhoneUsingPOST(email, chkEmail, phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email news
     * @param {boolean} emailNews emailNews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateEmailNewsUsingPOST(emailNews: boolean, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateEmailNewsUsingPOST(emailNews, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email
     * @param {string} [email] 
     * @param {string} [chkEmail] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateEmailUsingPOST(email?: string, chkEmail?: string, password?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateEmailUsingPOST(email, chkEmail, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update monthly communication
     * @param {boolean} postalOffer postalOffer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateMonthlyCommunicationUsingPOST(postalOffer: boolean, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateMonthlyCommunicationUsingPOST(postalOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update offer status
     * @param {string} offerNumber offerNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateOfferStatusUsingPOST(offerNumber: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateOfferStatusUsingPOST(offerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update password
     * @param {string} currentPasswordKey 
     * @param {string} newPasswordKey 
     * @param {string} checkNewPasswordKey 
     * @param {string} currentPassword 
     * @param {string} newPassword 
     * @param {string} checkNewPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updatePasswordUsingPOST(currentPasswordKey: string, newPasswordKey: string, checkNewPasswordKey: string, currentPassword: string, newPassword: string, checkNewPassword: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updatePasswordUsingPOST(currentPasswordKey, newPasswordKey, checkNewPasswordKey, currentPassword, newPassword, checkNewPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sms news
     * @param {boolean} smsNews smsNews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateSMSNewsUsingPOST(smsNews: boolean, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateSMSNewsUsingPOST(smsNews, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update digital receipt
     * @param {boolean} digitalReceipt digitalReceipt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateSendDigitalReceiptUsingPOST(digitalReceipt: boolean, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateSendDigitalReceiptUsingPOST(digitalReceipt, options).then((request) => request(this.axios, this.basePath));
    }
}
