import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { PaymentMode } from '@molecules/PaymentModes/PaymentModes';

export enum CardPaymentOption {
  SAVE = 'save',
  REMOVE = 'remove',
  NEW = 'new',
  EXISTING = 'existing',
}

const trackCardPaymentOption = (option: CardPaymentOption) => {
  const action = `payment_option_${option}_card`;

  if (action) {
    pushGTMEvent({
      event: 'Track',
      category: 'checkout',
      action,
      label: '',
      value: 0,
    });
  }
};

export default trackCardPaymentOption;
