import { Router } from 'next/router';

const clearFiltersFromQuery = (routerQuery: Router['query']): Router['query'] => {
  const { q, ...noFilter } = routerQuery;
  // @ts-ignore
  const searchKey = q?.split(':')[0].trim('');
  return searchKey && searchKey.length ? { ...noFilter, q: searchKey } : noFilter;
};

export default clearFiltersFromQuery;
