import { AxfoodApi } from '@occ/api-client';

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const getOnlineAndOfflineVouchers = async () => {
  const response = await axfoodApi.getAllExternalVouchersOnlineAndOfflineUsingGet();
  return response.data;
};

export const addVoucher = async (voucherCode: string) => {
  const response = await axfoodApi.applyVoucherToCartUsingPost({ voucherCode });
  return response.data;
};

export const removeVoucher = async (voucherCode: string) => {
  const response = await axfoodApi.releaseVoucherFromCartUsingDelete(voucherCode);
  return response.data;
};
