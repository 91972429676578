import styled, { css, keyframes } from 'styled-components';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
type sizeType = 'x-small' | 'small' | 'medium' | 'large';

export const StyledModal = styled.div<{
  size: sizeType;
  overflowHidden: boolean;
  isProductModal: boolean;
  verticallyCentered: boolean;
  youTubeModal?: boolean;
  hasMinHeight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: ${variables.zIndex.modal};
  background-color: ${(props) => (props.youTubeModal ? 'auto' : variables.colors.white)};
  max-width: 100%;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  animation: ${fadeIn} 300ms ease forwards;

  ${(
    props // TODO: solve some other way?
  ) =>
    props.isProductModal &&
    css`
      text-align: left;

      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        height: auto !important;
        min-height: 50%;
      }
    `};

  ${(props) =>
    props.overflowHidden &&
    css`
      overflow: hidden;
    `};

  ${(props) =>
    props.size === 'x-small' &&
    css`
      border: ${rem(1)} solid ${variables.colors.mediumGray};
      justify-content: unset;
      height: auto;
      max-width: ${rem(306)};
      min-width: ${rem(306)};
      box-shadow: ${rem(0)} ${rem(0)} ${rem(15)} ${rem(0)} rgba(0, 0, 0, 0.3);
    `};

  ${(props) =>
    props.size === 'small' &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        justify-content: unset;
        height: auto;
        max-width: ${rem(380)};
        min-width: ${rem(380)};
        max-height: calc(100vh - ${rem(128)});
      }
    `};

  ${(props) =>
    props.size === 'medium' &&
    css`
      justify-content: unset;

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        max-width: ${rem(520)};
        min-width: ${rem(520)};
        max-height: calc(100vh - ${rem(128)});
        min-height: ${props.hasMinHeight && rem(542)};
        height: ${props.hasMinHeight ? 'unset' : 'auto'};
      }
    `};

  ${(props) =>
    props.size === 'large' &&
    css`
      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        justify-content: unset;
        max-width: ${rem(894)};
        min-width: ${rem(894)};
        max-height: calc(100vh - ${rem(128)});
      }
    `};

  ${(props) =>
    props.youTubeModal &&
    css`
      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        justify-content: unset;
        max-width: ${rem(1024)};
        min-width: ${rem(1024)};
        max-height: ${rem(576)};
      }
    `};

  ${(props) =>
    props.verticallyCentered &&
    css`
      justify-content: center;
      position: relative;
    `}
`;

export const StyledContent = styled.div<{
  noPadding: boolean;
  size: sizeType;
  verticallyCentered: boolean;
  hasNavigation: boolean;
}>`
  width: 100%;
  height: 100%;
  padding: ${rem(40)} ${rem(20)};
  flex-direction: column;

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `};

  ${(props) =>
    props.size === 'small' &&
    css`
      height: auto;

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: ${rem(40)} ${rem(32)};
      }
    `};

  ${(props) =>
    props.size === 'medium' &&
    css`
      display: flex;

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: ${rem(40)};
        display: flex;
        flex: 1 1 auto;
      }
    `};

  ${(props) =>
    props.size === 'large' &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: ${rem(40)} ${rem(34)};
      }

      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        padding: ${rem(40)};
      }
    `};

  ${(props) =>
    props.verticallyCentered &&
    css`
      height: auto;
    `}

  ${(props) =>
    props.hasNavigation &&
    css`
      padding-top: 0 !important;
    `}
}

;
`;

export const StyledCallToAction = styled.div<{
  equalButtonWidth?: boolean;
}>`
  margin-top: ${rem(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;

    ${(props) =>
      props.equalButtonWidth &&
      css`
        display: grid;
        padding: 0 ${rem(42)};
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
      `}
  }
`;

export const StyledButton = styled(Button)<{
  width?: number;
  mobileWidth?: number;
}>`
  ${(props) =>
    props.mobileWidth &&
    css`
      ${Config.MEDIA.IS_MOBILE} {
        width: ${rem(props.mobileWidth)};
        margin: 0 auto;
      }
    `};

  ${(props) =>
    props.width &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        width: ${rem(props.width)};
      }
    `};
`;

export const StyledModalBackButton = styled(Button)``;

export const StyledModalNavigationWrapper = styled.div`
  position: sticky;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  padding: ${rem(20)} ${rem(20)} 0;
  z-index: ${variables.zIndex[2]};
`;
