import styled from 'styled-components';
import variables from '@styles/variables';
import Button from '@atoms/Button/Button';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  onClick: () => void;
  absolute?: boolean;
  color?: 'white' | 'black';
  size: number;
}

const CloseButtonWrapper = styled(Button)`
  margin-left: auto;
  cursor: pointer;
`;

const ModalCloseButton = ({ onClick, absolute = false, color = 'black', size }: Props) => {
  const { t } = useTranslation('common');
  return (
    <CloseButtonWrapper
      onClick={onClick}
      data-testid="modal-close-btn"
      theme="transparent"
      aria-label={t('closeLoginModalLabel')}
    >
      <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M.223.22a.75.75 0 011.061 0l6.72 6.718L14.713.231a.75.75 0 011.133.977l-.073.084-6.708 6.707 6.708 6.708.073.084a.75.75 0 01-1.133.977L8.003 9.06l-6.719 6.72a.75.75 0 01-1.133-.977l.072-.084 6.72-6.72L.223 1.28l-.072-.084A.75.75 0 01.223.22z"
          fill={color === 'white' ? variables.colors.white : variables.colors.lighterBlack}
          fillRule="nonzero"
        />
      </svg>
    </CloseButtonWrapper>
  );
};

export default ModalCloseButton;
