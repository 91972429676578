import { parse, serialize } from 'cookie';

export const cookieJar = () => ({
  get: (name: string) => {
    return (parse(document.cookie) || {})[name];
  },
  set: (name: string, value: string, expiresInDays: number) => {
    const siteName = window.location.hostname.split('.').slice(-2).join('.');

    document.cookie = serialize(name, value, {
      expires: new Date(Date.now() + expiresInDays * 24 * 3600 * 1000),
      domain: siteName,
      path: '/',
      secure: true,
    });
  },
});
