import type { AxfoodStoreInfoViewModel, AxfoodTimeSlotViewModel, Suggestion } from '@occ/api-client';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const event = 'Track';
const label = '';
const value = 0;

const getActionType = (isHomeDelivery: boolean) => {
  return isHomeDelivery ? 'home_delivery' : 'click_and_collect';
};

const getCategory = (isCheckout: boolean) => {
  return isCheckout ? 'checkout_delivery_options' : 'delivery_widget';
};

export const trackSearchSuggestions = (
  isHomeDelivery: boolean,
  suggestions: Suggestion[] | AxfoodStoreInfoViewModel[],
  isCheckout = false
) => {
  const resultsLength = Array.isArray(suggestions) ? suggestions.length : 0;

  pushGTMEvent({
    event,
    category: getCategory(isCheckout),
    action: `${getActionType(isHomeDelivery)}_location_searched`,
    label: `${resultsLength}_results`,
    value,
  });
};

export const trackDeliveryDate = (
  isHomeDelivery: boolean,
  slots: Array<AxfoodTimeSlotViewModel> | undefined,
  isCheckout = false
) => {
  let totalSlotsCounter = 0;
  let availableSlotsCounter = 0;

  if (Array.isArray(slots)) {
    totalSlotsCounter = slots.length;
    availableSlotsCounter = slots.filter((s) => {
      return Boolean(s.available);
    }).length;
  }

  pushGTMEvent({
    event,
    category: getCategory(isCheckout),
    action: `${getActionType(isHomeDelivery)}_date_selected`,
    label: `${availableSlotsCounter}_of_${totalSlotsCounter}_slots_available`,
    value,
  });
};

export const trackDeliverySelectedTime = (isHomeDelivery: boolean, isCheckout = false) => {
  pushGTMEvent({
    event,
    category: getCategory(isCheckout),
    action: `${getActionType(isHomeDelivery)}_time_selected`,
    label,
    value,
  });
};

export const trackDeliveryConfirmedTime = (isHomeDelivery: boolean, isCheckout = false) => {
  pushGTMEvent({
    event,
    category: getCategory(isCheckout),
    action: `${getActionType(isHomeDelivery)}_slot_confirmed`,
    label,
    value,
  });
};
