import styled, { css, keyframes } from 'styled-components';
import RoundButton from '@atoms/RoundButton/RoundButton';
import Button from '@atoms/Button/Button';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';
import Spinner from '@molecules/Spinner/Spinner';
import Config from '@config';
import Tooltip from '@molecules/Tooltip/Tooltip';

const scaleButtonIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(calc(36 / 44))
  }
`;

const scaleButtonOut = keyframes`
  from {
    transform: scale(calc(36 / 44))
  }
  to {
    transform: scale(1);
  }
`;

export const StyledMiniCart = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledMiniCartButton = styled(Button)`
  max-height: ${rem(44)} !important;
  min-height: ${rem(44)} !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 ${rem(10)};

  ${Config.MEDIA.BELOW_DESKTOP} {
    background-color: transparent !important;
    // Item spacing in header handled by wrapper
    padding: 0;
  }

  ${Config.MEDIA.IS_MOBILE_SMALL} {
    min-width: 0;
  }
`;

export const StyledRoundButton = styled(RoundButton)<{ animateIn: boolean; isAnimatingOut: boolean }>`
  ${(props) =>
    props.animateIn &&
    css`
      animation: ${scaleButtonIn} 0.25s ease forwards;
    `};

  ${(props) =>
    props.isAnimatingOut &&
    css`
      animation: ${scaleButtonOut} 0.25s ease;
    `};
`;

export const StyledPrice = styled.span`
  white-space: nowrap;
  margin-left: ${rem(5)};
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamMedium};
  display: inline-block;
  vertical-align: middle;

  ${Config.MEDIA.IS_MOBILE_XSMALL} {
    display: none;
  }

  ${Config.MEDIA.BELOW_DESKTOP} {
    color: ${variables.colors.black};
  }
`;

export const StyledCounter = styled.span<{ disabled: boolean }>`
  position: absolute;
  top: ${rem(-6)};
  right: ${rem(-2)};
  padding: ${rem(2)};
  background-color: ${variables.colors.white};
  color: ${variables.colors.primary};
  font-size: ${rem(11)};
  min-width: ${rem(21)};
  min-height: ${rem(21)};
  height: ${rem(21)};
  width: auto;
  border-radius: ${rem(12)};
  display: flex;
  justify-content: center;
  box-shadow: 0 0 ${rem(8)} ${rem(1)} rgba(50, 50, 50, 0.15);
  align-items: center;

  ${Config.MEDIA.BELOW_DESKTOP} {
    font-size: ${rem(8)};
    min-width: ${rem(14)};
    min-height: ${rem(14)};
    height: ${rem(14)};
    color: ${variables.colors.white};
    background-color: ${variables.colors.primary};
    right: ${rem(-8)};
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${rgba(variables.colors.primary, 0.4)};
    `};
`;

export const StyledSpinner = styled(Spinner)`
  margin: ${rem(2)};
`;

export const StyledCartIconWrapper = styled.div`
  height: ${rem(20)};

  ${Config.MEDIA.BELOW_DESKTOP} {
    position: relative;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  ${Config.MEDIA.IS_MOBILE} {
    z-index: ${variables.zIndex.deliveryPicker - 1};
  }
`;
