import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackCarouselSlideShown = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'homepage',
    action: 'swipe_more_products',
    label: '',
    value: 0,
  });
};
