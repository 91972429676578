import styled from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Link from '@atoms/Link/Link';

export const StyledAccordionNavNode = styled.li<{
  isActive: boolean;
  isExpanded: boolean;
  noBorder: boolean;
  isTopLevel: boolean;
}>`
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  border-left: ${(props) =>
    props.isTopLevel ? (props.isExpanded ? `${rem(2)} solid ${variables.colors.primary}` : '') : ''};
  border: ${(props) => (props.noBorder ? 'none' : '')};
  list-style: none;
  background: ${variables.colors.white};
`;

export const StyledAccordionNavNodeContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${rem(45)};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      > button {
        a {
          color: ${variables.colors.primary};
        }
      }
      svg,
      path {
        fill: ${variables.colors.primary};
      }
    }
  }
`;

export const StyledAccordionNavNodeLinkButton = styled.button`
  flex: 1;
  background: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  padding: 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledAccordionNavNodeLink = styled(Link)<{
  isHighlighted: boolean;
  isActive: boolean;
  hasChildren: boolean;
}>`
  line-height: ${rem(45)};
  padding-right: ${(props) => (props.hasChildren ? '' : rem(24))};

  &,
  &:visited {
    font-family: ${(props) =>
      props.isActive ? variables.fonts.fontGothamMedium : props.isHighlighted ? variables.fonts.fontGothamBook : ''};
    color: ${(props) => (props.isActive || props.isHighlighted ? variables.colors.primary : '')};
  }
`;

export const StyledAccordionNavNodeToggleButton = styled.button<{ isHighlighted: boolean; isActive: boolean }>`
  background: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  height: ${rem(45)};
  padding: 0 ${rem(24)} 0 ${rem(15)};

  &,
  &:visited {
    font-family: ${(props) =>
      props.isActive ? variables.fonts.fontGothamMedium : props.isHighlighted ? variables.fonts.fontGothamBook : ''};
    color: ${(props) => (props.isActive || props.isHighlighted ? variables.colors.primary : '')};
  }
`;

export const StyledAccordionNavNodeList = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${variables.colors.white};
`;
