import useTranslation from 'next-translate/useTranslation';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import { useMediaQuery } from 'react-responsive';
import Modal from '@organisms/Modal/Modal';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import Config from '@config';
import { StyledOrderNoticeAlert, StyledOrderNoticeButton } from '@atoms/OpenOrderNotice/OpenOrderNotice.styles';
import { setChangeOrderModalVisibility, selectChangeOrderModal } from '@slices/modalSlice';
import { selectStickyToolbar } from '@slices/toolbarSlice';
import trackCancelChangeOrder from '@helpers/analyticsHelpers/trackCancelChangeOrder';
import trackChangeOrderModal from '@helpers/analyticsHelpers/trackChangeOrderModal';
import { dateToHourAndMinutes, dateToDaysAndMonths } from '@helpers/date';
import useCart from '@hooks/useCart';
import useCartActions from '@hooks/useCartActions';

const OpenOrderNotice = () => {
  const { t } = useTranslation('openOrderNotice');
  const changeOrderModal = useAppSelector(selectChangeOrderModal);
  const stickyTop: boolean = useAppSelector(selectStickyToolbar);
  const dispatch = useAppDispatch();
  const { cart } = useCart();
  const { cancelChangeOrder } = useCartActions();

  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  return (
    <>
      {changeOrderModal.visible && (
        <Modal
          size="small"
          onClose={() => {
            dispatch(setChangeOrderModalVisibility(false));
            trackChangeOrderModal('cancel_change_order_modal', 'decline');
          }}
          onConfirm={() => {
            cancelChangeOrder(cart);
          }}
          closeOnEscape
          cancelButtonText={t('modal->cancel')}
          confirmButtonText={t('modal->confirm')}
          buttonWidth={100}
        >
          <Heading variant="h3">{t('modal->title')}</Heading>
        </Modal>
      )}
      {cart?.orderReference && (
        <StyledOrderNoticeAlert
          id="open-order-notice"
          stickyTop={!stickyTop && isMobile}
          animate={stickyTop && isMobile}
          hasCloseButton={false}
        >
          <div>
            <Text type="body" size="small" color="white">
              {t('text', {
                day: dateToDaysAndMonths(cart.orderReferenceSlotCloseTime),
                time: dateToHourAndMinutes(cart.orderReferenceSlotCloseTime),
              })}
              &nbsp;
            </Text>
            <StyledOrderNoticeButton
              data-testid="open-order-notice-button"
              theme="link"
              onClick={() => {
                dispatch(setChangeOrderModalVisibility(true));
                trackCancelChangeOrder('cancel_change_order_link');
                trackChangeOrderModal('cancel_change_order_modal', 'modal_shown');
              }}
            >
              {t('link')}
            </StyledOrderNoticeButton>
          </div>
        </StyledOrderNoticeAlert>
      )}
    </>
  );
};

const OpenOrderNoticeContainer = () => {
  const { cart } = useCart();
  if (!cart?.orderReference) return null;
  return <OpenOrderNotice />;
};

export default OpenOrderNoticeContainer;
