import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Config from '@config';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import Link from '@atoms/Link/Link';

export const saytToolbarPaddings = { vertical: 8, horizontal: 10 };

export const StyledSearchAsYouTypeResults = styled.div`
  background-color: ${variables.colors.white};
  position: absolute;
  top: 100%;
  width: 100vw;
  left: 0;
  padding: ${rem(20)} ${rem(20)} ${rem(28)};
  overflow-y: scroll;

  ${Config.MEDIA.IS_MOBILE} {
    overflow-y: visible;
    min-height: calc(var(--doc-height) - ${rem(60)});
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    border-radius: 0 0 ${rem(22)} ${rem(22)};
    box-shadow: 0 ${rem(-4)} ${rem(5)} rgba(0, 0, 0, 0.03);
    max-height: calc(100vh - ${rem(variables.size.toolbarHeight * 3)});
    min-height: ${rem(435)};
    top: 100%;
    width: 100%;

    @supports selector(::-webkit-scrollbar) {
      ::-webkit-scrollbar {
        width: ${rem(12)};
      }

      ::-webkit-scrollbar-track {
        margin-block: ${rem(20)};
      }

      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: ${rem(20)};
        border: ${rem(4)} solid transparent;
        box-shadow: 0 0 ${rem(1)} rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export const StyledResultsListHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(16)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    justify-content: flex-start;
    gap: ${rem(12)};
    align-items: baseline;
  }
`;

export const StyledResultsList = styled.div`
  ${Config.MEDIA.FROM_DESKTOP} {
    margin-right: ${rem(40)};
    padding-right: ${rem(40)};
    border-right: ${rem(1)} solid ${variables.colors.mediumGray};
  }

  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    margin-right: ${rem(60)};
    padding-right: ${rem(60)};
  }
`;

export const StyledGotoAllProductsBtn = styled(Link)`
  display: flex;
  margin-top: ${rem(28)};
  justify-content: center;

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-right: ${rem(120)};
  }
`;

export const StyledSearchTermHeading = styled(Heading)<{ center?: boolean }>`
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`;

export const StyledNoResultsText = styled(Text)`
  max-width: ${rem(560)};
  margin: ${rem(16)} auto 0;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledResultColumn = styled.div`
  flex: 1;
  min-width: 0;
`;
