import { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';

export const onlineGridElementHeight = () => css`
  min-height: ${rem(392)};
  ${Config.MEDIA.FROM_MOBILE} {
    min-height: ${rem(404)};
  }
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    min-height: ${rem(401)};
  }
`;

export const offlineGridElementHeight = () => css`
  min-height: ${rem(308)};
  ${Config.MEDIA.FROM_MOBILE} {
    min-height: ${rem(320)};
  }
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    min-height: ${rem(333)};
  }
`;
