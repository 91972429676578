import Icon from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';
import Text from '@atoms/Text/Text';
import Config from '@config';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  overflow: hidden;
  background-color: ${variables.colors.lighterBlack};
  min-height: ${rem(400)};

  ${Config.MEDIA.FROM_DESKTOP} {
    padding: ${rem(70)} 0;
    width: 100%;
  }
`;

export const StyledFooterGrid = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(747)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    max-width: ${rem(984)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    display: flex;
    max-width: ${rem(1025)};
  }
`;

export const StyledFooterNav = styled.nav`
  padding: ${rem(15)} ${rem(20)};
  border-bottom: ${rem(1)} solid ${rgba(variables.colors.white, 0.1)};

  ${Config.MEDIA.FROM_DESKTOP} {
    border-bottom: 0;
    padding: 0;
  }

  &:last-of-type {
    border: none;
  }
`;

export const StyledFooterColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${Config.MEDIA.FROM_DESKTOP} {
    flex-direction: row;
  }

  &:last-child {
    flex-direction: column;

    ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
      margin-left: auto;
    }

    ${StyledFooterNav} {
      display: flex;
      align-items: center;

      ${Config.MEDIA.FROM_DESKTOP} {
        margin-bottom: ${rem(25)};
      }
    }
  }
`;

export const StyledFooterNavHeading = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFooterNavHeadingLink = styled(Link)`
  display: block;
  font-size: ${rem(13)};
  line-height: ${rem(30)};
  white-space: nowrap;
  color: ${variables.colors.white};
  text-transform: uppercase;
  &:hover,
  &:visited {
    color: ${variables.colors.white};
  }
`;

export const StyledFooterLinks = styled.div`
  flex-direction: column;
  padding: ${rem(16)};
  margin: 0;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(184)};
    margin-right: ${rem(24)};
    padding: 0;
  }

  display: none;
  background-color: ${variables.colors.white};
  opacity: 0.08;

  ${Config.MEDIA.FROM_DESKTOP} {
    background-color: unset;
    opacity: unset;
    display: flex;
  }
`;

export const StyledFooterLinksText = styled(Text)`
  font-size: ${rem(13)};
  line-height: ${rem(30)};
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.darkGray};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const StyledFooterLinksLink = styled(Link)`
  font-size: ${rem(13)};
  line-height: ${rem(30)};
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.darkGray};

  &:visited {
    color: ${variables.colors.darkGray};
  }
  &:hover {
    color: ${variables.colors.darkerGray};
  }
`;

export const StyledFooterNavIcon = styled(Icon)`
  margin-right: ${rem(10)};
`;

export const StyledFooterBottomSection = styled.div`
  border: 0;
  padding: ${rem(30)} 0;
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(747)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    max-width: ${rem(984)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin: ${rem(35)} auto;
    max-width: ${rem(1025)};
    border-top: ${rem(1)} solid ${rgba(variables.colors.white, 0.15)};
  }
`;

export const StyledTrustedSite = styled.div`
  padding-bottom: ${rem(20)};
  padding-left: ${rem(20)};
  border-bottom: ${rem(1)} solid ${rgba(variables.colors.white, 0.15)};

  ${Config.MEDIA.FROM_DESKTOP} {
    float: right;
    border: 0;
    padding: 0;
  }
`;

export const StyledTrustedSiteIcon = styled.img`
  height: ${rem(72)};
  width: auto;
`;

export const StyledCopyright = styled(Text)`
  ${Config.MEDIA.BELOW_DESKTOP} {
    text-align: center;
    padding-top: ${rem(20)};
  }
`;

export const StyledSocialMediaWrapper = styled.div`
  display: flex;
  gap: ${rem(20)};

  padding: ${rem(15)} ${rem(20)};
  ${Config.MEDIA.FROM_DESKTOP} {
    padding: 0;
  }
`;

export const StyledSocialMediaImg = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
`;
