import { useEffect, useState } from 'react';
import useSmartEdit from "@hooks/useSmartEdit";

interface Props {
  image: imageMediaType;
}
const useShouldShowSkeleton = ({ image }: Props) => {
  const { isSmartEditEnabled } = useSmartEdit();
  const [show, setShow] = useState(!!image);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setShow(false);
    };

    if (image?.url) {
      img.src = image.url;
    }
  }, [image?.url]);

  if (isSmartEditEnabled) {
    return { showSkeleton: false };
  }
  return { showSkeleton: show };
};

export default useShouldShowSkeleton;
