import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import Config from '@config';
import { rem, rgba } from 'polished';
import Icon from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';

export const StyledButton = styled.button`
  border: none;
  display: flex;
  cursor: pointer;
  background-color: ${variables.colors.white};
  align-items: center;
  padding: 0 ${rem(20)};
  margin-right: 0;
  position: relative;
  border-left: ${rem(1)} solid ${variables.colors.lightGray};

  ${Config.MEDIA.FROM_DESKTOP} {
    border-left: none;
    margin-right: ${rem(-20)};
  }

  * {
    pointer-events: none;
  }
`;

export const StyledButtonText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: ${rem(10)};
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamMedium};
  max-width: ${rem(110)};

  ${Config.BREAKPOINTS.FROM_MOBILE} {
    max-width: ${rem(150)};
  }
  ${Config.BREAKPOINTS.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(400)};
  }
`;

export const StyledLogoutButton = styled(Button)`
  min-width: ${rem(150)};
  margin: 0 ${rem(50)};
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${rem(18)};
`;

export const StyledNavLink = styled(Link)`
  width: 100%;
  font-family: ${variables.fonts.fontGothamBook};
  padding: 0 ${rem(16)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  height: ${rem(50)};
  color: ${variables.colors.lighterBlack};
  background-color: ${variables.colors.white};
  font-size: ${rem(13)};
  height: ${rem(40)};

  &:visited {
    color: ${variables.colors.lighterBlack};
  }
  &:hover {
    color: ${variables.colors.lighterBlack};
  }
`;

export const StyledCustomerName = styled(Text)`
  padding: ${rem(16)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  margin-bottom: ${rem(8)};
`;

export const StyledLinksWrapper = styled.div`
  flex-grow: 1;
`;
