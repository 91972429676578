import variables from '@styles/variables';

const themeSettings: any = {
  error: {
    backgroundColor: variables.colors.primary,
    link: variables.colors.white,
    linkHover: variables.colors.white,
    linkUnderline: true,
    header: 'white',
    text: 'white',
  },
  info: {
    backgroundColor: variables.colors.lighterGray,
    linkUnderline: false,
    header: 'black',
    text: 'black',
  },
};

export default themeSettings;
