import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackLinkNavigation = (category: string, linkText?: string) => {
  if (category && linkText) {
    pushGTMEvent({
      event: 'Track',
      category,
      action: 'navigation',
      label: linkText,
      value: 0,
    });
  }
};

export default trackLinkNavigation;
