import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface CartUpdatingStatus {
  isUpdating: boolean;
  editReplacement: boolean;
}

const initialState: CartUpdatingStatus = {
  isUpdating: false,
  editReplacement: false,
};

export const cartUpdatingSlice = createAppSlice({
  name: 'cartUpdating',
  initialState,
  reducers: (create) => ({
    setIsUpdatingCart: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    }),
    setEditReplacement: create.reducer((state, action: PayloadAction<boolean>) => {
      state.editReplacement = action.payload;
    }),
  }),
  selectors: {
    selectCartUpdating: (cartUpdating) => cartUpdating,
    selectCartIsUpdating: (cartUpdating) => cartUpdating.isUpdating,
    selectCartUpdatingEditReplacement: (cartUpdating) => cartUpdating.editReplacement,
    selectEditReplacementBool: (cartUpdating) => Boolean(cartUpdating.editReplacement),
  },
});

// Selectors
export const { selectCartUpdating, selectCartIsUpdating, selectCartUpdatingEditReplacement, selectEditReplacementBool } =
  cartUpdatingSlice.selectors;

// Actions
export const { setIsUpdatingCart, setEditReplacement } = cartUpdatingSlice.actions;

export default cartUpdatingSlice.reducer;
