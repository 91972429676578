import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';
import Link from '@atoms/Link/Link';

export const StyledListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${rem(60)};
`;

export const StyledListItem = styled.li<{
  isExpanded: boolean;
  isRoot: boolean;
}>`
  border-bottom: ${rem(1)} solid ${rgba(variables.colors.white, 0.1)};
  text-transform: ${(props) => (props.isRoot ? 'uppercase' : 'none')};
  &:last-of-type {
    border: none;
  }
  > ${StyledListContainer} {
    display: none;
  }

  ${(props) =>
    props.isExpanded &&
    css`
      min-height: 100%;

      > ${StyledListContainer} {
        display: block;
        background-color: ${variables.colors.black};
      }

      > ${StyledContent} {
        background-color: ${variables.colors.lighterBlack};
        a {
          font-family: ${variables.fonts.fontGothamMedium};
          color: ${variables.colors.white};
        }
      }
    `}
`;

export const StyledFooterLink = styled(Link)`
  display: block;
  height: 100%;
  width: 100%;
  min-width: 0;
  line-height: ${rem(60)};
  font-size: ${rem(13)};
  color: ${variables.colors.mediumGray};
  font-family: ${variables.fonts.fontGothamBook};

  &:visited {
    color: ${variables.colors.mediumGray};
  }

  /* overrides for as={Text} */
  &:hover {
    cursor: pointer;
    color: ${variables.colors.darkGray};
  }
`;

export const StyledTextWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledToggleButton = styled.button`
  background: transparent;
  color: ${variables.colors.white};
  border: none;
  font-size: ${rem(25)};
  height: 100%;
  cursor: pointer;
  padding-right: ${rem(20)};
  padding-left: ${rem(20)};
  line-height: ${rem(60)};
  font-family: ${variables.fonts.fontGothamBook};
`;
