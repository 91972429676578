import axios from 'axios';
import { useEffect } from 'react';
import paths from '@constants/paths';
import debounce from '@helpers/debounce';
import { setOrderCloseTimePassedModalVisibility } from '@slices/modalSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { isAxiosError } from 'axios';
import Config from '@config';
import useCustomRouter from '@hooks/useCustomRouter';
import useUserAgent from '@hooks/useUserAgent';
import webToAppApi from '@api/web-to-app';

const useAxiosInterceptors = () => {
  const router = useCustomRouter();
  const dispatch = useAppDispatch();
  const { isNativeApp } = useUserAgent();

  const renewAuth = () => {
    if (isNativeApp) {
      webToAppApi.actionRenewAccessToken();
    } else if (router.pathname !== paths.USER_LOGIN) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, from: router.pathname, loginConfirm: true },
        },
        { pathname: paths.USER_LOGIN },
        { shallow: true }
      );
    }
  };

  const debouncedRenewAuth = debounce(renewAuth, Config.TIMEOUT.axiosInterceptorRedirectToLoginConfirmMs);

  useEffect(() => {
    const orderCloseTimePassedInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.data?.errorCode === 'order.openForEdit.orderCloseTimePassed') {
          dispatch(setOrderCloseTimePassedModalVisibility(true));
        } else {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axios.interceptors.response.eject(orderCloseTimePassedInterceptor);
    };
  }, []);

  useEffect(() => {
    const loginConfirmInterceptor = axios.interceptors.response.use(
      (res) => res,
      (error) => {
        if (isAxiosError(error)) {
          switch (error?.response?.status) {
            case 401:
            case 403:
              if (
                !(
                  typeof error?.response?.data === 'object' && (error.response.data as any).error === 'csrf.badormissing'
                )
              ) {
                if (error.response?.data.customerLoggedIn !== 'false') {
                  debouncedRenewAuth();
                }
              }
              break;
            default:
              break;
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(loginConfirmInterceptor);
    };
  }, [router.pathname]);
};

export default useAxiosInterceptors;
