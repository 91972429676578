import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import paths from '@constants/paths';

enum FilterLocation {
  SEARCH = 'search_page',
  CATEGORY = 'category_page',
  PROMOTION_ECOMMERCE = 'promotion_page_ecommerce',
  PROMOTION_STORE = 'promotion_page_store',
}

const getCategory = (path: string) => {
  if (path.startsWith(paths.SEARCH)) return FilterLocation.SEARCH;
  if (path.startsWith(paths.CATEGORY)) return FilterLocation.CATEGORY;
  if (path.startsWith(paths.OFFERS_ECOMMERCE)) return FilterLocation.PROMOTION_ECOMMERCE;
  if (path.startsWith(paths.OFFERS_STORE)) return FilterLocation.PROMOTION_STORE;
  return '';
};

const trackFilterOpened = (path: string, label: string) => {
  pushGTMEvent({
    event: 'Track',
    category: getCategory(path),
    action: 'filter_opened',
    label,
    value: 0,
  });
};
export default trackFilterOpened;
