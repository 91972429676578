import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackFaqWidgetToggle = (open: boolean) => {
  pushGTMEvent({
    event: 'Track',
    category: 'imbox',
    action: open ? 'opened_widget' : 'closed_widget',
    label: '',
    value: 0,
  });
};
