import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';
import Autocomplete from '@molecules/Autocomplete/Autocomplete';
import Button from '@atoms/Button/Button';
import Config from '@config';
import InputField from '@atoms/InputField/InputField';
import Form from '@molecules/Form/Form';

export const StyledStoreFinder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledStoreFinderFieldWrapper = styled.div<{ isTakeover: boolean }>`
  flex: 1;
  position: relative;
  margin: 0;

  ${(props) =>
    props.isTakeover &&
    css`
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${variables.colors.white};
      z-index: ${variables.zIndex.modal};
    `}
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-right: ${rem(20)};
  }
`;

export const StyledStoreFinderForm = styled(Form)`
  ${Config.MEDIA.IS_MOBILE} {
    flex-direction: column;
    gap: ${rem(20)};
    margin-bottom: ${rem(20)};
  }

  display: flex;
  margin-bottom: ${rem(38)};
  position: relative;
  z-index: ${variables.zIndex['1']};
`;

export const StyledMap = styled.div`
  width: 100%;
  height: ${rem(570)};
  background-color: lightblue;

  // Override google styling

  .gm-style-iw {
    padding: 0 !important;

    > button {
      top: 0 !important;
      right: 0 !important;
    }
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  gap: ${rem(13)};
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(224)};
  }
`;

export const StyledAutocomplete = styled(Autocomplete)<{ isTakeover: boolean }>`
  position: absolute;
  top: ${rem(52)};
  max-height: ${rem(300)};
  overflow: auto;
  ${(props) =>
    props.isTakeover &&
    css`
      max-height: unset;
      height: 100%;
      top: ${rem(60)};
      margin-top: ${rem(32)};
    `}
`;

export const StyledInputField = styled(InputField)<{ open: boolean }>`
  padding-left: ${rem(41)};
  padding-bottom: 0;
  ${(props) =>
    props.open &&
    css`
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        border: 0 none !important;
        border-radius: ${rem(26)} ${rem(26)} 0 0;
        box-shadow: 0 0 ${rem(28)} 0 ${rgba(24, 24, 24, 0.1)};
      }
    `}
`;
