import { css } from 'styled-components';
import { ThemeTypes, SizeTypes } from '@molecules/AddToCart/AddToCart';
import variables from '@styles/variables';
import { rem } from 'polished';

export const CommonInputFieldStyle = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size?: SizeTypes;
}>`
  margin: 0;
  border-radius: 0;
  border: 0 none;
  text-align: center;
  color: ${variables.colors.lighterBlack};
  background-color: transparent;
  height: ${rem(36)};
  font-size: ${rem(15)};
  font-family: ${variables.fonts.fontGothamMedium};

  ${(props) =>
    props.size === 'small' &&
    css`
      height: ${rem(28)};
    `};

  &:disabled {
    color: ${variables.colors.darkGray};
    -webkit-text-fill-color: ${variables.colors.darkGray};
    background-color: transparent;
  }

  &:focus {
    border: 0 none;
  }
`;

export const PrimaryInputFieldStyle = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size?: SizeTypes;
}>`
  ${CommonInputFieldStyle};
  color: ${variables.colors.lighterBlack};

  ${(props) =>
    props.hasItems &&
    css`
      color: ${variables.colors.white};

      &:disabled {
        -webkit-text-fill-color: ${variables.colors.white};
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      color: ${variables.colors.lighterBlack};
      ${props.hasItems &&
      css`
        color: ${variables.colors.white};
      `}
    `}
`;

export const SecondaryInputFieldStyle = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size?: SizeTypes;
}>`
  ${CommonInputFieldStyle};

  font-size: ${rem(13)};
  padding: 0;
  ${(props) =>
    props.hasItems &&
    css`
      color: ${variables.colors.lighterBlack};

      &:disabled {
        -webkit-text-fill-color: ${variables.colors.lighterBlack};
      }
    `}
  ${(props) =>
    props.isActive &&
    css`
      ${props.hasItems &&
      css`
        color: ${variables.colors.lighterBlack};
      `}
    `}
`;

export const WishlistInputFieldStyle = css<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size?: SizeTypes;
}>`
  ${CommonInputFieldStyle};

  font-size: ${rem(13)};
  padding: 0;
  ${(props) =>
    props.hasItems &&
    css`
      color: ${variables.colors.lighterBlack};

      &:disabled {
        -webkit-text-fill-color: ${variables.colors.darkerGray};
      }
    `}
  ${(props) =>
    props.isActive &&
    css`
      ${props.hasItems &&
      css`
        color: ${variables.colors.lighterBlack};
      `}
    `}
`;
