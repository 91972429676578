import { createAppSlice } from './createAppSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ConflictingModalType {
  open: boolean;
  items: Array<any>; // Actually Array<AxfoodCartEntryStatusViewModel>
  rejected: null | ((x: any) => void);
  resolved: null | ((value: any) => void);
  storeId?: string;
  reason?: string;
}

const initialState: ConflictingModalType = {
  open: false,
  items: [],
  resolved: null,
  rejected: null,
  storeId: undefined,
  reason: '',
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const conflictingModalSlice = createAppSlice({
  name: 'conflictingModal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    openConflictingModal: create.reducer((state, action: PayloadAction<{ open: boolean; reason?: string }>) => {
      state.open = action.payload.open;
      state.reason = action.payload.reason;
    }),
    setConflictingModalStoreId: create.reducer((state, action: PayloadAction<string>) => {
      state.storeId = action.payload;
    }),
    setItemsConflictingModal: create.reducer((state, action: PayloadAction<Array<any>>) => {
      state.items = action.payload;
    }),
    setResolveConflictingModal: create.reducer((state, action: PayloadAction<(value: any) => void>) => {
      state.resolved = action.payload;
    }),
    setRejectConflictingModal: create.reducer((state, action: PayloadAction<(reason: any) => void>) => {
      state.rejected = action.payload;
    }),
    setStateConflictingModal: create.reducer((state, action: PayloadAction<ConflictingModalType>) => {
      return action.payload;
    }),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectConflictingModal: (modal) => modal,
  },
});

// Actions
export const {
  openConflictingModal,
  setConflictingModalStoreId,
  setItemsConflictingModal,
  setResolveConflictingModal,
  setRejectConflictingModal,
  setStateConflictingModal,
} = conflictingModalSlice.actions;

// Selectors
export const { selectConflictingModal } = conflictingModalSlice.selectors;
