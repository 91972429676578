/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppVersionData } from '../dto';
// @ts-ignore
import { FAQEntryData } from '../dto';
// @ts-ignore
import { JobAdvertData } from '../dto';
// @ts-ignore
import { ReactTranslationData } from '../dto';
// @ts-ignore
import { ResponseEntity } from '../dto';
/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get supported app version from db
         * @param {string} deviceType deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersionNonStaticUsingGET: async (deviceType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceType' is not null or undefined
            assertParamExists('getAppVersionNonStaticUsingGET', 'deviceType', deviceType)
            const localVarPath = `/axfood/rest/non-static-supported-app-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (deviceType !== undefined) {
                localVarQueryParameter['deviceType'] = deviceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supported app version with static value from nginx
         * @param {string} deviceType deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersionUsingGET: async (deviceType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceType' is not null or undefined
            assertParamExists('getAppVersionUsingGET', 'deviceType', deviceType)
            const localVarPath = `/axfood/rest/supported-app-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (deviceType !== undefined) {
                localVarQueryParameter['deviceType'] = deviceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entries for category
         * @param {string} categoryUid categoryUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntriesForCategoryUsingGET: async (categoryUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryUid' is not null or undefined
            assertParamExists('getEntriesForCategoryUsingGET', 'categoryUid', categoryUid)
            const localVarPath = `/axfood/rest/faq/category/{categoryUid}/entries`
                .replace(`{${"categoryUid"}}`, encodeURIComponent(String(categoryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get job adverts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobAdvertsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get react translations for namespace
         * @param {string} site site
         * @param {string} namespace namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactTranslationsForNamespaceUsingGET: async (site: string, namespace: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('getReactTranslationsForNamespaceUsingGET', 'site', site)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getReactTranslationsForNamespaceUsingGET', 'namespace', namespace)
            const localVarPath = `/axfood/rest/translation/react/{namespace}`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (site !== undefined) {
                localVarQueryParameter['site'] = site;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get react translations
         * @param {string} site site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactTranslationsUsingGET: async (site: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('getReactTranslationsUsingGET', 'site', site)
            const localVarPath = `/axfood/rest/translation/react`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (site !== undefined) {
                localVarQueryParameter['site'] = site;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get translations
         * @param {string} site site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsUsingGET: async (site: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('getTranslationsUsingGET', 'site', site)
            const localVarPath = `/axfood/rest/translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (site !== undefined) {
                localVarQueryParameter['site'] = site;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register device
         * @param {string} customerId customerId
         * @param {string} appToken appToken
         * @param {string} deviceType deviceType
         * @param {string} [endpointARN] endpointARN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDeviceUsingGET: async (customerId: string, appToken: string, deviceType: string, endpointARN?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('registerDeviceUsingGET', 'customerId', customerId)
            // verify required parameter 'appToken' is not null or undefined
            assertParamExists('registerDeviceUsingGET', 'appToken', appToken)
            // verify required parameter 'deviceType' is not null or undefined
            assertParamExists('registerDeviceUsingGET', 'deviceType', deviceType)
            const localVarPath = `/axfood/rest/device/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (appToken !== undefined) {
                localVarQueryParameter['appToken'] = appToken;
            }

            if (endpointARN !== undefined) {
                localVarQueryParameter['endpointARN'] = endpointARN;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['deviceType'] = deviceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get supported app version from db
         * @param {string} deviceType deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppVersionNonStaticUsingGET(deviceType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppVersionData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppVersionNonStaticUsingGET(deviceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supported app version with static value from nginx
         * @param {string} deviceType deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppVersionUsingGET(deviceType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppVersionData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppVersionUsingGET(deviceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get entries for category
         * @param {string} categoryUid categoryUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntriesForCategoryUsingGET(categoryUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FAQEntryData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntriesForCategoryUsingGET(categoryUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get job adverts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobAdvertsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobAdvertData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobAdvertsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get react translations for namespace
         * @param {string} site site
         * @param {string} namespace namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReactTranslationsForNamespaceUsingGET(site: string, namespace: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactTranslationData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReactTranslationsForNamespaceUsingGET(site, namespace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get react translations
         * @param {string} site site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReactTranslationsUsingGET(site: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReactTranslationData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReactTranslationsUsingGET(site, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get translations
         * @param {string} site site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationsUsingGET(site: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslationsUsingGET(site, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register device
         * @param {string} customerId customerId
         * @param {string} appToken appToken
         * @param {string} deviceType deviceType
         * @param {string} [endpointARN] endpointARN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDeviceUsingGET(customerId: string, appToken: string, deviceType: string, endpointARN?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDeviceUsingGET(customerId, appToken, deviceType, endpointARN, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration)
    return {
        /**
         * 
         * @summary Get supported app version from db
         * @param {string} deviceType deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersionNonStaticUsingGET(deviceType: string, options?: any): AxiosPromise<AppVersionData> {
            return localVarFp.getAppVersionNonStaticUsingGET(deviceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supported app version with static value from nginx
         * @param {string} deviceType deviceType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersionUsingGET(deviceType: string, options?: any): AxiosPromise<AppVersionData> {
            return localVarFp.getAppVersionUsingGET(deviceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entries for category
         * @param {string} categoryUid categoryUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntriesForCategoryUsingGET(categoryUid: string, options?: any): AxiosPromise<Array<FAQEntryData>> {
            return localVarFp.getEntriesForCategoryUsingGET(categoryUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get job adverts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobAdvertsUsingGET(options?: any): AxiosPromise<Array<JobAdvertData>> {
            return localVarFp.getJobAdvertsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get react translations for namespace
         * @param {string} site site
         * @param {string} namespace namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactTranslationsForNamespaceUsingGET(site: string, namespace: string, options?: any): AxiosPromise<ReactTranslationData> {
            return localVarFp.getReactTranslationsForNamespaceUsingGET(site, namespace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get react translations
         * @param {string} site site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactTranslationsUsingGET(site: string, options?: any): AxiosPromise<Array<ReactTranslationData>> {
            return localVarFp.getReactTranslationsUsingGET(site, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get translations
         * @param {string} site site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsUsingGET(site: string, options?: any): AxiosPromise<object> {
            return localVarFp.getTranslationsUsingGET(site, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register device
         * @param {string} customerId customerId
         * @param {string} appToken appToken
         * @param {string} deviceType deviceType
         * @param {string} [endpointARN] endpointARN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDeviceUsingGET(customerId: string, appToken: string, deviceType: string, endpointARN?: string, options?: any): AxiosPromise<ResponseEntity> {
            return localVarFp.registerDeviceUsingGET(customerId, appToken, deviceType, endpointARN, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI {
    /**
     * 
     * @summary Get supported app version from db
     * @param {string} deviceType deviceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getAppVersionNonStaticUsingGET(deviceType: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getAppVersionNonStaticUsingGET(deviceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supported app version with static value from nginx
     * @param {string} deviceType deviceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getAppVersionUsingGET(deviceType: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getAppVersionUsingGET(deviceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entries for category
     * @param {string} categoryUid categoryUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getEntriesForCategoryUsingGET(categoryUid: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getEntriesForCategoryUsingGET(categoryUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get job adverts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getJobAdvertsUsingGET(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getJobAdvertsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get react translations for namespace
     * @param {string} site site
     * @param {string} namespace namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getReactTranslationsForNamespaceUsingGET(site: string, namespace: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getReactTranslationsForNamespaceUsingGET(site, namespace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get react translations
     * @param {string} site site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getReactTranslationsUsingGET(site: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getReactTranslationsUsingGET(site, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get translations
     * @param {string} site site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getTranslationsUsingGET(site: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getTranslationsUsingGET(site, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register device
     * @param {string} customerId customerId
     * @param {string} appToken appToken
     * @param {string} deviceType deviceType
     * @param {string} [endpointARN] endpointARN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public registerDeviceUsingGET(customerId: string, appToken: string, deviceType: string, endpointARN?: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).registerDeviceUsingGET(customerId, appToken, deviceType, endpointARN, options).then((request) => request(this.axios, this.basePath));
    }
}
