import { Key } from 'react';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { BANK_ID_DEVICES } from '@constants/bankid';
import { LoginFlowType } from '@organisms/BankIdLoginFlow/bankIdTypes';
import { CustomerData } from '@occ/api-client';

export const trackLoginConfirm = (isB2BCustomer: boolean, isCheckout: boolean) => {
  pushGTMEvent({
    event: 'Track',
    category: isCheckout ? 'checkout' : 'login_modal',
    action: `${isB2BCustomer ? 'corporate_customers_' : ''}login_to_confirm`,
    label: '',
    value: 0,
  });
};

export const trackIdentifyCustomer = (customerData: CustomerData, previousLogin: boolean) => {
  if (customerData.uid === 'anonymous' || customerData.idInSourceSystem === '0') {
    pushGTMEvent({
      event: 'identify',
      loggedIn: 'false',
    });
  } else {
    pushGTMEvent({
      event: 'identify',
      userId: customerData.idInSourceSystem,
      customerType: customerData.isB2BCustomer ? 'company' : 'individual',
      loginSource: previousLogin ? 'previous_login' : 'user_login',
    });
  }
};

export const trackLoginError = (errorMessage: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'login_modal',
    action: 'error_message_shown',
    label: errorMessage,
    value: 0,
  });
};

export const trackLoginModalTabChange = (key: Key, dontTrack?: boolean) => {
  if (dontTrack) return;
  const action = key === 'b2b' ? 'toggled_to_company' : key === 'bankId' ? 'toggled_to_BankId' : 'toggled_to_password';
  pushGTMEvent({
    event: 'Track',
    category: 'login_modal',
    action,
    label: '',
    value: 0,
  });
};

export const trackBankIdLoginDevice = (device: BANK_ID_DEVICES) => {
  const loginDevice = device === 'sameDevice' ? 'this_device' : device === 'otherDevice' ? 'other_device' : '';
  if (loginDevice) {
    pushGTMEvent({
      event: 'Track',
      category: 'login_modal',
      action: 'login_attempted',
      label: `bankid_on_${loginDevice}`,
      value: 0,
    });
  }
};

type ScreenNameType = 'QR' | 'Pending Statuses';
export const trackBankIdLoginCancelled = (screenName: ScreenNameType) => {
  pushGTMEvent({
    event: 'Track',
    category: 'login_modal',
    action: 'login_attempted',
    label: `bankid_login_aborted_${screenName}`,
    value: 0,
  });
};

export const trackBankIdError = (device: BANK_ID_DEVICES, errorMessage: string) => {
  const loginDevice = device === 'sameDevice' ? 'this_device' : device === 'otherDevice' ? 'other_device' : '';
  pushGTMEvent({
    event: 'Track',
    category: 'login_modal',
    action: 'error_message_shown',
    label: `BankID: ${errorMessage} / ${loginDevice}`,
    value: 0,
  });
};

export const trackLoginFlowAndSettings = (loginType: LoginFlowType, rememberMe: boolean) => {
  pushGTMEvent({
    event: 'Track',
    category: 'login_modal',
    action: 'user_logged_in_with_' + loginType,
    label: `keep_me_logged_in: ${rememberMe ? 'yes' : 'no'}`,
    value: 0,
  });
};
