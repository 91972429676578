import useSWR from 'swr';
import { CategoryNavigationTreeDeviceType, getCategoryNavigationTree } from '@api/interfaces/componentApi';
import UserAgent from '@helpers/useragent';
import prefixCategoryUrl from '@helpers/prefixCategoryUrl';
import useStore from '@hooks/useStore';

const getDeviceType = (): CategoryNavigationTreeDeviceType => {
  if (UserAgent.isNativeApp()) {
    if (UserAgent.isIOS()) {
      return 'IOS';
    } else if (UserAgent.isAndroid()) {
      return 'ANDROID';
    }
  }

  return 'OTHER';
};

type KeyType = {
  storeId: string;
};

const fetcher = async ({ storeId }: KeyType) => {
  const categoryNavigationTree = await getCategoryNavigationTree({ storeId, deviceType: getDeviceType() });
  prefixCategoryUrl(categoryNavigationTree.children);
  return categoryNavigationTree;
};

const useCategoryNavigationTree = () => {
  const { store } = useStore();
  const storeId = store?.storeId;

  const key = { endpoint: '/leftMenu/categorytree', storeId };

  // don't fetch categories before fetching store
  const shouldFetch = Boolean(store);
  const { data, isLoading } = useSWR(shouldFetch ? key : null, fetcher);

  return {
    data,
    isLoading,
  };
};

export default useCategoryNavigationTree;
