import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import GridCol from '@molecules/GridCol/GridCol';
import { rem } from 'polished';
import Link from '@atoms/Link/Link';
import Grid from '@atoms/Grid/Grid';

export const StyledSmallGridComponent = styled(GridCol)`
  z-index: 1;
  width: calc(100% - ${rem(1)});
  border: ${rem(1)} solid ${variables.colors.primary};
  margin: ${rem(-1)} ${rem(-1)} 0 ${rem(-1)};
  box-sizing: content-box;
`;

export const StyledGrid = styled(Grid)`
  height: 100%;
  margin: 0;
`;

export const StyledProductCol = styled(GridCol)<{ imagePosition: 'LEFT' | 'RIGHT' }>`
  display: block;

  > div {
    border: 0 !important;
    box-sizing: border-box !important;
    margin: 0 !important;
    ${(props) =>
      props.imagePosition === 'LEFT' &&
      css`
        border-left: ${rem(1)} solid ${variables.colors.lightGray} !important;
      `}
    ${(props) =>
      props.imagePosition === 'RIGHT' &&
      css`
        border-right: ${rem(1)} solid ${variables.colors.lightGray} !important;
      `}
  }
`;

/*
TODO move styling from StyledProductCol > div here when Product is styled-component.

export const StyledProductColItem = styled(Product)`
  border: 0 !important;
  box-sizing: border-box !important;
  margin: 0 !important;
`;
*/

export const StyledImageCol = styled(GridCol)`
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  color: unset !important;
`;
