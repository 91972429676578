import {useMediaQuery} from 'react-responsive';
import Config from '@config';
import {StyledCMSParagraphComponent} from './CMSParagraphComponent.styles';

type CmsParagraphComponentProps = {
  data: CmsParagraphComponentType;
  isStorePage?: boolean;
};

const CMSParagraphComponent = ({ data, isStorePage }: CmsParagraphComponentProps) => {
  const { content } = data;
  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });
  const showComponent = isStorePage ? fromDesktop : true;
  return showComponent ? (
    <StyledCMSParagraphComponent
      paddingBottom={data.paddingBottom}
      paddingLeft={data.paddingLeft}
      paddingTop={data.paddingTop}
      paddingRight={data.paddingRight}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : null;
};

export default CMSParagraphComponent;
