import styled from 'styled-components';
import Select from '@molecules/Select/Select';
import Icon from '@atoms/Icon/Icon';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';

export const StyledSelect = styled(Select)`
  padding: 0;
  width: ${rem(162)};
  margin: 0 0 ${rem(32)} auto;
`;

export const StyledNewsLine = styled.div`
  display: flex;
  gap: ${rem(20)};
  width: 100%;
  justify-content: space-between;
  text-align: left;
  padding: ${rem(20)} ${rem(20)} ${rem(20)} 0;
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
`;

export const StyledIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

export const StyledCenterText = styled(Text)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledLeftText = styled(Text)`
  width: ${rem(60)};
`;

export const StyledNewsComponent = styled.div`
  margin-bottom: ${rem(30)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-bottom: ${rem(40)};
  }
`;

export const StyledShowMoreButton = styled(Button)`
  margin: ${rem(30)} auto 0 auto;
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin: ${rem(40)} auto 0 auto;
  }
`;
