import { SearchApi, AxfoodApi } from '@occ/api-client';

const searchApi = new SearchApi({
  baseUrl: '',
});

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const getSearchResultsAsYouType = async (query: string, page: number, size: number) =>
  searchApi.getSearchResultsAsYouTypeUsingGet(
    {
      q: query,
      page,
      size,
    },
    { format: 'json' }
  );

export const getSearchResults = async (
  query: string,
  page: number,
  size: number,
  show?: 'Page' | 'All',
  sort?: string
) => {
  const response = await searchApi.getSearchResultsUsingGet1(
    {
      q: query,
      page,
      size,
      show,
      sort,
    },
    { format: 'json' }
  );
  return response.data;
};

export const getSuggestedSearchTerms = (query: string) =>
  searchApi.getAutocompleteSuggestionsUsingGet('SearchBox', { term: query });

export const getSearchCleanResults = (q: string, page: number, size: number) =>
  searchApi.getSearchCleanResultsUsingGet(
    {
      q,
      page,
      size,
    },
    { format: 'json' }
  );

export const getProduct = async (productCode: string) => {
  const response = await axfoodApi.getProductUsingGet1(productCode);
  return response.data;
};

export const getPromotionProduct = async (storeId: string, promotionCode: string) => {
  const response = await axfoodApi.getProductUsingGet(storeId, promotionCode);
  return response.data;
};

interface GetMarketingProductsParams {
  productCode: string;
  variant: string;
  page: number;
  pageSize: number;
}

export const getMarketingProducts = (
  { productCode, variant: displayType, page, pageSize }: GetMarketingProductsParams,
  signal: AbortSignal
) => axfoodApi.getProductRecommendationUsingGet(productCode, displayType, { page, pageSize }, { signal });

interface GetPromotionProductsParams {
  promotion: string;
  excludeProducts: string;
  page: number;
  pageSize: number;
}
export const getPromotionProducts = ({ promotion: promotionCode }: GetPromotionProductsParams, signal: AbortSignal) =>
  axfoodApi.getPromotionProductsUsingGet(promotionCode, {}, { signal });
