import { useEffect, useRef } from 'react';

const useDocumentScroll = (
  callback: (callBackObject: { previousScrollTop: number; currentScrollTop: number }) => void,
  deps?: unknown[]
) => {
  const previousScrollTop = useRef(0);

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body;

    callback({ previousScrollTop: previousScrollTop.current, currentScrollTop });

    previousScrollTop.current = currentScrollTop;
  }

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScroll);
    return () => {
      window.removeEventListener('scroll', handleDocumentScroll);
    };
  }, deps);
};

export default useDocumentScroll;
