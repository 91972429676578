import { ChangeEvent, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { StyledCreateForm, StyledFieldsWrapper, StyledInputField } from './CreateNewListForm.styles';
import Button from '@atoms/Button/Button';
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';

interface Props {
  onCreateNewList: (input: string) => void;
  isSaving: boolean;
}

const today = format(new Date(), 'dd MMM yyyy', { locale: sv });

const CreateNewListForm = ({ onCreateNewList, isSaving }: Props) => {
  const { t } = useTranslation('addToList');
  const defaultNewListName = t('addToList->defaultListName', { date: today });
  const [inputValue, setInputValue] = useState(defaultNewListName);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onCreateNewListHandler = () => {
    if (inputValue.trim()) {
      onCreateNewList(inputValue);
    }
  };

  const isBtnDisabled = !inputValue.trim() || isSaving;

  return (
    <StyledCreateForm>
      <StyledFieldsWrapper>
        <StyledInputField
          name="new-list"
          type="text"
          placeholder={t('addToList->input->placeholder')}
          onChange={onChangeHandler}
          disabled={isSaving}
          aria-label={t('addToList->new->label')}
          value={inputValue}
          clearable
          onClear={() => setInputValue('')}
        />

        <Button
          disabled={isBtnDisabled}
          onClick={onCreateNewListHandler}
          theme="primary"
          type="button"
          data-testid="add-to-list-create-form-button"
          isSubmitting={isSaving}
        >
          {t('addToList->button->unsaved')}
        </Button>
      </StyledFieldsWrapper>
    </StyledCreateForm>
  );
};

export default CreateNewListForm;
