import useSWR from 'swr';
import useCustomer from '@hooks/useCustomer';
import { getProductsForBuyAllBanner } from '@api/interfaces/componentApi';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';

const useBuyAllProducts = (componentId: string) => {
  const { customer } = useCustomer();
  const { store } = useStore();
  const { cart } = useCart();

  const key = {
    endpoint: '/buyAllProductsComponent',
    customerId: customer?.uid,
    storeId: store?.storeId,
    slotCode: cart?.slot?.code,
    componentId,
  };
  const shouldFetch = Boolean(store) && Boolean(componentId) && Boolean(customer);
  const { data, isLoading, error } = useSWR(shouldFetch ? key : null, ({ componentId }) =>
    getProductsForBuyAllBanner(componentId)
  );

  return { data, isLoading, error };
};

export default useBuyAllProducts;
