import { ReactNode, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import trackShowMore from '@helpers/analyticsHelpers/trackShowMoreProductInfo';
import { StyledExandableContainer, StyledExandableContainerButton } from './ExpandableContainer.styles';

interface Props {
  children: ReactNode;
  maxHeight: number;
  meta: string;
  initiallyExpanded?: boolean;
}

const ExpandableContainer = ({ maxHeight, children, meta, initiallyExpanded }: Props) => {
  const setInitiallyExpanded = initiallyExpanded || false;
  const [isExpanded, setIsExpanded] = useState(setInitiallyExpanded);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const { t } = useTranslation('expandableContainer');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = contentRef?.current as any;
    setIsOverflowed(current.getBoundingClientRect().height > maxHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef?.current?.getBoundingClientRect().height]);

  const handleClick = () => {
    if (!isExpanded) {
      trackShowMore(meta);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div onClick={() => (!isExpanded ? setIsExpanded(!isExpanded) : null)}>
      <StyledExandableContainer
        $faded={!isExpanded && isOverflowed}
        height={!isExpanded ? maxHeight : 'auto'}
        data-testid="expandable-container-animated-area"
      >
        <div ref={contentRef}>{children}</div>
      </StyledExandableContainer>
      {isOverflowed && (
        <StyledExandableContainerButton theme="link" onClick={handleClick} data-testid="expandable-container-button">
          {t(isExpanded ? 'showLess' : 'showMore')}
        </StyledExandableContainerButton>
      )}
    </div>
  );
};

export default ExpandableContainer;
