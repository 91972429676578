import useTranslation from 'next-translate/useTranslation';
import HighlightText from '@atoms/HighlightText/HighlightText';
import Label from '@atoms/Label/Label';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import Text from '@atoms/Text/Text';
import Info from '@icons/info.svg';

type addressType = {
  town: string;
  line1: string;
  postalCode: string;
};

type suggestionType = {
  displayName: string;
  storeId: string;
  address: addressType;
  externalPickupLocation: string;
  franchise: string;
};

interface Props {
  suggestion: suggestionType;
  searchTerm: string;
  iteration: number;
  listComponentOnClick?: (
    displayName: string,
    storeId: string,
    address: addressType,
    suggestion: suggestionType
  ) => void;
  isOrderReopened?: boolean;
  selectedStore?: string;
}

const StyledPickupLocationListItem = styled.div<{ first?: boolean; disabled?: boolean }>`
  position: relative;
  padding: ${rem(17)} ${rem(28)} ${rem(17)} 0;
  flex: 1;
  font-family: ${variables.fonts.fontGothamBook};
  font-size: ${rem(15)};
  line-height: ${rem(24)};

  ${(props) =>
    props.first &&
    css`
      border-top: ${rem(1)} solid ${variables.colors.lightGray};
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${variables.colors.darkGray};
      cursor: default;
    `}
`;

const StyledPickupLocationListItemHead = styled.div<{ first?: boolean; disabled?: boolean }>`
  font-family: ${variables.fonts.fontGothamMedium};

  &-tooltip {
    position: absolute;
    right: ${rem(10)};
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledPickupLocationListItemTooltip = styled.div<{ first?: boolean; disabled?: boolean }>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const PickupLocationListItem = ({
  suggestion,
  searchTerm,
  iteration,
  listComponentOnClick,
  isOrderReopened,
  selectedStore,
}: Props) => {
  const { t } = useTranslation('deliverypicker');
  const { displayName, storeId, address, externalPickupLocation, franchise } = suggestion;
  const formattedPostalCode = address.postalCode
    ? `${address.postalCode.slice(0, 3)} ${address.postalCode.slice(3, 5)}`
    : '';
  const isSelectable = !(isOrderReopened && !!franchise && selectedStore !== suggestion.storeId);

  return (
    <StyledPickupLocationListItem
      data-testid="pickup-location-list-item"
      first={iteration === 0}
      data-disabled={!isSelectable}
      disabled={!isSelectable}
      onClick={() => {
        if (listComponentOnClick && isSelectable) listComponentOnClick(displayName, storeId, address, suggestion);
      }}
    >
      {externalPickupLocation && (
        <Label variant="mixAndMatch">
          {t('deliveryPicker->autoComplete->pickupLocation->labels->externalLocation')}
        </Label>
      )}

      <StyledPickupLocationListItemHead>
        <HighlightText disabled={!isSelectable} text={displayName} term={searchTerm} />
      </StyledPickupLocationListItemHead>
      <Text type="body" size="small">
        <HighlightText disabled={!isSelectable} text={`${address.line1}, `} term={searchTerm} />
        <HighlightText disabled={!isSelectable} text={`${formattedPostalCode} ${address.town}`} term={searchTerm} />
      </Text>
      {!isSelectable && (
        <StyledPickupLocationListItemTooltip>
          <ScreenReaderMessage>
            {t('deliveryPicker->autoComplete->pickupLocation->franchise->storeNotChangeable')}
          </ScreenReaderMessage>
          <IconTooltip
            position="row-left"
            align="right"
            content={t('deliveryPicker->autoComplete->pickupLocation->franchise->storeNotChangeable')}
            iconProps={{ svg: Info, size: 16 }}
          />
        </StyledPickupLocationListItemTooltip>
      )}
    </StyledPickupLocationListItem>
  );
};

export default PickupLocationListItem;
