import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackFormError = (source: string, name: string, message: string, prefix = '') => {
  pushGTMEvent({
    event: 'Track',
    category: `${source}_error`,
    action: 'error_message_shown',
    label: `${prefix ? `${prefix}_` : ''}${name}_${message}`,
    value: 0,
  });
};
