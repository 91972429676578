import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import PriceLabel from '@components/molecules/PriceLabel/PriceLabel';
import type { AxfoodBasicProductViewModel } from '@occ/api-client';
import ProductQuantityInputField from '@molecules/ProductQuantityInputField/ProductQuantityInputField';
import useResponsive from '@hooks/useResponsive';
import {
  StyledImageContainer,
  StyledListItemSavePrice,
  StyledPriceLabelWrapper,
  StyledProductInfo,
  StyledProductLink,
  StyledProductListItem,
  StyledProductName,
  StyledText,
  StyledWillysImagePlaceholder,
} from '@molecules/Autocomplete/ListItems/SearchAsYouTypeListItem.styles';
import useCustomRouter from '@hooks/useCustomRouter';
import slugify from '@helpers/slugify';
import paths from '@constants/paths';
import { useProductImage } from '@hooks/useProductImage';
import WillysImagePlaceholder from '@icons/non-standard/WILLYS-IMAGE-PLACEHOLDER-72px.svg';
import { forwardRef } from 'react';

interface Props {
  product: AxfoodBasicProductViewModel;
  onProductClick: (product: AxfoodBasicProductViewModel) => void;
  searchTerm: string;
}

const ProductListItem = forwardRef<HTMLDivElement, Props>(({ product, onProductClick, searchTerm }: Props, ref) => {
  const { t } = useTranslation('product');
  const router = useCustomRouter();
  const { isMobile } = useResponsive();

  const promotion = product?.potentialPromotions?.[0];
  const hasPromotion = !!promotion;
  const hasMixMatch = promotion?.realMixAndMatch === true;
  const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
  const hasComparePrice = (product?.comparePrice !== '' && product?.comparePriceUnit) || null;
  // @ts-ignore
  const lowestHistoricalPrice = promotion?.lowestHistoricalPrice;
  const url = `${slugify(product.name)}-${product.code}`;
  const query = { ...router.query, name: encodeURIComponent(url), productCode: product.code };
  const as = `${paths.PDP}/${encodeURI(url)}`;
  const { productImageURL, productImageAltText, onImageLoadError } = useProductImage(product);

  return (
    <StyledProductListItem data-testid="sayt-list-item" ref={ref}>
      <StyledProductLink
        href={{ pathname: router.pathname, query }}
        onClick={() => onProductClick(product)}
        nativeAppMethodOverride="replace"
        asProp={{ pathname: as }}
        scroll={false}
        internal
        shallow
      >
        <StyledImageContainer>
          {product ? (
            product?.image && (
              <Image
                unoptimized
                itemProp="image"
                alt={productImageAltText}
                src={productImageURL}
                width={isMobile ? 56 : 72}
                height={isMobile ? 56 : 72}
                onError={onImageLoadError}
              />
            )
          ) : (
            <StyledWillysImagePlaceholder svg={WillysImagePlaceholder} size={72} />
          )}
          {product && (
            <StyledPriceLabelWrapper>
              {hasSavePriceLabel &&
                (!lowestHistoricalPrice || lowestHistoricalPrice?.value === product.priceValue || hasMixMatch) && (
                  <StyledListItemSavePrice>
                    {hasMixMatch
                      ? promotion?.conditionLabelFormatted
                        ? promotion?.conditionLabelFormatted
                        : promotion?.conditionLabel
                      : promotion?.conditionLabel}
                  </StyledListItemSavePrice>
                )}
              <PriceLabel variant="list" product={product} />
            </StyledPriceLabelWrapper>
          )}
        </StyledImageContainer>

        <StyledProductInfo>
          {product && (
            <>
              <StyledProductName type="body" size="responsive">
                {product?.name}
              </StyledProductName>
              <StyledText type="detail" size="small" color="gray">
                {product?.productLine2}
              </StyledText>

              {!hasPromotion && (
                <StyledText type="detail" size="small" color="gray">
                  {hasComparePrice
                    ? `${t('product->price->compare')} ${product?.comparePrice}/${product?.comparePriceUnit}`
                    : t('product->price->compare-missing')}
                </StyledText>
              )}

              {hasPromotion && (
                <StyledText type="detail" size="small" color="red">
                  {promotion.comparePrice
                    ? `${t('product->price->compare')} ${
                        (promotion.comparePrice || '').indexOf('/') > -1
                          ? promotion.comparePrice
                          : `${promotion.comparePrice}/${product.comparePriceUnit}`
                      }`
                    : t('product->price->compare-missing')}

                  {`${promotion?.redeemLimitLabel ? ` • ${promotion?.redeemLimitLabel}` : ''}`}
                </StyledText>
              )}
            </>
          )}
        </StyledProductInfo>
      </StyledProductLink>

      <ProductQuantityInputField
        product={product}
        variant="cart"
        sizeOverride={isMobile ? 'xsmall' : 'medium'}
        listName={`search_as_you_type | ${searchTerm}`}
      />
    </StyledProductListItem>
  );
});

ProductListItem.displayName = 'ProductListItem';

export default ProductListItem;
