/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodBasicStatusResponseViewModel } from '../dto';
// @ts-ignore
import { AxfoodCheckB2BByIdDTO } from '../dto';
// @ts-ignore
import { AxfoodCheckB2BDTO } from '../dto';
// @ts-ignore
import { AxfoodCheckCustomerDTO } from '../dto';
// @ts-ignore
import { CheckCustomerForm } from '../dto';
// @ts-ignore
import { EncryptedTokenAssertionForm } from '../dto';
// @ts-ignore
import { VerifyB2BCustomerForm } from '../dto';
// @ts-ignore
import { VerifyCustomerForm } from '../dto';
/**
 * PasswordResetApi - axios parameter creator
 * @export
 */
export const PasswordResetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change
         * @param {string} [token] 
         * @param {string} [pwd] 
         * @param {string} [checkPwd] 
         * @param {boolean} [rememberMe] 
         * @param {string} [pwdKey] 
         * @param {string} [checkPwdKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST: async (token?: string, pwd?: string, checkPwd?: string, rememberMe?: boolean, pwdKey?: string, checkPwdKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/pw/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (pwd !== undefined) {
                localVarQueryParameter['pwd'] = pwd;
            }

            if (checkPwd !== undefined) {
                localVarQueryParameter['checkPwd'] = checkPwd;
            }

            if (rememberMe !== undefined) {
                localVarQueryParameter['rememberMe'] = rememberMe;
            }

            if (pwdKey !== undefined) {
                localVarQueryParameter['pwdKey'] = pwdKey;
            }

            if (checkPwdKey !== undefined) {
                localVarQueryParameter['checkPwdKey'] = checkPwdKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Existing Customer
         * @param {CheckCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCustomerExistsUsingPOST: async (form: CheckCustomerForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('checkCustomerExistsUsingPOST', 'form', form)
            const localVarPath = `/login/pw/checkExistingCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check B2B Customer By Sapid Or AxboId
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistingB2BCustomerBySapIdOrAxboIdUsingGET: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('checkExistingB2BCustomerBySapIdOrAxboIdUsingGET', 'username', username)
            const localVarPath = `/login/pw/checkb2bcustomerbysapidOrAxboId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check B2B Customer By Sapid
         * @param {string} sapid sapid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistingB2BCustomerBySapIdUsingGET: async (sapid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sapid' is not null or undefined
            assertParamExists('checkExistingB2BCustomerBySapIdUsingGET', 'sapid', sapid)
            const localVarPath = `/login/pw/checkb2bcustomerbysapid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (sapid !== undefined) {
                localVarQueryParameter['sapid'] = sapid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check B2B Customer
         * @param {string} organisationNumber organisationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistingB2BCustomerUsingGET: async (organisationNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationNumber' is not null or undefined
            assertParamExists('checkExistingB2BCustomerUsingGET', 'organisationNumber', organisationNumber)
            const localVarPath = `/login/pw/checkb2bcustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (organisationNumber !== undefined) {
                localVarQueryParameter['organisationNumber'] = organisationNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Customer
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        checkExistingCustomerUsingGET: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('checkExistingCustomerUsingGET', 'username', username)
            const localVarPath = `/login/pw/checkcustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recover B2B Account
         * @param {VerifyB2BCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverB2BCustomerUsingPOST: async (form: VerifyB2BCustomerForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('recoverB2BCustomerUsingPOST', 'form', form)
            const localVarPath = `/login/pw/recoverb2baccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate generated pwd reset Token
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmailTokenUsingPOST: async (token?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/pw/validatetoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recover account
         * @param {VerifyCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        verifyNewCustomerUsingPOST: async (form: VerifyCustomerForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifyNewCustomerUsingPOST', 'form', form)
            const localVarPath = `/login/pw/recoveraccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify SMS Token
         * @param {EncryptedTokenAssertionForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsTokenUsingPOST: async (form: EncryptedTokenAssertionForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('verifySmsTokenUsingPOST', 'form', form)
            const localVarPath = `/login/pw/verifysmstoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetApi - functional programming interface
 * @export
 */
export const PasswordResetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change
         * @param {string} [token] 
         * @param {string} [pwd] 
         * @param {string} [checkPwd] 
         * @param {boolean} [rememberMe] 
         * @param {string} [pwdKey] 
         * @param {string} [checkPwdKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordUsingPOST(token?: string, pwd?: string, checkPwd?: string, rememberMe?: boolean, pwdKey?: string, checkPwdKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordUsingPOST(token, pwd, checkPwd, rememberMe, pwdKey, checkPwdKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check Existing Customer
         * @param {CheckCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCustomerExistsUsingPOST(form: CheckCustomerForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCheckCustomerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCustomerExistsUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check B2B Customer By Sapid Or AxboId
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkExistingB2BCustomerBySapIdOrAxboIdUsingGET(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCheckB2BByIdDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkExistingB2BCustomerBySapIdOrAxboIdUsingGET(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check B2B Customer By Sapid
         * @param {string} sapid sapid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkExistingB2BCustomerBySapIdUsingGET(sapid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCheckB2BByIdDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkExistingB2BCustomerBySapIdUsingGET(sapid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check B2B Customer
         * @param {string} organisationNumber organisationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkExistingB2BCustomerUsingGET(organisationNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCheckB2BDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkExistingB2BCustomerUsingGET(organisationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check Customer
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async checkExistingCustomerUsingGET(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCheckCustomerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkExistingCustomerUsingGET(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recover B2B Account
         * @param {VerifyB2BCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverB2BCustomerUsingPOST(form: VerifyB2BCustomerForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverB2BCustomerUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate generated pwd reset Token
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmailTokenUsingPOST(token?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmailTokenUsingPOST(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recover account
         * @param {VerifyCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async verifyNewCustomerUsingPOST(form: VerifyCustomerForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyNewCustomerUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify SMS Token
         * @param {EncryptedTokenAssertionForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifySmsTokenUsingPOST(form: EncryptedTokenAssertionForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodBasicStatusResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifySmsTokenUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordResetApi - factory interface
 * @export
 */
export const PasswordResetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResetApiFp(configuration)
    return {
        /**
         * 
         * @summary Change
         * @param {string} [token] 
         * @param {string} [pwd] 
         * @param {string} [checkPwd] 
         * @param {boolean} [rememberMe] 
         * @param {string} [pwdKey] 
         * @param {string} [checkPwdKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(token?: string, pwd?: string, checkPwd?: string, rememberMe?: boolean, pwdKey?: string, checkPwdKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.changePasswordUsingPOST(token, pwd, checkPwd, rememberMe, pwdKey, checkPwdKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Existing Customer
         * @param {CheckCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCustomerExistsUsingPOST(form: CheckCustomerForm, options?: any): AxiosPromise<AxfoodCheckCustomerDTO> {
            return localVarFp.checkCustomerExistsUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check B2B Customer By Sapid Or AxboId
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistingB2BCustomerBySapIdOrAxboIdUsingGET(username: string, options?: any): AxiosPromise<AxfoodCheckB2BByIdDTO> {
            return localVarFp.checkExistingB2BCustomerBySapIdOrAxboIdUsingGET(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check B2B Customer By Sapid
         * @param {string} sapid sapid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistingB2BCustomerBySapIdUsingGET(sapid: string, options?: any): AxiosPromise<AxfoodCheckB2BByIdDTO> {
            return localVarFp.checkExistingB2BCustomerBySapIdUsingGET(sapid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check B2B Customer
         * @param {string} organisationNumber organisationNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistingB2BCustomerUsingGET(organisationNumber: string, options?: any): AxiosPromise<AxfoodCheckB2BDTO> {
            return localVarFp.checkExistingB2BCustomerUsingGET(organisationNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Customer
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        checkExistingCustomerUsingGET(username: string, options?: any): AxiosPromise<AxfoodCheckCustomerDTO> {
            return localVarFp.checkExistingCustomerUsingGET(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recover B2B Account
         * @param {VerifyB2BCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverB2BCustomerUsingPOST(form: VerifyB2BCustomerForm, options?: any): AxiosPromise<object> {
            return localVarFp.recoverB2BCustomerUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate generated pwd reset Token
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmailTokenUsingPOST(token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.validateEmailTokenUsingPOST(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recover account
         * @param {VerifyCustomerForm} form form
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        verifyNewCustomerUsingPOST(form: VerifyCustomerForm, options?: any): AxiosPromise<object> {
            return localVarFp.verifyNewCustomerUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify SMS Token
         * @param {EncryptedTokenAssertionForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsTokenUsingPOST(form: EncryptedTokenAssertionForm, options?: any): AxiosPromise<AxfoodBasicStatusResponseViewModel> {
            return localVarFp.verifySmsTokenUsingPOST(form, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResetApi - object-oriented interface
 * @export
 * @class PasswordResetApi
 * @extends {BaseAPI}
 */
export class PasswordResetApi extends BaseAPI {
    /**
     * 
     * @summary Change
     * @param {string} [token] 
     * @param {string} [pwd] 
     * @param {string} [checkPwd] 
     * @param {boolean} [rememberMe] 
     * @param {string} [pwdKey] 
     * @param {string} [checkPwdKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public changePasswordUsingPOST(token?: string, pwd?: string, checkPwd?: string, rememberMe?: boolean, pwdKey?: string, checkPwdKey?: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).changePasswordUsingPOST(token, pwd, checkPwd, rememberMe, pwdKey, checkPwdKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Existing Customer
     * @param {CheckCustomerForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkCustomerExistsUsingPOST(form: CheckCustomerForm, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkCustomerExistsUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check B2B Customer By Sapid Or AxboId
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkExistingB2BCustomerBySapIdOrAxboIdUsingGET(username: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkExistingB2BCustomerBySapIdOrAxboIdUsingGET(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check B2B Customer By Sapid
     * @param {string} sapid sapid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkExistingB2BCustomerBySapIdUsingGET(sapid: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkExistingB2BCustomerBySapIdUsingGET(sapid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check B2B Customer
     * @param {string} organisationNumber organisationNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkExistingB2BCustomerUsingGET(organisationNumber: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkExistingB2BCustomerUsingGET(organisationNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Customer
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkExistingCustomerUsingGET(username: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkExistingCustomerUsingGET(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recover B2B Account
     * @param {VerifyB2BCustomerForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public recoverB2BCustomerUsingPOST(form: VerifyB2BCustomerForm, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).recoverB2BCustomerUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate generated pwd reset Token
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public validateEmailTokenUsingPOST(token?: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).validateEmailTokenUsingPOST(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recover account
     * @param {VerifyCustomerForm} form form
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public verifyNewCustomerUsingPOST(form: VerifyCustomerForm, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).verifyNewCustomerUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify SMS Token
     * @param {EncryptedTokenAssertionForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public verifySmsTokenUsingPOST(form: EncryptedTokenAssertionForm, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).verifySmsTokenUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }
}
