import useTranslation from 'next-translate/useTranslation';
import Icon from '@atoms/Icon/Icon';
import { useRouter } from 'next/router';
import paths from '@constants/paths';
import { StyledLink, StyledLinkWrapper, StyledNewsComponentDetail, StyledWrapper } from './NewsComponentDetail.styles';
import Download from '@icons/download.svg';
import useCmsComponents from '@hooks/useCmsComponents';

const NewsComponentDetail = () => {
  const { t } = useTranslation('newsComponent');
  const router = useRouter();
  const fetchUid = decodeURI(router.asPath.replace(`${paths.ARTICLE.NEWSDETAIL}/`, ''));
  const { data, isLoading } = useCmsComponents(JSON.stringify(fetchUid));
  const newsItem = data?.component?.filter((component): component is NewsItemType => 'year' in component)?.[0];

  return !isLoading && newsItem ? (
    <StyledWrapper>
      <StyledLinkWrapper>
        <StyledLink
          href={{
            pathname: paths.ARTICLE.NEWSPAGE,
          }}
          internal
        >
          {t('links->newsAndPress')}
        </StyledLink>
      </StyledLinkWrapper>
      {newsItem?.content && <StyledNewsComponentDetail dangerouslySetInnerHTML={{ __html: newsItem.content }} />}
      <StyledLinkWrapper>
        {newsItem.pdf && (
          <StyledLink href={newsItem.pdf.url} target="_blank">
            <Icon svg={Download} size={16} />
            {t('detail->pdf')}
          </StyledLink>
        )}
        {newsItem.image && (
          <StyledLink href={newsItem.image.url} target="_blank">
            <Icon svg={Download} size={16} />
            {t('detail->image')}
          </StyledLink>
        )}
      </StyledLinkWrapper>
    </StyledWrapper>
  ) : null;
};

export default NewsComponentDetail;
