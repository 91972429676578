import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import CmsContentSlot from '@atoms/CmsContentSlot/CmsContentSlot';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};

  ${Config.MEDIA.FROM_DESKTOP} {
    flex-direction: row;
    gap: ${rem(120)};
    justify-content: center;
  }
`;

export const StyledSlot = styled(CmsContentSlot)`
  width: 100%;
`;
