import type { PageWithSlots } from '@api/interfaces/pageApi';
import { ReactNode } from 'react';
import collectSlots from '@api/interfaces/collectSlots';
import useSmartEdit from '@hooks/useSmartEdit';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import CmsContentSlot from '@atoms/CmsContentSlot/CmsContentSlot';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  productSlots: string[];
}

const WillysProductPageTemplate = ({ cmsPage, children, productSlots }: Props) => {
  const { HeaderCenter, ProductMainContent, FooterCenter } = collectSlots(cmsPage.slots, productSlots);
  const { isSmartEditEnabled } = useSmartEdit();

  return (
    <>
      {(isSmartEditEnabled || HeaderCenter?.component?.length > 0) && (
        <CmsContentSlot slotData={HeaderCenter} data-testid="headerCenter-slot">
          {HeaderCenter?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </CmsContentSlot>
      )}
      {children && children}
      {(isSmartEditEnabled || ProductMainContent?.component?.length > 0) && (
        <CmsContentSlot slotData={ProductMainContent} data-testid="productMainContent-slot">
          {ProductMainContent?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </CmsContentSlot>
      )}
      {(isSmartEditEnabled || FooterCenter?.component?.length > 0) && (
        <CmsContentSlot slotData={FooterCenter} data-testid="footerCenter-slot">
          {FooterCenter?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </CmsContentSlot>
      )}
    </>
  );
};

export default WillysProductPageTemplate;
