import dynamic from 'next/dynamic';

const FaqComponent = dynamic(() => import('./FaqComponent'), {
  ssr: false,
});

const DynamicFaqComponent = () => {
  return <FaqComponent />;
};

export default DynamicFaqComponent;
