import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackClickForward = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'buy_all_beam',
    action: 'arrow_click_forward',
    label: '',
    value: 0,
  });
};

export const trackClickBackward = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'buy_all_beam',
    action: 'arrow_click_backward',
    label: '',
    value: 0,
  });
};

export const trackCloseModal = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'buy_all_beam',
    action: 'modal_closed',
    label: '',
    value: 0,
  });
};

export const trackChangeQuantity = (increase: boolean) => {
  pushGTMEvent({
    event: 'Track',
    category: 'buy_all_beam',
    action: increase ? 'increased_quantity_from_beam' : 'decreased_quantity_from_beam',
    label: '',
    value: 0,
  });
};

export const trackOpenModal = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'buy_all_beam',
    action: 'opened_modal',
    label: '',
    value: 0,
  });
};

export const trackPercentageOfProductsAdded = (percentage: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'buy_all_beam',
    action: 'add_beam_products_to_cart',
    label: '',
    value: percentage,
  });
};
