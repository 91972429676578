import { useMemo } from 'react';
import useCmsComponents from '@hooks/useCmsComponents';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import SkeletonComponent from '@organisms/SkeletonComponent/SkeletonComponent';
import type { ContentSlotWsDTO } from '@occ/api-client';
import { StyledColumnComponent } from './ColumnComponent.styles';
import useSmartEdit from '@hooks/useSmartEdit';

interface CmsComponentContentSlotWsDTO extends ContentSlotWsDTO {
  cmsComponents: string;
  cmsComponentTypeCodes: string;
  uuid: string;
  widthPercent: string;
}
interface Props {
  column: CmsComponentContentSlotWsDTO;
  context?: SlotContextType;
}

const ColumnComponent = ({ column, context }: Props) => {
  const skeletonTemplates = useMemo(() => JSON.parse(column?.cmsComponentTypeCodes), [column]);
  const { data: columnData, isLoading } = useCmsComponents(column.cmsComponents);
  const { isSmartEditEnabled } = useSmartEdit();

  if (isLoading && !isSmartEditEnabled) {
    return skeletonTemplates?.map((template: string, index: number) => (
      <SkeletonComponent key={`${index}-${template}`} template={template} />
    ));
  }
  return (
    <StyledColumnComponent widthPercent={`${column.widthPercent === '33' ? '33.33' : column.widthPercent}%`}>
      {(columnData?.component ?? []).map((child) => (
        <CmsComponent key={`${child?.uuid}-${child.uid}`} component={child} context={context} />
      ))}
    </StyledColumnComponent>
  );
};

export default ColumnComponent;
