import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';
import { onlineGridElementHeight, offlineGridElementHeight } from '@styles/mixins/gridElementHeight';

export const StyledVoucherCard = styled.div<{ productType: OnlineOfflineProductType }>`
  ${onlineGridElementHeight()};
  overflow: hidden;
  color: ${variables.colors.black};
  grid-column: span 1;
  z-index: 1;

  ${(props) =>
    props.productType === 'offline' &&
    css`
      ${offlineGridElementHeight()};
    `}
`;

export const StyledVoucherCardContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${rem(16)};
  height: 100%;
  background-image: url('/images/voucher_background.svg');
  background-position: center;
`;

export const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledVoucherOverlay = styled.div<{ productType: OnlineOfflineProductType }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  padding: ${rem(40)};
  align-items: center;
  justify-content: center;
  background-color: ${rgba(variables.colors.white, 0.75)};
  ${onlineGridElementHeight()};

  ${(props) =>
    props.productType === 'offline' &&
    css`
      ${offlineGridElementHeight()};
    `}
`;
