import { StyledSelectOption, StyledSelectOptionLabel } from './Select.styles';

interface Props {
  option: OptionType;
  onItemClick: (option: OptionType) => void;
  isSelected: boolean;
  className?: string;
}

const Option = ({ option, onItemClick, isSelected, className }: Props) => {
  return (
    <StyledSelectOption
      className={className}
      key={option.value}
      role="menuitem"
      onClick={() => onItemClick(option)}
      data-testid="select-option"
      tabIndex={0}
    >
      <StyledSelectOptionLabel type="body" color="black" isSelected={isSelected} title={option.label}>
        {option.label}
      </StyledSelectOptionLabel>
    </StyledSelectOption>
  );
};

export default Option;
