import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';
import Link from '@atoms/Link/Link';

export const StyledNavigation = styled.div`
  flex: 1;
  margin-left: ${rem(20)};
  padding-left: ${rem(20)};
  align-items: center;
  display: none;

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    display: flex;
  }
`;

export const StyledNavigationWrapper = styled.nav`
  display: flex;
`;

export const StyledLink = styled(Link)<{ active: boolean }>`
  padding: ${rem(10)};
  color: ${variables.colors.lighterBlack};
  text-decoration: none;
  font-weight: 500;
  font-size: ${rem(15)};

  &:hover {
    color: ${variables.colors.primary};
  }

  ${(props) =>
    props.active &&
    css`
      &,
      &:visited {
        color: ${variables.colors.primary};
      }
    `}
`;

export const StyledLinkWrapper = styled.div`
  position: relative;

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    margin-right: ${rem(23)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-right: ${rem(30)};
  }
`;
