import { useEffect, useRef } from 'react';
import { trackProductImpressionsWithPosition } from '@helpers/analyticsHelpers/trackProductImpressions';
import useCustomer from '@hooks/useCustomer';
import useTwoTilesProductFetcher from '@molecules/TwoTilesComponent/useTwoTilesProductFetcher';
import { StyledProductDummy } from '@molecules/MixMatchBeam/MixMatchBeam.styles';
import Carousel, { ItemType } from '@molecules/Carousel/Carousel';
import { trackCarouselSlideShown } from '@helpers/trackCarousel';
import Foldout from './Foldout';
import useProductCarousel from './useProductCarousel';
import { StyledFoldoutWrapper, StyledGridWrapper, StyledSpinner } from './TwoTilesFoldoutComponent.styles';
import { AxfoodProductDetailsViewModel } from '@occ/api-client';
import useStore from '@hooks/useStore';

const TwoTilesFoldoutComponent = ({
  data,
  show,
  title,
}: {
  data: TwoTilesComponentType;
  show: boolean;
  title: string;
}) => {
  const { store } = useStore();
  const { customer } = useCustomer();
  const gridRef = useRef<HTMLDivElement>(null);
  const { amountOfSlides, productsPerSlide, productElements, makeProductElements, recalculateSlides } =
    useProductCarousel(show, gridRef, data.productListTitle);

  const { showSpinner, products } = useTwoTilesProductFetcher(show, data.uid, [
    data.uid,
    show,
    customer?.uid,
    store?.storeId,
  ]);

  useEffect(() => {
    makeProductElements(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const trackProducts = (items: ItemType[]) => {
    trackCarouselSlideShown();
    const productArray: { product: AxfoodProductDetailsViewModel; position: number }[] = [];
    items.forEach((el) => {
      productArray.push({ product: (el.item as any)?.props?.product, position: el.index });
    });

    if (productArray.length > 0) {
      trackProductImpressionsWithPosition(productArray, title);
    }
  };

  return (
    <StyledFoldoutWrapper ref={gridRef} show={show}>
      <Foldout show={show} callback={recalculateSlides}>
        <StyledGridWrapper hasPagination={amountOfSlides > 1}>
          {showSpinner ? (
            <StyledSpinner />
          ) : (
            <Carousel
              elements={productElements}
              elementsPerSlide={productsPerSlide}
              elementDummy={<StyledProductDummy />}
              variant="mixmatch"
              onSlideShown={trackProducts}
              title={title}
            />
          )}
        </StyledGridWrapper>
      </Foldout>
    </StyledFoldoutWrapper>
  );
};

export default TwoTilesFoldoutComponent;
