import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Tabs from '@molecules/Tabs/Tabs';

export const StyledSwipeableSection = styled.div`
  width: calc(100% + ${rem(40)});
  margin-left: ${rem(-20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: 100%;
    margin-left: 0;
  }
`;

export const StyledSwipeableSectionTabs = styled(Tabs)`
  margin-bottom: ${rem(36)};
`;
