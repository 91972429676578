import { useEffect, useMemo } from 'react';
import paths from '@constants/paths';
import useCustomRouter from '@hooks/useCustomRouter';
import useCustomer from '@hooks/useCustomer';
import useResponsive from '@hooks/useResponsive';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectToolbarSearchIsExpanded } from '@slices/toolbarSlice';

const useDisplayStates = () => {
  const { isMobile, isTablet, isClient } = useResponsive();
  const router = useCustomRouter();
  const { customer } = useCustomer();
  const isSearchExpanded = useAppSelector(selectToolbarSearchIsExpanded);

  const mobileSearchVisible = isSearchExpanded && isMobile;
  const hideFaqWidget = useMemo(() => router.asPath.startsWith(paths.ARTICLE.FAQPAGE), [router.asPath]);
  const showScrollToTop = useMemo(
    () =>
      router.asPath.startsWith(paths.SEARCH) ||
      router.asPath.startsWith(paths.CATEGORY) ||
      router.asPath.startsWith(paths.OFFERS),
    [router.asPath]
  );
  const enableLoginPrompt = useMemo(
    () =>
      !router.asPath.startsWith(paths.USER) &&
      !router.asPath.startsWith(paths.REGISTER) &&
      customer?.isAnonymous &&
      isClient &&
      (isMobile || isTablet),
    [router.asPath, customer?.isAnonymous, isClient, isMobile, isTablet]
  );

  useEffect(() => {
    const handler = () => {
      document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', handler);
    handler();
    return () => window.removeEventListener('resize', handler);
  }, []);

  return {
    mobileSearchVisible,
    hideFaqWidget,
    showScrollToTop,
    enableLoginPrompt,
  };
};

export default useDisplayStates;
