import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Link from '@atoms/Link/Link';
import Icon from '@atoms/Icon/Icon';

export const StyledUspWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${rem(44)};
  background-color: ${variables.colors.white};
`;

export const StyledUspItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const StyledUspLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const StyledUspItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUspText = styled.span`
  display: contents;
  color: ${variables.colors.darkerGray};
  font-family: ${variables.fonts.fontGothamBook};
  font-size: ${rem(13)};
`;

export const StyledUspNewsText = styled.span`
  color: ${variables.colors.primary};
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamMedium};
  text-transform: uppercase;
  margin-right: ${rem(5)};
`;

export const StyledUspIcon = styled(Icon)<{ hasLink: boolean }>`
  margin-right: ${rem(5)};
  ${(props) =>
    props.hasLink &&
    css`
      position: relative;
      top: ${rem(2)};
    `}
`;
