import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SwipeDirections, SwipeEventData, useSwipeable } from '@hooks/react-swipeable';
import Icon from '@atoms/Icon/Icon';
import Config from '@config';
import useResizeObserver from '@react-hook/resize-observer';
import Text from '@atoms/Text/Text';
import useResponsive from '@hooks/useResponsive';
import {
  AnimatedContainer,
  CarouselButton,
  CarouselR,
  CarouselSlide,
  CarouselWrapper,
  CarouseSlideGroup,
  Pagination,
  PaginationDot,
  PaginationDots,
  PaginationWrapper,
  StyledCarousel,
  StyledCarouselHeader,
  StyledNavigationArrowsWrapper,
} from './Carousel.styles';
import Heading from '@atoms/Heading/Heading';
import { getDotStyle } from '@helpers/carouselHelper';
import packageComponentHelper from '@helpers/packageComponentHelper';
import { trackClickBackward, trackClickForward } from '@helpers/analyticsHelpers/trackBuyAllProductsComponent';
import Next from '@icons/next.svg';
import NextLarge from '@icons/next_large.svg';
import Previous from '@icons/previous.svg';
import PreviousLarge from '@icons/previous_large.svg';

export type Variant = 'default' | 'mixmatch' | 'slotpicker' | 'multisearch' | 'buyAllProducts' | 'lastBuy';

export type ItemType = {
  item: ReactNode;
  index: number;
};
const clamp = (current: number, min: number, max: number) => Math.max(min, Math.min(current, max));

const paginationMoveDistance = 12;
export const paginationItemThreshold = 3;
export const paginationMinItems = 5;

export interface Props {
  'data-testid'?: string;
  className?: string;
  elements: Array<ReactNode>;
  elementsPerSlide: number;
  elementDummy?: ReactNode;
  slideToPage?: number;
  variant?: Variant;
  title?: string;
  onSlideShown?: (items?: any) => void;
  fetchMore?: (searchWord: string, page: number) => void;
  pages?: number;
}

const CarouselHeading = ({ variant, title }: { variant: Variant; title: string }) => {
  if (variant === 'multisearch' || variant === 'buyAllProducts') {
    return (
      <Heading variant="h2" size="small">
        {title}
      </Heading>
    );
  } else if (variant === 'lastBuy') {
    return (
      <Text type="subtitle" size="large">
        {title}
      </Text>
    );
  }

  return (
    <Text type="subhead" size="medium">
      {title}
    </Text>
  );
};

const Carousel = ({
  elements,
  elementsPerSlide,
  elementDummy,
  slideToPage = 0,
  variant = 'default',
  onSlideShown,
  title,
  fetchMore,
  pages,
  ...props
}: Props) => {
  const [slides, setSlides] = useState<ReactNode>([]);
  const [slideIndex, setSlideIndex] = useState(slideToPage);
  const amountOfSlides = pages ? pages : Math.ceil(elements.length / elementsPerSlide);
  const [initialSwipeDir, setInitialSwipeDir] = useState<SwipeDirections | null>(null);
  const [translate, setTranslate] = useState(0);
  const animatedContainerRef = useRef<HTMLDivElement>(null);
  const animatedPaginationRef = useRef<HTMLDivElement>(null);
  const SLIDE_THRESHOLD = 50;
  const { isMobile, isTablet } = useResponsive();
  const packageHelper = packageComponentHelper();

  const isTouch = useMediaQuery({
    query: Config.DEVICE.IS_TOUCH,
  });

  const showSlotPickerArrows = !(isTouch && isMobile) && variant === 'slotpicker';

  const handleTransition = (sIndex: number, deltaX = 0) => {
    const container = animatedContainerRef?.current;
    const cssGap = variant !== 'slotpicker' || showSlotPickerArrows ? 0 : 8;
    if (container) {
      const containerWidth = container.clientWidth || 1280;
      const distanceToMove = containerWidth - Math.abs(deltaX);
      setTranslate(-containerWidth * sIndex);
      const transitionEndEvent = () => {
        if (container) {
          container.style.transitionDuration = '0s';
          container.removeEventListener('transitionend', transitionEndEvent);
        }
      };
      container.addEventListener('transitionend', transitionEndEvent);
      const maxWidth = 1280;
      const minWidth = 320;
      const transitionDuration = ((distanceToMove + minWidth) / maxWidth) * 0.5;
      container.style.transitionDuration = `${transitionDuration}s`;
      setTimeout(() => {
        container.style.left = `${-(containerWidth + cssGap) * sIndex}px`;
      }, 1);
    }
  };

  const handlePaginationTransition = (sIndex: number) => {
    const container = animatedPaginationRef?.current;
    if (container) {
      if (initialSwipeDir === 'Right' && slideIndex === paginationItemThreshold) {
        container.style.left = `0px`;
        return;
      }
      if (slideIndex < paginationItemThreshold || slideIndex > amountOfSlides - (paginationItemThreshold + 1)) {
        return;
      }
      const transitionEndEvent = () => {
        if (container) {
          container.style.transitionDuration = '0s';
          container.removeEventListener('transitionend', transitionEndEvent);
        }
      };
      container.addEventListener('transitionend', transitionEndEvent);
      container.style.transitionDuration = '200ms';
      setTimeout(() => {
        container.style.left = `${
          -paginationMoveDistance * sIndex + paginationMoveDistance * paginationItemThreshold
        }px`;
      }, 1);
    }
  };

  const handleStep = (indexDelta: number, deltaX?: number) => {
    const clampedValue = clamp(slideIndex + indexDelta, 0, amountOfSlides - 1);
    setSlideIndex(clampedValue);
    if (amountOfSlides > paginationMinItems) handlePaginationTransition(clampedValue);
    handleTransition(clampedValue, deltaX);
  };

  const createSlides = () => {
    if (slideIndex > amountOfSlides) {
      setSlideIndex(0);
    }

    const slidesArr = [];
    let tempArray = [];
    let count: number = 0;
    for (let i = 0; i < elements.length; i += 1) {
      // @ts-ignore
      const item = elements[i]?.props?.component;
      if (item) {
        const size = packageHelper.getSpanSize(item);
        count = count + (isMobile ? size.s : isTablet ? size.m : size.l);
      } else {
        count = count + 1;
      }
      tempArray.push(<Fragment key={`slide-id-${i}-item-id-${i}`}>{elements[i]}</Fragment>);
      if (count === elementsPerSlide || i === elements.length - 1) {
        slidesArr.push(
          <CarouselSlide variant={variant} key={`slide-id-${i}`}>
            <CarouseSlideGroup variant={variant}>{tempArray.map((item) => item)}</CarouseSlideGroup>
          </CarouselSlide>
        );
        tempArray = [];
        count = count - elementsPerSlide;
      }
    }
    return slidesArr;
  };

  const getItemsShown = (): ItemType[] => {
    const startIndex = slideIndex * elementsPerSlide;
    const items = [...elements].slice(startIndex, startIndex + elementsPerSlide);
    return items.map((item, i) => {
      return { item, index: i + startIndex };
    });
  };

  const pagination = () => {
    const dotList = [];
    for (let x = 0; x < amountOfSlides; x += 1) {
      dotList.push(
        <PaginationDot
          key={`carousel-pagination-${x}`}
          state={getDotStyle({ index: x, currentSlide: slideIndex, amountOfSlides })}
        />
      );
    }
    return dotList;
  };

  useResizeObserver(animatedContainerRef, () => {
    setSlideIndex(slideIndex);
    handleTransition(slideIndex);
    handlePaginationTransition(slideIndex);
  });

  const swipeHandlers = useSwipeable({
    onSwipeStart: (e) => {
      setInitialSwipeDir(e.dir);
    },
    onSwiped: (e) => {
      setInitialSwipeDir(null);
      if (isTouch && !e.first) {
        if (-e.deltaX > SLIDE_THRESHOLD) {
          handleStep(1, e.deltaX);
        } else if (-e.deltaX < -SLIDE_THRESHOLD) {
          handleStep(-1, e.deltaX);
        } else {
          handleStep(0, e.deltaX);
        }
      }
    },
    onSwiping: (e) => {
      const container = animatedContainerRef?.current;
      if (isTouch && !e.first && (initialSwipeDir === 'Left' || initialSwipeDir === 'Right') && container) {
        container.style.transitionDuration = '0s';
        container.style.left = `${translate + e.deltaX}px`;
      }
    },
    preventDefaultTouchmoveEvent: (e: SwipeEventData) => {
      return e.dir === 'Left' || e.dir === 'Right';
    },
  });

  const iconSize = variant === 'default' || variant === 'slotpicker' ? 20 : 32;
  const carouselArrows = (
    <>
      {amountOfSlides > 1 && (
        <>
          <CarouselButton
            data-testid="left-arrow-button"
            disabled={slideIndex === 0}
            variant={variant}
            theme="transparent"
            hide={variant !== 'slotpicker' && (isMobile || isTablet) && isTouch}
            type="button"
            onClick={() => {
              handleStep(-1);
              if (variant === 'buyAllProducts') trackClickBackward();
            }}
          >
            <Icon svg={iconSize >= 32 ? PreviousLarge : Previous} size={iconSize} />
          </CarouselButton>

          <CarouselButton
            data-testid="right-arrow-button"
            disabled={slideIndex === amountOfSlides - 1}
            variant={variant}
            theme="transparent"
            hide={variant !== 'slotpicker' && (isMobile || isTablet) && isTouch}
            type="button"
            onClick={() => {
              handleStep(1);
              if (variant === 'buyAllProducts') trackClickForward();
            }}
          >
            <Icon svg={iconSize >= 32 ? NextLarge : Next} size={iconSize} />
          </CarouselButton>
        </>
      )}
    </>
  );

  useEffect(() => {
    setSlides(createSlides());
    if (onSlideShown) {
      onSlideShown(getItemsShown());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsPerSlide, slideIndex, elements]);

  return (
    <StyledCarousel variant={variant} className={props['className']}>
      {variant !== 'slotpicker' && (
        <StyledCarouselHeader variant={variant}>
          <CarouselHeading variant={variant} title={title || ''} />
          <StyledNavigationArrowsWrapper>{carouselArrows}</StyledNavigationArrowsWrapper>
        </StyledCarouselHeader>
      )}
      <CarouselWrapper data-testid={props['data-testid']} {...swipeHandlers}>
        <CarouselR variant={variant}>
          {showSlotPickerArrows && carouselArrows}

          {slides && (
            <div style={{ overflow: variant !== 'slotpicker' || showSlotPickerArrows ? 'hidden' : 'unset' }}>
              <AnimatedContainer
                data-testid="animated-container"
                ref={animatedContainerRef}
                noArrowDesign={variant === 'slotpicker' && !showSlotPickerArrows}
              >
                {slides}
              </AnimatedContainer>
            </div>
          )}
          {(variant === 'mixmatch' ||
            variant === 'multisearch' ||
            variant === 'buyAllProducts' ||
            variant === 'lastBuy') &&
            amountOfSlides > 1 && (
              <PaginationWrapper>
                <Pagination>
                  <div style={{ overflow: 'hidden' }}>
                    <PaginationDots ref={animatedPaginationRef}>{pagination()}</PaginationDots>
                  </div>
                </Pagination>
              </PaginationWrapper>
            )}
        </CarouselR>
      </CarouselWrapper>
    </StyledCarousel>
  );
};

export default Carousel;
