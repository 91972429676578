import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackCancelChangeOrder = (type: 'cancel_change_order_link' | 'cancel_change_order_from_order_detail') => {
  pushGTMEvent({
    event: 'Track',
    category: type,
    action: 'click_cancel_change_order',
    label: '',
    value: 0,
  });
};

export default trackCancelChangeOrder;
