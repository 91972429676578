import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';

export const StyledSection = styled.section<{
  padding: Padding;
  backgroundColor: string;
}>`
  min-height: ${rem(310)};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.padding &&
    props.padding.top &&
    css`
      padding-top: ${props.padding.top}px;
    `}
  ${(props) =>
    props.padding &&
    props.padding.right &&
    css`
      padding-right: ${props.padding.right}px;
    `}
  ${(props) =>
    props.padding &&
    props.padding.bottom &&
    css`
      padding-bottom: ${props.padding.bottom}px;
    `}
  ${(props) =>
    props.padding &&
    props.padding.left &&
    css`
      padding-left: ${props.padding.left}px;
    `}
  ${(props) =>
    props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
    `}
`;

export const StyledDiv = styled.div<{ smallBanner: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: ${rem(20)};
  padding-top: ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(20)} ${rem(20)} 0;
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(1048)};
    padding: ${rem(30)} ${rem(30)} 0;
  }

  ${(props) =>
    props.smallBanner &&
    css`
      gap: 0;
      ${Config.MEDIA.FROM_MOBILE} {
        padding: ${rem(15)} ${rem(10)} 0;
      }
    `}
`;

export const StyledHeading = styled(Heading)`
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(540)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(605)};
  }
`;

export const StyledText = styled(Text)`
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(462)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(680)};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  &:hover:not(.disabled) {
    @media (hover: hover) and (pointer: fine) {
      opacity: 0.8;
    }
  }
`;

export const StyledYoutubeIcon = styled(Icon)`
  margin-right: ${rem(10)};
`;

export const StyledImg = styled.img`
  width: 100%;
`;

export const StyledArrow = styled(Icon)`
  margin-left: ${rem(10)};
`;

export const StyledLink = styled(Link)<{
  isWrapping?: boolean;
}>`
  margin-top: auto;

  ${(props) =>
    props.isWrapping &&
    css`
      margin-top: 0;
      color: initial;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      &:visited {
        color: initial;
      }
    `}
`;
