import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';

export const StyledSuggestions = styled.div`
  margin-left: ${rem(-20)};
  margin-right: ${rem(-20)};
  margin-bottom: ${rem(16)};
  overflow-x: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-bottom: ${rem(24)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-bottom: ${rem(28)};
  }
`;

export const StyledScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: min-content;
  gap: ${rem(4)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
`;
