import { useContext, useEffect, useState } from 'react';
import { SymplifyContext } from '@utility/ABTest/Symplify/SymplifyContextProvider';
import { cookieJar } from '@utility/ABTest/Symplify/cookieJar';
import cookie from 'cookie';

const useSymplifyExperiment = (testName: string, defaultVariant: string) => {
  const { sstInstance } = useContext(SymplifyContext);

  const [variant, setVariant] = useState(defaultVariant);

  const findVariation = (testName: string, defaultVariant: string) => {
    setVariant(sstInstance?.findVariation(testName, cookieJar()) || defaultVariant);
  };

  useEffect(() => {
    if (sstInstance?.config.latest?.privacy_mode === 0) {
      // Immediately find variation if privacy mode is DEFAULT (we don't care about consent)
      findVariation(testName, defaultVariant);
    } else {
      // Check if consent has already been given
      if (cookie.parse(document.cookie || '')['sg_optin'] === '1') {
        findVariation(testName, defaultVariant);
      } else {
        // If not, wait for consent
        const eventHandler = () => {
          findVariation(testName, defaultVariant);
        };

        // @ts-ignore
        document.addEventListener('symplify-booted', eventHandler);
        return () => {
          // @ts-ignore
          document.removeEventListener('symplify-booted', eventHandler);
        };
      }
    }
  });

  return variant;
};

export default useSymplifyExperiment;
