import styled from 'styled-components';
import variables from '@styles/variables';

export const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${variables.zIndex.toolbar - 1};
  cursor: pointer;
`;
