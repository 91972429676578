import { ReactNode } from 'react';
import StyledGridContainer from './GridContainer.styles';

export type gridContainerSizeType = 'small' | 'large';

export interface GridContainerProps {
  children: ReactNode;
  className?: string;
  noPadding?: boolean;
  addPadding?: boolean;
  size?: gridContainerSizeType;
}

const GridContainer = ({ children, className, noPadding, addPadding, size = 'large' }: GridContainerProps) => (
  <StyledGridContainer className={className} noPadding={noPadding} addPadding={addPadding} size={size}>
    {children}
  </StyledGridContainer>
);

export default GridContainer;
