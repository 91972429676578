import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Icon from '@atoms/Icon/Icon';

// Distance between checkbox and label
const checkboxHorizontalGap = 12;
// Size of checkbox (width and height, including border)
const checkboxSize = 24;
const checkmarkIconSize = 12;

export const StyledContainer = styled.div`
  padding-left: ${rem(checkboxHorizontalGap + checkboxSize)};
  min-height: ${rem(20)};
  display: flex;
  align-items: center;

  & + & {
    margin-top: ${rem(16)};
  }
`;

export const StyledCheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin-left: ${rem(-26)};
  height: ${rem(13)};
  width: ${rem(13)};

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;

      & + label {
        color: ${variables.colors.darkGray};
        cursor: default;

        &:before {
          cursor: default;
          border-color: ${variables.colors.mediumGray};
        }
      }
    `}
`;

export const StyledCheckboxLabel = styled.label<{ bold: boolean; checked: boolean }>`
  position: relative;
  cursor: pointer;
  font-size: ${rem(13)};
  font-family: ${(props) => (props.bold ? variables.fonts.fontGothamMedium : variables.fonts.fontGothamBook)};
  color: ${variables.colors.lighterBlack};
  line-height: ${rem(24)};
  min-height: ${rem(24)};

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: ${rem(0 - (checkboxHorizontalGap + checkboxSize))};
    width: ${rem(22)};
    height: ${rem(22)};
    border: ${rem(1)} solid ${(props) => (props.checked ? variables.colors.highlight : variables.colors.darkGray)};
    border-radius: ${rem(2)};
    margin-right: ${rem(10)};
    vertical-align: sub;
    background: ${(props) => (props.checked ? variables.colors.highlight : variables.colors.white)};
  }
`;

export const StyledCheckMarkIcon = styled(Icon)`
  position: absolute;
  // Center the checkmark icon inside the checkbox
  left: ${rem(-(checkmarkIconSize / 2 + checkboxHorizontalGap + checkboxSize / 2))};
  top: ${rem(6)};

  svg {
    color: ${variables.colors.white};
  }
`;
