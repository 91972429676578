import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import trackShowMore from '@helpers/analyticsHelpers/trackShowMoreRelatedProducts';
import Heading from '@atoms/Heading/Heading';
import Grid from '@atoms/Grid/Grid';
import LoadMore from '@molecules/LoadMore/LoadMore';
import ProductsWithTracking from '@molecules/ProductsWithTracking/ProductsWithTracking';
import Config from '@config';
import { StyledRelatedProductsBeam, StyledTitleWrapper } from './RelatedProductsBeam.styles';

const PAGE_SIZE = 20;

type Props = {
  data: any; // TODO: Unclear data type
  title: string;
  offline?: boolean;
  gridTheme: GridThemeType;
  isPage?: boolean;
};

const RelatedProductsBeam = ({ data, title, gridTheme, offline, isPage }: Props): null | JSX.Element => {
  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const handleRef = (ref: HTMLDivElement | null) => setGridRef(ref);
  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });
  const isTabletPortrait = useMediaQuery({
    query: Config.BREAKPOINTS.IS_TABLET_PORTRAIT,
  });
  const fromTabletLandscape = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_LANDSCAPE,
  });

  const getLimit = () => {
    if (isTabletPortrait) {
      return 3;
    }
    if (fromTabletLandscape) {
      return 4;
    }

    return 2;
  };
  const [limit, setLimit] = useState(getLimit());

  useEffect(() => {
    setLimit(getLimit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTabletPortrait, fromTabletLandscape]);

  const showMore = () => {
    trackShowMore(title);
    setLimit(limit + PAGE_SIZE);
  };

  return data?.items?.length > 0 ? (
    <StyledRelatedProductsBeam data-testid="relatedproducts-beam" isPage={isPage}>
      <StyledTitleWrapper>
        <Heading variant="h2" size="small">
          {title}
        </Heading>
      </StyledTitleWrapper>
      <Grid theme={gridTheme} passRef={handleRef}>
        <ProductsWithTracking
          products={[...data.items].splice(0, limit)}
          disableMixMatchButton
          offline={offline}
          category={title}
          containerRef={gridRef}
        />
      </Grid>
      <LoadMore pageSize={PAGE_SIZE} total={data.items.length} limit={limit} onClick={showMore} showProgress />
    </StyledRelatedProductsBeam>
  ) : null;
};

export default RelatedProductsBeam;
