import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

interface Tracking {
  action: 'current' | 'new';
  parent: string;
}
const trackShoppingList = (tracking: Tracking) => {
  pushGTMEvent({
    event: 'Track',
    category: 'shopping_list',
    action: `saved_to_${tracking.action}_list_from_${tracking.parent}`,
    label: '',
    value: 0,
  });
};

export default trackShoppingList;
