import styled from 'styled-components';
import { rem } from 'polished';

export const StyledScreenReaderMessage = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: ${rem(1)};
  height: ${rem(1)};
  overflow: hidden;
`;
