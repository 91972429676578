import styled, { css } from 'styled-components';
import { rem } from 'polished';

const sizeMixin = (size: number) => css`
  & > svg {
    ${size &&
    css`
      width: ${rem(size)};
      height: auto;
    `}
  }
`;

export const StyledIconButton = styled.button<{ size: number }>`
  all: unset;
  cursor: pointer;
  display: inline-flex;

  ${(props) => sizeMixin(props.size)}
`;

export const StyledIconSpan = styled.span<{ size: number }>`
  display: inline-flex;

  ${(props) => sizeMixin(props.size)}
`;
