const debounce = (fn: (...args: any) => void, ms: number) => {
  let timer: any;
  const debounced = (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, ms);
  };

  debounced.cancel = () => {
    if (timer !== undefined) {
      clearTimeout(timer);
    }
  };

  return debounced;
};
export default debounce;
