import { rem } from 'polished';
import styled from 'styled-components';

export const StyledTileContainer = styled.div`
  padding-bottom: ${rem(12)};
  margin-left: ${rem(-4)};
  margin-right: ${rem(-4)};
`;

export const StyledActiveFilters = styled.div`
  border-top: none;
  margin: 0;
  width: 100%;
`;
