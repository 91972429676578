import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';
import PriceLabel from '@molecules/PriceLabel/PriceLabel';
import Image from 'next/image';

export const StyledProductDetailsLeft = styled.div<{ isPage?: boolean }>`
  position: relative;
  display: flex;
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  padding: ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(35)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    border-right: ${(props) => (props.isPage ? 'none' : `${rem(1)} solid ${variables.colors.mediumGray}`)};
    border-bottom: 0 none;
    padding: ${rem(40)};
  }
`;

export const StyledProductDetailsLeftHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(35)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    padding: ${rem(40)};
  }
`;

export const StyledProductDetailsLeftHeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const StyledAgeRestrictionLabelContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledProductDetailsAddToList = styled.div`
  margin-bottom: ${rem(24)};
  position: relative;
  z-index: ${variables.zIndex[2]};
`;

export const StyledProductDetailsEnergyLabel = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${rem(-84)};
  display: flex;
  justify-content: flex-end;
  margin-right: ${rem(84)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-right: ${rem(72)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    margin-right: 0;
  }
`;

export const StyledProductDetailsImageContainer = styled.div`
  position: relative;
`;

export const StyledProductDetailsImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: calc(100vh - #{${rem(208)}});

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    margin: ${rem(128)} 0;
  }
`;

export const StyledProductDetailsPrice = styled.div<{ isPage?: boolean }>`
  position: relative;
  margin-right: ${(props) => (props.isPage ? 0 : rem(46))};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-right: ${rem(40)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    margin-right: 0;
  }
`;

export const StyledProductDetailsPriceLabel = styled.div`
  height: ${rem(20)};
  padding: ${rem(4)} ${rem(8)};
  font-size: ${rem(10)};
  font-family: ${variables.fonts.fontGothamMedium};
  background: ${variables.colors.lightGray};
  text-transform: uppercase;
  border-radius: ${rem(2)};
  margin-bottom: ${rem(4)};
  min-width: 100%;
  text-align: center;
`;

export const StyledProductDetailsPriceSplash = styled(PriceLabel)`
  right: 0;
  top: auto;
  margin-top: 0;
`;

export const StyledProductDetailsZCodes = styled.div<{ native: boolean | undefined }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.native &&
    css`
      margin-top: ${rem(40)};
    `}
`;

export const StyledProductDetailsZCodesIcon = styled.img`
  margin-bottom: ${rem(5)};
  height: ${rem(20)};
  width: ${rem(20)};

  &:hover {
    transition: transform ease 250ms;
    transform: scale(2.75);
  }
`;

export const StyledProductImage = styled(Image)<{ isPage?: boolean }>`
  width: ${rem(276)};
  height: ${rem(276)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(320)};
    height: ${rem(320)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    width: ${rem(275)};
    height: ${rem(275)};
  }

  ${(props) =>
    props.isPage &&
    css`
      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        width: ${rem(416)};
        height: ${rem(416)};
      }
    `}
`;
