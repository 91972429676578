import styled, { css, keyframes } from 'styled-components';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';
import Icon from '@atoms/Icon/Icon';
import OutsideClickWatcher from '@utility/OutsideClickWatcher/OutsideClickWatcher';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';

const SELECT_CONTAINER_HEIGHT = rem(40);
const SELECT_CONTAINER_HEIGHT_MEDIUM = rem(52);

export const StyledOutsideClickWatcher = styled(OutsideClickWatcher)`
  position: relative;
  width: 100%;
`;

export const StyledSelect = styled.div<{ hasError: boolean; size: 'small' | 'medium'; disabled?: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  color: ${variables.colors.lighterBlack};
  width: 100%;
  height: ${(props) => (props.size === 'medium' ? SELECT_CONTAINER_HEIGHT_MEDIUM : SELECT_CONTAINER_HEIGHT)};
  padding: ${rem(10)} ${rem(20)};
  border: ${rem(1)} solid ${(props) => (props.hasError ? variables.colors.primaryDark : variables.colors.darkGray)};
  border-radius: ${rem(35)};
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `};
`;

export const StyledSelectLabel = styled.label`
  height: ${rem(16)};
  margin: 0 0 ${rem(8)} 0;
  font-family: ${variables.fonts.fontGothamMedium};
  color: ${variables.colors.black};
  font-size: ${rem(13)};
  font-weight: 500;
  text-transform: uppercase;
  display: block;
`;

export const show = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
`;

export const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const FadeAnimation = styled.div<{ show: boolean; extraCss: string }>`
  ${(props) =>
    props.show
      ? css`
          opacity: 0;
          animation: ${show} ease-out 0.15s forwards;
        `
      : css`
          opacity: 1;
          animation: ${hide} ease-out 0.15s forwards;
        `};
  ${(props) => props.extraCss}
`;

export const StyledOptionContainerCSSProp = (adjustedTop: number, isAddToList: boolean) => `
  position: absolute;
  right: 0;
  top: ${isAddToList ? 'auto' : adjustedTop > 0 ? rem(-adjustedTop) : 0};
  min-width: 100%;
  z-index: ${variables.zIndex['2']};
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-height: 100vh;
  overflow-y: auto;
  background-color: ${variables.colors.white};
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(18)};
  box-shadow: 0 ${rem(17)} ${rem(20)} ${rgba(0, 0, 0, 0.1)};
`;

export const StyledSelectBoxText = styled(Text)`
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: ${rem(12)};
`;

export const StyledSelectBoxIcon = styled(Icon)`
  margin-left: auto;
  pointer-events: none;
  transition: transform 0.11s linear;
`;

export const StyledSelectOption = styled.li`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: ${rem(52)};
  padding: ${rem(10)} ${rem(20)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  transition: background 0.15s ease-in;
  cursor: pointer;

  &:hover {
    background: ${variables.colors.lighterGray};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledSelectOptionLabel = styled(Text)<{ isSelected: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.isSelected &&
    css`
      font-family: ${variables.fonts.fontGothamMedium};
    `};
`;

export const StyledError = styled.span`
  font-size: ${rem(11)};
  color: ${variables.colors.primaryDark};
  display: block;
  margin-top: ${rem(12)};
`;
