import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';

export const StyledProductListItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${rem(88)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  padding: ${rem(20)} ${rem(12)};
`;

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledTextWrapper = styled.div<{ variant?: 'addToList' | 'default' }>`
  display: flex;
  flex-direction: column;
  min-width: ${rem(112)};
  width: ${rem(112)};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${rem(12)};
  margin-left: ${rem(12)};
  flex: 1;

  ${(props) =>
    props.variant === 'addToList' &&
    css`
      gap: ${rem(4)};
    `}
`;

export const StyledText = styled(Text)`
  line-height: ${rem(16)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPromotion = styled.div`
  margin-top: ${rem(15)};
  font-size: ${rem(11)};
`;

export const StyledAccent = styled.span`
  color: ${variables.colors.primary};
  margin-right: ${rem(5)};
`;

export const StyledSavings = styled.span`
  color: ${variables.colors.darkerGray};
`;

export const StyledSum = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  margin-left: ${rem(20)};
`;

export const StyledDeleteButton = styled(Button)`
  margin-bottom: ${rem(8)};
`;
