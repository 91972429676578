import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackGlobalMessage = (action: string, label: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'notification',
    action,
    label,
    value: 0,
  });
};
