import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

type SizesValueType = { 'x-small': number; small: number; medium: number; large: number; xlarge: number };
type ColorValueType = { white: string; black: string; red: string; grey: string; highlight: string };
type SizeStringType = 'x-small' | 'small' | 'medium' | 'large' | 'xlarge' | '';
type ColorType = 'white' | 'black' | 'red' | 'grey' | 'highlight';

const sizes: SizesValueType = {
  'x-small': 16,
  small: 24,
  medium: 36,
  large: 44,
  xlarge: 55,
};

const colors: ColorValueType = {
  white: variables.colors.white,
  black: variables.colors.black,
  red: variables.colors.primary,
  grey: variables.colors.mediumGray,
  highlight: variables.colors.highlight,
};

export const StyledRoundButton = styled.button<{ size: SizeStringType; color?: ColorType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  width: ${(props) => (props.size ? rem(sizes[props.size]) : rem(50))};
  height: ${(props) => (props.size ? rem(sizes[props.size]) : rem(50))};
  border-radius: 50%;
  border: 0;

  ${(props) =>
    props.color &&
    css`
      background-color: ${colors[props.color]};
    `}

  :disabled {
    opacity: 0.6;
    cursor: default;
  }

  @supports (-webkit-touch-callout: none) {
    padding: 0;
  }
`;
