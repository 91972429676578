import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useCustomRouter from '@hooks/useCustomRouter';
import useSnackBar from '@hooks/useSnackbar';
import clearFiltersFromQuery from '@helpers/clearFiltersFromQuery';
import Heading from '@atoms/Heading/Heading';
import Icon from '@atoms/Icon/Icon';
import FilterList from '@molecules/FilterList/FilterList';
import {
  sizes,
  StyledCloseIcon,
  StyledOpenFilterButton,
  StyledProductListFilter,
  StyledProductListFilterTakeoverBody,
  StyledProductListFilterTakeoverButton,
  StyledProductListFilterTakeoverFooter,
  StyledProductListFilterTakeoverHeader,
  StyledProductListFilterWrapper,
} from './ProductListFilter.styles';
import useResponsive from '@hooks/useResponsive';
import type { BreadcrumbDataOfSearchStateData, FacetDataOfobject } from '@occ/api-client';
import trackProductListFilter from '@helpers/analyticsHelpers/trackProductListFilter';
import Filter from '@icons/filter.svg';
import CloseSmall from '@icons/close_small.svg';
import SlideInSection from '@molecules/SlideInSection/SlideInSection';
import { toggleSlideInSection } from '@slices/slideInSectionSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';

type Props = {
  totalNumberOfResults: number;
  breadcrumbs: Array<BreadcrumbDataOfSearchStateData>;
  facets?: FacetDataOfobject[];
  isLoading?: boolean;
  gtmTrackOnFilterOpened?: boolean;
};

const ProductListFilter = ({
  totalNumberOfResults = 0,
  breadcrumbs,
  facets,
  isLoading,
  gtmTrackOnFilterOpened = true,
}: Props) => {
  const [activeCategory, setActiveCategory] = useState('');
  const [showFilterSlideInSection, setShowFilterSlideInSection] = useState<boolean>(false);
  const { isMobile } = useResponsive();
  const router = useCustomRouter();
  const isPromotionPage = router.pathname === '/erbjudanden' || router.pathname === '/erbjudanden/[mode]';
  const addSnack = useSnackBar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('productFilter');

  const addSnackWithData = () =>
    addSnack({
      icon: 'valid',
      text: t('resetFilterNotification'),
      bottomPosition: isMobile ? sizes.footerHeight + 20 : undefined,
    });

  const clearFilters = () => {
    const nextQuery = clearFiltersFromQuery(router.query);
    router.replace({ pathname: router.pathname, query: nextQuery });
    addSnackWithData();
  };

  return (
    <StyledProductListFilter $loading={isLoading} data-testid="productlist-filter">
      {!isLoading && facets && facets.length > 0 && (
        <StyledOpenFilterButton
          onClick={() => setShowFilterSlideInSection(true)}
          theme="secondary"
          size="small"
          isPromotionPage={isPromotionPage}
          data-testid="toggle-filter-slide-in-section"
        >
          <Icon svg={Filter} size={16} />
          <span>{breadcrumbs?.length > 0 ? t('appliedFilters', { amount: breadcrumbs.length }) : t('filter')}</span>
        </StyledOpenFilterButton>
      )}

      {showFilterSlideInSection && (
        <SlideInSection
          onClose={() => {
            setShowFilterSlideInSection(false);
            dispatch(toggleSlideInSection(false));
          }}
        >
          <StyledProductListFilterWrapper>
            <StyledProductListFilterTakeoverHeader>
              <Heading variant="h2" size="small">
                {t('title')}
              </Heading>
              <StyledCloseIcon svg={CloseSmall} size={16} onClick={() => setShowFilterSlideInSection(false)} />
            </StyledProductListFilterTakeoverHeader>
            <StyledProductListFilterTakeoverBody>
              {facets &&
                facets?.length > 0 &&
                facets.map((facet) => {
                  return (
                    facet.values &&
                    facet?.values?.length > 0 && (
                      <FilterList
                        code={facet?.code as string}
                        name={t(`categories->${facet.code}`)}
                        option={facet}
                        key={facet.name}
                        expandedListName={activeCategory}
                        toggleList={() => {
                          const activeCat = activeCategory === facet.name ? '' : facet.name;
                          if (activeCat && gtmTrackOnFilterOpened) {
                            trackProductListFilter(router.asPath, t(`categories->${facet.code}`));
                          }
                          setActiveCategory(activeCat as string);
                        }}
                        breadcrumbs={breadcrumbs}
                      />
                    )
                  );
                })}
            </StyledProductListFilterTakeoverBody>
            <StyledProductListFilterTakeoverFooter>
              <StyledProductListFilterTakeoverButton
                disabled={Object.keys(breadcrumbs).length <= 0}
                theme="secondary"
                onClick={clearFilters}
              >
                {t('clear')}
              </StyledProductListFilterTakeoverButton>
              <StyledProductListFilterTakeoverButton onClick={() => setShowFilterSlideInSection(false)} theme="primary">
                {t('done', { amount: totalNumberOfResults })}
              </StyledProductListFilterTakeoverButton>
            </StyledProductListFilterTakeoverFooter>
          </StyledProductListFilterWrapper>
        </SlideInSection>
      )}
    </StyledProductListFilter>
  );
};

export default ProductListFilter;
