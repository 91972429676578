/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../dto';
// @ts-ignore
import { AxfoodBasicOrderViewModel } from '../dto';
// @ts-ignore
import { AxfoodBasicProductViewModel } from '../dto';
// @ts-ignore
import { AxfoodCartEntryStatusViewModel } from '../dto';
// @ts-ignore
import { AxfoodClickAndCollectOrderBoxesViewModel } from '../dto';
// @ts-ignore
import { AxfoodOrderViewModel } from '../dto';
// @ts-ignore
import { OrderDataV2Response } from '../dto';
// @ts-ignore
import { OrderHistoryData } from '../dto';
// @ts-ignore
import { StartClickAndCollectProcessData } from '../dto';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('cancelOrderUsingPOST', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/cancelOrder/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finishes the collect process of an order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishCollectProcessUsingPOST: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('finishCollectProcessUsingPOST', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/clickandcollect/{orderCode}/finish`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is digital receipt
         * @param {string} getDigitalReceiptReference getDigitalReceiptReference
         * @param {string} date date
         * @param {string} storeId storeId
         * @param {string} memberCardNumber memberCardNumber
         * @param {string} [source] source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptUsingGET: async (getDigitalReceiptReference: string, date: string, storeId: string, memberCardNumber: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getDigitalReceiptReference' is not null or undefined
            assertParamExists('getDigitalReceiptUsingGET', 'getDigitalReceiptReference', getDigitalReceiptReference)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getDigitalReceiptUsingGET', 'date', date)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getDigitalReceiptUsingGET', 'storeId', storeId)
            // verify required parameter 'memberCardNumber' is not null or undefined
            assertParamExists('getDigitalReceiptUsingGET', 'memberCardNumber', memberCardNumber)
            const localVarPath = `/axfood/rest/order/orders/digitalreceipt/{getDigitalReceiptReference}`
                .replace(`{${"getDigitalReceiptReference"}}`, encodeURIComponent(String(getDigitalReceiptReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (memberCardNumber !== undefined) {
                localVarQueryParameter['memberCardNumber'] = memberCardNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for an order
         * @param {string} q Order Code Used in query to database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullOrderDataUsingGET: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getFullOrderDataUsingGET', 'q', q)
            const localVarPath = `/axfood/rest/orderdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for an order
         * @param {string} q Order Code Used in query to database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullOrderDataUsingGET1: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getFullOrderDataUsingGET1', 'q', q)
            const localVarPath = `/axfood/rest/orderdata/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get last order lines
         * @param {string} baseStore baseStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOrderLinesUsingGET: async (baseStore: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseStore' is not null or undefined
            assertParamExists('getLastOrderLinesUsingGET', 'baseStore', baseStore)
            const localVarPath = `/axfood/rest/order/last/lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (baseStore !== undefined) {
                localVarQueryParameter['baseStore'] = baseStore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryUsingGET1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/order/orderHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order receipt
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderReceiptUsingGET: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('getOrderReceiptUsingGET', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/orders/{orderCode}/receipt`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('getOrderUsingGET', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/orders/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders for complaint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/order/orders/complaint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is order process finished
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isOrderProcessFinishedUsingGET: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('isOrderProcessFinishedUsingGET', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/isOrderProccessFinished/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Locks a box of a click and collect order
         * @param {string} orderCode orderCode
         * @param {string} boxId boxId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOrderBoxUsingPOST: async (orderCode: string, boxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('lockOrderBoxUsingPOST', 'orderCode', orderCode)
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('lockOrderBoxUsingPOST', 'boxId', boxId)
            const localVarPath = `/axfood/rest/order/clickandcollect/{orderCode}/boxes/{boxId}/lock`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)))
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the collect process of an order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCollectProcessUsingPOST: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('startCollectProcessUsingPOST', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/clickandcollect/{orderCode}/start`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlocks a box of a click and collect order
         * @param {string} orderCode orderCode
         * @param {string} boxId boxId
         * @param {string} [previousOpened] previousOpened
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOrderBoxUsingPOST: async (orderCode: string, boxId: string, previousOpened?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('unlockOrderBoxUsingPOST', 'orderCode', orderCode)
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('unlockOrderBoxUsingPOST', 'boxId', boxId)
            const localVarPath = `/axfood/rest/order/clickandcollect/{orderCode}/boxes/{boxId}/unlock`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)))
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (previousOpened !== undefined) {
                localVarQueryParameter['previousOpened'] = previousOpened;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate order entries
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOrderEntriesUsingGET: async (orderCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCode' is not null or undefined
            assertParamExists('validateOrderEntriesUsingGET', 'orderCode', orderCode)
            const localVarPath = `/axfood/rest/order/validate/{orderCode}`
                .replace(`{${"orderCode"}}`, encodeURIComponent(String(orderCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrderUsingPOST(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderUsingPOST(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finishes the collect process of an order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishCollectProcessUsingPOST(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodClickAndCollectOrderBoxesViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishCollectProcessUsingPOST(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is digital receipt
         * @param {string} getDigitalReceiptReference getDigitalReceiptReference
         * @param {string} date date
         * @param {string} storeId storeId
         * @param {string} memberCardNumber memberCardNumber
         * @param {string} [source] source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalReceiptUsingGET(getDigitalReceiptReference: string, date: string, storeId: string, memberCardNumber: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalReceiptUsingGET(getDigitalReceiptReference, date, storeId, memberCardNumber, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get data for an order
         * @param {string} q Order Code Used in query to database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullOrderDataUsingGET(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDataV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullOrderDataUsingGET(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get data for an order
         * @param {string} q Order Code Used in query to database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullOrderDataUsingGET1(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDataV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullOrderDataUsingGET1(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get last order lines
         * @param {string} baseStore baseStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastOrderLinesUsingGET(baseStore: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodBasicProductViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastOrderLinesUsingGET(baseStore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryUsingGET1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderHistoryData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryUsingGET1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order receipt
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderReceiptUsingGET(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderReceiptUsingGET(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderUsingGET(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderUsingGET(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get orders for complaint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodBasicOrderViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is order process finished
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isOrderProcessFinishedUsingGET(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isOrderProcessFinishedUsingGET(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Locks a box of a click and collect order
         * @param {string} orderCode orderCode
         * @param {string} boxId boxId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockOrderBoxUsingPOST(orderCode: string, boxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodClickAndCollectOrderBoxesViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockOrderBoxUsingPOST(orderCode, boxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the collect process of an order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCollectProcessUsingPOST(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartClickAndCollectProcessData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCollectProcessUsingPOST(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlocks a box of a click and collect order
         * @param {string} orderCode orderCode
         * @param {string} boxId boxId
         * @param {string} [previousOpened] previousOpened
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockOrderBoxUsingPOST(orderCode: string, boxId: string, previousOpened?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodClickAndCollectOrderBoxesViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockOrderBoxUsingPOST(orderCode, boxId, previousOpened, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate order entries
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOrderEntriesUsingGET(orderCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodCartEntryStatusViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateOrderEntriesUsingGET(orderCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST(orderCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelOrderUsingPOST(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finishes the collect process of an order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishCollectProcessUsingPOST(orderCode: string, options?: any): AxiosPromise<AxfoodClickAndCollectOrderBoxesViewModel> {
            return localVarFp.finishCollectProcessUsingPOST(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is digital receipt
         * @param {string} getDigitalReceiptReference getDigitalReceiptReference
         * @param {string} date date
         * @param {string} storeId storeId
         * @param {string} memberCardNumber memberCardNumber
         * @param {string} [source] source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalReceiptUsingGET(getDigitalReceiptReference: string, date: string, storeId: string, memberCardNumber: string, source?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getDigitalReceiptUsingGET(getDigitalReceiptReference, date, storeId, memberCardNumber, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data for an order
         * @param {string} q Order Code Used in query to database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullOrderDataUsingGET(q: string, options?: any): AxiosPromise<OrderDataV2Response> {
            return localVarFp.getFullOrderDataUsingGET(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data for an order
         * @param {string} q Order Code Used in query to database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullOrderDataUsingGET1(q: string, options?: any): AxiosPromise<OrderDataV2Response> {
            return localVarFp.getFullOrderDataUsingGET1(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get last order lines
         * @param {string} baseStore baseStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOrderLinesUsingGET(baseStore: string, options?: any): AxiosPromise<Array<AxfoodBasicProductViewModel>> {
            return localVarFp.getLastOrderLinesUsingGET(baseStore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryUsingGET1(options?: any): AxiosPromise<Array<OrderHistoryData>> {
            return localVarFp.getOrderHistoryUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order receipt
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderReceiptUsingGET(orderCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.getOrderReceiptUsingGET(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderCode: string, options?: any): AxiosPromise<AxfoodOrderViewModel> {
            return localVarFp.getOrderUsingGET(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders for complaint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersUsingGET(options?: any): AxiosPromise<Array<AxfoodBasicOrderViewModel>> {
            return localVarFp.getOrdersUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is order process finished
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isOrderProcessFinishedUsingGET(orderCode: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isOrderProcessFinishedUsingGET(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Locks a box of a click and collect order
         * @param {string} orderCode orderCode
         * @param {string} boxId boxId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOrderBoxUsingPOST(orderCode: string, boxId: string, options?: any): AxiosPromise<AxfoodClickAndCollectOrderBoxesViewModel> {
            return localVarFp.lockOrderBoxUsingPOST(orderCode, boxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the collect process of an order
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCollectProcessUsingPOST(orderCode: string, options?: any): AxiosPromise<StartClickAndCollectProcessData> {
            return localVarFp.startCollectProcessUsingPOST(orderCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlocks a box of a click and collect order
         * @param {string} orderCode orderCode
         * @param {string} boxId boxId
         * @param {string} [previousOpened] previousOpened
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOrderBoxUsingPOST(orderCode: string, boxId: string, previousOpened?: string, options?: any): AxiosPromise<AxfoodClickAndCollectOrderBoxesViewModel> {
            return localVarFp.unlockOrderBoxUsingPOST(orderCode, boxId, previousOpened, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate order entries
         * @param {string} orderCode orderCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOrderEntriesUsingGET(orderCode: string, options?: any): AxiosPromise<Array<AxfoodCartEntryStatusViewModel>> {
            return localVarFp.validateOrderEntriesUsingGET(orderCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Cancel order
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public cancelOrderUsingPOST(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).cancelOrderUsingPOST(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finishes the collect process of an order
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public finishCollectProcessUsingPOST(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).finishCollectProcessUsingPOST(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is digital receipt
     * @param {string} getDigitalReceiptReference getDigitalReceiptReference
     * @param {string} date date
     * @param {string} storeId storeId
     * @param {string} memberCardNumber memberCardNumber
     * @param {string} [source] source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getDigitalReceiptUsingGET(getDigitalReceiptReference: string, date: string, storeId: string, memberCardNumber: string, source?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getDigitalReceiptUsingGET(getDigitalReceiptReference, date, storeId, memberCardNumber, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data for an order
     * @param {string} q Order Code Used in query to database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getFullOrderDataUsingGET(q: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getFullOrderDataUsingGET(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data for an order
     * @param {string} q Order Code Used in query to database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getFullOrderDataUsingGET1(q: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getFullOrderDataUsingGET1(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get last order lines
     * @param {string} baseStore baseStore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getLastOrderLinesUsingGET(baseStore: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getLastOrderLinesUsingGET(baseStore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderHistoryUsingGET1(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderHistoryUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order receipt
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderReceiptUsingGET(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderReceiptUsingGET(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderUsingGET(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderUsingGET(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get orders for complaint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersUsingGET(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is order process finished
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public isOrderProcessFinishedUsingGET(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).isOrderProcessFinishedUsingGET(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Locks a box of a click and collect order
     * @param {string} orderCode orderCode
     * @param {string} boxId boxId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public lockOrderBoxUsingPOST(orderCode: string, boxId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).lockOrderBoxUsingPOST(orderCode, boxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the collect process of an order
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public startCollectProcessUsingPOST(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).startCollectProcessUsingPOST(orderCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlocks a box of a click and collect order
     * @param {string} orderCode orderCode
     * @param {string} boxId boxId
     * @param {string} [previousOpened] previousOpened
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public unlockOrderBoxUsingPOST(orderCode: string, boxId: string, previousOpened?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).unlockOrderBoxUsingPOST(orderCode, boxId, previousOpened, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate order entries
     * @param {string} orderCode orderCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public validateOrderEntriesUsingGET(orderCode: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).validateOrderEntriesUsingGET(orderCode, options).then((request) => request(this.axios, this.basePath));
    }
}
