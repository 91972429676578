import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useCustomRouter from '@hooks/useCustomRouter';
import { useMediaQuery } from 'react-responsive';
import KEYS from '@helpers/keys';
import { getSearchAutocomplete } from '@api/interfaces/searchApi';
import Icon from '@atoms/Icon/Icon';
import AutocompleteSearchListItem from '@molecules/Autocomplete/ListItems/SearchListItem';
import Autocomplete from '@molecules/Autocomplete/Autocomplete';
import useUserAgent from '@hooks/useUserAgent';
import Config from '@config';
import debounce from '@helpers/debounce';
import Paths from '@constants/paths';
import {
  StyledSearch,
  StyledSearchButtonSubmit,
  StyledSearchClearButton,
  StyledSearchContainer,
  StyledSearchField,
  StyledSearchForm,
} from './Search.styles';
import SearchIcon from '@icons/search.svg';
import Clear from '@icons/clear.svg';
import type { AutocompleteSuggestionData } from '@occ/api-client';

const Search = () => {
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const minSearchLength = 2;
  const { t } = useTranslation('search');
  const [suggestions, setSuggestions] = useState<AutocompleteSuggestionData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isBlurIgnored, setIgnoreBlur] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionIndex, setSuggestionIndex] = useState(-1);
  const router = useCustomRouter();
  const { isMobileDevice } = useUserAgent();
  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });
  const fromTabletLandscape = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_LANDSCAPE,
  });

  const getSuggestions = (term: string) => {
    if (term.length >= minSearchLength && fromTabletLandscape) {
      return getSearchAutocomplete(encodeURIComponent(term.toLowerCase())).then((res) => {
        setSuggestions(res.data.suggestions ?? []);
        setShowSuggestions(true);
      });
    }
    return setSuggestions([]);
  };

  const handleClear = () => {
    setSearchTerm('');
    setShowSuggestions(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetSuggestions = useCallback(debounce(getSuggestions, Config.TIMEOUT.searchGetSuggestionsMs), []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    setSuggestionIndex(-1);
    setSearchTerm(val);
    return debouncedGetSuggestions(val);
  };

  const ignoreBlur = () => {
    setIgnoreBlur(true);
  };

  const clearIgnoreBlur = () => {
    setIgnoreBlur(false);
  };

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleBlur = () => {
    if (isBlurIgnored) return;
    setShowSuggestions(false);
  };

  const autoCompleteIsOpen = showSuggestions && suggestions.length > 0 && fromTabletLandscape;

  const dismissKeyboard = () => {
    if (isMobileDevice) {
      inputRef.current?.blur();
    }
  };

  const search = (e?: KeyboardEvent<HTMLInputElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    debouncedGetSuggestions.cancel();
    router.push({ pathname: '/sok', query: { q: searchTerm.trim() } });
    dismissKeyboard();
    setShowSuggestions(false);
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KEYS.ESCAPE:
        e.currentTarget.blur();
        break;
      default:
        break;
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KEYS.ENTER:
        search(e);
        break;
      case KEYS.TAB:
        e.currentTarget.blur();
        break;
      case KEYS.UP:
        e.preventDefault();
        break;
      case KEYS.DOWN:
        e.preventDefault();
        autoCompleteRef.current?.querySelector<HTMLOptionElement>('[role="option"]')?.focus();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isMobile) {
      inputRef.current?.focus();
    }
  }, [isMobile]);

  useEffect(() => {
    if (router.route !== Paths.SEARCH) {
      handleClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const autocompleteFragment = (
    <Autocomplete
      id="autocomplete-fragment"
      ref={autoCompleteRef}
      inputRef={inputRef}
      searchTerm={searchTerm}
      suggestions={suggestions}
      suggestionIndex={suggestionIndex}
      ListComponent={AutocompleteSearchListItem}
      showSuggestions={setShowSuggestions}
      setSearchTerm={setSearchTerm}
      setSuggestionIndex={setSuggestionIndex}
      rounded
    />
  );

  return (
    <StyledSearch role="search">
      <StyledSearchForm
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={ignoreBlur}
        onMouseUp={clearIgnoreBlur}
        onMouseOut={clearIgnoreBlur}
        onTouchStart={ignoreBlur}
        onTouchEnd={clearIgnoreBlur}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <StyledSearchButtonSubmit
          color="black"
          size="medium"
          onClick={search}
          data-testid="search-button"
          aria-label={t('search->field->placeholder')}
        >
          <Icon svg={SearchIcon} size={16} color="white" />
        </StyledSearchButtonSubmit>
        <StyledSearchContainer>
          <StyledSearchField
            ref={inputRef}
            name="search"
            type="search"
            aria-haspopup={autoCompleteIsOpen}
            aria-expanded={autoCompleteIsOpen}
            aria-controls="autocomplete-fragment"
            aria-autocomplete="list"
            role="combobox"
            variant="rounded"
            placeholder={isMobile ? t('search->field->mobile->placeholder') : t('search->field->placeholder')}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={handleBlur}
            value={searchTerm}
            aria-label={isMobile ? t('search->field->mobile->placeholder') : t('search->field->placeholder')}
            autoComplete="off"
            autocompleteComponent={autoCompleteIsOpen && autocompleteFragment}
          />
          <StyledSearchClearButton
            hidden={!searchTerm}
            visible={!!searchTerm}
            type="button"
            onMouseDown={handleClear}
            data-testid="clear-search-button"
          >
            <Icon svg={Clear} size={16} />
          </StyledSearchClearButton>
        </StyledSearchContainer>
      </StyledSearchForm>
    </StyledSearch>
  );
};

export default Search;
