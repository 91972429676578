import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import Modal from '@organisms/Modal/Modal';
import Text from '@atoms/Text/Text';
import useTranslation from 'next-translate/useTranslation';
import { setEmptyCartModalVisibility, selectEmptyCartModal } from '@slices/modalSlice';
import { StyledHeading } from './CheckoutEmptyCartModal.styles';
import { useEffect } from 'react';
import { trackCheckoutSessionTimeout } from '@helpers/analyticsHelpers/trackCheckoutSubmitError';
import useCart from '@hooks/useCart';

const CheckoutEmptyCartModal = () => {
  const { visible } = useAppSelector(selectEmptyCartModal);
  const { t } = useTranslation('checkoutEmptyCartModal');
  const dispatch = useAppDispatch();
  const { refreshCart } = useCart();

  const close = () => {
    dispatch(setEmptyCartModalVisibility(false));
  };

  useEffect(() => {
    if (visible) {
      refreshCart();
      trackCheckoutSessionTimeout(t('modal->text->line1'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      {visible && (
        <Modal
          size="small"
          onClose={close}
          onConfirm={close}
          confirmButtonText={t('modal->buttons->ok')}
          closeOnEscape
          buttonWidth={100}
        >
          <StyledHeading variant="h2" size="small">
            {t('modal->title')}
          </StyledHeading>

          <Text type="body">{t('modal->text->line1')}</Text>
        </Modal>
      )}
    </>
  );
};

export default CheckoutEmptyCartModal;
