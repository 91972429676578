import styled, { css } from 'styled-components';
import variables from '@styles/variables';

export const StyledHighLightText = styled.span<{ disabled: boolean }>`
  strong {
    font-family: ${variables.fonts.fontGothamMedium};
    color: ${variables.colors.primary};
    font-weight: 500;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${variables.colors.darkGray};

      strong {
        color: ${variables.colors.darkGray};
      }
    `}
`;
