import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';
import Checkbox from '@atoms/Checkbox/Checkbox';
import Text from '@atoms/Text/Text';

export const StyledFilterList = styled.div<{ expanded: boolean }>`
  min-width: 100%;
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  margin: 0;
  min-height: ${rem(72)};
  border-radius: ${rem(2)};
  cursor: pointer;
  color: ${variables.colors.lighterBlack};
  padding: ${rem(17)} ${rem(20)};
  font-size: ${rem(15)};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(20)};

  ${(props) =>
    props.expanded &&
    css`
      border-color: transparent;
      color: ${variables.colors.primary};
    `}
`;

export const StyledFilterListSpan = styled.span`
  font-family: ${variables.fonts.fontGothamMedium};
`;

export const StyledFilterListOptions = styled.div<{ showAll: boolean; expanded: boolean }>`
  flex-basis: 100%;
  order: 99;
  display: none;
  transition: max-height 1s ease;
  padding: 0 ${rem(20)};

  ${(props) =>
    props.expanded &&
    css`
      display: block;
    `}

  ${(props) =>
    props.showAll &&
    css`
      padding-bottom: ${rem(12)};
      border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: 0;
        border: 0;
      }
    `}
`;

export const StyledFilterListOptionsContainer = styled.div``;

export const StyledFilterListShowAll = styled.div`
  flex-basis: 100%;
  order: 100;
  display: flex;
  justify-content: center;
  padding: ${rem(10)};
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(13)};
  color: ${variables.colors.highlight};
`;

export const StyledFilterListCheckbox = styled(Checkbox)`
  left: ${rem(10)};
  position: relative;
`;
export const StyledFilterListLabel = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(16)};
  cursor: pointer;
`;
export const StyledFilterCheckBoxAndCount = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(4)};
`;

export const StyledFilterListSelectedText = styled(Text)`
  display: inline;
`;
