import { MouseEvent, ReactNode, useEffect } from 'react';
import { StyledBackdrop } from './Backdrop.styles';

interface Props {
  children?: ReactNode;
  callback: () => void;
  display: boolean;
  mobileOnly?: boolean;
  transparent?: boolean;
  preventLockPage?: boolean;
  zIndex?: number;
  className?: string;
}

const Backdrop = ({
  children,
  callback,
  display,
  mobileOnly,
  transparent,
  preventLockPage = false,
  zIndex,
  className,
}: Props) => {
  useEffect(() => {
    if (!preventLockPage) {
      const html = document.getElementsByTagName('html')[0];
      html.style.overflow = 'hidden';

      return () => {
        html.style.removeProperty('overflow');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const close = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      callback();
    }
  };

  return (
    <StyledBackdrop
      data-testid="backdrop"
      onMouseDown={close}
      hasChildren={!!children}
      isOpen={display}
      mobileOnly={!!mobileOnly}
      transparent={!!transparent}
      zIndex={zIndex}
      className={className}
    >
      {children}
    </StyledBackdrop>
  );
};

export default Backdrop;
