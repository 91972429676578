import {
  CustomersApi,
  AxfoodApi,
  CustomerData,
  RegisterB2BCustomerForm,
  RegisterCustomerForm,
  SearchCompanyForm,
  VerifyMembersEmailFlowForm,
  VerifyMembersPhoneFlowForm,
} from '@occ/api-client';

// Override optional properties from API
export interface OverriddenCustomerData extends CustomerData {
  isB2BCustomer: boolean;
  uid: string;
  isAnonymous: boolean;
  sapId: string;
  socialSecurityNumer: string;
  suppressAllEmails: boolean;
}

const customersApi = new CustomersApi({
  baseUrl: '',
});

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export const getCustomer = async (): Promise<OverriddenCustomerData> => {
  const response = await axfoodApi.getSessionInformationUsingGet();
  const customer = response.data;

  // Override and default properties from API
  return {
    ...customer,
    uid: customer.uid || 'anonymous',
    isB2BCustomer: customer.isB2BCustomer || false,
    isAnonymous: customer.uid === 'anonymous' || false,
    sapId: customer.uid !== 'anonymous' ? customer.sapId ?? '' : '',
    socialSecurityNumer: customer.uid !== 'anonymous' ? customer.socialSecurityNumer ?? '' : '',
    suppressAllEmails: customer?.suppressAllEmails ?? false,
  };
};

export const lookupB2bCustomer = (form: SearchCompanyForm) => axfoodApi.lookupB2BCustomerUsingPost(form);

export const registerB2cCustomer = (form: RegisterCustomerForm, autoLogin?: boolean) =>
  customersApi.registerCustomerUsingPost(form, { autoLogin });

export const deleteSavedCard = (cardId: string, signal?: AbortSignal) => {
  return axfoodApi.deleteAgreementUsingDelete(cardId, { signal, format: 'json' });
};

export const registerB2BCustomer = async (form: RegisterB2BCustomerForm) => {
  const response = await axfoodApi.registerB2BCustomerUsingPost(form);
  return response.data;
};

export const verifyAccountWithEmail = (form: VerifyMembersEmailFlowForm) => axfoodApi.verifyEmailUsingPost(form);

export const verifyAccountWithPhone = (form: VerifyMembersPhoneFlowForm) => axfoodApi.verifyPhoneUsingPost(form);
