import { ReactNode } from 'react';
import StyledGridRow from './GridRow.styles';

interface Props {
  children: ReactNode;
  noGap?: boolean;
}

const GridRow = ({ children, noGap = false }: Props) => <StyledGridRow noGap={noGap}>{children}</StyledGridRow>;

export default GridRow;
