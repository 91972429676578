import { useEffect, useMemo, useState } from 'react';
import {
  StyledVoucherCard,
  StyledVoucherCardContent,
  StyledHeadingWrapper,
  StyledVoucherOverlay,
} from './VoucherCard.styles';
import Modal from '@organisms/Modal/Modal';
import useTranslation from 'next-translate/useTranslation';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import { StyledTextWithMargin, StyledVoucherHeading } from '@molecules/PersonalElementsCard/PersonalElementsCard.styles';
import { discountTypes } from '@molecules/PersonalElementsCard/PersonalElementsCard';
import getDisplayVoucher from '@helpers/getDisplayVoucher';
import { AxfoodExternalVoucher } from '@occ/api-client';

interface Props {
  voucher: AxfoodExternalVoucher;
  type: OnlineOfflineProductType;
}

const VoucherCard = ({ voucher, type }: Props) => {
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [voucherType, setVoucherType] = useState('');
  const [displayVoucher, setDisplayVoucher] = useState<AxfoodExternalVoucher>({} as AxfoodExternalVoucher);
  const { t } = useTranslation('vouchercard');

  const isOnlineOnly = useMemo(() => {
    if (displayVoucher?.voucherChannelData) {
      const { online, omni, selfcheckout, selfscan, checkout } = displayVoucher.voucherChannelData;
      return online && !omni && !selfcheckout && !selfscan && !checkout;
    }
    return false;
  }, [displayVoucher]);

  useEffect(() => {
    getDisplayVoucher([voucher], setVoucherType, setDisplayVoucher);
  }, [voucher]);

  return (
    <>
      <StyledVoucherCard productType={type}>
        <StyledVoucherCardContent>
          {voucher.status !== 'Tillgänglig' && (
            <StyledVoucherOverlay productType={type}>
              <Text type="label">{t('voucher->usedVoucher')}</Text>
            </StyledVoucherOverlay>
          )}
          <StyledHeadingWrapper>
            <Heading variant="h3">{t('voucher->title')}</Heading>
            <Heading variant="h3" color="red">{`${voucher.discountValue}${
              voucher.discountType === 'Procent' ? '%' : 'kr'
            }`}</Heading>
          </StyledHeadingWrapper>
          <Text type="body" size="small">{`${t('voucher->text')} ${voucher.expirationDate}`}</Text>
          <Button
            theme="secondary-light-bg"
            onClick={() => {
              if (voucher.status === 'Tillgänglig') setShowVoucherModal(true);
            }}
          >
            {t('voucher->button')}
          </Button>
        </StyledVoucherCardContent>
      </StyledVoucherCard>
      {showVoucherModal && (
        <Modal
          size="small"
          onClose={() => setShowVoucherModal(false)}
          onConfirm={() => setShowVoucherModal(false)}
          confirmButtonText={t('voucher->modal->button')}
          closeOnEscape
          buttonWidth={100}
          hasCloseIcon
        >
          <StyledVoucherHeading variant="h2" size="small">
            {t('voucher->modal->title', {
              value: voucher.discountValue,
              unit:
                voucher.discountType === discountTypes.ABSOLUTE
                  ? t('voucher->modal->absoluteValue')
                  : t('voucher->modal->percentageValue'),
            })}
          </StyledVoucherHeading>

          <StyledTextWithMargin type="body" size="small">
            {t(`voucher->modal->${voucherType}->text`)}
          </StyledTextWithMargin>
          <Text type="body" size="small" data-testid="voucher-requirements-and-validIn">
            {!!voucher.minPurchaseAmount &&
              t(`voucher->modal->${voucherType}->requirements`, {
                value: voucher.minPurchaseAmount,
              })}
            {t(`voucher->modal->${voucherType}->validIn`)}
          </Text>
          <StyledTextWithMargin type="body" size="small">
            {t('voucher->modal->validUntil', {
              date: voucher.expirationDate,
            })}
          </StyledTextWithMargin>
          {!isOnlineOnly && (
            <>
              <Text type="label">{t('voucher->modal->code')}</Text>
              <StyledTextWithMargin type="price" size="large" color="red">
                {voucher.voucherNbr}
              </StyledTextWithMargin>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default VoucherCard;
