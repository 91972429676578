import { ReactNode, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import AccordionButton from './AccordionButton';
import AccordionContent from './AccordionContent';
import { StyledAccordion } from './Accordion.styles';

interface Props {
  children: ReactNode;
  accordionKey: string;
  title: string | ReactNode;
  isOpen?: boolean;
  customClickHandler?: (key: string) => void;
  enableScroll?: boolean;
  isCartPage?: boolean;
}

const Accordion = ({ children, accordionKey, title, isOpen, customClickHandler, enableScroll, isCartPage }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const onceRef = useRef(true);
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const handleClick = (key: string) => {
    if (customClickHandler) {
      customClickHandler(key);
    } else {
      setInternalIsOpen(!internalIsOpen);
    }
  };

  const isContentOpen = isOpen !== undefined ? isOpen : internalIsOpen;

  const handleOnAnimationEnd = () => {
    if (onceRef.current) {
      if (isContentOpen && enableScroll && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
      onceRef.current = false;
    }
  };

  return (
    <StyledAccordion ref={ref} data-testid="accordion" data-visibility-state={isContentOpen ? 'true' : 'false'}>
      <AccordionButton
        onClick={handleClick}
        title={title}
        accordionKey={accordionKey}
        active={isContentOpen}
        isCartPage={isCartPage}
      />
      <AnimateHeight
        height={isContentOpen ? 'auto' : 0}
        onAnimationEnd={handleOnAnimationEnd}
        data-testid="animate-height-accordion"
      >
        <AccordionContent>{children}</AccordionContent>
      </AnimateHeight>
    </StyledAccordion>
  );
};

export default Accordion;
