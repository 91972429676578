import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackOpenMixMatchBeam = (productSKU: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'multi_product_offer',
    action: 'product_shown',
    label: productSKU,
    value: 0,
  });
};

export default trackOpenMixMatchBeam;
