import Router, { NextRouter } from 'next/router';

const { router } = Router;

export type NavigatorPropKeys =
  | 'isNativeApp'
  | 'isReactNative'
  | 'isMobileDevice'
  | 'isIOS'
  | 'isAndroid'
  | 'isSafariBrowser'
  | 'isChromeBrowser';
export type RouterPropKeys = 'isEcommerce' | 'isNativeAndEcommerce' | 'isWebOrNativeAndEcommerce';

type NavigatorPropType = Record<NavigatorPropKeys, () => boolean>;
type RouterPropType = Record<RouterPropKeys, (router?: NextRouter) => boolean>;

type UserAgentType = NavigatorPropType & RouterPropType;

const UserAgent: UserAgentType = {
  isEcommerce: (_router) => (_router || router)?.query?.viewconfig === 'ecommerce',
  isNativeAndEcommerce: (_router) => UserAgent.isNativeApp() && UserAgent.isEcommerce(_router),
  isWebOrNativeAndEcommerce: (_router) =>
    !UserAgent.isNativeApp() || (UserAgent.isNativeApp() && UserAgent.isEcommerce(_router)),
  isNativeApp: () => /(?:Axfood|Willys)+MobileApp/.test(navigator?.userAgent),
  isReactNative: () => /(?:Axfood|Willys)+MobileApp-ReactNative/.test(navigator?.userAgent),
  isMobileDevice: () => /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator?.userAgent),
  isIOS: () =>
    /iPhone|iPad|iPod/i.test(navigator?.userAgent) ||
    // iPad on iOS 13 detection
    (navigator?.userAgent.includes('Mac') && 'ontouchend' in document),
  isAndroid: () => /Android/i.test(navigator?.userAgent),
  isChromeBrowser: () => /chrome|crios/i.test(navigator?.userAgent),
  isSafariBrowser: () => !UserAgent.isChromeBrowser() && /Safari/i.test(navigator?.userAgent),
};

export default UserAgent;
