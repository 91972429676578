import styled, { keyframes, css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

const BACKGROUND_COLOR = variables.colors.lightGray;
const HIGHLIGHT_COLOR = variables.colors.lighterGray;

export const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;
export const SkeletonRow = styled.span<{
  width: number | string;
  height: number | string;
  animation: boolean;
}>`
  display: inline-block;
  width: ${(props) =>
    Number.isInteger(props.width)
      ? css`
          ${rem(props.width)}
        `
      : props.width};
  height: ${(props) =>
    Number.isInteger(props.height)
      ? css`
          ${rem(props.height)}
        `
      : props.height};
  background-color: ${BACKGROUND_COLOR};
  background-image: linear-gradient(90deg, ${BACKGROUND_COLOR}, ${HIGHLIGHT_COLOR}, ${BACKGROUND_COLOR});
  background-repeat: no-repeat;
  margin: ${rem(5)} 0;
  border-radius: ${rem(3)};
  animation: ${(props) => (props.animation ? css`1.2s ${skeletonKeyframes} ease-in-out infinite` : 'none')};
`;
export const SkeletonCircle = styled(SkeletonRow)`
  border-radius: 50%;
`;
export const SkeletonHalfCircle = styled(SkeletonRow)<{ width: number | string }>`
  border-top-left-radius: ${(props) =>
    Number.isInteger(props.width)
      ? css`
          ${rem(props.width)}
        `
      : props.width};
  border-top-right-radius: ${(props) =>
    Number.isInteger(props.width)
      ? css`
          ${rem(props.width)}
        `
      : props.width};
`;

export const SkeletonRect = styled(SkeletonRow)`
  border-radius: 0;
`;
export const SkeletonButton = styled(SkeletonRow)`
  border-radius: ${rem(40)};
`;
