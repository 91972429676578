/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BaseOptionData } from './base-option-data';
// May contain unused imports in some cases
// @ts-ignore
import { CategoryData } from './category-data';
// May contain unused imports in some cases
// @ts-ignore
import { ClassificationData } from './classification-data';
// May contain unused imports in some cases
// @ts-ignore
import { CountryData } from './country-data';
// May contain unused imports in some cases
// @ts-ignore
import { EnergyDeclaration } from './energy-declaration';
// May contain unused imports in some cases
// @ts-ignore
import { FutureStockData } from './future-stock-data';
// May contain unused imports in some cases
// @ts-ignore
import { ImageData } from './image-data';
// May contain unused imports in some cases
// @ts-ignore
import { PriceData } from './price-data';
// May contain unused imports in some cases
// @ts-ignore
import { PriceRangeData } from './price-range-data';
// May contain unused imports in some cases
// @ts-ignore
import { ProductBasketTypeEnum } from './product-basket-type-enum';
// May contain unused imports in some cases
// @ts-ignore
import { ProductInfoAttachmentData } from './product-info-attachment-data';
// May contain unused imports in some cases
// @ts-ignore
import { ProductReferenceData } from './product-reference-data';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionData } from './promotion-data';
// May contain unused imports in some cases
// @ts-ignore
import { ReviewData } from './review-data';
// May contain unused imports in some cases
// @ts-ignore
import { SolrFirstVariantCategoryEntryData } from './solr-first-variant-category-entry-data';
// May contain unused imports in some cases
// @ts-ignore
import { StockData } from './stock-data';
// May contain unused imports in some cases
// @ts-ignore
import { UnitData } from './unit-data';
// May contain unused imports in some cases
// @ts-ignore
import { VariantMatrixElementData } from './variant-matrix-element-data';
// May contain unused imports in some cases
// @ts-ignore
import { VariantOptionData } from './variant-option-data';

/**
 * 
 * @export
 * @interface ProductData
 */
export interface ProductData {
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'activeSubstance'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'addToCartDisabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'addToCartDisabledMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'addToCartMessage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'availableForPickup'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'averageRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'averageWeight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'bargainProduct'?: boolean;
    /**
     * 
     * @type {Array<BaseOptionData>}
     * @memberof ProductData
     */
    'baseOptions'?: Array<BaseOptionData>;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'baseProduct'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'bulkyProduct'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'campaignRanking'?: number;
    /**
     * 
     * @type {Array<CategoryData>}
     * @memberof ProductData
     */
    'categories'?: Array<CategoryData>;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'categoryGroupText'?: string;
    /**
     * 
     * @type {Array<ClassificationData>}
     * @memberof ProductData
     */
    'classifications'?: Array<ClassificationData>;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'commercialName1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'commercialName2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'commercialName3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'comparePrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'comparePriceUnit'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'configurable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'configuratorType'?: string;
    /**
     * 
     * @type {PriceData}
     * @memberof ProductData
     */
    'depositPrice'?: PriceData;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'ean'?: string;
    /**
     * 
     * @type {EnergyDeclaration}
     * @memberof ProductData
     */
    'energyDeclaration'?: EnergyDeclaration;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'extraInfoCode'?: string;
    /**
     * 
     * @type {Array<SolrFirstVariantCategoryEntryData>}
     * @memberof ProductData
     */
    'firstCategoryNameList'?: Array<SolrFirstVariantCategoryEntryData>;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'fruitAndVegetableVariety'?: string;
    /**
     * 
     * @type {Array<FutureStockData>}
     * @memberof ProductData
     */
    'futureStocks'?: Array<FutureStockData>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'gdprTrackingIncompliant'?: boolean;
    /**
     * 
     * @type {Array<ImageData>}
     * @memberof ProductData
     */
    'images'?: Array<ImageData>;
    /**
     * 
     * @type {Array<PromotionData>}
     * @memberof ProductData
     */
    'inactivePotentialPromotions'?: Array<PromotionData>;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'incrementValue'?: number;
    /**
     * 
     * @type {ProductInfoAttachmentData}
     * @memberof ProductData
     */
    'infoAttachmentData'?: ProductInfoAttachmentData;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'isDrugProduct'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'loyaltySaveValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'manufacturer'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'manufacturingCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'medicineLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'medicineType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'multidimensional'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'newsSplashProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'nicotineMedicalProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'nonEkoProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'notAllowedAnonymous'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'notAllowedB2b'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'numberOfReviews'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'offlineProduct'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'originCountry'?: string;
    /**
     * 
     * @type {Array<CountryData>}
     * @memberof ProductData
     */
    'otherFruitAndVegetableCountries'?: Array<CountryData>;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'pickQuantity'?: number;
    /**
     * 
     * @type {Array<PromotionData>}
     * @memberof ProductData
     */
    'potentialPromotions'?: Array<PromotionData>;
    /**
     * 
     * @type {PriceData}
     * @memberof ProductData
     */
    'price'?: PriceData;
    /**
     * 
     * @type {PriceRangeData}
     * @memberof ProductData
     */
    'priceRange'?: PriceRangeData;
    /**
     * 
     * @type {ProductBasketTypeEnum}
     * @memberof ProductData
     */
    'productBasketType'?: ProductBasketTypeEnum;
    /**
     * 
     * @type {Array<ProductReferenceData>}
     * @memberof ProductData
     */
    'productReferences'?: Array<ProductReferenceData>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'purchasable'?: boolean;
    /**
     * 
     * @type {Array<ReviewData>}
     * @memberof ProductData
     */
    'reviews'?: Array<ReviewData>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'salableOnline'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'saleStop'?: ProductDataSaleStopEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'seoDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'seoTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    'solrSearchScore'?: number;
    /**
     * 
     * @type {StockData}
     * @memberof ProductData
     */
    'stock'?: StockData;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'summary'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'tobaccoFreeNicotineProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'tobaccoProduct'?: boolean;
    /**
     * 
     * @type {UnitData}
     * @memberof ProductData
     */
    'unit'?: UnitData;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'url'?: string;
    /**
     * 
     * @type {Array<VariantMatrixElementData>}
     * @memberof ProductData
     */
    'variantMatrix'?: Array<VariantMatrixElementData>;
    /**
     * 
     * @type {Array<VariantOptionData>}
     * @memberof ProductData
     */
    'variantOptions'?: Array<VariantOptionData>;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'variantType'?: string;
    /**
     * 
     * @type {Array<PriceData>}
     * @memberof ProductData
     */
    'volumePrices'?: Array<PriceData>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    'volumePricesFlag'?: boolean;
}

export const ProductDataSaleStopEnum = {
    Hard: 'HARD',
    Soft: 'SOFT'
} as const;

export type ProductDataSaleStopEnum = typeof ProductDataSaleStopEnum[keyof typeof ProductDataSaleStopEnum];


