import styled from 'styled-components';
import Config from '@config';

type SizeType = {
  startRow?: { s?: number; m?: number; l?: number };
  startCol?: { s?: number; m?: number; l?: number };
  spanRow?: { s?: number; m?: number; l?: number };
  spanCol?: { s?: number; m?: number; l?: number };
};
const StyledGridCol = styled.div<{ size: SizeType }>`
  position: relative;
  grid-row-start: ${(props) => props?.size.startRow?.s || 'auto'};
  grid-column-start: ${(props) => props?.size?.startCol?.s || 'auto'};
  grid-row-end: ${(props) => (props?.size?.spanRow?.s ? `span ${props.size.spanRow.s}` : 'auto')};
  grid-column-end: ${(props) => (props?.size?.spanCol?.s ? `span ${props.size.spanCol.s}` : 'auto')};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    grid-row-start: ${(props) => props?.size.startRow?.m};
    grid-column-start: ${(props) => props?.size?.startCol?.m};
    grid-row-end: ${(props) => (props?.size?.spanRow?.m ? `span ${props.size.spanRow.m}` : '')};
    grid-column-end: ${(props) => (props?.size?.spanCol?.m ? `span ${props.size.spanCol.m}` : '')};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    grid-row-start: ${(props) => props?.size.startRow?.l};
    grid-column-start: ${(props) => props?.size?.startCol?.l};
    grid-row-end: ${(props) => (props?.size?.spanRow?.l ? `span ${props.size.spanRow.l}` : '')};
    grid-column-end: ${(props) => (props?.size?.spanCol?.l ? `span ${props.size.spanCol.l}` : '')};
  }
`;
export default StyledGridCol;
