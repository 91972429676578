// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list_item
import type {
  AxfoodBasicProductViewModel,
  AxfoodCartProductViewModel,
  AxfoodOfflineProductViewModel,
  AxfoodProductDetailsViewModel,
  VoucherData,
} from '@occ/api-client';
import { isOnlineProduct } from '@helpers/typeGuards/product';

export type AnalyticsEcommerceItemType = {
  item_id: string;
  item_name?: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
};

export const parseNumeric = (str: string | number): number => {
  let number = str;
  if (typeof number === 'number') {
    return number;
  }

  number = number.replace(/[,:;]/g, '.');
  number = number.replace(/\s/g, '');
  return parseFloat(number);
};

const getOnlineProductUnitPrice = (
  product: AxfoodProductDetailsViewModel | AxfoodCartProductViewModel | AxfoodBasicProductViewModel
) => {
  const piecesItem = !product.productBasketType || product.productBasketType.code === 'ST';
  let price = parseNumeric(product.price || 0);
  if (piecesItem && product.price && product.price.substring(product.price.length - 2) === 'kg') {
    price = Math.round(price * (product.averageWeight || 0) * 100) / 100;
  } else if (!piecesItem) {
    price = Math.round(price * (product.incrementValue || 0) * 100) / 100;
  }
  return price || 0;
};

const getOfflineProductUnitPrice = (product: AxfoodOfflineProductViewModel) => {
  return product.potentialPromotions?.[0].price || parseNumeric(product.potentialPromotions?.[0].comparePrice || 0);
};

export const convertProductToAnalyticsEcommerceItem = (
  product:
    | AxfoodProductDetailsViewModel
    | AxfoodOfflineProductViewModel
    | AxfoodCartProductViewModel
    | AxfoodBasicProductViewModel,
  quantity?: false | number,
  listName?: string
) => {
  const itemId = isOnlineProduct(product)
    ? product.code
    : `offline-${product.name}-${product.potentialPromotions?.[0].code}`;
  const price = isOnlineProduct(product) ? getOnlineProductUnitPrice(product) : getOfflineProductUnitPrice(product);

  const item: AnalyticsEcommerceItemType = {
    item_id: itemId,
    item_name: product.name,
    price: price,
    ...(listName ? { item_list_name: listName } : {}),
  };

  if (quantity !== false) {
    const maybePickQuantity = 'pickQuantity' in product ? product.pickQuantity : 0;
    item.quantity = quantity || (product.online ? maybePickQuantity : 0);
  }

  return item;
};

export const computeAnalyticsEcommerceItemsTotalValue = (items: AnalyticsEcommerceItemType[]) =>
  Math.round(items.reduce((total, item) => total + (item.price || 0) * (item.quantity || 1), 0) * 100) / 100;

export const convertProductsToAnalyticsEcommerceItems = (
  products: (AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel | AxfoodCartProductViewModel)[],
  quantity?: false,
  listName?: string
) => {
  return products.map((product) => convertProductToAnalyticsEcommerceItem(product, quantity, listName));
};

export const convertVoucherToAnalyticsEcommerceItem = (voucher: VoucherData): AnalyticsEcommerceItemType => {
  return {
    item_id: voucher.code || '',
    item_name: voucher.voucherCode || '',
    price: voucher?.savedValue?.value !== undefined ? -voucher.savedValue.value : undefined,
    quantity: 1,
  };
};

export const convertVouchersToAnalyticsEcommerceItems = (vouchers: VoucherData[]): AnalyticsEcommerceItemType[] =>
  vouchers.map((voucher) => convertVoucherToAnalyticsEcommerceItem(voucher));
