import styled from 'styled-components';
import { rem } from 'polished';
import Text from '@atoms/Text/Text';
import Config from '@config';
import variables from '@styles/variables';
import Container from '@atoms/Container/Container';

export const StyledContainer = styled(Container)`
  ${Config.MEDIA.IS_MOBILE} {
    background-color: ${variables.colors.lighterGray};
  }

  padding: 0;
`;

export const BackLinkText = styled(Text)`
  width: 100%;
  margin: 0;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(variables.size.checkoutBoxWidthTablet)};
    margin: ${rem(33)} auto ${rem(33)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(variables.size.checkoutBoxWidthDesktop)};
  }
`;
