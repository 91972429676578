import { ReactNode, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ColorType } from '@atoms/Text/Text';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import paths from '@constants/paths';
import { ValueOf } from '@helpers/types';
import { ErrorPage, Wrapper, Header, Info, StyledLink, StyledReloadButton } from './ErrorComponent.styles';
import errorComponentThemes from './errorComponentThemes';
import webToAppApi from '@api/web-to-app';
import useUserAgent from '@hooks/useUserAgent';

// Needs to be updated when adding more error pages.
const handledErrors = [401, 404, 410, 500] as const;

type StatusType = (typeof handledErrors)[number] | 'default' | number;

interface Props {
  errorCode: (typeof handledErrors)[number] | number;
  theme?: 'error' | 'info';
  children?: ReactNode;
  heading?: string;
  route?: ValueOf<typeof paths>;
}

const ErrorComponent = ({ errorCode, theme = 'error', children, heading, route }: Props) => {
  const { t } = useTranslation('error');
  const { isNativeApp } = useUserAgent();
  const router = useRouter();
  const path = isNativeApp ? 'app' : route || 'default';
  let status: StatusType = errorCode as StatusType;

  if (!handledErrors.includes(errorCode as (typeof handledErrors)[number]) || isNativeApp) status = 'default';

  const getLinkLocation = (_status: StatusType) => {
    switch (_status) {
      case 410:
        return '/mitt-konto/ordrar';
      case 404:
      default:
        return '/';
    }
  };

  useEffect(() => {
    const title = heading || t(`${path}->${status}->title`);
    pushGTMEvent({
      event: 'Track',
      category: 'error_page',
      action: 'error_message_shown',
      label: `${errorCode}:${title}`,
      value: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasSentRenewActionTokenAction, setHasSentRenewActionTokenAction] = useState<boolean>(false);

  useEffect(() => {
    if (!hasSentRenewActionTokenAction && isNativeApp && errorCode === 401) {
      webToAppApi.actionRenewAccessToken();
      setHasSentRenewActionTokenAction(true);
    }
  }, [hasSentRenewActionTokenAction, isNativeApp, errorCode]);

  return (
    <>
      <Head>
        <title>{heading || t(`${path}->${status}->head`)}</title>
      </Head>
      <ErrorPage theme={theme}>
        <Wrapper>
          <Header variant="h1" color={errorComponentThemes[theme].header as HeadingColors}>
            {heading || t(`${path}->${status}->title`)}
          </Header>

          <Info data-testid="error-info-text" type="body" color={errorComponentThemes[theme].text as ColorType}>
            {children || (
              <>
                {!isNativeApp && (status === 401 || status === 404 || status === 410) && (
                  <>
                    {`${t(`${path}->${status}->errorTextStart`)} `}
                    <StyledLink href={getLinkLocation(status)} theme={theme}>
                      {t(`${path}->${status}->errorTextLink`)}
                    </StyledLink>
                    {` ${t(`${path}->${status}->errorTextEnd`)}`}
                  </>
                )}
                {!isNativeApp && (status === 500 || status === 'default') && (
                  <>
                    <StyledReloadButton theme="link" onClick={router.reload}>
                      {t(`${path}->${status}->errorTextLink`)}
                    </StyledReloadButton>
                    {` ${t(`${path}->${status}->errorTextEnd`)}`}
                  </>
                )}
                {isNativeApp && `${t(`${path}->${status}->errorTextStart`)} `}
              </>
            )}
          </Info>
        </Wrapper>
      </ErrorPage>
    </>
  );
};

export default ErrorComponent;
