import { ReactNode } from 'react';
import { StyledContainer } from './Container.styles';

type Props = {
  children?: ReactNode;
  className?: string;
  noPadding?: boolean;
  addPaddingIfCartOrMenuOpen?: boolean;
  restrictWidthToMediumLayout?: boolean;
};

const Container = ({
  className,
  children,
  noPadding,
  addPaddingIfCartOrMenuOpen,
  restrictWidthToMediumLayout = false,
}: Props) => {
  return (
    <StyledContainer
      noPadding={noPadding}
      addPaddingIfCartOrMenuOpen={addPaddingIfCartOrMenuOpen}
      className={className}
      restrictWidthToMediumLayout={restrictWidthToMediumLayout}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
