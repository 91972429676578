import packageComponentHelper from '@helpers/packageComponentHelper';
import useResponsiveCMSMedia from '@hooks/useResponsiveCMSMedia';
import { StyledImage, StyledImageGridComponent, StyledLink } from './ImageGridComponent.styles';
import GridComponentTitleAndButton from '@molecules/GridComponentTitleAndButton/GridComponentTitleAndButton';
import { trackImageGridComponent } from '@helpers/analyticsHelpers/trackImageGridComponent';

interface Props {
  data: ImageGridComponentType;
}

const ImageGridComponent = ({ data }: Props) => {
  const packageHelper = packageComponentHelper();

  const {
    spanLength,
    displayRow,
    displayColumn,
    gridCols,
    imageLinkUrl,
    imageTarget,
    title,
    titleColor,
    buttonTitlePosition,
    buttonColor,
    buttonTextColor,
    buttonText,
    enableButton,
  } = data || {};
  const { image } = useResponsiveCMSMedia({ media: data.image });

  return (
    <>
      {data && gridCols > 0 && (
        <StyledImageGridComponent
          size={{
            startRow: { s: parseInt(displayRow, 10) },
            startCol: packageHelper.getStartCol(displayColumn, gridCols, parseInt(spanLength, 10)),
            spanCol: packageHelper.getSpanSize(data),
          }}
        >
          {!enableButton && imageLinkUrl ? (
            <StyledLink
              href={imageLinkUrl}
              target={imageTarget === 'sameWindow' ? '_self' : '_blank'}
              data-testid="image-grid-link"
              onClick={() => trackImageGridComponent(title || '')}
            >
              <StyledImage src={image.url} alt={image.altText} />
              <GridComponentTitleAndButton
                title={title}
                titleColor={titleColor}
                enableButton={enableButton}
                buttonTitlePosition={buttonTitlePosition}
                imageLinkUrl={imageLinkUrl}
                imageTarget={imageTarget}
                buttonText={buttonText}
                buttonTextColor={buttonTextColor}
                buttonColor={buttonColor}
                testId="imageGrid"
              />
            </StyledLink>
          ) : (
            <>
              <StyledImage src={image.url} alt={image.altText} />
              <GridComponentTitleAndButton
                title={title}
                titleColor={titleColor}
                enableButton={enableButton}
                buttonTitlePosition={buttonTitlePosition}
                imageLinkUrl={imageLinkUrl}
                imageTarget={imageTarget}
                buttonText={buttonText}
                buttonTextColor={buttonTextColor}
                buttonColor={buttonColor}
                testId="imageGrid"
              />
            </>
          )}
        </StyledImageGridComponent>
      )}
    </>
  );
};

export default ImageGridComponent;
