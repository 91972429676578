import { CategoryApi } from '@occ/api-client';

const categoryApi = new CategoryApi({
  baseUrl: '',
});

type Props = {
  q: string;
  categoryPath: string;
  size?: number;
  page?: number;
  code?: string;
  sort?: string;
};

export const getCategory = async ({ q, categoryPath, size = 30, page = 0, sort }: Props) => {
  const result = await categoryApi.getCategoryUsingGet(
    categoryPath,
    {
      q,
      page,
      size,
      sort,
    },
    { format: 'json' }
  );
  return result.data;
};
