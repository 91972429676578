import DOMPurify from 'isomorphic-dompurify';
import { StyledHighLightText } from './HighlightText.styles';

interface Props {
  text: string;
  term: string;
  disabled?: boolean;
}

const HighlightText = ({ text, term, disabled = false }: Props) => {
  const sanitizedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '');
  const findAllOccurrencesRegExp = new RegExp(sanitizedTerm, 'gim');
  const indices: Array<number> = [];
  const usedIndices: Array<string> = [];
  let result;

  if (sanitizedTerm) {
    while ((result = findAllOccurrencesRegExp.exec(text))) {
      indices.push(result.index);
    }
  }

  const getHighlightedText = () => {
    let highlightedText = text;
    if (indices.length) {
      for (let i = indices.length - 1; i >= 0; i -= 1) {
        const index = indices[i];
        const word = text.substring(index, index + sanitizedTerm.length);
        if (usedIndices.indexOf(word) < 0) {
          highlightedText =
            highlightedText.substring(0, index) +
            `<strong>${word}</strong>` +
            highlightedText.substring(index + sanitizedTerm.length);
          usedIndices.push(word);
        }
      }
    }

    return highlightedText;
  };

  return (
    <StyledHighLightText
      disabled={disabled}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getHighlightedText()) }}
    />
  );
};

export default HighlightText;
