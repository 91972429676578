import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';

export const StyledInfoBlock = styled.div`
  &:not(:first-child) {
    margin-top: ${rem(15)};
  }
`;

export const StyledGhsWrapper = styled.div`
  display: flex;
`;

export const StyledGhsSymbols = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${rem(10)};

  &:empty {
    margin-right: 0;
  }

  > span {
    margin-right: ${rem(5)};
  }
`;

export const StyledGhsLabels = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledEnergyDeclarationImage = styled.img`
  margin-top: ${rem(24)};

  width: ${rem(181)};
  ${Config.MEDIA.IS_MOBILE} {
    width: ${rem(199)};
  }
`;

export const StyledGhsIcon = styled.img`
  height: ${rem(24)};
  width: auto;
`;
