/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ProductData } from './product-data';

/**
 * 
 * @export
 * @interface ProductReferenceData
 */
export interface ProductReferenceData {
    /**
     * 
     * @type {string}
     * @memberof ProductReferenceData
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductReferenceData
     */
    'preselected'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductReferenceData
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReferenceData
     */
    'referenceType'?: ProductReferenceDataReferenceTypeEnum;
    /**
     * 
     * @type {ProductData}
     * @memberof ProductReferenceData
     */
    'target'?: ProductData;
}

export const ProductReferenceDataReferenceTypeEnum = {
    Accessories: 'ACCESSORIES',
    BaseProduct: 'BASE_PRODUCT',
    ConsistsOf: 'CONSISTS_OF',
    DiffOrderunit: 'DIFF_ORDERUNIT',
    Followup: 'FOLLOWUP',
    Mandatory: 'MANDATORY',
    Similar: 'SIMILAR',
    Select: 'SELECT',
    Sparepart: 'SPAREPART',
    Others: 'OTHERS',
    Upselling: 'UPSELLING',
    Crosselling: 'CROSSELLING'
} as const;

export type ProductReferenceDataReferenceTypeEnum = typeof ProductReferenceDataReferenceTypeEnum[keyof typeof ProductReferenceDataReferenceTypeEnum];


