import styled from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';
import Link from '@atoms/Link/Link';

export const StyledNavigationMenu = styled.div`
  width: 100%;
`;

export const StyledTextWrapper = styled.div<{ paddingLeft: number; hasActiveBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-left: ${(props) => rem(props.paddingLeft)};
  border-left: ${rem(2)} solid
    ${(props) => (props.hasActiveBorder ? variables.colors.darkGray : variables.colors.mediumGray)};
  height: ${rem(48)};
  align-items: center;
  overflow: hidden;
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${rem(20)};
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  overflow: hidden;
`;
