import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Link from '@atoms/Link/Link';
import Text from '@atoms/Text/Text';
import Config from '@config';

const tabletStyling = css`
  flex-direction: row;
  > div {
    width: 33%;

    &:last-child {
      margin-top: ${rem(30)};
    }
  }
`;

export const StyledContentWrapper = styled.div<{ menuCartOpen?: boolean; cartOpen: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${Config.MEDIA.IS_TABLET} {
    ${tabletStyling};
  }

  ${(props) =>
    props.menuCartOpen &&
    css`
      ${Config.MEDIA.IS_DESKTOP_LARGE} {
        ${tabletStyling};
      }
    `}

  ${(props) =>
    props.cartOpen &&
    css`
      ${Config.MEDIA.IS_DESKTOP_MEDIUM} {
        ${tabletStyling};
      }
    `}
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${rem(10)};
`;

export const StyledOpeningHoursText = styled(Text)`
  cursor: pointer;
`;

export const StyledItemWrapper = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(30)};

  ${(props) =>
    props.gap &&
    css`
      gap: ${rem(props.gap)};
    `}
`;
