import { AxfoodStoreInfoViewModel } from '@occ/api-client';
import paths from '@constants/paths';

export const extractStoreId = (storeParameters?: string | string[]) => {
  if (storeParameters?.length === 2) {
    const lastParam = storeParameters[1];
    return lastParam.slice(-4);
  }
  // Case: Invalid format
  return '';
};

const slugifyStoreUrl = (storeUrl: string) => {
  return storeUrl
    .replace(/[áàäâãå]/gi, 'a')
    .replace(/[óòöôõ]/gi, 'o')
    .toLowerCase();
};

export const generateStoreUrl = (store: AxfoodStoreInfoViewModel) => {
  // Remove town from store name
  const storeNamePart = (store.name || '')
    .split(' ')
    .filter((storeName) => storeName !== store.address?.town)
    .join('-');

  return slugifyStoreUrl(
    `${paths.STORE_PAGE}/${store.address?.town?.replaceAll(' ', '-')}/${storeNamePart}-${store.storeId}`
  );
};
