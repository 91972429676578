import { TransformableInfo } from 'logform';

let logger: any;
if (typeof window === 'undefined') {
  const { createLogger, format, transports } = require('winston');
  const cookie = require('cookie');
  const os = require('os');

  const formatAxiosConfig = format((info: TransformableInfo) => {
    if (!info.config) {
      return info;
    }

    info.related = {
      id: [],
      ip: [],
      hosts: [info.host.name],
      user: [],
    };

    const { config } = info;
    info.http = {
      request: {
        id: config.metadata.requestId,
        method: config.method.toUpperCase(),
        content_type: config.content_type || '',
        referrer: config.headers['referer'] || '',
        bytes: config.headers['content-length'],
        headers: config.headers || [], // XXX - weird but not actually all request headers
        cookies: cookie.parse(config.headers?.cookie || ''),
        body: config.metadata.data || '',
      },
    };
    info.request_id = info.http.request.id;
    info.related.id.push(info.http.request.id);

    const { JSESSIONID } = cookie.parse(config.headers?.cookie || '');
    info.user = {
      session: {
        id: JSESSIONID || '',
      },
    };
    info.session_id = info.user.session.id;
    info.related.id.push(info.user.session.id);

    const url = new URL(config.url, config.url.startsWith('http') ? undefined : config.baseURL);
    info.url = {
      original: config.url,
      full: config.url,
      scheme: url.protocol.slice(0, -1),
      domain: url.hostname,
      port: url.port ? url.port : url.protocol === 'https:' ? 443 : 80,
      path: url.pathname,
      query: url.search,
    };
    info.related.hosts.push(info.url.domain);

    info.event = {
      id: info.http.request.id,
      kind: 'event',
      category: 'web',
      duration: new Date().getTime() - config?.metadata.startTime.getTime(),
    };

    const ua = 'axios/1.4.0';
    info.user_agent = {
      original: ua,
    };

    return info;
  });

  const formatAxiosRequest = format((info: TransformableInfo) => {
    if (!info.req) {
      return info;
    }

    const { req } = info;
    info.http.version = `HTTP/${req.res?.httpVersion || '1.1'}`;
    info.http.request.host = req.host;
    info.request_line = req._header?.split('\r\n')[0];
    info.related.hosts.push(info.http.request.host);

    info.client = {
      ip: req.connection?.localAddress || req.socket?.localAddress || req.connection?.socket?.localAddress || '',
      name: info.host.name,
    };
    info.related.ip.push(info.client.ip);
    info.related.hosts.push(info.client.name);

    info.server = {
      ip: req.connection?.remoteAddress || req.socket?.remoteAddress || req.connection?.socket?.remoteAddress || '',
      name: req.socket.servername,
    };
    info.related.ip.push(info.server.ip);
    info.related.hosts.push(info.server.name);

    return info;
  });

  const formatAxiosResponse = format((info: TransformableInfo) => {
    if (!info.res) {
      return info;
    }

    const { res } = info;
    info.http.response = {
      status_code: res.status,
      content_type: res.headers['content-type'] || '',
      bytes: res.headers['content-length'],
      headers: res.headers,
    };
    info.response_code = res.status;

    return info;
  });

  const formatHttpMessage = format((info: TransformableInfo) => {
    if (!info.http) {
      return info;
    }

    info.message = `${info.message}: ${info.event?.id || '-'} "${info.request_line || '-'}" ${
      info.response_code || '-'
    } ${info.http.request?.bytes || '-'} "${info.http.request?.referrer || '-'}" "${info.user_agent?.original}"`;
    return info;
  });

  const formatFields = format((info: TransformableInfo) => {
    info.fields = {
      env: process.env,
    };
    return info;
  });

  const formatEcs = format((info: TransformableInfo) => {
    const { timestamp, level, message, config, req, res, ...rest } = info;
    return {
      '@timestamp': timestamp,
      type: 'node_combined',
      log: { level },
      message,
      ...rest,
    };
  });

  logger = createLogger({
    level: 'info',
    format: format.combine(
      format.timestamp(),
      formatAxiosConfig(),
      formatAxiosRequest(),
      formatAxiosResponse(),
      formatHttpMessage(),
      formatFields()
    ),
    defaultMeta: {
      labels: {},
      tags: ['nodejs', 'access', 'upstream'],
      service: {
        application: 'willys-front',
        name: process.env.SERVICE || process.env.SERVICE_NAME || '',
        environment: process.env.ENVIRONMENT || process.env.SERVICE_ENVIRONMENT || '',
        release: process.env.RELEASE || process.env.SERVICE_RELEASE || '',
        version: process.env.VERSION || process.env.SERVICE_VERSION || '',
        type: 'frontend',
      },
      host: {
        name: os.hostname(),
      },
    },
    colorize: false,
    transports: [],
    // Log unhandled exceptions
    exceptionHandlers: [new transports.File({ filename: 'logs/willys-node-exceptions.log' })],
  });

  // If we are running in a container on ECS/Fargate log to stdout
  if (process.env.AWS_EXECUTION_ENV === 'AWS_ECS_FARGATE') {
    logger.add(
      new transports.Console({
        format: format.combine(formatEcs(), format.json()),
      })
    );
  } else if (process.env.NODE_ENV !== 'production') {
    // If we're not in production then log to console
    logger.add(
      new transports.Console({
        format: format.combine(
          format.colorize({ all: true }),
          format.printf(
            (info: any) => `${info.timestamp} ${info.level} : ${info.message} ${info.http?.request.host || ''}`
          )
        ),
      })
    );
  } else {
    logger.add(
      new transports.File({
        filename: 'logs/willys-node-error.log',
        level: 'error',
        format: format.combine(formatEcs(), format.json()),
      })
    );
    logger.add(
      new transports.File({
        filename: 'logs/willys-node-combined.log',
        format: format.combine(formatEcs(), format.json()),
      })
    );
  }
} else {
  logger = console;
}

// Suppress all logger calls when running test
if (process.env.NODE_ENV === 'test') {
  logger = {
    error: () => {},
    info: () => {},
  };
}

export default logger;
