import Text from '@atoms/Text/Text';
import { StyledAccordionButton, StyledAccordionButtonIcon } from './Accordion.styles';
import AccordionDownSmall from '@icons/accordion-down_small.svg';
import { ReactNode } from 'react';

export type AccordionButtonProps = {
  title: string | ReactNode;
  onClick: (accordionKey: string) => void;
  accordionKey: string;
  active: boolean;
  isCartPage?: boolean;
};

const AccordionButton = ({ title, onClick, accordionKey, active, isCartPage }: AccordionButtonProps) => {
  return (
    <StyledAccordionButton active={active} type="button" onClick={() => onClick(accordionKey)} isCartPage={isCartPage}>
      {typeof title === 'string' ? (
        <Text type="body" color="black">
          {title}
        </Text>
      ) : (
        title
      )}

      <StyledAccordionButtonIcon svg={AccordionDownSmall} size={12} active={active} />
    </StyledAccordionButton>
  );
};

export default AccordionButton;
