import styled from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';
import Icon from '@atoms/Icon/Icon';
import { StyledProductSavePrice } from '@molecules/Product/Product.styles';
import Config from '@config';
import Link from '@atoms/Link/Link';

export const StyledProductListItem = styled.div<{ first?: boolean }>`
  position: relative;
  padding: ${rem(12)} ${rem(20)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-left: ${rem(-20)};
  margin-right: ${rem(-20)};
  width: calc(100% + ${rem(38)});

  ${Config.MEDIA.FROM_DESKTOP} {
    padding: ${rem(20)} ${rem(22)};
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &:first-child {
    border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  }
`;

export const StyledProductLink = styled(Link)`
  display: flex;
  flex: 1;
  min-width: 0;
`;

export const StyledImageContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  align-self: center;
`;

export const StyledProductInfo = styled.div`
  margin-left: ${rem(32)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${rem(10)};

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-left: ${rem(48)};
  }
`;

export const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledProductName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;

  ${Config.MEDIA.IS_MOBILE} {
    white-space: normal;

    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
export const StyledWillysImagePlaceholder = styled(Icon)`
  height: ${rem(56)};
  width: ${rem(56)};

  svg {
    width: auto;
    height: 100%;
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    height: ${rem(72)};
    width: ${rem(72)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    padding-right: ${rem(32)};
  }
`;

export const StyledListItemSavePrice = styled(StyledProductSavePrice)`
  font-size: ${rem(8)};
  position: static;
  line-height: normal;
  height: auto;
  padding: ${rem(2)} ${rem(4)};
  margin-bottom: ${rem(2)};
  color: ${variables.colors.lighterBlack};
`;

export const StyledPriceLabelWrapper = styled.div`
  position: absolute;
  margin: 0;
  top: ${rem(-5)};
  right: ${rem(-20)};
  // Prevent Safari from scaling up text to minimum "readable" size
  -webkit-text-size-adjust: none;

  ${Config.MEDIA.FROM_DESKTOP} {
    top: ${rem(-10)};
    right: ${rem(-30)};
  }
`;
