import useSWR from 'swr';
import { getCart } from '@api/interfaces/cartApi';
import useCustomer from '@hooks/useCustomer';

export const getCartEndpoint = '/api/cart';

const useCart = () => {
  const { customer } = useCustomer();

  const key = {
    getCartEndpoint,
    customerId: customer?.uid,
  };
  const shouldFetch = Boolean(customer);
  const { data, error, mutate, isLoading } = useSWR(shouldFetch ? key : null, getCart);

  return {
    cart: data,
    isCartEmpty: !data?.products?.length,
    hasFetchedCart: Boolean(data && !isLoading),
    error,
    isLoading,
    refreshCart: mutate,
  };
};

export default useCart;
