import useResponsive from '@hooks/useResponsive';
import paths from '@constants/paths';
import useCmsComponents from '@hooks/useCmsComponents';
import { extractStoreId } from '@helpers/storeHelper';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const useCmsStoreContent = (uid: string) => {
  const router = useRouter();
  const { isMobile, isTablet } = useResponsive();
  // TODO: We should move away from rendering different content on different routes
  const isStorePage = useMemo(
    () => router.pathname.startsWith(paths.STORE_PAGE) || router.pathname.startsWith(paths.APP_STORE_PAGE),
    [router.pathname]
  );

  const { data: swrData, isLoading } = useCmsComponents(
    JSON.stringify(uid),
    isStorePage ? extractStoreId(router.query?.storeParameters) : undefined
  );

  const horizontalBannerData = swrData?.component?.filter(
    (component): component is HorizontalBannerType => component.typeCode === 'HorizontalBannerComponent'
  )?.[0];

  const titleContent = isStorePage
    ? horizontalBannerData?.title
    : isMobile
    ? horizontalBannerData?.titleMobile
    : isTablet
    ? horizontalBannerData?.titleTablet
    : horizontalBannerData?.title;

  const preambleContent = isMobile
    ? horizontalBannerData?.preambleMobile
    : isTablet
    ? horizontalBannerData?.preambleTablet
    : horizontalBannerData?.preamble;

  return {
    titleContent,
    preambleContent,
    isLoading: isStorePage ? isLoading : false,
    horizontalBannerData,
  };
};

export default useCmsStoreContent;
