import axios from 'axios';
import logger from './logger';
import { getCSRFToken } from '@api/interfaces/sessionApi';
import { v4 as uuidv4 } from 'uuid';

// this is client side only
if (typeof window !== 'undefined') {
  if (process.env.NODE_ENV === 'development') {
    axios.defaults.withCredentials = true;
  }
  let csrfToken: string;
  axios.interceptors.request.use((req) => {
    // add csrf token to all (POST, DELETE, PATCH etc) requests
    req.headers['X-CSRF-Token'] = csrfToken;
    req.headers['X-Request-Id'] = uuidv4();
    return req;
  });
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, config } = error;
      if (response && response.status === 401 && response.data.error === 'csrf.badormissing') {
        try {
          const csrfResponse = await getCSRFToken();
          csrfToken = csrfResponse.data;
          return axios(config); // retry original request
        } catch (e) {
          logger.error('Refresh CSRF-token failed');
        }
      }
      return Promise.reject(error);
    }
  );
}
