import styled from 'styled-components';
import { rem } from 'polished';
import Link from '@atoms/Link/Link';
import Heading from '@atoms/Heading/Heading';

export const StyledTitleAndButtonWrapper = styled.div<{ align?: 'flex-start' | 'center' | 'flex-end' }>`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: ${(props) => props.align};
  padding: ${rem(40)} ${rem(16)};
`;

export const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(24)};
  text-align: center;
`;

export const StyledButtonLink = styled(Link)`
  height: auto;
  width: auto;
`;

export const StyledHeading = styled(Heading)<{ color: string }>`
  color: ${(props) => props.color};
`;
