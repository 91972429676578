import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import { SkeletonRow } from '@atoms/Skeleton/Skeleton.styles';
import Skeleton from '@atoms/Skeleton/Skeleton';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(2)};
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
  }

  ${SkeletonRow} {
    margin: ${rem(2)} 0 0 0;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: 50%;
  }
`;
