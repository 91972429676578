import { css } from 'styled-components';
import { rem } from 'polished';
import { ButtonProps } from '@atoms/Button/Button';

export const ButtonSizesStyles = css<ButtonProps>`
  ${(props) =>
    props.size === 'default' &&
    css`
      height: ${rem(52)};
      min-width: ${rem(75)};
      padding: 0 ${rem(28)};
    `}

  ${(props) =>
    (props.size === 'small' || props.theme === 'tile') &&
    css`
      height: ${rem(40)};
      min-width: ${rem(59)};
      padding: 0 ${rem(20)};
    `}

  ${(props) =>
    props.size === 'xsmall' &&
    css`
      height: ${rem(32)};
      min-width: ${rem(36)};
      padding: 0 ${rem(12)};
    `}
`;

export const LinkSizesStyles = css<ButtonProps>`
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${rem(13)};
      line-height: ${rem(16)};
    `}
`;
