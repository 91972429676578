import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';
import Heading from '@atoms/Heading/Heading';
import { StyledCmsNavNode, StyledCmsNavNodeItem } from '@molecules/CMSNavNode/CMSNavNode.styles';

export const StyledSideNav = styled.nav<{ isOpen: boolean }>`
  background-color: ${variables.colors.white};
  font-size: ${rem(13)};
  overflow-x: hidden;
  width: 100%;
  z-index: ${variables.zIndex.sideNav};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  max-height: 100vh;
  -ms-overflow-style: ${(props) => (props.isOpen ? 'auto' : 'none')};
  border-right: ${(props) => (props.isOpen ? `${rem(1)} solid ${variables.colors.mediumGray}` : 'none')};

  ${(props) =>
    props.isOpen
      ? css`
          visibility: visible;
          max-width: ${rem(variables.size.sidenavWidthSmall)};
          ${Config.MEDIA.FROM_DESKTOP_LARGE} {
            max-width: ${rem(variables.size.sidenavWidth)};
          }

          transition-property: visibility, max-width;
          transition-delay: 0ms, 0ms;
          transition-duration: 0ms, ${variables.animation.layout};
        `
      : css`
          visibility: hidden;
          max-width: 0;

          transition-property: visibility, max-width;
          transition-delay: ${variables.animation.layout}, 0ms;
          transition-duration: 0ms, ${variables.animation.layout};
        `};

  ${Config.MEDIA.FROM_DESKTOP} {
    position: sticky;
    z-index: ${variables.zIndex[0]};
  }

  > ${StyledCmsNavNode} {
    > ${StyledCmsNavNodeItem} {
      &:first-child {
        border-top: 0;
      }
    }
  }
`;

export const StyledSideNavSection = styled.div`
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
`;

export const StyledSideNavHeading = styled(Heading)`
  margin: ${rem(35)} ${rem(24)} ${rem(10)};
`;
