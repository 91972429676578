import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackShowMoreRelatedProducts = (title: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'product_detail',
    action: 'show_more_products',
    label: title,
    value: 0,
  });
};

export default trackShowMoreRelatedProducts;
