import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import Link from '@atoms/Link/Link';
import variables from '@styles/variables';
import Config from '@config';
import errorComponentThemes from './errorComponentThemes';

export const ErrorPage = styled.div`
  height: 100vh;
  background-color: ${(props) => errorComponentThemes[props.theme].backgroundColor};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20%;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 70%;
  text-align: center;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: 50%;
    padding: ${rem(20)};
  }
`;

export const Header = styled(Heading)`
  margin-bottom: ${rem(30)};
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-bottom: ${rem(50)};
  }
`;

export const Info = styled(Text)`
  max-width: 100%;
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: 60%;
  }
`;

export const StyledLink = styled(Link)`
  display: inline;
  font-size: ${rem(15)};
  line-height: ${rem(24)};
  text-decoration: none;
  ${(props) =>
    errorComponentThemes[props.theme].linkUnderline &&
    css`
      text-decoration: underline;
    `}
  ${(props) =>
    errorComponentThemes[props.theme].link &&
    css`
      color: ${errorComponentThemes[props.theme].link} !important;
    `}

  &:hover {
    ${(props) =>
      errorComponentThemes[props.theme].linkHover &&
      css`
        color: ${errorComponentThemes[props.theme].linkHover} !important;
      `}
  }
`;

export const StyledReloadButton = styled(Button)`
  display: inline;
  font-size: ${rem(15)};
  line-height: ${rem(24)};
  color: ${variables.colors.white};
  text-decoration: underline;
  &:hover {
    color: ${variables.colors.white};
  }
`;
