import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackChangeOrderModal = (
  modal: 'change_order_modal' | 'cancel_change_order_modal',
  action: 'modal_shown' | 'confirm' | 'decline'
) => {
  pushGTMEvent({
    event: 'Track',
    category: modal,
    action,
    label: '',
    value: 0,
  });
};

export default trackChangeOrderModal;
