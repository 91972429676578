import { ReactNode, useEffect, useRef, useState } from 'react';
import Product from '@molecules/Product/Product';
import debounce from '@helpers/debounce';
import Config from '@config';
import { useMediaQuery } from 'react-responsive';
import { AxfoodProductDetailsViewModel } from '@occ/api-client';

const useProductCarousel = (show: boolean, ref: any, listName: string, extraDeps = []) => {
  const [amountOfSlides, setAmountOfSlides] = useState(0);
  const [productsPerSlide, setProductsPerSlide] = useState(0);
  const [productElements, setProductElements] = useState<ReactNode[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isTabletPortrait = useMediaQuery({
    query: Config.BREAKPOINTS.IS_TABLET_PORTRAIT,
  });
  const fromTabletLandscape = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_LANDSCAPE,
  });
  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });

  const PRODUCT_WIDTH = fromTabletLandscape ? 204 : fromDesktop ? 202 : 0;

  // The list of product components to be rendered
  const makeProductElements = (items: Array<AxfoodProductDetailsViewModel>) => {
    const elementsArr: Array<ReactNode> = [];

    items.forEach((child) => {
      elementsArr.push(
        <Product
          product={child}
          key={`mix-match-${child.code}`}
          variant="mixmatch"
          disableMixMatchButton
          category={listName}
        />
      );
    });

    setProductElements(elementsArr);
  };

  const recalculateSlides = (_show: boolean) => {
    const gridEl = ref?.current;
    if (_show && gridEl) {
      const gridWidth = gridEl.clientWidth;
      let _productsPerSlide;
      if (PRODUCT_WIDTH > 0) {
        _productsPerSlide = Math.round(gridWidth / PRODUCT_WIDTH);
      } else {
        _productsPerSlide = isTabletPortrait ? 3 : 2;
      }

      if (_productsPerSlide > 0) {
        const amount = Math.ceil(productElements.length / _productsPerSlide);
        setProductsPerSlide(_productsPerSlide);
        setAmountOfSlides(amount);
      }
    }
  };

  const debouncedCalculateSlides = debounce(recalculateSlides, Config.TIMEOUT.productCarouselCalculateSlidesMs);

  useEffect(() => {
    // Assure products are counted on snow fetches
    if (!amountOfSlides) {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, Config.TIMEOUT.productCarouselResizeMs);
    }
    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    };
  }, [productElements, amountOfSlides]);

  useEffect(() => {
    const localCalculateSlides = () => debouncedCalculateSlides(true);
    window.addEventListener('resize', localCalculateSlides);

    return () => {
      window.removeEventListener('resize', localCalculateSlides);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, debouncedCalculateSlides, ...extraDeps]);

  return { amountOfSlides, productsPerSlide, productElements, recalculateSlides, makeProductElements };
};

export default useProductCarousel;
