import { useEffect, useState } from 'react';
import Text from '@atoms/Text/Text';
import Config from '@config';
import Icon, { IconProps } from '@atoms/Icon/Icon';
import { StyledLink, StyledSnackBar, StyledTextWrapper } from './SnackBar.styles';
import Validation from '@icons/validation.svg';

export type TIconSnackbar = null | 'valid';

interface Props {
  text: string;
  show: (show: boolean) => void;
  bottomPosition?: number;
  callback?: () => void;
  icon: TIconSnackbar;
  link?: string;
  linkText?: string;
  onClick?: () => void;
}

const SnackBar = ({ text, show, callback, bottomPosition, icon, link, linkText, onClick }: Props) => {
  const [visible, setVisibility] = useState<boolean | null>(null);

  useEffect(() => {
    if (visible === null) {
      setVisibility(true);
      setTimeout(() => {
        setVisibility(false);
      }, Config.TIMEOUT.snackBarDestroyMs);
    }

    if (visible === false) {
      if (show) {
        setTimeout(() => {
          show(false);
          if (callback) callback();
        }, Config.TIMEOUT.snackBarAnimationMs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const icons: {
    [key: string]: IconProps;
  } = {
    valid: { svg: Validation, size: 16, color: 'highlight' },
  };

  return (
    <StyledSnackBar slideIn={visible} bottomPosition={bottomPosition}>
      <StyledTextWrapper role="status">
        <Text type="body" size="small" color="white">
          {text}
        </Text>
        {icon && icons[icon] && <Icon {...icons[icon]} />}
      </StyledTextWrapper>
      {link && (
        <StyledLink
          href={link}
          onClick={(e) => {
            if (onClick) {
              // prevent router to navigate and instead do whatever is passed in to onClick
              e.preventDefault();
              onClick();
            }
          }}
        >
          {linkText}
        </StyledLink>
      )}
    </StyledSnackBar>
  );
};

export default SnackBar;
