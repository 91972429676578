/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EnergyDeclaration } from './energy-declaration';
// May contain unused imports in some cases
// @ts-ignore
import { ImageData } from './image-data';
// May contain unused imports in some cases
// @ts-ignore
import { ProductBasketType } from './product-basket-type';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionDTO } from './promotion-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionData } from './promotion-data';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionDataV2 } from './promotion-data-v2';

/**
 * ProductPromotionData containing data regarding products with corresponding promotions.
 * @export
 * @interface ProductPromotionDataV2
 */
export interface ProductPromotionDataV2 {
    /**
     * 
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'averageWeight'?: number;
    /**
     * Identifier for this product
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'code': string;
    /**
     * The compare price for the product
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'comparePrice': string;
    /**
     * Unit of compare price
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'comparePriceUnit'?: string;
    /**
     * The deposit price of a product.
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'depositPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'displayVolume'?: string;
    /**
     * 
     * @type {EnergyDeclaration}
     * @memberof ProductPromotionDataV2
     */
    'energyDeclaration'?: EnergyDeclaration;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'gdprTrackingIncompliant': boolean;
    /**
     * Used to send category information to google analytics
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'googleAnalyticsCategory'?: string;
    /**
     * If the product has display price or not.
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'hasDeposit': boolean;
    /**
     * 
     * @type {ImageData}
     * @memberof ProductPromotionDataV2
     */
    'image': ImageData;
    /**
     * 
     * @type {Array<PromotionData>}
     * @memberof ProductPromotionDataV2
     */
    'inactivePotentialPromotions'?: Array<PromotionData>;
    /**
     * How much of this product is possible to add as a minimum
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'incrementValue'?: ProductPromotionDataV2IncrementValueEnum;
    /**
     * If the product is drug or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'isDrugProduct': boolean;
    /**
     * What kind of labels this product have
     * @type {Array<string>}
     * @memberof ProductPromotionDataV2
     */
    'labels': Array<string>;
    /**
     * PIL Lowest historical price of the promotion
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'lowestHistoricalPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'manufacturer'?: string;
    /**
     * The name of the product
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'name': string;
    /**
     * If the product has new label or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'newsSplashProduct': boolean;
    /**
     * If the product is nicotine medical or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'nicotineMedicalProduct': boolean;
    /**
     * If the product is non-ecological or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'nonEkoProduct': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'notAllowedAnonymous': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'notAllowedB2b': boolean;
    /**
     * If the product is online or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'online': boolean;
    /**
     * 
     * @type {ImageData}
     * @memberof ProductPromotionDataV2
     */
    'originalImage'?: ImageData;
    /**
     * If the product is out of stock or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'outOfStock': boolean;
    /**
     * Field containing extra information about the product. Usually the manufacturer and the display weight.
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'pickupProductLine2': string;
    /**
     * 
     * @type {Array<PromotionDTO>}
     * @memberof ProductPromotionDataV2
     */
    'potentialPromotions'?: Array<PromotionDTO>;
    /**
     * Formatted price with currency
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'price': string;
    /**
     * Price of product without unit
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'priceNoUnit'?: string;
    /**
     * Price unit of the product
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'priceUnit': string;
    /**
     * Price value of the product, For gift products the price is always 0, for x for y promotions the price is always null
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'priceValue': number;
    /**
     * 
     * @type {ProductBasketType}
     * @memberof ProductPromotionDataV2
     */
    'productBasketType'?: ProductBasketType;
    /**
     * Cart type of the product
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'productCartType'?: string;
    /**
     * Field containing extra information about the product. Usually the manufacturer and the display weight.
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'productLine2': string;
    /**
     * 
     * @type {Array<PromotionDataV2>}
     * @memberof ProductPromotionDataV2
     */
    'promotions'?: Array<PromotionDataV2>;
    /**
     * campaign ranking that will determine order
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'ranking'?: number;
    /**
     * Amount saved from original price
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'savingsAmount': number;
    /**
     * If the product is nicotine medical or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'showGoodPriceIcon': boolean;
    /**
     * solr search score that will determine order, higher precedence then ranking
     * @type {number}
     * @memberof ProductPromotionDataV2
     */
    'solrSearchScore'?: number;
    /**
     * 
     * @type {ImageData}
     * @memberof ProductPromotionDataV2
     */
    'thumbnail': ImageData;
    /**
     * 
     * @type {string}
     * @memberof ProductPromotionDataV2
     */
    'title'?: string;
    /**
     * If the product is tobacco free nicotine or not
     * @type {boolean}
     * @memberof ProductPromotionDataV2
     */
    'tobaccoFreeNicotineProduct': boolean;
}

export const ProductPromotionDataV2IncrementValueEnum = {
    NUMBER_0_DOT_0: 0.0,
    NUMBER_0_DOT_1: 0.1,
    NUMBER_0_DOT_2: 0.2,
    NUMBER_0_DOT_3: 0.3,
    NUMBER_1_DOT_0: 1.0,
    NUMBER_1_DOT_1: 1.1,
    NUMBER_1_DOT_2: 1.2
} as const;

export type ProductPromotionDataV2IncrementValueEnum = typeof ProductPromotionDataV2IncrementValueEnum[keyof typeof ProductPromotionDataV2IncrementValueEnum];


