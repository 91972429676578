import React, { useMemo } from 'react';
import {
  StyledAgeRestrictionLabelContainer,
  StyledProductDetailsAddToList,
  StyledProductDetailsEnergyLabel,
  StyledProductDetailsImageContainer,
  StyledProductDetailsImageWrapper,
  StyledProductDetailsLeft,
  StyledProductDetailsLeftHeader,
  StyledProductDetailsLeftHeaderWrapper,
  StyledProductDetailsPrice,
  StyledProductDetailsPriceLabel,
  StyledProductDetailsPriceSplash,
  StyledProductDetailsZCodes,
  StyledProductDetailsZCodesIcon,
  StyledProductImage,
} from '@organisms/ProductDetails/ProductDetailsLeftSection/ProductDetailsLeftSection.styles';
import Image from 'next/image';
import EnergyLabel from '@atoms/EnergyLabel/EnergyLabel';
import { isOfflineProduct, isOfflinePromotion, isOnlineProduct } from '@helpers/typeGuards/product';
import { getPdpAgeRestrictionImage, getValidProductLabels } from '@helpers/productHelper';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import useUserAgent from '@hooks/useUserAgent';
import type {
  AxfoodOfflineProductViewModel,
  AxfoodOfflinePromotionViewModel,
  AxfoodProductDetailsViewModel,
  AxfoodPromotionViewModel,
} from '@occ/api-client';
import { useProductImage } from '@hooks/useProductImage';
import AddToListComponent from '@organisms/AddToListComponent/AddToListComponent';
import { makeSingleOfflineEntry, makeSingleOnlineEntry } from '@helpers/wishlistProductEntries';
import webToAppApi from '@api/web-to-app';
import trackShoppingList from '@helpers/analyticsHelpers/trackShoppingList';

const getPriceLabel = (promotion: AxfoodPromotionViewModel | AxfoodOfflinePromotionViewModel) =>
  promotion?.realMixAndMatch && 'conditionLabelFormatted' in promotion && promotion.conditionLabelFormatted
    ? promotion.conditionLabelFormatted
    : promotion.conditionLabel;

interface ProductDetailsLeftSectionProps {
  product: AxfoodOfflineProductViewModel | AxfoodProductDetailsViewModel;
  quantity: number | null;
  isPage?: boolean;
}

const ProductDetailsLeftSection = ({ product, quantity, isPage }: ProductDetailsLeftSectionProps) => {
  const { t } = useTranslation('product');
  const router = useCustomRouter();
  const { isReactNative } = useUserAgent();
  const ageRestrictionImage = useMemo(() => product && getPdpAgeRestrictionImage(product), [product]);

  const promotion = product?.potentialPromotions?.[0];
  const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
  const hasMixMatch = promotion?.realMixAndMatch === true;
  const lowestHistoricalPrice = !isOfflinePromotion(promotion) && promotion?.lowestHistoricalPrice;
  const { productImageURL, productImageAltText, onImageLoadError } = useProductImage(product);

  return (
    <StyledProductDetailsLeft isPage={isPage}>
      <StyledProductDetailsImageWrapper>
        <StyledProductDetailsImageContainer>
          <StyledProductImage
            priority
            unoptimized
            itemProp="image"
            src={productImageURL}
            alt={productImageAltText}
            width={isPage ? 416 : 320}
            height={isPage ? 416 : 320}
            data-testid="main-image"
            onError={onImageLoadError}
            isPage={isPage}
          />

          {ageRestrictionImage && (
            <StyledAgeRestrictionLabelContainer>
              <Image
                priority
                unoptimized
                itemProp="image"
                src={`/icons/labels/${ageRestrictionImage}`}
                alt={t(`product->label->age_restricted_18`)}
                width={64}
                height={64}
              />
            </StyledAgeRestrictionLabelContainer>
          )}
        </StyledProductDetailsImageContainer>

        {product.energyDeclaration && (
          <StyledProductDetailsEnergyLabel>
            <EnergyLabel
              energyDeclaration={product.energyDeclaration}
              onClick={() => {
                if (isOnlineProduct(product)) {
                  router.push({ pathname: router.pathname, query: { ...router.query, tab: '3' } }, router.asPath);
                }
              }}
              size="large"
            />
          </StyledProductDetailsEnergyLabel>
        )}
      </StyledProductDetailsImageWrapper>
      <StyledProductDetailsLeftHeader>
        <StyledProductDetailsLeftHeaderWrapper>
          <div data-testid="left-labels">
            <StyledProductDetailsAddToList>
              <AddToListComponent
                product={product as AxfoodProductDetailsViewModel}
                btnIconSize={isPage ? 24 : 20}
                productEntries={
                  isOfflineProduct(product)
                    ? [makeSingleOfflineEntry(product, quantity)]
                    : [makeSingleOnlineEntry(product, quantity)]
                }
                tracking={{ parent: 'product_detail' }}
                addToListCallback={({ option, productEntries }) => {
                  webToAppApi.eventShoppingListAddItem(option.id, productEntries[0] as ProductEntryType);
                  trackShoppingList({ action: 'current', parent: 'product_detail' });
                }}
                createNewListCallback={({ inputValue }) => {
                  webToAppApi.eventShoppingListCreated(inputValue);
                  trackShoppingList({ action: 'new', parent: 'product_detail' });
                }}
                key={`add-to-list-${isOnlineProduct(product) ? product?.code : promotion?.code}-${product.name}`}
              />
            </StyledProductDetailsAddToList>
            {(product?.labels?.length || 0) > 0 && (
              <StyledProductDetailsZCodes native={isReactNative} data-testid="zcodes-icons">
                {getValidProductLabels(product, 3)
                  .filter((label) => label !== 'age_restricted_18')
                  .map((label) => {
                    return (
                      <StyledProductDetailsZCodesIcon
                        src={`/icons/labels/${label}.svg`}
                        key={label}
                        title={t(`product->label->${label}`)}
                      />
                    );
                  })}
              </StyledProductDetailsZCodes>
            )}
          </div>

          <StyledProductDetailsPrice isPage={isPage}>
            {hasSavePriceLabel &&
              (!lowestHistoricalPrice || lowestHistoricalPrice?.formattedValue === product.price || hasMixMatch) && (
                <StyledProductDetailsPriceLabel>
                  {`${hasMixMatch ? t('product->mixmatch->label') : ''} ${getPriceLabel(promotion)}`}
                </StyledProductDetailsPriceLabel>
              )}
            <StyledProductDetailsPriceSplash product={product} />
          </StyledProductDetailsPrice>
        </StyledProductDetailsLeftHeaderWrapper>
      </StyledProductDetailsLeftHeader>
    </StyledProductDetailsLeft>
  );
};

export default ProductDetailsLeftSection;
