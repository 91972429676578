import { getPage } from '@api/interfaces/pageApi';
import useSWR from 'swr';
import useCustomRouter from '@hooks/useCustomRouter';
import useStore from '@hooks/useStore';
import useCart from '@hooks/useCart';
import { CmsApi } from '@occ/api-client';
import useCustomer from '@hooks/useCustomer';

export const endpoint = 'cms/page';
const cmsApi = new CmsApi({ baseUrl: '/axfoodcommercewebservices/v2' });

/**
 * Hook for fetching cms pages.
 *
 * Changes to customer, storeId, deliveryModeCode will trigger a revalidate
 */
const useCmsPage = (label?: string, pageType = 'ContentPage', code?: string) => {
  const router = useCustomRouter();
  const { store } = useStore();
  const { cart } = useCart();
  const { customer } = useCustomer();

  const storeId = store?.storeId;
  const slotCode = cart?.slot?.code;

  const cmsTicketId = typeof router?.query.cmsTicketId === 'string' ? router?.query.cmsTicketId : undefined;

  const key = {
    endpoint,
    label,
    pageType,
    code,
    cmsTicketId,
    customerId: customer?.uid,
    storeId,
    slotCode,
  };

  const { data, error, isLoading } = useSWR(
    key,
    ({ label, pageType, code, cmsTicketId }) => getPage({ label, pageType, code, cmsTicketId, cmsApi }),
    {
      keepPreviousData: true,
    }
  );

  return {
    cmsPage: data,
    isLoading,
    error,
  };
};

export default useCmsPage;
