import styled from 'styled-components';
import { rem } from 'polished';
import Heading from '@atoms/Heading/Heading';
import Alert from '@molecules/Alert/Alert';

export const StyledAlert = styled(Alert)`
  flex-direction: column;
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${rem(15)};
`;
