import { ProductInfoNutrientComponentData, ProductInfoNutrientDetailData } from '@occ/api-client';
import sortNutrientComponents from '@helpers/sortNutrientComponents';
import { Fragment } from 'react';
import { StyledProductTableOfInformationSection } from '@molecules/ProductTableOfInformation/ProductTableOfInformation.styles';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import useTranslation from 'next-translate/useTranslation';
import Table from '@atoms/Table/Table';
import {
  StyledComponentHeading,
  StyledComponentSection,
} from '@molecules/ProductTableOfInformation/MultipackTableOfInformation/MultipackTableOfInformation.styles';

interface Props {
  nutrientComponents: ProductInfoNutrientComponentData[];
}

const MultipackTableOfInformation = ({ nutrientComponents }: Props) => {
  const { t } = useTranslation('product');

  const sortedNutrientComponents = sortNutrientComponents(nutrientComponents);

  return (
    <>
      {sortedNutrientComponents.map((nutrientComponent: ProductInfoNutrientComponentData) => {
        const nutrientHeader =
          nutrientComponent.nutrientHeaders && nutrientComponent.nutrientHeaders?.length > 0
            ? nutrientComponent.nutrientHeaders[0]
            : null;

        return (
          <StyledComponentSection key={nutrientComponent.sequenceNumber}>
            <StyledComponentHeading variant="h3">{nutrientComponent.description}</StyledComponentHeading>

            {nutrientComponent.countryOfOriginCode && (
              <StyledProductTableOfInformationSection>
                <Heading variant="h3" label>
                  {t('product->countryOfOrigin')}
                </Heading>
                <Text type="body" color="black">
                  {nutrientComponent.countryOfOriginCode}
                </Text>
              </StyledProductTableOfInformationSection>
            )}

            {nutrientHeader && (
              <StyledProductTableOfInformationSection>
                <Table>
                  <thead>
                    <tr>
                      <td>
                        <Heading variant="h3" label>
                          {t('product->nutrition->title')}
                        </Heading>
                      </td>
                      <td>
                        {nutrientHeader && (
                          <Text type="body" size="small" color="gray">
                            {t('product->forEachUnitOfMeasurement')} {nutrientHeader.nutrientBasisQuantity}{' '}
                            {nutrientHeader.nutrientBasisQuantityMeasurementUnitCode}
                          </Text>
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {nutrientHeader?.nutrientDetails?.map((nutrition: ProductInfoNutrientDetailData, i: number) => (
                      <tr key={`${nutrition.nutrientTypeCode}-${nutrition.quantityContained}-${i}`}>
                        <td>{nutrition?.nutrientTypeCode}</td>
                        <td>
                          {nutrition?.measurementPrecisionCode?.toLowerCase()} {nutrition?.quantityContained}{' '}
                          {nutrition?.measurementUnitCode}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </StyledProductTableOfInformationSection>
            )}
          </StyledComponentSection>
        );
      })}
    </>
  );
};

export default MultipackTableOfInformation;
