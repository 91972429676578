import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';

export const StyledLastBuyComponent = styled.div`
  margin-bottom: ${rem(32)};

  ${Config.MEDIA.IS_MOBILE_SMALL} {
    margin-left: ${rem(-20)};
    margin-right: ${rem(-20)};
  }
`;
