/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodDeliverabilityViewModel } from '../dto';
// @ts-ignore
import { OrderTrackingStatusHistoryData } from '../dto';
/**
 * DeliveryApi - axios parameter creator
 * @export
 */
export const DeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getDeliverability
         * @param {string} postalCode postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliverabilityUsingGET: async (postalCode: string, b2b?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalCode' is not null or undefined
            assertParamExists('getDeliverabilityUsingGET', 'postalCode', postalCode)
            const localVarPath = `/axfood/rest/shipping/delivery/{postalCode}/deliverability`
                .replace(`{${"postalCode"}}`, encodeURIComponent(String(postalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (b2b !== undefined) {
                localVarQueryParameter['b2b'] = b2b;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order tracking status for ongoing orders of customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOngoingTrackingStatusesUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/orderDeliveryStatus/ongoing-tracking-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order tracking status
         * @param {string} trackingID trackingID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTrackingStatusUsingGET: async (trackingID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackingID' is not null or undefined
            assertParamExists('getOrderTrackingStatusUsingGET', 'trackingID', trackingID)
            const localVarPath = `/axfood/rest/orderDeliveryStatus/{trackingID}`
                .replace(`{${"trackingID"}}`, encodeURIComponent(String(trackingID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryApi - functional programming interface
 * @export
 */
export const DeliveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getDeliverability
         * @param {string} postalCode postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliverabilityUsingGET(postalCode: string, b2b?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodDeliverabilityViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliverabilityUsingGET(postalCode, b2b, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order tracking status for ongoing orders of customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOngoingTrackingStatusesUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTrackingStatusHistoryData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOngoingTrackingStatusesUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order tracking status
         * @param {string} trackingID trackingID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderTrackingStatusUsingGET(trackingID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderTrackingStatusHistoryData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderTrackingStatusUsingGET(trackingID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryApi - factory interface
 * @export
 */
export const DeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryApiFp(configuration)
    return {
        /**
         * 
         * @summary getDeliverability
         * @param {string} postalCode postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliverabilityUsingGET(postalCode: string, b2b?: boolean, options?: any): AxiosPromise<AxfoodDeliverabilityViewModel> {
            return localVarFp.getDeliverabilityUsingGET(postalCode, b2b, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order tracking status for ongoing orders of customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOngoingTrackingStatusesUsingGET(options?: any): AxiosPromise<Array<OrderTrackingStatusHistoryData>> {
            return localVarFp.getOngoingTrackingStatusesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order tracking status
         * @param {string} trackingID trackingID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTrackingStatusUsingGET(trackingID: string, options?: any): AxiosPromise<OrderTrackingStatusHistoryData> {
            return localVarFp.getOrderTrackingStatusUsingGET(trackingID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryApi - object-oriented interface
 * @export
 * @class DeliveryApi
 * @extends {BaseAPI}
 */
export class DeliveryApi extends BaseAPI {
    /**
     * 
     * @summary getDeliverability
     * @param {string} postalCode postalCode
     * @param {boolean} [b2b] b2b
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public getDeliverabilityUsingGET(postalCode: string, b2b?: boolean, options?: AxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).getDeliverabilityUsingGET(postalCode, b2b, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order tracking status for ongoing orders of customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public getOngoingTrackingStatusesUsingGET(options?: AxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).getOngoingTrackingStatusesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order tracking status
     * @param {string} trackingID trackingID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryApi
     */
    public getOrderTrackingStatusUsingGET(trackingID: string, options?: AxiosRequestConfig) {
        return DeliveryApiFp(this.configuration).getOrderTrackingStatusUsingGET(trackingID, options).then((request) => request(this.axios, this.basePath));
    }
}
