import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import HighlightText from '@atoms/HighlightText/HighlightText';
import Link from '@atoms/Link/Link';

type suggestionType = {
  term: string;
};

interface Props {
  suggestion: suggestionType;
  searchTerm: string;
  iteration: number;
  onClick?: () => void;
}

const StyledSearchListItem = styled(Link)<{ first?: boolean }>`
  padding: ${rem(17)} ${rem(28)} ${rem(17)} 0;
  flex: 1;
  font-family: ${variables.fonts.fontGothamBook};
  font-size: ${rem(15)};
  border-bottom: ${rem(1)} solid ${variables.colors.lightGray};
  line-height: ${rem(25)};
  ${(props) =>
    props.first &&
    css`
      border-top: ${rem(1)} solid ${variables.colors.lightGray};
    `}
  &:hover {
    color: ${variables.colors.black};
  }
`;

const SearchListItem = ({ suggestion, searchTerm, iteration, onClick }: Props) => {
  const { term } = suggestion;
  return (
    <StyledSearchListItem
      internal
      first={iteration === 0}
      variant="nav"
      href={`/sok?q=${encodeURIComponent(term.toLowerCase())}`}
      onClick={onClick}
    >
      <HighlightText text={term} term={searchTerm} />
    </StyledSearchListItem>
  );
};

export default SearchListItem;
