import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackNoResults = (keyword: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'multisearch',
    action: 'keyword_not_found',
    label: keyword,
    value: 0,
  });
};

export const trackEditWithNoResults = (keyword: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'multisearch',
    action: 'edited_keyword_not_found',
    label: keyword,
    value: 0,
  });
};

export const trackSearchWords = (keywords: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'multisearch',
    action: 'search',
    label: keywords,
    value: 0,
  });
};
