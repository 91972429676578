import Button from '@atoms/Button/Button';
import styled, { css } from 'styled-components';
import Icon from '@atoms/Icon/Icon';
import { rem } from 'polished';

export const StyledButton = styled(Button)<{ alignment: string }>`
  align-self: center;

  ${(props) =>
    props.alignment === 'LEFT' &&
    css`
      margin-right: auto;
    `}

  ${(props) =>
    props.alignment === 'CENTER' &&
    css`
      margin-right: auto;
      margin-left: auto;
    `}

  ${(props) =>
    props.alignment === 'RIGHT' &&
    css`
      margin-left: auto;
    `}
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${rem(10)};
`;
