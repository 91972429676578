import styled from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledForm = styled.form`
  &:invalid button[type='submit'] {
    opacity: 0.6;
    cursor: default;
  }
`;

export const StyledErrorMessage = styled.span`
  font-size: ${rem(11)};
  color: ${variables.colors.primaryDark};
  display: block;
  margin-top: ${rem(12)};
`;
