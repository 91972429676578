import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import { H1Styles, H2Styles, H3Styles, HeadingProps } from '@atoms/Heading/Heading.styles';
import { imageStyling, iconStyling, textStyling } from '@organisms/CmsComponent/CmsComponent.styles';

interface CMSParagraphProps {
  paddingBottom?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingLeft?: string;
}

export const StyledCMSParagraphComponent = styled.div<CMSParagraphProps & HeadingProps>`
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    ${(props) =>
      props.paddingBottom &&
      css`
        padding-bottom: ${rem(parseInt(props.paddingBottom, 10))};
      `};
    ${(props) =>
      props.paddingRight &&
      css`
        padding-right: ${rem(parseInt(props.paddingRight, 10))};
      `};
    ${(props) =>
      props.paddingTop &&
      css`
        padding-top: ${rem(parseInt(props.paddingTop, 10))};
      `};
    ${(props) =>
      props.paddingLeft &&
      css`
        padding-left: ${rem(parseInt(props.paddingLeft, 10))};
      `};
  }

  h1 {
    ${H1Styles};
    display: flex;
    gap: ${rem(10)};
  }

  h2 {
    ${H2Styles};
    display: flex;
    gap: ${rem(10)};
  }

  h3 {
    ${H3Styles};
    display: flex;
    gap: ${rem(10)};
  }

  ${imageStyling};
  ${iconStyling};
  ${textStyling};
`;
