import { useEffect, useState } from 'react';
import Config from '@config';

/**
 * This hook is used for fixing rendering issues when usabilla generates a screenshot
 * Returns a boolean which indicates if you should apply styling fixes to your element
 * -----------------------------------------------------------------------------------
 * const applyUsabillaFix = useUsabillaOverride();
 * ...
 * <MyElement applyUsabillaFix={applyUsabillaFix} />
 */

const useUsabillaOverride = (): boolean => {
  const [apply, setApply] = useState(false);
  const [applyUsabillaFix, setApplyUsabillaFix] = useState(false);

  useEffect(() => {
    window?.usabilla_live &&
      window?.usabilla_live(
        'setEventCallback',
        (_category: unknown, action: string, _label: unknown, _value: unknown, _eventdata: unknown) => {
          if (action === undefined || action !== 'Feedback:Open') {
            return;
          }

          if (action === 'Feedback:Open') {
            setApply(true);
          }

          setTimeout(() => setApply(false), Config.TIMEOUT.usabilaApplyMs);
        }
      );
  }, []);

  useEffect(() => {
    if (apply) {
      setApplyUsabillaFix(true);
    }

    return () => {
      if (apply) setApplyUsabillaFix(false);
    };
  }, [apply]);

  return applyUsabillaFix;
};

export default useUsabillaOverride;
