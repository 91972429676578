import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import type { ParsedUrlQuery } from 'querystring';

export const trackSortedProducts = (label: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'category_page',
    action: 'sorted_products',
    label,
    value: 0,
  });
};

export const trackShownMoreProducts = (query: ParsedUrlQuery) => {
  pushGTMEvent({
    event: 'Track',
    category: 'category_page',
    action: 'shown_more_products',
    label: Array.isArray(query.categories) ? query.categories.join('/') : (query.categories as string),
    value: 0,
  });
};
