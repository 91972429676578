import Modal from '@organisms/Modal/Modal';
import { StyledIframe } from './YoutubeModal.styles';

interface Props {
  youtubeId?: string;
  setShowModal: (show: boolean) => void;
}

const YoutubeModal = ({ youtubeId, setShowModal }: Props) => {
  return (
    <Modal size="large" onClose={() => setShowModal(false)} noPadding hasCloseIcon youTubeModal closeIconColor="white">
      <StyledIframe
        title="youtubePlayer"
        src={`https://www.youtube-nocookie.com/embed/${youtubeId}`}
        frameBorder="0"
        data-testid="youtubePlayer"
      />
    </Modal>
  );
};
export default YoutubeModal;
