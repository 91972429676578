import useTranslation from 'next-translate/useTranslation';
import { EnergyLabelTextLabel, EnergyLabelContainer } from '@atoms/EnergyLabel/EnergyLabel.styles';
import Image from 'next/image';
import { EnergyDeclaration } from '@occ/api-client';

export interface EnergyLabelProps {
  energyDeclaration: EnergyDeclaration;
  onClick?: () => void;
  size?: 'large' | 'small';
}

const getEnergyDeclarationArrowImageUrl = (grade: EnergyDeclaration['value']) =>
  grade ? `/images/${grade.toUpperCase()}.png` : undefined;

const EnergyLabel = ({ energyDeclaration, onClick, size = 'small' }: EnergyLabelProps) => {
  const { t } = useTranslation('product');

  const imageUrl = getEnergyDeclarationArrowImageUrl(energyDeclaration.value);
  if (!imageUrl) {
    return null;
  }

  return (
    <EnergyLabelContainer>
      <Image
        unoptimized
        data-testid="energy-label-image"
        src={imageUrl}
        width={size === 'large' ? 111 : 73}
        height={size === 'large' ? 66 : 44}
        onClick={onClick}
        style={{ cursor: Boolean(onClick) ? 'pointer' : 'default', display: 'block' }}
        alt={t(`product->energyDeclaration->altText->${energyDeclaration.value?.toUpperCase()}`)}
      />
      <EnergyLabelTextLabel external target="_blank" href={energyDeclaration.link as string}>
        {t('product->label->energy_label')}
      </EnergyLabelTextLabel>
    </EnergyLabelContainer>
  );
};

export default EnergyLabel;
