import { ReactNode, useState } from 'react';
import Icon, { IconColorType } from '@atoms/Icon/Icon';
import { StyledAlert, StyledButton } from './Alert.styles';
import CloseSmall from '@icons/close_small.svg';
interface Props {
  callback?: (id: string) => void;
  children: ReactNode;
  theme?: messageTheme;
  fontColor?: string;
  backgroundColor?: string;
  hasCloseButton?: boolean;
  buttonColor?: IconColorType;
  id: string;
  className?: string;
}

const Alert = ({
  id,
  callback,
  children,
  theme = 'global',
  fontColor = '',
  backgroundColor = '',
  buttonColor,
  hasCloseButton = true,
  className,
}: Props) => {
  const [displayMessage, setDisplayMessage] = useState(true);

  const alertStyle = {
    color: fontColor,
    background: backgroundColor,
  };

  const buttonColors: { [key: string]: IconColorType } = {
    global: 'white',
    permanent: 'black',
    info: 'black',
    cookie: 'white',
  };

  const onClickHandler = () => {
    if (callback) callback(id);
    setDisplayMessage(false);
  };

  return (
    <>
      {displayMessage && (
        <StyledAlert
          role="alert"
          data-testid="alert-message"
          style={alertStyle}
          className={className}
          closeable={hasCloseButton}
          theme={theme}
        >
          {children}
          {hasCloseButton && (
            <StyledButton data-testid="alert-close-button" theme="transparent" variant={theme} onClick={onClickHandler}>
              <Icon svg={CloseSmall} size={12} color={buttonColor || buttonColors[theme]} data-testid="close-icon" />
            </StyledButton>
          )}
        </StyledAlert>
      )}
    </>
  );
};

export default Alert;
