import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import { useEffect, useState } from 'react';
import useResponsive from '@hooks/useResponsive';

type backgroundImageType = {
  widescreen: imageMediaType;
  desktop: imageMediaType;
  tablet: imageMediaType;
  mobile: imageMediaType;
};

function useResponsiveCMSMedia(cmsData: {
  title?: string;
  titleTablet?: string;
  titleMobile?: string;
  media: responsiveImageMediaType;
}) {
  const { fromTabletPortrait, fromDesktop, fromDesktopXL } = useResponsive();

  const pickImage = (backgroundImages: backgroundImageType) => {
    if (fromDesktopXL) {
      return (
        backgroundImages.widescreen || backgroundImages.desktop || backgroundImages.tablet || backgroundImages.mobile
      );
    }
    if (fromDesktop) {
      return (
        backgroundImages.desktop || backgroundImages.widescreen || backgroundImages.tablet || backgroundImages.mobile
      );
    }

    if (fromTabletPortrait) {
      return (
        backgroundImages.tablet || backgroundImages.desktop || backgroundImages.widescreen || backgroundImages.mobile
      );
    }

    return backgroundImages.mobile || backgroundImages.tablet || backgroundImages.desktop || backgroundImages.widescreen;
  };

  const backgroundImages = {
    mobile: cmsData?.media?.mobile,
    tablet: cmsData?.media?.tablet,
    desktop: cmsData?.media?.desktop,
    widescreen: cmsData?.media?.widescreen,
  };

  const getTitle = () => {
    if (fromDesktop) {
      return cmsData?.title;
    }
    if (fromTabletPortrait) {
      return cmsData?.titleTablet;
    }
    return cmsData?.titleMobile;
  };
  const [data, setData] = useState<{ image: imageMediaType; title?: string }>({
    image: pickImage(backgroundImages),
    title: getTitle(),
  });

  useEffect(() => {
    setData({ image: pickImage(backgroundImages), title: getTitle() });
  }, [fromDesktopXL, fromDesktop, fromTabletPortrait]);

  return data;
}

export default useResponsiveCMSMedia;
