import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import GridCol from '@molecules/GridCol/GridCol';
import { rem } from 'polished';
import Config from '@config';
import Link from '@atoms/Link/Link';
import Heading from '@atoms/Heading/Heading';

export const StyledImageGridComponent = styled(GridCol)`
  z-index: 1;
  width: calc(100% - ${rem(1)});
  background-color: ${variables.colors.white};
  overflow: hidden;
  position: relative;
  align-self: center;
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  margin: ${rem(-1)} 0 0 ${rem(-1)};
  box-sizing: content-box;
  height: ${rem(392)};

  ${Config.MEDIA.FROM_MOBILE} {
    height: ${rem(404)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    height: ${rem(401)};
  }
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  width: 100%;
  color: unset !important;
`;

export const StyledHeading = styled(Heading)`
  margin: ${rem(40)} ${rem(12)} 0 ${rem(12)};
  text-align: center;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin: ${rem(50)} ${rem(12)} 0 ${rem(12)};
  }
`;
