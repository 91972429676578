import { paginationMinItems, paginationItemThreshold } from '@molecules/Carousel/Carousel';

export type DotStyle = {
  size: number;
  active: boolean;
};

enum EnumDotType {
  LARGE = 8,
  MEDIUM = 6,
  SMALL = 4,
}

export type getDotStylePayload = {
  index: number;
  currentSlide: number;
  amountOfSlides: number;
};

export const getDotStyle = ({ index, currentSlide, amountOfSlides }: getDotStylePayload): DotStyle => {
  const dotStyle: DotStyle = { size: EnumDotType.SMALL, active: false };

  if (amountOfSlides <= paginationMinItems) {
    dotStyle.size = EnumDotType.LARGE;
    dotStyle.active = index === currentSlide;
  } else if (currentSlide === index) {
    dotStyle.size = EnumDotType.LARGE;
    dotStyle.active = true;
  } else if (currentSlide < paginationItemThreshold) {
    if (index <= paginationItemThreshold) {
      dotStyle.size = EnumDotType.LARGE;
    } else if (index === paginationItemThreshold + 1) {
      dotStyle.size = EnumDotType.MEDIUM;
    }
  } else if (currentSlide > amountOfSlides - (paginationItemThreshold + 1)) {
    if (index >= amountOfSlides - (paginationItemThreshold + 1)) {
      dotStyle.size = EnumDotType.LARGE;
    } else if (index === amountOfSlides - (paginationItemThreshold + 2)) {
      dotStyle.size = EnumDotType.MEDIUM;
    }
  } else {
    if (index === currentSlide + 1) {
      dotStyle.size = EnumDotType.LARGE;
    } else if (index === currentSlide + 2) {
      dotStyle.size = EnumDotType.MEDIUM;
    } else if (index === currentSlide - 1) {
      dotStyle.size = EnumDotType.LARGE;
    } else if (index === currentSlide - 2) {
      dotStyle.size = EnumDotType.MEDIUM;
    }
  }

  return dotStyle;
};
