import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Link from '@atoms/Link/Link';
import Heading from '@atoms/Heading/Heading';

export const StyledCmsNavNode = styled.ul<{
  isTopLevel: boolean;
  hasChildren: boolean;
  isExpanded: boolean | undefined;
  showNode: boolean;
}>`
  display: ${(props) => (props.isTopLevel || props.isExpanded || props.hasChildren || props.showNode ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${(props) => (props.isExpanded ? variables.colors.lightGray : variables.colors.lighterGray)};
  color: ${(props) => (props.isExpanded ? variables.colors.primary : '')};
`;

export const StyledCmsNavNodeItem = styled.li<{
  hasChildren: boolean;
  isExpanded?: boolean;
  isNested?: boolean;
  noBorder?: boolean;
}>`
  min-height: ${rem(45)};
  align-items: ${(props) => (props.isNested ? 'flex-start' : 'center')};
  flex-direction: ${(props) => (props.isNested ? 'column' : 'row')};
  border-left: ${(props) => (props.isExpanded ? `${rem(2)} solid ${variables.colors.primary}` : '')};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  border: ${(props) => (props.noBorder ? 'none' : '')};
  display: ${(props) => (props.isExpanded ? 'flex' : props.hasChildren ? 'none' : 'flex')};
  font-family: ${(props) => (props.isExpanded ? variables.fonts.fontGothamBook : '')};
  background-color: ${variables.colors.white};
  color: ${(props) => (props.isExpanded ? variables.colors.primary : variables.colors.black)};
  background: ${variables.colors.white};

  &:first-of-type {
    border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  }
  &:last-of-type {
    border: none;
    border-left: ${(props) => (props.isExpanded ? `${rem(2)} solid ${variables.colors.primary}` : 'none')};
  }
`;

export const StyledCmsNavNodeHeading = styled(Heading)`
  margin: ${rem(35)} ${rem(24)} ${rem(10)};
`;

export const StyledCmsNavNodeLinkButton = styled.button`
  background: transparent;
  border: none;
  text-align: left;
  font-family: ${variables.fonts.fontGothamBook};
  padding-right: 0;
  padding-left: 0;
  width: 100%;
`;

export const StyledCmsNavNodeHeadingButton = styled.button<{ adjustPadding: boolean }>`
  background: transparent;
  border: none;
  text-align: left;
  font-family: ${variables.fonts.fontGothamBook};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${rem(45)};
  width: 100%;
  padding-left: ${(props) => rem(props.adjustPadding ? 22 : 24)};
  padding-right: 0;
  cursor: pointer;

  &:hover {
    color: ${variables.colors.primary};
    svg,
    path {
      fill: ${variables.colors.primary};
    }
  }
`;

export const StyledCmsNavNodeLink = styled(Link)<{ isActive: boolean; isExpanded: boolean | undefined }>`
  padding-left: ${(props) => (props.isExpanded ? rem(36) : rem(24))};
  padding-right: ${rem(24)};
  line-height: ${rem(45)};
  width: 100%;
  max-width: 100%;

  ${(props) =>
    props.isActive &&
    css`
      &,
      &:hover,
      &:visited {
        color: ${variables.colors.primary};
        font-family: ${variables.fonts.fontGothamMedium};
      }
    `}
`;

export const StyledCmsNavNodeTitle = styled.span<{ isExpanded: boolean }>`
  max-width: ${rem(185)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: ${rem(13)};
  padding: 0;
  color: ${(props) => (props.isExpanded ? variables.colors.primary : '')};
`;

export const StyledCmsNavNodeIconWrapper = styled.span<{ isExpanded: boolean }>`
  height: ${rem(45)};
  padding-left: ${rem(15)};
  padding-right: ${rem(24)};
  display: flex;
  align-items: center;

  ${(props) =>
    props.isExpanded &&
    css`
      font-size: ${rem(27)};
      padding-bottom: ${rem(1)};
      color: ${variables.colors.primary};
    `}
`;
