/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PriceData } from './price-data';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionData } from './promotion-data';
// May contain unused imports in some cases
// @ts-ignore
import { PromotionThemeData } from './promotion-theme-data';

/**
 * 
 * @export
 * @interface AxfoodPromotionCartViewModel
 */
export interface AxfoodPromotionCartViewModel {
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodPromotionCartViewModel
     */
    'applied': boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'campaignType'?: AxfoodPromotionCartViewModelCampaignTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'cartLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'cartLabelFormatted'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'comparePrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'conditionLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'conditionLabelFormatted'?: string;
    /**
     * 
     * @type {PriceData}
     * @memberof AxfoodPromotionCartViewModel
     */
    'lowestHistoricalPrice'?: PriceData;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'mainProductCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {PriceData}
     * @memberof AxfoodPromotionCartViewModel
     */
    'price'?: PriceData;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'priority': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AxfoodPromotionCartViewModel
     */
    'productCodes'?: Array<string>;
    /**
     * 
     * @type {PromotionData}
     * @memberof AxfoodPromotionCartViewModel
     */
    'promotionData'?: PromotionData;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'promotionPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'promotionRedeemLimit'?: number;
    /**
     * 
     * @type {PromotionThemeData}
     * @memberof AxfoodPromotionCartViewModel
     */
    'promotionTheme'?: PromotionThemeData;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'promotionType'?: string;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'qualifyingCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AxfoodPromotionCartViewModel
     */
    'realMixAndMatch': boolean;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'redeemLimitLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'rewardLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'splashTitleText'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'textLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'textLabelGenerated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodPromotionCartViewModel
     */
    'textLabelManual'?: string;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'timesUsed'?: number;
    /**
     * 
     * @type {number}
     * @memberof AxfoodPromotionCartViewModel
     */
    'validUntil'?: number;
}

export const AxfoodPromotionCartViewModelCampaignTypeEnum = {
    General: 'GENERAL',
    Loyalty: 'LOYALTY',
    Segmented: 'SEGMENTED'
} as const;

export type AxfoodPromotionCartViewModelCampaignTypeEnum = typeof AxfoodPromotionCartViewModelCampaignTypeEnum[keyof typeof AxfoodPromotionCartViewModelCampaignTypeEnum];


