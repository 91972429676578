import WillysDynamicMasterTemplate from './WillysDynamicMasterTemplate/WillysDynamicMasterTemplate';
import WillysHomepageTemplate from './WillysHomepageTemplate/WillysHomepageTemplate';
import WillysShoppingTemplate from './WillysShoppingTemplate/WillysShoppingTemplate';
import WillysCategoryTemplate from './WillysCategoryTemplate/WillysCategoryTemplate';
import WillysStoreTemplate from './WillysStoreTemplate/WillysStoreTemplate';
import WillysProductPageTemplate from './WillysProductPageTemplate/WillysProductPageTemplate';

export {
  WillysDynamicMasterTemplate,
  WillysHomepageTemplate,
  WillysShoppingTemplate,
  WillysCategoryTemplate,
  WillysStoreTemplate,
  WillysProductPageTemplate,
};
