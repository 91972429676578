import Config from '@config';
import { rem } from 'polished';
import styled, { css, keyframes } from 'styled-components';

export const show = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: ${rem(999)};
  }
`;

export const hide = keyframes`
  from {
    max-height: ${rem(999)};
  }
  to {
    max-height: 0;
  }
`;

export const StyledFoldoutWrapper = styled.div<{ show: boolean }>`
  ${(props) =>
    props.show &&
    css`
      margin-top: ${rem(-1)};
      margin-bottom: ${rem(-1)};
    `}
`;

export const StyledGridWrapper = styled.div<{ hasPagination: boolean }>`
  position: relative;
  min-height: ${rem(393)};

  ${(props) =>
    props.hasPagination &&
    css`
      margin-bottom: ${rem(40)};
    `}

  ${Config.BREAKPOINTS.FROM_MOBILE} {
    min-height: ${rem(405)};
  }
  ${Config.BREAKPOINTS.FROM_TABLET_PORTRAIT} {
    min-height: ${rem(402)};
  }
`;

export const StyledSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FoldOutAnimation = styled.div<{ show: boolean }>`
  overflow-y: hidden;
  ${(props) =>
    props.show
      ? css`
          max-height: ${rem(999)};
          animation: ${show} ease-out 0.7s forwards;
        `
      : css`
          max-height: ${rem(999)};
          animation: ${hide} ease-out 0.7s forwards;
        `}
`;
