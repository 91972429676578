import styled from 'styled-components';
import Text from '@atoms/Text/Text';
import { rem } from 'polished';
import Button from '@atoms/Button/Button';

export const StyledNoResultsText = styled(Text)`
  max-width: ${rem(560)};
  margin: ${rem(16)} auto 0;
`;

export const StyledButton = styled(Button)`
  margin: ${rem(24)} auto 0;
`;
