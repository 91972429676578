import {
  WillysDynamicMasterTemplate,
  WillysHomepageTemplate,
  WillysShoppingTemplate,
  WillysCategoryTemplate,
  WillysStoreTemplate,
  WillysProductPageTemplate,
} from '@templates';
import { ReactNode } from 'react';
import type { PageWithSlots } from '@api/interfaces/pageApi';
import type { ContentSlotWsDTO, PageAdaptedData } from '@occ/api-client';

interface ActualContentSlotWsDTO {
  contentSlot: Array<{
    key: string;
    value: ContentSlotWsDTOWithPosition;
  }>;
}

interface ContentSlotWsDTOWithPosition extends ContentSlotWsDTO {
  position: string;
}

const slotsLiteral = {
  WillysHomepageTemplate: ['Position1', 'Position3', 'Position5', 'Position7', 'Content-usp-Willys-Homepage'],
  WillysShoppingTemplate: ['Position1', 'Position3'],
  WillysDynamicMasterTemplate: ['TopWide', 'TopRightContent', 'MainContent', 'RightContent', 'BottomWide'],
  WillysStoreTemplate: ['TopWide', 'MainContent1', 'MainContent2', 'MainContent3', 'RightContent'],
  WillysCategoryTemplate: ['HeaderCenter', 'CategoryMainContent', 'FooterCenter'],
  WillysProductPageTemplate: ['HeaderCenter', 'ProductMainContent', 'FooterCenter'],
};

export const getSlotList = (template = '') => {
  return slotsLiteral[template as keyof typeof slotsLiteral] || [];
};

export const layoutSlotList = ['HeaderLeft', 'HeaderCenter', 'NavigationLeft', 'FooterCenter', 'MessageBox'];

export const getCmsTemplate = (cmsPage?: PageWithSlots, children?: ReactNode) => {
  if (!cmsPage) return null;

  const slotList = getSlotList(cmsPage.template);

  const templateLiteral = {
    WillysHomepageTemplate: (
      <WillysHomepageTemplate cmsPage={cmsPage} homePageSlots={slotList}>
        {children}
      </WillysHomepageTemplate>
    ),
    WillysShoppingTemplate: (
      <WillysShoppingTemplate cmsPage={cmsPage} shoppingSlots={slotList}>
        {children}
      </WillysShoppingTemplate>
    ),
    WillysDynamicMasterTemplate: (
      <WillysDynamicMasterTemplate cmsPage={cmsPage} dynamicMasterSlots={slotList}>
        {children}
      </WillysDynamicMasterTemplate>
    ),
    WillysStoreTemplate: (
      <WillysStoreTemplate cmsPage={cmsPage} storeSlots={slotList}>
        {children}
      </WillysStoreTemplate>
    ),
    WillysCategoryTemplate: (
      <WillysCategoryTemplate cmsPage={cmsPage} categorySlots={slotList}>
        {children}
      </WillysCategoryTemplate>
    ),
    WillysProductPageTemplate: (
      <WillysProductPageTemplate cmsPage={cmsPage} productSlots={slotList}>
        {children}
      </WillysProductPageTemplate>
    ),
  };

  return templateLiteral[cmsPage.template as keyof typeof templateLiteral] || null;
};

export const getSlotsForPage = (cmsPage: PageAdaptedData) => {
  const slotsForPage = slotsLiteral[cmsPage.template as keyof typeof slotsLiteral] || null;

  return (cmsPage.contentSlots as unknown as ActualContentSlotWsDTO).contentSlot
    .map((s) => s.value)
    .filter((s) => [...slotsForPage, ...layoutSlotList].includes(s.position));
};
