import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import Button from '@atoms/Button/Button';
import Heading from '@atoms/Heading/Heading';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Icon from '@atoms/Icon/Icon';
import { StyledProductSavePrice } from '@molecules/Product/Product.styles';
import Carousel from '@molecules/Carousel/Carousel';
import { CarouselWrapper } from '@molecules/Carousel/Carousel.styles';

export const StyledProductDummy = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${variables.colors.white};
  width: calc(100% - ${rem(1)});
`;

export const StyledBuyButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${rem(50)};
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${rem(15)};
`;

export const StyledProductListItem = styled.div`
  position: relative;
  padding: 0 ${rem(20)} ${rem(12)} ${rem(20)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-left: ${rem(-20)};
  margin-right: ${rem(-20)};
  width: calc(100% + ${rem(38)});

  ${Config.MEDIA.FROM_DESKTOP} {
    padding: 0 ${rem(22)} ${rem(20)} ${rem(22)};
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &:first-child {
    border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  }
`;

export const StyledProductListItemWrapper = styled.div<{
  disabled?: boolean;
}>`
  position: relative;
  padding-top: ${rem(12)};
  overflow: hidden;

  ${Config.MEDIA.FROM_DESKTOP} {
    padding-top: ${rem(20)};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;

export const StyledModalContent = styled.div`
  padding: 0 ${rem(20)} 0;
  flex: 1;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(20)};
  padding: ${rem(20)};
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 ${rem(8)} ${rem(28)} 0 ${rgba(24, 24, 24, 0.5)};
  background-color: ${variables.colors.white};
  gap: ${rem(12)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(20)} ${rem(40)};
  }
`;

export const StyledButton = styled(Button)`
  min-width: 0;
  flex: 1;
`;

export const StyledProductListItemRow = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
`;

export const StyledImageContainer = styled.div`
  flex-shrink: 0;
  position: relative;
`;

export const StyledProductInfo = styled.div`
  margin-left: ${rem(32)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${rem(10)};

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-left: ${rem(48)};
  }
`;

export const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledWillysImagePlaceholder = styled(Icon)`
  height: ${rem(56)};
  width: ${rem(56)};

  svg {
    width: auto;
    height: 100%;
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    height: ${rem(72)};
    width: ${rem(72)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    padding-right: ${rem(32)};
  }
`;

export const StyledListItemSavePrice = styled(StyledProductSavePrice)`
  font-size: ${rem(8)};
  position: static;
  line-height: normal;
  height: auto;
  padding: ${rem(2)} ${rem(4)};
  margin-bottom: ${rem(2)};
  color: ${variables.colors.lighterBlack};
`;

export const StyledPriceLabelWrapper = styled.div`
  position: absolute;
  margin: 0;
  top: ${rem(-5)};
  right: ${rem(-20)};
  // Prevent Safari from scaling up text to minimum "readable" size
  -webkit-text-size-adjust: none;

  ${Config.MEDIA.FROM_DESKTOP} {
    top: ${rem(-10)};
    right: ${rem(-30)};
  }
`;

export const StyledCarousel = styled(Carousel)`
  ${CarouselWrapper} {
    border-left: ${rem(1)} solid ${variables.colors.mediumGray};
  }
`;
