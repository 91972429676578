/**
 * Function used to encode URLs.
 *
 * @param string
 */
const slugify = (string: string): string =>
  string
    .replace(/[áàäâãå]/gi, 'a')
    .replace(/[éèëê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/%/g, 'procent')
    .replace(/&/g, 'och')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/\*-/g, '-');
export default slugify;
