import { MouseEvent, ReactNode } from 'react';
import { StyledLabel } from './Label.styles';

export type LabelType = 'mixed' | 'news' | 'mixAndMatch';

interface Props {
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  className?: string;
  children: ReactNode;
  variant: LabelType;
}

const Label = ({ onClick, className, children, variant }: Props) => {
  return (
    <StyledLabel onClick={onClick} variant={variant} className={className}>
      {children}
    </StyledLabel>
  );
};

export default Label;
