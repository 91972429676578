import { LottieOptions } from '@molecules/LottiePlayer/lottieTypes';
import dynamic from 'next/dynamic';

const DynamicLottie = dynamic(() => import('./LottiePlayerInternal' /* webpackChunkName: 'LottiePlayer' */));

const LottiePlayer = (props: LottieOptions) => {
  return <DynamicLottie {...props} />;
};

export default LottiePlayer;
