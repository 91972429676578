import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackCheckoutSubmitError = (message: string, errorCode: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'checkout_error',
    action: 'error_message_shown',
    label: `continue_to_payment: ${message} / ${errorCode}`,
    value: 0,
  });
};

export const trackCheckoutSessionTimeout = (message: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'checkout_error',
    action: 'error_message_shown',
    label: `session_timeout: ${message}`,
    value: 0,
  });
};
