import Config from '@config';

const convertPatterns = [{ from: 'erbjudanden/online-', to: 'produkt/' }];

export const mapCanonicalUrl = (path: string) => {
  let convertedPath = path;
  try {
    convertPatterns.some((pattern) => {
      if (path && path.match(pattern.from)) {
        // Convert to canonical url if the url is matched in any predefined patterns
        convertedPath = convertedPath.replace(pattern.from, pattern.to);
        return true;
      }
      return false;
    });
    // Exclude any query parameters
    return convertedPath.split('?')[0];
  } catch (e) {
    return path;
  }
};

export const updateCanonicalLink = (origin: string, path: string) => {
  return origin + mapCanonicalUrl(path);
};
