import { StyledTable } from '@atoms/Table/Table.styles';
import { ReactElement } from 'react';

export type TableProps = {
  children: ReactElement[];
};

const Table = ({ children }: TableProps) => {
  return <StyledTable>{children}</StyledTable>;
};

export default Table;
