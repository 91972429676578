import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: ${rem(30)} 0 0;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
  }
`;

export const HeadingTitle = styled(Heading)`
  margin-right: ${rem(8)};
`;

export const BreadcrumbsWrapper = styled.div`
  margin-top: ${rem(13)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(18)};
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(8)};
`;

export const StyledAmountText = styled(Text)`
  text-align: center;
  margin-bottom: ${rem(12)};
`;
