/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddToCartFormListWrapper } from '../dto';
// @ts-ignore
import { AddressData } from '../dto';
// @ts-ignore
import { AxfoodCartEntryStatusViewModel } from '../dto';
// @ts-ignore
import { AxfoodCartRestorationViewModel } from '../dto';
// @ts-ignore
import { AxfoodCartStatusViewModel } from '../dto';
// @ts-ignore
import { AxfoodCartViewModel } from '../dto';
// @ts-ignore
import { AxfoodMergeCartViewModel } from '../dto';
// @ts-ignore
import { AxfoodMiniCartViewModel } from '../dto';
// @ts-ignore
import { CustomerContactData } from '../dto';
// @ts-ignore
import { CustomerContactForm } from '../dto';
// @ts-ignore
import { CustomerExtraData } from '../dto';
// @ts-ignore
import { StockLevelEntryData } from '../dto';
/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add products to cart
         * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartUsingPOST: async (formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formListWrapper' is not null or undefined
            assertParamExists('addToCartUsingPOST', 'formListWrapper', formListWrapper)
            const localVarPath = `/axfood/rest/cart/addProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (calculateCart !== undefined) {
                localVarQueryParameter['calculateCart'] = calculateCart;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formListWrapper, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add products to cart
         * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartUsingPOST1: async (formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formListWrapper' is not null or undefined
            assertParamExists('addToCartUsingPOST1', 'formListWrapper', formListWrapper)
            const localVarPath = `/axfood/rest/minicart/addProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (calculateCart !== undefined) {
                localVarQueryParameter['calculateCart'] = calculateCart;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formListWrapper, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply voucher to cart
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyVoucherToCartUsingPOST: async (voucherCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherCode' is not null or undefined
            assertParamExists('applyVoucherToCartUsingPOST', 'voucherCode', voucherCode)
            const localVarPath = `/axfood/rest/cart/voucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (voucherCode !== undefined) {
                localVarQueryParameter['voucherCode'] = voucherCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cart Status
         * @param {string} [slotCode] slotCode
         * @param {string} [storeId] storeId
         * @param {boolean} [onlyCheckExternal] onlyCheckExternal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCartStatusUsingGET: async (slotCode?: string, storeId?: string, onlyCheckExternal?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (slotCode !== undefined) {
                localVarQueryParameter['slotCode'] = slotCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (onlyCheckExternal !== undefined) {
                localVarQueryParameter['onlyCheckExternal'] = onlyCheckExternal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ECC StockLevels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEccStockLevelsForSessionCartUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/ecc/stocklevels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel session cart
         * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCartUsingDELETE: async (cancelPossibleContinueCart?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (cancelPossibleContinueCart !== undefined) {
                localVarQueryParameter['cancelPossibleContinueCart'] = cancelPossibleContinueCart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel session cart
         * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCartUsingDELETE1: async (cancelPossibleContinueCart?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/minicart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (cancelPossibleContinueCart !== undefined) {
                localVarQueryParameter['cancelPossibleContinueCart'] = cancelPossibleContinueCart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Continue shop for order
         * @param {string} orderNumber orderNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueShopForOrderUsingPOST: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('continueShopForOrderUsingPOST', 'orderNumber', orderNumber)
            const localVarPath = `/axfood/rest/cart/carts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = orderNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Threshold status for free delivery or pickup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartStatusForFreeDeliveryOrPickupUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/status/threshold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/minicart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes on restored cart from last session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangesOnRestoredCartFromLastSessionUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/status/lastsession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Status of conflicting cart entries
         * @param {'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous'} type type
         * @param {string} [slotCode] slotCode
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {boolean} [onlyCheckExternal] onlyCheckExternal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConflictingEntriesUsingGET: async (type: 'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous', slotCode?: string, storeId?: string, b2b?: boolean, onlyCheckExternal?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getConflictingEntriesUsingGET', 'type', type)
            const localVarPath = `/axfood/rest/cart/status/conflicts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (slotCode !== undefined) {
                localVarQueryParameter['slotCode'] = slotCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (b2b !== undefined) {
                localVarQueryParameter['b2b'] = b2b;
            }

            if (onlyCheckExternal !== undefined) {
                localVarQueryParameter['onlyCheckExternal'] = onlyCheckExternal;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAddressesUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/customer-addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerContactUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/customer-contact-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer extra info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerExtraInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/customer-extra-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer likely delivery address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerLikelyDeliveryAddressUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/customer-likely-delivery-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get delivery address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryAddressUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/delivery-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mergeable persistent cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMergablePersistentCartUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product to cart
         * @param {number} [qty] 
         * @param {string} [productCodePost] 
         * @param {string} [pickUnit] 
         * @param {boolean} [noReplacementFlag] 
         * @param {boolean} [hideDiscountToolTip] 
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST: async (qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/addProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (qty !== undefined) {
                localVarQueryParameter['qty'] = qty;
            }

            if (productCodePost !== undefined) {
                localVarQueryParameter['productCodePost'] = productCodePost;
            }

            if (pickUnit !== undefined) {
                localVarQueryParameter['pickUnit'] = pickUnit;
            }

            if (noReplacementFlag !== undefined) {
                localVarQueryParameter['noReplacementFlag'] = noReplacementFlag;
            }

            if (hideDiscountToolTip !== undefined) {
                localVarQueryParameter['hideDiscountToolTip'] = hideDiscountToolTip;
            }

            if (calculateCart !== undefined) {
                localVarQueryParameter['calculateCart'] = calculateCart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add order to cart
         * @param {string} order order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST1: async (order: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('putToCartUsingPOST1', 'order', order)
            const localVarPath = `/axfood/rest/cart/put/order/{order}`
                .replace(`{${"order"}}`, encodeURIComponent(String(order)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product to cart
         * @param {number} [qty] 
         * @param {string} [productCodePost] 
         * @param {string} [pickUnit] 
         * @param {boolean} [noReplacementFlag] 
         * @param {boolean} [hideDiscountToolTip] 
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST2: async (qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/minicart/addProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (qty !== undefined) {
                localVarQueryParameter['qty'] = qty;
            }

            if (productCodePost !== undefined) {
                localVarQueryParameter['productCodePost'] = productCodePost;
            }

            if (pickUnit !== undefined) {
                localVarQueryParameter['pickUnit'] = pickUnit;
            }

            if (noReplacementFlag !== undefined) {
                localVarQueryParameter['noReplacementFlag'] = noReplacementFlag;
            }

            if (hideDiscountToolTip !== undefined) {
                localVarQueryParameter['hideDiscountToolTip'] = hideDiscountToolTip;
            }

            if (calculateCart !== undefined) {
                localVarQueryParameter['calculateCart'] = calculateCart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add order to cart
         * @param {string} order order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST3: async (order: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('putToCartUsingPOST3', 'order', order)
            const localVarPath = `/axfood/rest/minicart/put/order/{order}`
                .replace(`{${"order"}}`, encodeURIComponent(String(order)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Release voucher from cart
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseVoucherFromCartUsingDELETE: async (voucherCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherCode' is not null or undefined
            assertParamExists('releaseVoucherFromCartUsingDELETE', 'voucherCode', voucherCode)
            const localVarPath = `/axfood/rest/cart/voucher/{voucherCode}`
                .replace(`{${"voucherCode"}}`, encodeURIComponent(String(voucherCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore cart and close open orders
         * @param {string} action action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCartAndCloseOpenOrdersUsingPOST: async (action: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('restoreCartAndCloseOpenOrdersUsingPOST', 'action', action)
            const localVarPath = `/axfood/rest/cart/restore-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore cart
         * @param {string} action action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCartUsingPOST: async (action: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('restoreCartUsingPOST', 'action', action)
            const localVarPath = `/axfood/rest/cart/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set customer contact info
         * @param {CustomerContactForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCustomerContactUsingPOST: async (form: CustomerContactForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('setCustomerContactUsingPOST', 'form', form)
            const localVarPath = `/axfood/rest/cart/customer-contact-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set customer extra door code, user comment and users order preference
         * @param {string} [commentFromCustomer] 
         * @param {string} [doorCode] 
         * @param {string} [usersOrderReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCustomerExtraUsingPOST: async (commentFromCustomer?: string, doorCode?: string, usersOrderReference?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/customer-extra-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (commentFromCustomer !== undefined) {
                localVarQueryParameter['commentFromCustomer'] = commentFromCustomer;
            }

            if (doorCode !== undefined) {
                localVarQueryParameter['doorCode'] = doorCode;
            }

            if (usersOrderReference !== undefined) {
                localVarQueryParameter['usersOrderReference'] = usersOrderReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set delivery address
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [postalCode] 
         * @param {string} [town] 
         * @param {string} [addressId] 
         * @param {string} [email] 
         * @param {string} [cellphone] 
         * @param {string} [companyName] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeliveryAddressUsingPOST: async (firstName?: string, lastName?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, town?: string, addressId?: string, email?: string, cellphone?: string, companyName?: string, longitude?: number, latitude?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/delivery-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (addressLine1 !== undefined) {
                localVarQueryParameter['addressLine1'] = addressLine1;
            }

            if (addressLine2 !== undefined) {
                localVarQueryParameter['addressLine2'] = addressLine2;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (town !== undefined) {
                localVarQueryParameter['town'] = town;
            }

            if (addressId !== undefined) {
                localVarQueryParameter['addressId'] = addressId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (cellphone !== undefined) {
                localVarQueryParameter['cellphone'] = cellphone;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set delivery mode
         * @param {string} deliveryModeCode deliveryModeCode
         * @param {string} [newSuggestedStoreId] newSuggestedStoreId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeliveryModeUsingPOST: async (deliveryModeCode: string, newSuggestedStoreId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryModeCode' is not null or undefined
            assertParamExists('setDeliveryModeUsingPOST', 'deliveryModeCode', deliveryModeCode)
            const localVarPath = `/axfood/rest/cart/delivery-mode/{deliveryModeCode}`
                .replace(`{${"deliveryModeCode"}}`, encodeURIComponent(String(deliveryModeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (newSuggestedStoreId !== undefined) {
                localVarQueryParameter['newSuggestedStoreId'] = newSuggestedStoreId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set postal code
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPostalCodeUsingPOST: async (postalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalCode' is not null or undefined
            assertParamExists('setPostalCodeUsingPOST', 'postalCode', postalCode)
            const localVarPath = `/axfood/rest/cart/postal-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cart modifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartModificationsUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/updatecartmodifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update do not replace flag for product
         * @param {boolean} doNotReplace doNotReplace
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoNotReplaceFlagUsingPOST: async (doNotReplace: boolean, productCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doNotReplace' is not null or undefined
            assertParamExists('updateDoNotReplaceFlagUsingPOST', 'doNotReplace', doNotReplace)
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('updateDoNotReplaceFlagUsingPOST', 'productCode', productCode)
            const localVarPath = `/axfood/rest/cart/product/{productCode}/do-not-replace`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (doNotReplace !== undefined) {
                localVarQueryParameter['doNotReplace'] = doNotReplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update do not replace flag for products on cart
         * @param {boolean} doNotReplace doNotReplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoNotReplaceFlagUsingPOST1: async (doNotReplace: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doNotReplace' is not null or undefined
            assertParamExists('updateDoNotReplaceFlagUsingPOST1', 'doNotReplace', doNotReplace)
            const localVarPath = `/axfood/rest/cart/products/do-not-replace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (doNotReplace !== undefined) {
                localVarQueryParameter['doNotReplace'] = doNotReplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update eko replacement flag on cart entries
         * @param {boolean} replaceEko replaceEko
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEkoReplacementFlagUsingPOST: async (replaceEko: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'replaceEko' is not null or undefined
            assertParamExists('updateEkoReplacementFlagUsingPOST', 'replaceEko', replaceEko)
            const localVarPath = `/axfood/rest/cart/update-ekoreplacement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (replaceEko !== undefined) {
                localVarQueryParameter['replaceEko'] = replaceEko;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (Depricated) Update no replacement flag on cart entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoReplacementFlagUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/update-noreplacement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add products to cart
         * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCartUsingPOST(formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCartUsingPOST(formListWrapper, calculateCart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add products to cart
         * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCartUsingPOST1(formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodMiniCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCartUsingPOST1(formListWrapper, calculateCart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply voucher to cart
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyVoucherToCartUsingPOST(voucherCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyVoucherToCartUsingPOST(voucherCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cart Status
         * @param {string} [slotCode] slotCode
         * @param {string} [storeId] storeId
         * @param {boolean} [onlyCheckExternal] onlyCheckExternal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCartStatusUsingGET(slotCode?: string, storeId?: string, onlyCheckExternal?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartStatusViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCartStatusUsingGET(slotCode, storeId, onlyCheckExternal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ECC StockLevels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEccStockLevelsForSessionCartUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockLevelEntryData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEccStockLevelsForSessionCartUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel session cart
         * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCartUsingDELETE(cancelPossibleContinueCart?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCartUsingDELETE(cancelPossibleContinueCart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel session cart
         * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCartUsingDELETE1(cancelPossibleContinueCart?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodMiniCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCartUsingDELETE1(cancelPossibleContinueCart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Continue shop for order
         * @param {string} orderNumber orderNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueShopForOrderUsingPOST(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueShopForOrderUsingPOST(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Threshold status for free delivery or pickup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartStatusForFreeDeliveryOrPickupUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartStatusForFreeDeliveryOrPickupUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get session cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get session cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartUsingGET1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodMiniCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartUsingGET1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes on restored cart from last session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangesOnRestoredCartFromLastSessionUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodCartEntryStatusViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangesOnRestoredCartFromLastSessionUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Status of conflicting cart entries
         * @param {'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous'} type type
         * @param {string} [slotCode] slotCode
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {boolean} [onlyCheckExternal] onlyCheckExternal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConflictingEntriesUsingGET(type: 'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous', slotCode?: string, storeId?: string, b2b?: boolean, onlyCheckExternal?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConflictingEntriesUsingGET(type, slotCode, storeId, b2b, onlyCheckExternal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAddressesUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAddressesUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerContactUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerContactData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerContactUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer extra info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerExtraInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerExtraData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerExtraInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer likely delivery address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerLikelyDeliveryAddressUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerLikelyDeliveryAddressUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get delivery address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryAddressUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryAddressUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get mergeable persistent cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMergablePersistentCartUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodMergeCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMergablePersistentCartUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add product to cart
         * @param {number} [qty] 
         * @param {string} [productCodePost] 
         * @param {string} [pickUnit] 
         * @param {boolean} [noReplacementFlag] 
         * @param {boolean} [hideDiscountToolTip] 
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putToCartUsingPOST(qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putToCartUsingPOST(qty, productCodePost, pickUnit, noReplacementFlag, hideDiscountToolTip, calculateCart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add order to cart
         * @param {string} order order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putToCartUsingPOST1(order: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putToCartUsingPOST1(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add product to cart
         * @param {number} [qty] 
         * @param {string} [productCodePost] 
         * @param {string} [pickUnit] 
         * @param {boolean} [noReplacementFlag] 
         * @param {boolean} [hideDiscountToolTip] 
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putToCartUsingPOST2(qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodMiniCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putToCartUsingPOST2(qty, productCodePost, pickUnit, noReplacementFlag, hideDiscountToolTip, calculateCart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add order to cart
         * @param {string} order order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putToCartUsingPOST3(order: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodMiniCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putToCartUsingPOST3(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Release voucher from cart
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseVoucherFromCartUsingDELETE(voucherCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.releaseVoucherFromCartUsingDELETE(voucherCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore cart and close open orders
         * @param {string} action action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreCartAndCloseOpenOrdersUsingPOST(action: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartRestorationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreCartAndCloseOpenOrdersUsingPOST(action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore cart
         * @param {string} action action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreCartUsingPOST(action: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreCartUsingPOST(action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set customer contact info
         * @param {CustomerContactForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCustomerContactUsingPOST(form: CustomerContactForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCustomerContactUsingPOST(form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set customer extra door code, user comment and users order preference
         * @param {string} [commentFromCustomer] 
         * @param {string} [doorCode] 
         * @param {string} [usersOrderReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCustomerExtraUsingPOST(commentFromCustomer?: string, doorCode?: string, usersOrderReference?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCustomerExtraUsingPOST(commentFromCustomer, doorCode, usersOrderReference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set delivery address
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [postalCode] 
         * @param {string} [town] 
         * @param {string} [addressId] 
         * @param {string} [email] 
         * @param {string} [cellphone] 
         * @param {string} [companyName] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDeliveryAddressUsingPOST(firstName?: string, lastName?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, town?: string, addressId?: string, email?: string, cellphone?: string, companyName?: string, longitude?: number, latitude?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDeliveryAddressUsingPOST(firstName, lastName, addressLine1, addressLine2, postalCode, town, addressId, email, cellphone, companyName, longitude, latitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set delivery mode
         * @param {string} deliveryModeCode deliveryModeCode
         * @param {string} [newSuggestedStoreId] newSuggestedStoreId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDeliveryModeUsingPOST(deliveryModeCode: string, newSuggestedStoreId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDeliveryModeUsingPOST(deliveryModeCode, newSuggestedStoreId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set postal code
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPostalCodeUsingPOST(postalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPostalCodeUsingPOST(postalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update cart modifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCartModificationsUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCartModificationsUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update do not replace flag for product
         * @param {boolean} doNotReplace doNotReplace
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoNotReplaceFlagUsingPOST(doNotReplace: boolean, productCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoNotReplaceFlagUsingPOST(doNotReplace, productCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update do not replace flag for products on cart
         * @param {boolean} doNotReplace doNotReplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoNotReplaceFlagUsingPOST1(doNotReplace: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoNotReplaceFlagUsingPOST1(doNotReplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update eko replacement flag on cart entries
         * @param {boolean} replaceEko replaceEko
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEkoReplacementFlagUsingPOST(replaceEko: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEkoReplacementFlagUsingPOST(replaceEko, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (Depricated) Update no replacement flag on cart entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoReplacementFlagUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoReplacementFlagUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartApiFp(configuration)
    return {
        /**
         * 
         * @summary Add products to cart
         * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartUsingPOST(formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.addToCartUsingPOST(formListWrapper, calculateCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add products to cart
         * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartUsingPOST1(formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options?: any): AxiosPromise<AxfoodMiniCartViewModel> {
            return localVarFp.addToCartUsingPOST1(formListWrapper, calculateCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply voucher to cart
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyVoucherToCartUsingPOST(voucherCode: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.applyVoucherToCartUsingPOST(voucherCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cart Status
         * @param {string} [slotCode] slotCode
         * @param {string} [storeId] storeId
         * @param {boolean} [onlyCheckExternal] onlyCheckExternal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCartStatusUsingGET(slotCode?: string, storeId?: string, onlyCheckExternal?: boolean, options?: any): AxiosPromise<AxfoodCartStatusViewModel> {
            return localVarFp.checkCartStatusUsingGET(slotCode, storeId, onlyCheckExternal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ECC StockLevels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEccStockLevelsForSessionCartUsingGET(options?: any): AxiosPromise<Array<StockLevelEntryData>> {
            return localVarFp.checkEccStockLevelsForSessionCartUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel session cart
         * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCartUsingDELETE(cancelPossibleContinueCart?: boolean, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.clearCartUsingDELETE(cancelPossibleContinueCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel session cart
         * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCartUsingDELETE1(cancelPossibleContinueCart?: boolean, options?: any): AxiosPromise<AxfoodMiniCartViewModel> {
            return localVarFp.clearCartUsingDELETE1(cancelPossibleContinueCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Continue shop for order
         * @param {string} orderNumber orderNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueShopForOrderUsingPOST(orderNumber: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.continueShopForOrderUsingPOST(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Threshold status for free delivery or pickup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartStatusForFreeDeliveryOrPickupUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.getCartStatusForFreeDeliveryOrPickupUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get session cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.getCartUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get session cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET1(options?: any): AxiosPromise<AxfoodMiniCartViewModel> {
            return localVarFp.getCartUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes on restored cart from last session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangesOnRestoredCartFromLastSessionUsingGET(options?: any): AxiosPromise<Array<AxfoodCartEntryStatusViewModel>> {
            return localVarFp.getChangesOnRestoredCartFromLastSessionUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Status of conflicting cart entries
         * @param {'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous'} type type
         * @param {string} [slotCode] slotCode
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {boolean} [onlyCheckExternal] onlyCheckExternal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConflictingEntriesUsingGET(type: 'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous', slotCode?: string, storeId?: string, b2b?: boolean, onlyCheckExternal?: boolean, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getConflictingEntriesUsingGET(type, slotCode, storeId, b2b, onlyCheckExternal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAddressesUsingGET(options?: any): AxiosPromise<Array<AddressData>> {
            return localVarFp.getCustomerAddressesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerContactUsingGET(options?: any): AxiosPromise<CustomerContactData> {
            return localVarFp.getCustomerContactUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer extra info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerExtraInfoUsingGET(options?: any): AxiosPromise<CustomerExtraData> {
            return localVarFp.getCustomerExtraInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer likely delivery address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerLikelyDeliveryAddressUsingGET(options?: any): AxiosPromise<AddressData> {
            return localVarFp.getCustomerLikelyDeliveryAddressUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get delivery address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryAddressUsingGET(options?: any): AxiosPromise<AddressData> {
            return localVarFp.getDeliveryAddressUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mergeable persistent cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMergablePersistentCartUsingGET(options?: any): AxiosPromise<AxfoodMergeCartViewModel> {
            return localVarFp.getMergablePersistentCartUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add product to cart
         * @param {number} [qty] 
         * @param {string} [productCodePost] 
         * @param {string} [pickUnit] 
         * @param {boolean} [noReplacementFlag] 
         * @param {boolean} [hideDiscountToolTip] 
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST(qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.putToCartUsingPOST(qty, productCodePost, pickUnit, noReplacementFlag, hideDiscountToolTip, calculateCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add order to cart
         * @param {string} order order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST1(order: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.putToCartUsingPOST1(order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add product to cart
         * @param {number} [qty] 
         * @param {string} [productCodePost] 
         * @param {string} [pickUnit] 
         * @param {boolean} [noReplacementFlag] 
         * @param {boolean} [hideDiscountToolTip] 
         * @param {boolean} [calculateCart] calculateCart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST2(qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options?: any): AxiosPromise<AxfoodMiniCartViewModel> {
            return localVarFp.putToCartUsingPOST2(qty, productCodePost, pickUnit, noReplacementFlag, hideDiscountToolTip, calculateCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add order to cart
         * @param {string} order order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToCartUsingPOST3(order: string, options?: any): AxiosPromise<AxfoodMiniCartViewModel> {
            return localVarFp.putToCartUsingPOST3(order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Release voucher from cart
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseVoucherFromCartUsingDELETE(voucherCode: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.releaseVoucherFromCartUsingDELETE(voucherCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore cart and close open orders
         * @param {string} action action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCartAndCloseOpenOrdersUsingPOST(action: string, options?: any): AxiosPromise<AxfoodCartRestorationViewModel> {
            return localVarFp.restoreCartAndCloseOpenOrdersUsingPOST(action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore cart
         * @param {string} action action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCartUsingPOST(action: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.restoreCartUsingPOST(action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set customer contact info
         * @param {CustomerContactForm} form form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCustomerContactUsingPOST(form: CustomerContactForm, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.setCustomerContactUsingPOST(form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set customer extra door code, user comment and users order preference
         * @param {string} [commentFromCustomer] 
         * @param {string} [doorCode] 
         * @param {string} [usersOrderReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCustomerExtraUsingPOST(commentFromCustomer?: string, doorCode?: string, usersOrderReference?: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.setCustomerExtraUsingPOST(commentFromCustomer, doorCode, usersOrderReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set delivery address
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [postalCode] 
         * @param {string} [town] 
         * @param {string} [addressId] 
         * @param {string} [email] 
         * @param {string} [cellphone] 
         * @param {string} [companyName] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeliveryAddressUsingPOST(firstName?: string, lastName?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, town?: string, addressId?: string, email?: string, cellphone?: string, companyName?: string, longitude?: number, latitude?: number, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.setDeliveryAddressUsingPOST(firstName, lastName, addressLine1, addressLine2, postalCode, town, addressId, email, cellphone, companyName, longitude, latitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set delivery mode
         * @param {string} deliveryModeCode deliveryModeCode
         * @param {string} [newSuggestedStoreId] newSuggestedStoreId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeliveryModeUsingPOST(deliveryModeCode: string, newSuggestedStoreId?: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.setDeliveryModeUsingPOST(deliveryModeCode, newSuggestedStoreId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set postal code
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPostalCodeUsingPOST(postalCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.setPostalCodeUsingPOST(postalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cart modifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCartModificationsUsingPOST(options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.updateCartModificationsUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update do not replace flag for product
         * @param {boolean} doNotReplace doNotReplace
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoNotReplaceFlagUsingPOST(doNotReplace: boolean, productCode: string, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.updateDoNotReplaceFlagUsingPOST(doNotReplace, productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update do not replace flag for products on cart
         * @param {boolean} doNotReplace doNotReplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoNotReplaceFlagUsingPOST1(doNotReplace: boolean, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.updateDoNotReplaceFlagUsingPOST1(doNotReplace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update eko replacement flag on cart entries
         * @param {boolean} replaceEko replaceEko
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEkoReplacementFlagUsingPOST(replaceEko: boolean, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.updateEkoReplacementFlagUsingPOST(replaceEko, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (Depricated) Update no replacement flag on cart entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoReplacementFlagUsingPOST(options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.updateNoReplacementFlagUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * 
     * @summary Add products to cart
     * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
     * @param {boolean} [calculateCart] calculateCart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public addToCartUsingPOST(formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).addToCartUsingPOST(formListWrapper, calculateCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add products to cart
     * @param {AddToCartFormListWrapper} formListWrapper formListWrapper
     * @param {boolean} [calculateCart] calculateCart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public addToCartUsingPOST1(formListWrapper: AddToCartFormListWrapper, calculateCart?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).addToCartUsingPOST1(formListWrapper, calculateCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply voucher to cart
     * @param {string} voucherCode voucherCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public applyVoucherToCartUsingPOST(voucherCode: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).applyVoucherToCartUsingPOST(voucherCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cart Status
     * @param {string} [slotCode] slotCode
     * @param {string} [storeId] storeId
     * @param {boolean} [onlyCheckExternal] onlyCheckExternal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public checkCartStatusUsingGET(slotCode?: string, storeId?: string, onlyCheckExternal?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).checkCartStatusUsingGET(slotCode, storeId, onlyCheckExternal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ECC StockLevels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public checkEccStockLevelsForSessionCartUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).checkEccStockLevelsForSessionCartUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel session cart
     * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public clearCartUsingDELETE(cancelPossibleContinueCart?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).clearCartUsingDELETE(cancelPossibleContinueCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel session cart
     * @param {boolean} [cancelPossibleContinueCart] Whether possible continuation of the cart should be cancelled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public clearCartUsingDELETE1(cancelPossibleContinueCart?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).clearCartUsingDELETE1(cancelPossibleContinueCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Continue shop for order
     * @param {string} orderNumber orderNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public continueShopForOrderUsingPOST(orderNumber: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).continueShopForOrderUsingPOST(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Threshold status for free delivery or pickup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCartStatusForFreeDeliveryOrPickupUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCartStatusForFreeDeliveryOrPickupUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get session cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCartUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCartUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get session cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCartUsingGET1(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCartUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes on restored cart from last session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getChangesOnRestoredCartFromLastSessionUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getChangesOnRestoredCartFromLastSessionUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Status of conflicting cart entries
     * @param {'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous'} type type
     * @param {string} [slotCode] slotCode
     * @param {string} [storeId] storeId
     * @param {boolean} [b2b] b2b
     * @param {boolean} [onlyCheckExternal] onlyCheckExternal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getConflictingEntriesUsingGET(type: 'all' | 'allExceptNotAllowedAnonymous' | 'allExceptPartialWithReplacementsAndNotAllowed' | 'replacable' | 'nonReplacable' | 'notAllowedAnonymous', slotCode?: string, storeId?: string, b2b?: boolean, onlyCheckExternal?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getConflictingEntriesUsingGET(type, slotCode, storeId, b2b, onlyCheckExternal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer addresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCustomerAddressesUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCustomerAddressesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer contact
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCustomerContactUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCustomerContactUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer extra info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCustomerExtraInfoUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCustomerExtraInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer likely delivery address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCustomerLikelyDeliveryAddressUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getCustomerLikelyDeliveryAddressUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get delivery address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getDeliveryAddressUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getDeliveryAddressUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get mergeable persistent cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getMergablePersistentCartUsingGET(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).getMergablePersistentCartUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add product to cart
     * @param {number} [qty] 
     * @param {string} [productCodePost] 
     * @param {string} [pickUnit] 
     * @param {boolean} [noReplacementFlag] 
     * @param {boolean} [hideDiscountToolTip] 
     * @param {boolean} [calculateCart] calculateCart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public putToCartUsingPOST(qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).putToCartUsingPOST(qty, productCodePost, pickUnit, noReplacementFlag, hideDiscountToolTip, calculateCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add order to cart
     * @param {string} order order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public putToCartUsingPOST1(order: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).putToCartUsingPOST1(order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add product to cart
     * @param {number} [qty] 
     * @param {string} [productCodePost] 
     * @param {string} [pickUnit] 
     * @param {boolean} [noReplacementFlag] 
     * @param {boolean} [hideDiscountToolTip] 
     * @param {boolean} [calculateCart] calculateCart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public putToCartUsingPOST2(qty?: number, productCodePost?: string, pickUnit?: string, noReplacementFlag?: boolean, hideDiscountToolTip?: boolean, calculateCart?: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).putToCartUsingPOST2(qty, productCodePost, pickUnit, noReplacementFlag, hideDiscountToolTip, calculateCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add order to cart
     * @param {string} order order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public putToCartUsingPOST3(order: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).putToCartUsingPOST3(order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Release voucher from cart
     * @param {string} voucherCode voucherCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public releaseVoucherFromCartUsingDELETE(voucherCode: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).releaseVoucherFromCartUsingDELETE(voucherCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore cart and close open orders
     * @param {string} action action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public restoreCartAndCloseOpenOrdersUsingPOST(action: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).restoreCartAndCloseOpenOrdersUsingPOST(action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore cart
     * @param {string} action action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public restoreCartUsingPOST(action: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).restoreCartUsingPOST(action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set customer contact info
     * @param {CustomerContactForm} form form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public setCustomerContactUsingPOST(form: CustomerContactForm, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).setCustomerContactUsingPOST(form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set customer extra door code, user comment and users order preference
     * @param {string} [commentFromCustomer] 
     * @param {string} [doorCode] 
     * @param {string} [usersOrderReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public setCustomerExtraUsingPOST(commentFromCustomer?: string, doorCode?: string, usersOrderReference?: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).setCustomerExtraUsingPOST(commentFromCustomer, doorCode, usersOrderReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set delivery address
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [addressLine1] 
     * @param {string} [addressLine2] 
     * @param {string} [postalCode] 
     * @param {string} [town] 
     * @param {string} [addressId] 
     * @param {string} [email] 
     * @param {string} [cellphone] 
     * @param {string} [companyName] 
     * @param {number} [longitude] 
     * @param {number} [latitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public setDeliveryAddressUsingPOST(firstName?: string, lastName?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, town?: string, addressId?: string, email?: string, cellphone?: string, companyName?: string, longitude?: number, latitude?: number, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).setDeliveryAddressUsingPOST(firstName, lastName, addressLine1, addressLine2, postalCode, town, addressId, email, cellphone, companyName, longitude, latitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set delivery mode
     * @param {string} deliveryModeCode deliveryModeCode
     * @param {string} [newSuggestedStoreId] newSuggestedStoreId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public setDeliveryModeUsingPOST(deliveryModeCode: string, newSuggestedStoreId?: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).setDeliveryModeUsingPOST(deliveryModeCode, newSuggestedStoreId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set postal code
     * @param {string} postalCode postalCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public setPostalCodeUsingPOST(postalCode: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).setPostalCodeUsingPOST(postalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cart modifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public updateCartModificationsUsingPOST(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).updateCartModificationsUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update do not replace flag for product
     * @param {boolean} doNotReplace doNotReplace
     * @param {string} productCode productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public updateDoNotReplaceFlagUsingPOST(doNotReplace: boolean, productCode: string, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).updateDoNotReplaceFlagUsingPOST(doNotReplace, productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update do not replace flag for products on cart
     * @param {boolean} doNotReplace doNotReplace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public updateDoNotReplaceFlagUsingPOST1(doNotReplace: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).updateDoNotReplaceFlagUsingPOST1(doNotReplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update eko replacement flag on cart entries
     * @param {boolean} replaceEko replaceEko
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public updateEkoReplacementFlagUsingPOST(replaceEko: boolean, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).updateEkoReplacementFlagUsingPOST(replaceEko, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (Depricated) Update no replacement flag on cart entries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public updateNoReplacementFlagUsingPOST(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).updateNoReplacementFlagUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}
