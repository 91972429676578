import DOMPurify from 'isomorphic-dompurify';
import React, { ReactNode, useState } from 'react';
import { cmsAlignToFlexAlign, cmsAlignToTextAlign } from '@helpers/cmsComponentHelpers';
import YoutubeModal from '@molecules/YoutubeModal/YoutubeModal';
import getPaddingFromCms from '@helpers/getPaddingFromCms';
import useResponsiveCMSMedia from '@hooks/useResponsiveCMSMedia';
import {
  StyledArrow,
  StyledButton,
  StyledDiv,
  StyledHeading,
  StyledImg,
  StyledLink,
  StyledSection,
  StyledText,
  StyledYoutubeIcon,
} from './VerticalBannerComponent.styles';
import Video from '@icons/video.svg';
import ArrowRight from '@icons/arrow-right.svg';

interface Props {
  data: VerticalBannerType;
}

const VerticalBannerComponent = ({ data }: Props) => {
  const alignItems = cmsAlignToFlexAlign(data?.textAlign);
  const textAlign = cmsAlignToTextAlign(data?.textAlign);
  const { image } = useResponsiveCMSMedia(data);
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const isButtonEnabled = data?.buttonEnabled === 'true' && data.bannerType !== 'SMALL';
  const hasWrappingLink = data?.buttonEnabled === 'false' && !!data.urlLink;

  const button = (
    <StyledButton
      theme="secondary"
      color={data?.buttonTextColor}
      title={data?.buttonText}
      borderColor={data?.buttonColor}
      onClick={() => {
        if (data.videoLink) setShowYoutubeModal(true);
      }}
    >
      {data.videoLink && <StyledYoutubeIcon svg={Video} size={24} customColor={data?.buttonTextColor} />}
      {data?.buttonText}
    </StyledButton>
  );

  const wrapWithLink = (content: ReactNode, renderWrapper?: boolean) => {
    return (
      <StyledLink
        href={data?.urlLink}
        target={data?.urlLinkInNewWindow === 'true' ? '_blank' : ''}
        isWrapping={renderWrapper}
        data-testid={renderWrapper ? 'wrapper-link' : 'link'}
      >
        {content}
      </StyledLink>
    );
  };

  const conditionalLinkWrap = (renderLink: boolean, content: ReactNode) => {
    return renderLink ? wrapWithLink(content, true) : content;
  };

  return (
    <>
      {showYoutubeModal && <YoutubeModal youtubeId={data.videoLink} setShowModal={setShowYoutubeModal} />}

      <StyledSection
        style={{
          textAlign,
          alignItems,
        }}
        padding={getPaddingFromCms(data)}
        backgroundColor={data?.backgroundColor}
      >
        {conditionalLinkWrap(
          hasWrappingLink,
          <>
            <StyledImg src={image.url} alt={image.altText} />
            <StyledDiv
              style={{
                textAlign,
                alignItems,
              }}
              smallBanner={data.bannerType === 'SMALL'}
            >
              {data.title &&
                conditionalLinkWrap(
                  !hasWrappingLink && !!data.urlLink,
                  <StyledHeading
                    color={data?.titleTextColor as HeadingColors}
                    variant="h3"
                    label={data.bannerType === 'SMALL'}
                  >
                    <span data-testid="title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.title) }} />
                    {data.bannerType === 'SMALL' && data.buttonEnabled === 'true' && (
                      <StyledArrow svg={ArrowRight} size={12} />
                    )}
                  </StyledHeading>
                )}
              {data.preamble && (
                <StyledText
                  style={{ color: data?.preambleTextColor }}
                  type="body"
                  size={data.bannerType === 'MEDIUM' ? 'medium' : 'small'}
                  data-testid="preamble"
                >
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.preamble) }} />
                </StyledText>
              )}
              {isButtonEnabled && (data?.urlLink ? wrapWithLink(button) : button)}
            </StyledDiv>
          </>
        )}
      </StyledSection>
    </>
  );
};

export default VerticalBannerComponent;
