import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface ToolbarState {
  search: {
    isExpanded: boolean;
  };
  stickyToolbar: boolean;
}

const initialState: ToolbarState = {
  search: {
    isExpanded: false,
  },
  stickyToolbar: false,
};

export const toolbarSlice = createAppSlice({
  name: 'toolbar',
  initialState,
  reducers: (create) => ({
    setToolbarSearchIsExpanded: create.reducer((state, action: PayloadAction<boolean>) => {
      state.search.isExpanded = action.payload;
    }),
    setStickyToolbar: create.reducer((state, action: PayloadAction<boolean>) => {
      state.stickyToolbar = action.payload;
    }),
  }),
  selectors: {
    selectToolbar: (toolbar) => toolbar,
    selectStickyToolbar: (toolbar) => toolbar.stickyToolbar,
    selectToolbarSearchIsExpanded: (toolbar) => toolbar.search.isExpanded,
  },
});

// Selectors
export const { selectToolbar, selectStickyToolbar, selectToolbarSearchIsExpanded } = toolbarSlice.selectors;

// Actions
export const { setToolbarSearchIsExpanded, setStickyToolbar } = toolbarSlice.actions;
