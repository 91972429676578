import paths from '@constants/paths';

const prefixCategoryUrl = (children: Array<AccordionNavNodeType>) => {
  children.map((child) => {
    child.url = `${paths.CATEGORY}/${child.url}`;
    if (child.children.length) {
      prefixCategoryUrl(child.children);
    }
    return child;
  });
};
export default prefixCategoryUrl;
