import styled from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Icon from '@atoms/Icon/Icon';
import Config from '@config';
import Button from '@atoms/Button/Button';

export const StyledButton = styled(Button)`
  display: inline-flex;
  margin: ${rem(8)} ${rem(4)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin: ${rem(4)};
  }
`;

export const StyledText = styled.p`
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(13)};
`;

export const StyledAmount = styled.span`
  margin: 0 0 0 ${rem(8)};
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.darkerGray};
`;

export const StyledCloseIcon = styled(Icon)`
  margin-left: ${rem(8)};
`;
