import React from 'react';
import { StyledErrorMessage } from './Form.styles';

interface Props {
  name: string;
  children: React.ReactNode;
  className?: string;
}

const ErrorMessage = ({ name, children, className }: Props) => {
  return (
    <StyledErrorMessage role="alert" className={className} data-testid="input-error-message" id={`input-${name}-error`}>
      {children}
    </StyledErrorMessage>
  );
};

export default ErrorMessage;
