/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodAddressValidationResultViewModel } from '../dto';
// @ts-ignore
import { SuggestAddressResponse } from '../dto';
/**
 * PostalApi - axios parameter creator
 * @export
 */
export const PostalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get address suggestions for addressline
         * @param {string} addressline addressline
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestionsUsingGET: async (addressline: string, maxRows?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressline' is not null or undefined
            assertParamExists('getAddressSuggestionsUsingGET', 'addressline', addressline)
            const localVarPath = `/axfood/rest/suggest/addressline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (addressline !== undefined) {
                localVarQueryParameter['addressline'] = addressline;
            }

            if (maxRows !== undefined) {
                localVarQueryParameter['maxRows'] = maxRows;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get address suggestions
         * @param {string} query query
         * @param {string} [postalCode] postalCode
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestionsUsingGET1: async (query: string, postalCode?: string, maxRows?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getAddressSuggestionsUsingGET1', 'query', query)
            const localVarPath = `/axfood/rest/suggest/street`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (maxRows !== undefined) {
                localVarQueryParameter['maxRows'] = maxRows;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get locality suggestions
         * @param {string} query query
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalitySuggestionsUsingGET: async (query: string, maxRows?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getLocalitySuggestionsUsingGET', 'query', query)
            const localVarPath = `/axfood/rest/suggest/locality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (maxRows !== undefined) {
                localVarQueryParameter['maxRows'] = maxRows;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get postal code suggestions
         * @param {string} code code
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostalCodeSuggestionsUsingGET: async (code: string, maxRows?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getPostalCodeSuggestionsUsingGET', 'code', code)
            const localVarPath = `/axfood/rest/suggest/postalcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (maxRows !== undefined) {
                localVarQueryParameter['maxRows'] = maxRows;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate address line
         * @param {string} line line
         * @param {string} [suggestions] suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidatedAddressLineUsingGET: async (line: string, suggestions?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'line' is not null or undefined
            assertParamExists('getValidatedAddressLineUsingGET', 'line', line)
            const localVarPath = `/axfood/rest/validate/addressline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (line !== undefined) {
                localVarQueryParameter['line'] = line;
            }

            if (suggestions !== undefined) {
                localVarQueryParameter['suggestions'] = suggestions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate address
         * @param {string} [street] street
         * @param {string} [postalcode] postalcode
         * @param {string} [locality] locality
         * @param {string} [suggestions] suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidatedAddressUsingGET: async (street?: string, postalcode?: string, locality?: string, suggestions?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }

            if (postalcode !== undefined) {
                localVarQueryParameter['postalcode'] = postalcode;
            }

            if (locality !== undefined) {
                localVarQueryParameter['locality'] = locality;
            }

            if (suggestions !== undefined) {
                localVarQueryParameter['suggestions'] = suggestions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is delivery available for address line
         * @param {string} line line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeliveryAvailableForAddressLineUsingGET: async (line: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'line' is not null or undefined
            assertParamExists('isDeliveryAvailableForAddressLineUsingGET', 'line', line)
            const localVarPath = `/axfood/rest/delivery/addressline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (line !== undefined) {
                localVarQueryParameter['line'] = line;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is delivery available
         * @param {string} street street
         * @param {string} postalcode postalcode
         * @param {string} locality locality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeliveryAvailableUsingGET: async (street: string, postalcode: string, locality: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'street' is not null or undefined
            assertParamExists('isDeliveryAvailableUsingGET', 'street', street)
            // verify required parameter 'postalcode' is not null or undefined
            assertParamExists('isDeliveryAvailableUsingGET', 'postalcode', postalcode)
            // verify required parameter 'locality' is not null or undefined
            assertParamExists('isDeliveryAvailableUsingGET', 'locality', locality)
            const localVarPath = `/axfood/rest/delivery/delivery-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }

            if (postalcode !== undefined) {
                localVarQueryParameter['postalcode'] = postalcode;
            }

            if (locality !== undefined) {
                localVarQueryParameter['locality'] = locality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostalApi - functional programming interface
 * @export
 */
export const PostalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get address suggestions for addressline
         * @param {string} addressline addressline
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressSuggestionsUsingGET(addressline: string, maxRows?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressSuggestionsUsingGET(addressline, maxRows, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get address suggestions
         * @param {string} query query
         * @param {string} [postalCode] postalCode
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressSuggestionsUsingGET1(query: string, postalCode?: string, maxRows?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressSuggestionsUsingGET1(query, postalCode, maxRows, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get locality suggestions
         * @param {string} query query
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalitySuggestionsUsingGET(query: string, maxRows?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalitySuggestionsUsingGET(query, maxRows, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get postal code suggestions
         * @param {string} code code
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostalCodeSuggestionsUsingGET(code: string, maxRows?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostalCodeSuggestionsUsingGET(code, maxRows, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate address line
         * @param {string} line line
         * @param {string} [suggestions] suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValidatedAddressLineUsingGET(line: string, suggestions?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodAddressValidationResultViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValidatedAddressLineUsingGET(line, suggestions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate address
         * @param {string} [street] street
         * @param {string} [postalcode] postalcode
         * @param {string} [locality] locality
         * @param {string} [suggestions] suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValidatedAddressUsingGET(street?: string, postalcode?: string, locality?: string, suggestions?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodAddressValidationResultViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValidatedAddressUsingGET(street, postalcode, locality, suggestions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is delivery available for address line
         * @param {string} line line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDeliveryAvailableForAddressLineUsingGET(line: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDeliveryAvailableForAddressLineUsingGET(line, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is delivery available
         * @param {string} street street
         * @param {string} postalcode postalcode
         * @param {string} locality locality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDeliveryAvailableUsingGET(street: string, postalcode: string, locality: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDeliveryAvailableUsingGET(street, postalcode, locality, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostalApi - factory interface
 * @export
 */
export const PostalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostalApiFp(configuration)
    return {
        /**
         * 
         * @summary Get address suggestions for addressline
         * @param {string} addressline addressline
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestionsUsingGET(addressline: string, maxRows?: string, options?: any): AxiosPromise<SuggestAddressResponse> {
            return localVarFp.getAddressSuggestionsUsingGET(addressline, maxRows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get address suggestions
         * @param {string} query query
         * @param {string} [postalCode] postalCode
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestionsUsingGET1(query: string, postalCode?: string, maxRows?: string, options?: any): AxiosPromise<SuggestAddressResponse> {
            return localVarFp.getAddressSuggestionsUsingGET1(query, postalCode, maxRows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get locality suggestions
         * @param {string} query query
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalitySuggestionsUsingGET(query: string, maxRows?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getLocalitySuggestionsUsingGET(query, maxRows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get postal code suggestions
         * @param {string} code code
         * @param {string} [maxRows] maxRows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostalCodeSuggestionsUsingGET(code: string, maxRows?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPostalCodeSuggestionsUsingGET(code, maxRows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate address line
         * @param {string} line line
         * @param {string} [suggestions] suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidatedAddressLineUsingGET(line: string, suggestions?: string, options?: any): AxiosPromise<AxfoodAddressValidationResultViewModel> {
            return localVarFp.getValidatedAddressLineUsingGET(line, suggestions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate address
         * @param {string} [street] street
         * @param {string} [postalcode] postalcode
         * @param {string} [locality] locality
         * @param {string} [suggestions] suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidatedAddressUsingGET(street?: string, postalcode?: string, locality?: string, suggestions?: string, options?: any): AxiosPromise<AxfoodAddressValidationResultViewModel> {
            return localVarFp.getValidatedAddressUsingGET(street, postalcode, locality, suggestions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is delivery available for address line
         * @param {string} line line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeliveryAvailableForAddressLineUsingGET(line: string, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.isDeliveryAvailableForAddressLineUsingGET(line, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is delivery available
         * @param {string} street street
         * @param {string} postalcode postalcode
         * @param {string} locality locality
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDeliveryAvailableUsingGET(street: string, postalcode: string, locality: string, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.isDeliveryAvailableUsingGET(street, postalcode, locality, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostalApi - object-oriented interface
 * @export
 * @class PostalApi
 * @extends {BaseAPI}
 */
export class PostalApi extends BaseAPI {
    /**
     * 
     * @summary Get address suggestions for addressline
     * @param {string} addressline addressline
     * @param {string} [maxRows] maxRows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public getAddressSuggestionsUsingGET(addressline: string, maxRows?: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).getAddressSuggestionsUsingGET(addressline, maxRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get address suggestions
     * @param {string} query query
     * @param {string} [postalCode] postalCode
     * @param {string} [maxRows] maxRows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public getAddressSuggestionsUsingGET1(query: string, postalCode?: string, maxRows?: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).getAddressSuggestionsUsingGET1(query, postalCode, maxRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get locality suggestions
     * @param {string} query query
     * @param {string} [maxRows] maxRows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public getLocalitySuggestionsUsingGET(query: string, maxRows?: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).getLocalitySuggestionsUsingGET(query, maxRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get postal code suggestions
     * @param {string} code code
     * @param {string} [maxRows] maxRows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public getPostalCodeSuggestionsUsingGET(code: string, maxRows?: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).getPostalCodeSuggestionsUsingGET(code, maxRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate address line
     * @param {string} line line
     * @param {string} [suggestions] suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public getValidatedAddressLineUsingGET(line: string, suggestions?: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).getValidatedAddressLineUsingGET(line, suggestions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate address
     * @param {string} [street] street
     * @param {string} [postalcode] postalcode
     * @param {string} [locality] locality
     * @param {string} [suggestions] suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public getValidatedAddressUsingGET(street?: string, postalcode?: string, locality?: string, suggestions?: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).getValidatedAddressUsingGET(street, postalcode, locality, suggestions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is delivery available for address line
     * @param {string} line line
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public isDeliveryAvailableForAddressLineUsingGET(line: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).isDeliveryAvailableForAddressLineUsingGET(line, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is delivery available
     * @param {string} street street
     * @param {string} postalcode postalcode
     * @param {string} locality locality
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public isDeliveryAvailableUsingGET(street: string, postalcode: string, locality: string, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).isDeliveryAvailableUsingGET(street, postalcode, locality, options).then((request) => request(this.axios, this.basePath));
    }
}
