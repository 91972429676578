import { ReactNode, useEffect, useRef } from 'react';
import { StyledGrid } from './Grid.styles';

interface Props {
  children?: ReactNode;
  className?: string;
  passRef?: (ref: HTMLDivElement | null) => void;
  theme?: GridThemeType;
}

const Grid = ({ className, children, passRef, theme }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Ugly solution for making sure ref.current exists
    setTimeout(() => {
      if (passRef) passRef(ref.current);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledGrid ref={ref} className={className} isPdp={theme === 'pdp'} data-id="grid" data-testid="grid">
      {children}
    </StyledGrid>
  );
};

export default Grid;
