import styled, { css } from 'styled-components';
import Link from '@atoms/Link/Link';
import { rem } from 'polished';
import variables from '@styles/variables';
import Spinner from '@molecules/Spinner/Spinner';
import Config from '@config';

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const StyledNotFoundText = styled.div`
  padding-top: ${rem(24)};
  padding-bottom: ${rem(24)};
`;

export const StyledProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    flex-direction: row;
  }
`;

export const StyledProductDetailsEnergyDeclaration = styled.div`
  border-top: ${rem(1)} solid ${variables.colors.mediumGray};
  padding-top: ${rem(27)};
  margin-top: ${rem(27)};
`;

export const StyledProductDetailsHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};
`;

export const StyledProductDetailsRight = styled.div<{ isPage?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${(props) =>
    props.isPage &&
    css`
      ${Config.MEDIA.IS_MOBILE} {
        border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
      }
    `}
`;

export const StyledProductDetailsRightTop = styled.div`
  padding: ${rem(20)};
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(35)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    padding: ${rem(40)};
  }
`;

export const StyledProductDetailsManufacturerWrapper = styled.div`
  margin-bottom: ${rem(20)};
`;

export const StyledProductDetailsManufacturer = styled.span`
  font-size: ${rem(13)};
  margin-right: ${rem(5)};
  text-transform: uppercase;
`;

export const StyledProductDetailsManufacturerLink = styled(Link)`
  font-size: ${rem(13)};
  margin-right: ${rem(5)};
  text-transform: uppercase;
`;

export const StyledProductDetailsManufacturerVolume = styled.span`
  font-size: ${rem(13)};
  color: ${variables.colors.darkerGray};
`;

export const StyledProductDetailsQuantityField = styled.div`
  width: 100%;
  margin-bottom: ${rem(50)};
  max-width: ${rem(180)};
`;

export const StyledProductDetailsRightBottom = styled.div`
  width: 100%;
`;

export const StyledProductDetailsPriceAndPromotion = styled.div`
  margin-bottom: ${rem(16)};
`;

export const BreadcrumbsWrapper = styled.div<{ isPage?: boolean }>`
  margin-bottom: ${rem(18)};

  ${(props) =>
    props.isPage &&
    css`
      padding: ${rem(20)};
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: ${rem(40)};
        margin: 0;
      }
    `}
`;
