import React, { ReactNode } from 'react';
import ABTestContext from '../ABTestContext';
import useSymplifyExperiment from '@utility/ABTest/Symplify/useSymplifyExperiment';

interface ExperimentProps {
  testName: string;
  children: ReactNode;
  defaultVariant: string;
}

const SymplifyExperiment = ({ testName, children, defaultVariant }: ExperimentProps) => {
  const variant = useSymplifyExperiment(testName, defaultVariant);

  return <ABTestContext.Provider value={variant}>{children}</ABTestContext.Provider>;
};

export default SymplifyExperiment;
