// to prevent differences in date helpers, locale is set to 'sv-SE'
// update this if we should start supporting more languanges

const LOCALE = 'sv-SE';

/**
 * @summary checks if a Date is valid
 * @param date
 * @returns boolean
 */
export const isValidDate = (date: Date) => Boolean(date.getDate());

/**
 * @summary helper to replace moment.parse('xx-xx-xxxx')
 * Options cheatsheet: https://devhints.io/wip/intl-datetime
 * @param someDate
 * @param options
 */
export const dateToLocale = (someDate: string | number | Date, options: Intl.DateTimeFormatOptions): string => {
  const date = new Date(someDate);
  const validDate = isValidDate(date);
  return validDate ? date.toLocaleDateString(LOCALE, options) : '';
};

/**
 * @summary helper to replace moment(date).format('YY-MM-DD')
 * @example '2020-11-28 17:00:00' => '20-11-28'
 * @param date
 */
export const dateToShortNumeric = (date?: number): string => {
  if (!date) return '';
  return dateToLocale(date, {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  });
};

/**
 * @summary helper to replace moment(date).format('YYYY-MM-DD')
 * @example '2020-11-28 17:00:00' => '2020-11-28'
 * @param date
 */
export const dateToNumeric = (date: string | number | Date): string => {
  return dateToLocale(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

/**
 * @summary helper to replace moment(date).format('LT')
 * @example '2020-11-28 17:00:00' => '17:00' / 'kl 17:00'
 * @param someDate
 * @param addPrefix
 */
export const dateToHourAndMinutes = (someDate?: number | string, addPrefix?: boolean): string => {
  if (!someDate) return '';
  const date = new Date(someDate);
  const validDate = isValidDate(date);
  return validDate
    ? `${addPrefix ? 'kl ' : ''}${date.toLocaleString(LOCALE, { hour: 'numeric', minute: 'numeric' })}`
    : '';
};

/**
 * @summary helper to replace moment(date).format('DD/MM')
 * @example '2020-11-28 17:00:00' => '28/11'
 * @param someDate
 */
export const dateToDaysAndMonths = (someDate?: number | string): string => {
  if (!someDate) return '';
  const date = new Date(someDate);
  const validDate = isValidDate(date);
  return validDate ? date.toLocaleString(LOCALE, { day: 'numeric', month: 'numeric' }).replace('-', '/') : '';
};

/**
 * @summary helper to replace moment(date).format('YYYY-MM-DD [kl.] HH:mm')
 * @example '2020-11-28 17:00:00' => '2020-11-28 kl 17:00'
 * @param date
 * @returns string
 */
export const fullDateWithTime = (date?: number | string): string => {
  return (date && `${dateToNumeric(date)} kl ${dateToHourAndMinutes(date)}`) || '';
};

/**
 * @summary helper function that formats a timestamp number into a date string
 * in the format "tisdag 20 jan. kl. 00:05".
 * @example: 1638316800 => "tisdag 20 jan. kl. 00:05"
 * @param timestamp
 * @returns string
 */

export const formatToLongDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const validDate = isValidDate(date);
  if (!validDate) return '';
  const formattedDate = dateToLocale(timestamp, {
    day: 'numeric',
    month: 'short',
    weekday: 'long',
  });

  return `${formattedDate.replace('.', '')} kl. ${dateToHourAndMinutes(timestamp)}`;
};

/**
 * @summary helper function that formats a timestamp number into a date string
 * in the format "20 jan 00:05".
 * @example: 1638316800 => "20 jan 00:05"
 * @param timestamp
 * @returns string
 */
export const formatToShortDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const validDate = isValidDate(date);
  if (!validDate) return '';
  const formattedDate = dateToLocale(timestamp, {
    day: 'numeric',
    month: 'short',
  });

  return `${formattedDate.replace('.', '')} ${dateToHourAndMinutes(timestamp)}`;
};

/**
 * @summary helper to replace moment(date).format('DD MMM')
 * @example '2020-11-28 17:00:00' => "28 nov"
 * @param date
 * @returns string
 */
export const dateToShortMonth = (date: string | number | Date): string => {
  return dateToLocale(date, { day: 'numeric', month: 'short' }).replace('.', '');
};

/**
 * @summary helper to replace moment(date).format('DD MMM YYYY')
 * @example '2020-11-28 17:00:00' => "28 nov 2023"
 * @param date
 * @returns string
 */
export const dateToShortMonthAndYear = (date: string | number | Date): string => {
  return dateToLocale(date, { day: 'numeric', month: 'short', year: 'numeric' }).replace('.', '');
};

/**
 * @summary checks if a date is today
 * @param someDate
 * @returns boolean
 */
export const isToday = (someDate: Date | number): boolean => {
  const today = new Date();
  const dateToCheck = typeof someDate === 'number' ? new Date(someDate) : someDate;
  const validDate = isValidDate(dateToCheck);

  return (
    validDate &&
    dateToCheck.getDate() === today.getDate() &&
    dateToCheck.getMonth() === today.getMonth() &&
    dateToCheck.getFullYear() === today.getFullYear()
  );
};

/**
 * @summary checks if a date is tomorrow
 * @param someDate - Date
 * @returns boolean
 */
export const isTomorrow = (someDate: Date): boolean => {
  const tomorrow = new Date();
  const validDate = isValidDate(someDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return validDate && tomorrow.toDateString() === someDate.toDateString();
};

/**
 * @summary checks if a date is in the past
 * @param someDate - Date
 * @returns boolean
 */
export const isDateInPast = (someDate: Date): boolean => {
  const today = new Date();
  const validDate = isValidDate(someDate);
  return validDate && someDate < today;
};

/**
 * @summary returns first date current month
 * @returns Date
 */
export const getFirstDateOfThisMonth = () => {
  const date = new Date();
  date.setDate(1);
  return date;
};

/**
 * @summary adds x amount of days to date
 * @param date
 * @param days
 * @returns string on format YYYY-MM-DD
 */
export const addDays = (date: Date | number | string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 * @summary get day name
 * @param date
 * @returns string on format 'Friday'
 */
export const getDayName = (date: Date | number | string) => {
  const localDate = new Date(date);
  return localDate.toLocaleDateString(LOCALE, { weekday: 'long' });
};

/**
 * @summary create a date range
 * @param from
 * @param to
 * @returns a date range object
 */
export const range = (from: string | number | Date, to: string | number | Date) => {
  const localRange = { from: new Date(from), to: new Date(to) };
  const difference = localRange.to.getTime() - localRange.from.getTime();
  const amountOfDays = Math.ceil(difference / (1000 * 3600 * 24));
  return {
    from: localRange.from,
    to: localRange.to,
    daysDiff: amountOfDays,
    getDaysArray: () => {
      const localArray = [];
      for (let i = 0; i < amountOfDays; i += 1) {
        localArray.push(addDays(localRange.from, i));
      }
      return localArray;
    },
  };
};

/**
 * @summary formats two timestamps into a formatted date range
 * @param from
 * @param to
 * @example 1670670000000, 1670675400000 => 'lör 10/12 12:00-13:30'
 * @returns a date range object
 */
export const formatIntervalOnDate = (from: number, to?: number): string => {
  const fromTime = dateToHourAndMinutes(from, false);
  const formattedDate = dateToLocale(from, {
    day: 'numeric',
    month: 'numeric',
    weekday: 'short',
  });

  if (to && from !== to) {
    const toTime = dateToHourAndMinutes(to, false);
    return `${formattedDate} ${fromTime}-${toTime}`;
  } else {
    return `${formattedDate} ${fromTime}`;
  }
};
