import { ReactNode, useEffect, useState } from 'react';
import { FoldOutAnimation } from './TwoTilesFoldoutComponent.styles';

interface Props {
  show: boolean;
  children: ReactNode;
  callback: (show: boolean) => void;
}

const Foldout = ({ show, children, callback }: Props) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
    callback(show);
  };

  return shouldRender ? (
    <FoldOutAnimation show={show} onAnimationEnd={onAnimationEnd}>
      {children}
    </FoldOutAnimation>
  ) : null;
};

export default Foldout;
