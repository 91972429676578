import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { AxfoodStoreInfoViewModel } from '@occ/api-client';

export const trackStoreFinderSearchField = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'find_store',
    action: 'search_click',
    label: '',
    value: 0,
  });
};

export const trackViewAllStoresList = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'find_store',
    action: 'see_all_stores',
    label: '',
    value: 0,
  });
};

export const trackGoToStorePage = (storeName: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'find_store',
    action: 'see_store_page',
    label: storeName,
    value: 0,
  });
};

export const trackFindClosestStore = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'find_store',
    action: 'find_closest_store',
    label: '',
    value: 0,
  });
};

export const trackDisabledLocationServices = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'find_store',
    action: 'location_service_not_activated',
    label: '',
    value: 0,
  });
};

export const trackStoreSuggestionClick = (store: AxfoodStoreInfoViewModel) => {
  pushGTMEvent({ event: 'Track', category: 'find_store', action: 'search_store', label: store.name, value: 0 });
};

export const trackMapClick = (store: AxfoodStoreInfoViewModel) => {
  pushGTMEvent({ event: 'Track', category: 'find_store', action: 'map_click', label: store.name, value: 0 });
};

export const trackStoreSuggestionsEmpty = () => {
  pushGTMEvent({ event: 'Track', category: 'find_store', action: 'store_not_found', label: '', value: 0 });
};

export const trackClickInStoreDetail = (label: string) => {
  pushGTMEvent({ event: 'Track', category: 'find_store', action: 'store_page_link_click', label, value: 0 });
};
