import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import Icon from '@atoms/Icon/Icon';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import ProductListItem from '@molecules/ProductListItem/ProductListItem';
import { StyledAddToCartButton } from '@molecules/AddToCart/AddToCart.styles';
import Config from '@config';

export const StyledWishlistWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  flex-direction: column;
  margin-top: ${rem(24)};
  text-align: left;
`;

export const StyledText = styled(Text)`
  text-transform: uppercase;
`;

export const StyledWishlistWrapperTopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledWishlistRow = styled(Button)<{ isSelected: boolean }>`
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  padding: ${rem(20)};
  text-align: left;
  display: flex;
  align-items: center;
  height: ${rem(64)};
  border-radius: ${rem(4)};
  justify-content: unset;

  ${(props) =>
    props.isSelected &&
    css`
      border: ${rem(2)} solid ${variables.colors.highlight};
      margin: 0 ${rem(-1)};
    `}
  &:hover {
    @media (hover: hover) and (pointer: fine) {
      border: ${rem(2)} solid ${variables.colors.highlight};
      margin: 0 ${rem(-1)};
    }
  }
`;

export const StyledWishlistRowText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${rem(16)};
`;

export const StyledProductListItem = styled(ProductListItem)`
  text-align: left;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: auto;

  ${StyledAddToCartButton} {
    &:not(:first-child) {
      background-color: ${variables.colors.lightGray};

      svg {
        color: ${variables.colors.lighterBlack};
      }
    }
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${rem(20)};
  position: sticky;
  bottom: 0;
  left: 0;
  padding: ${rem(20)};
  width: 100%;
  box-shadow: 0 ${rem(8)} ${rem(28)} 0 ${rgba(variables.colors.lighterBlack, 0.5)};
  background-color: ${variables.colors.white};
`;

export const StyledModalContentWrapper = styled.div`
  padding: 0 ${rem(20)} ${rem(20)};
  flex: 1;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: 0 ${rem(40)} ${rem(40)};
  }
`;

export const StyledButton = styled(Button)`
  flex: 1;
`;
