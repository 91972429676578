import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledContainer = styled.div<{
  noPadding?: boolean;
  addPaddingIfCartOrMenuOpen?: boolean;
  restrictWidthToMediumLayout: boolean;
}>`
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: ${(props) =>
    props.noPadding ? `${rem(variables.size.containerPadding)} 0` : rem(variables.size.containerPadding)};
  overflow-x: hidden;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${variables.container.xs};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    overflow-x: unset;
    max-width: ${variables.container.sm};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    padding: 0 0 ${rem(variables.size.containerPadding)} 0;
    max-width: ${variables.container.md};
    ${(props) =>
      props.addPaddingIfCartOrMenuOpen === true &&
      css`
        padding: 0 ${rem(variables.size.containerPadding)} ${rem(variables.size.containerPadding)};
      `}
  }

  ${(props) =>
    !props.restrictWidthToMediumLayout &&
    css`
      ${Config.MEDIA.FROM_DESKTOP_MEDIUM} {
        max-width: ${variables.container.lg};
      }

      ${Config.MEDIA.FROM_DESKTOP_LARGE} {
        max-width: ${variables.container.lg};
      }

      ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
        max-width: ${variables.container.xl};
      }
    `}
`;
