import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface ModalState {
  changeOrder: {
    visible: boolean;
  };
  orderCloseTimePassed: {
    visible: boolean;
  };
  emptyCart: {
    visible: boolean;
  };
}

const initialState: ModalState = {
  changeOrder: { visible: false },
  orderCloseTimePassed: { visible: false },
  emptyCart: { visible: false },
};

export const modalSlice = createAppSlice({
  name: 'modals',
  initialState,
  reducers: (create) => ({
    setChangeOrderModalVisibility: create.reducer((state, action: PayloadAction<boolean>) => {
      state.changeOrder.visible = action.payload;
    }),
    setOrderCloseTimePassedModalVisibility: create.reducer((state, action: PayloadAction<boolean>) => {
      state.orderCloseTimePassed.visible = action.payload;
    }),
    setEmptyCartModalVisibility: create.reducer((state, action: PayloadAction<boolean>) => {
      state.emptyCart.visible = action.payload;
    }),
  }),
  selectors: {
    selectModals: (modal) => modal,
    selectChangeOrderModal: (modal) => modal.changeOrder,
    selectOrderCloseTimePassedModal: (modal) => modal.orderCloseTimePassed,
    selectEmptyCartModal: (modal) => modal.emptyCart,
  },
});

// Actions
export const { setChangeOrderModalVisibility, setEmptyCartModalVisibility, setOrderCloseTimePassedModalVisibility } =
  modalSlice.actions;

// Selectors
export const { selectOrderCloseTimePassedModal, selectEmptyCartModal, selectChangeOrderModal } = modalSlice.selectors;
