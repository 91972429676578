import type { ComponentWsDTO, ContentSlotWsDTO } from '@occ/api-client';

export interface ContentSlotWsDTOWithComponent extends ContentSlotWsDTO {
  component: Array<ComponentWsDTO>;
}

interface MappedSlotData {
  [key: string]: ContentSlotWsDTOWithComponent;
}

/**
 * ContentSlotWsDTO[], Array<string> -> MappedSlotData
 * @param slots
 * @param slotArray
 */
const collectSlots = (slots: ContentSlotWsDTO[] = [], slotArray: Array<string>) => {
  let contentSlotsObject: MappedSlotData = {};

  const contentSlotsMap = new Map(slots?.map((s) => [s.position, s]));

  slotArray.forEach((slot) => {
    const contentSlot = contentSlotsMap.get(slot);
    const component = contentSlot?.components?.component || [];
    if (contentSlot) contentSlotsObject[slot] = { ...contentSlot, component };
  });

  return contentSlotsObject;
};

export default collectSlots;
