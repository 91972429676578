import React, { useImperativeHandle, useRef, forwardRef } from 'react';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import ScreenReaderMessage from '@atoms/ScreenReaderMessage/ScreenReaderMessage';
import ErrorMessage from '@molecules/Form/ErrorMessage';
import useInputValidator from '@helpers/form/useInputValidator';
import { StyledTextArea, StyledTextAreaLabel, StyledTextAreaLabelWrapper } from './TextArea.styles';
import Info from '@icons/info.svg';

type VariantType = 'box' | 'line' | 'rounded';

export interface Props
  extends Pick<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    | 'aria-autocomplete'
    | 'aria-haspopup'
    | 'aria-label'
    | 'autoComplete'
    | 'className'
    | 'disabled'
    | 'readOnly'
    | 'inputMode'
    | 'maxLength'
    | 'minLength'
    | 'name'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'onKeyDown'
    | 'onKeyUp'
    | 'onPaste'
    | 'placeholder'
    | 'required'
    | 'role'
    | 'tabIndex'
    | 'value'
  > {
  customValidity?: string;
  customValidator?: (value: string) => string;
  takeover?: boolean;
  variant?: VariantType;
  className?: string;
  placeholder?: string;
  onCloseTakeover?: () => void;
  clearable?: boolean;
  onClear?: () => void;
  label?: string;
  labelTooltip?: string;
  resize?: boolean;
}

const TextArea = forwardRef(
  (
    {
      name,
      variant = 'box',
      className,
      placeholder,
      onFocus,
      onChange,
      onKeyDown,
      onKeyUp,
      onPaste,
      onBlur,
      label,
      labelTooltip,
      value,
      minLength,
      maxLength,
      required,
      customValidator,
      customValidity,
      onCloseTakeover,
      clearable,
      onClear,
      disabled,
      readOnly,
      inputMode,
      autoComplete,
      resize = true,
      tabIndex,
      role,
      ...props
    }: Props,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => inputRef.current!, [inputRef]);

    const [isDirty, customValidityState] = useInputValidator(inputRef, value as string, customValidity, {
      required,
      minLength,
      maxLength,
      customValidator,
    });

    return (
      <div>
        <StyledTextAreaLabelWrapper>
          {label && (
            <StyledTextAreaLabel>
              {label}
              {labelTooltip && <ScreenReaderMessage>{labelTooltip}</ScreenReaderMessage>}
            </StyledTextAreaLabel>
          )}
          {labelTooltip && (
            <IconTooltip
              content={labelTooltip}
              iconProps={{ svg: Info, size: 16 }}
              align="center"
              position="top"
              color="black"
            />
          )}
        </StyledTextAreaLabelWrapper>
        <StyledTextArea
          role={role}
          name={name}
          ref={inputRef}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onPaste={onPaste}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          aria-label={props['aria-label'] || label}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          inputMode={inputMode}
          autoComplete={autoComplete}
          variant={variant}
          className={className}
          resize={resize}
          isDirty={isDirty}
          aria-describedby={customValidityState && name ? `input-${name}-error` : undefined}
          tabIndex={tabIndex}
        />
        {isDirty && name && customValidityState && <ErrorMessage name={name}>{customValidityState}</ErrorMessage>}
      </div>
    );
  }
);
TextArea.displayName = 'TextArea';

export default TextArea;
