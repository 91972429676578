import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import { gridContainerSizeType } from './GridContainer';

const StyledGridContainer = styled.div<{ addPadding?: boolean; noPadding?: boolean; size: gridContainerSizeType }>`
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 ${(props) => (props?.noPadding ? rem(0) : rem(20))};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: 0 ${rem(34)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    padding: 0;

    ${(props) =>
      props.size === 'small' &&
      css`
        width: ${rem(1036)};
      `};

    ${(props) =>
      props.size === 'large' &&
      css`
        width: ${rem(940)};
      `};
  }
  ${Config.MEDIA.FROM_DESKTOP} {
    ${(props) =>
      props.addPadding === true &&
      css`
        padding: 0 ${rem(20)};
      `};

    ${(props) =>
      props.size === 'large' &&
      css`
        width: ${rem(1032)};
      `};
  }
  ${Config.MEDIA.FROM_DESKTOP_LARGE} {
    ${(props) =>
      props.size === 'large' &&
      css`
        width: ${rem(1224)};
      `};
  }
  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    ${(props) =>
      props.size === 'small' &&
      css`
        width: ${rem(968)};
      `};

    ${(props) =>
      props.size === 'large' &&
      css`
        width: ${rem(1464)};
      `};
  }
`;
export default StyledGridContainer;
