import Button from '@atoms/Button/Button';
import ProgressBar from '@atoms/ProgressBar/ProgressBar';
import Text from '@atoms/Text/Text';
import Spinner from '@molecules/Spinner/Spinner';
import styled from 'styled-components';
import { rem } from 'polished';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  total: number;
  limit: number;
  pageSize?: number;
  onClick: () => void;
  showProgress: boolean;
  loading?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: ${rem(180)};
`;

const Info = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const LoadMoreBtn = styled(Button)`
  min-width: ${rem(112)};
  margin-top: ${rem(16)};
`;

const LoadMore = ({ total, limit, pageSize, onClick, loading = false, showProgress }: Props) => {
  const { t } = useTranslation('loadMore');

  return limit < total ? (
    <Wrapper data-testid="loadmore-wrapper">
      <Content>
        {showProgress && (
          <>
            <Info type="subhead" size="small">
              {t('showing', { x: limit, y: total })}
            </Info>
            <ProgressBar progress={limit} total={total} />
          </>
        )}
        <LoadMoreBtn theme="primary" onClick={onClick} data-testid="load-more-btn" disabled={loading}>
          {loading ? (
            <Spinner color="white" />
          ) : (
            t(`${pageSize !== undefined && limit + pageSize < total ? 'showMore' : 'showAll'}`)
          )}
        </LoadMoreBtn>
      </Content>
    </Wrapper>
  ) : null;
};

export default LoadMore;
