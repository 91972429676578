import { Dispatch, SetStateAction, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PAGE_SIZES from '@constants/pageSizes';
import Config from '@config';

export default function useResponsiveProductLimit(
  pageName: string
): [number, Dispatch<SetStateAction<number>>, () => number] {
  const isMobile = useMediaQuery({ query: Config.BREAKPOINTS.IS_MOBILE });
  const fromDesktop = useMediaQuery({ query: Config.BREAKPOINTS.FROM_DESKTOP });

  const getPageLimit = () => {
    if (isMobile) {
      return PAGE_SIZES.PAGE[pageName as keyof typeof PAGE_SIZES.PAGE].SIZE.MOBILE;
    }
    if (fromDesktop) {
      return PAGE_SIZES.PAGE[pageName as keyof typeof PAGE_SIZES.PAGE].SIZE.DESKTOP;
    }
    return PAGE_SIZES.PAGE[pageName as keyof typeof PAGE_SIZES.PAGE].SIZE.TABLET;
  };

  const [totalVisibleItems, setTotalVisibleItems] = useState(getPageLimit());

  return [totalVisibleItems, setTotalVisibleItems, getPageLimit];
}
