import { createContext, useContext, useMemo } from 'react';
import useCustomRouter from '@hooks/useCustomRouter';

export const smartEditStorageKey = 'smartedit-sessions';

const toggleBodyClasses = (enable: boolean, uid: string, uuid: string, catalogVersion?: string) => {
  if (enable) {
    document.body.classList.add(`smartedit-page-uid-${uid}`);
    document.body.classList.add(`smartedit-page-uuid-${uuid}`);
    document.body.classList.add(`smartedit-catalog-version-uuid-${catalogVersion}`);
  } else {
    document.body.classList.remove(`smartedit-page-uid-${uid}`);
    document.body.classList.remove(`smartedit-page-uuid-${uuid}`);
    document.body.classList.remove(`smartedit-catalog-version-uuid-${catalogVersion}`);
  }
};

export const SmartEditContext = createContext<{ catalogVersionUuid?: string }>({});

const useSmartEdit = () => {
  const router = useCustomRouter();
  const isSmartEditEnabled = useMemo(() => !!router.query.cmsTicketId, [router.query]);
  const catalogVersionUuid = useContext(SmartEditContext).catalogVersionUuid;

  return {
    catalogVersionUuid,
    isSmartEditEnabled,
    toggleBodyClasses,
  };
};

export default useSmartEdit;
