import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import CmsContentSlot from '@atoms/CmsContentSlot/CmsContentSlot';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};

  ${Config.MEDIA.FROM_DESKTOP} {
    flex-direction: row;
    gap: ${rem(120)};
    justify-content: center;
  }
`;

export const StyledRightContentWrapper = styled.div<{ hideSlot?: boolean; addMarginTop: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};

  margin-top: ${(props) => (props.addMarginTop ? rem(20) : 0)};

  ${Config.MEDIA.FROM_DESKTOP} {
    min-width: ${rem(240)};
    max-width: ${rem(240)};
    margin-top: ${(props) => (props.addMarginTop ? rem(40) : 0)};
    gap: ${rem(40)};
  }

  ${(props) =>
    props.hideSlot &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        display: none;
      }
      ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
        display: flex;
      }
    `}
`;

export const StyledSlot = styled(CmsContentSlot)`
  width: 100%;
`;

export const StyledMainSlot = styled(CmsContentSlot)<{ hasRightContent: boolean; addMarginTop: boolean }>`
  width: 100%;
  margin-top: ${(props) => (props.addMarginTop ? rem(20) : 0)};

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-top: ${(props) => (props.addMarginTop ? rem(40) : 0)};
  }
  ${(props) =>
    props.hasRightContent &&
    css`
      ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
        max-width: ${rem(720)};
      }
    `}
`;
