/**
 * Used when data is pushed in from mimer/spss to a product banner
 */

import axios, { AxiosResponse, CancelToken } from 'axios';

const getTwoTileProductList = (componentUid: string, cancelToken: CancelToken): Promise<AxiosResponse<any>> => {
  const API_URL = `/twoTilesButtonAndProductBannerComponent/${componentUid}`;
  return axios.get(API_URL, { cancelToken });
};
export default getTwoTileProductList;
