import TwoTilesComponent from '@molecules/TwoTilesComponent/TwoTilesComponent';
import { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import logger from '@logger';

interface Props {
  data: TwoTilesComponentType;
  context: SlotContextType;
}

const TrackedTwoTilesComponent = ({ data, context }: Props) => {
  const logImpression = () => {
    pushGTMEcommerceEvent({
      event: EcommerceEvent.VIEW_PROMOTION,
      ecommerce: {
        promotion_id: data.name || 'bricka',
        creative_name: 'bricka',
        creative_slot: `${context.slotName} ${context.slotPosition}`,
      },
    });
  };

  const handleError = (error: Error) => {
    logger.error({ error: 'TwoTilesComponent tracking impression failed', message: error.message });
  };

  const onClick = () => {
    pushGTMEcommerceEvent({
      event: EcommerceEvent.SELECT_PROMOTION,
      ecommerce: {
        promotion_id: data.name || 'bricka',
        creative_name: 'bricka',
        creative_slot: `${context.slotName} ${context.slotPosition}`,
      },
    });
  };

  return (
    <TwoTilesComponent
      data={data}
      onClick={onClick}
      trackingObject={{ logImpression, handleError, contentId: data.name || 'bricka' }}
    />
  );
};

export default TrackedTwoTilesComponent;
