import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import Config from '@config';
import { rem } from 'polished';

export const StyledRelatedProductsBeam = styled.div<{ isPage?: boolean }>`
  padding: ${rem(34)} 0 ${rem(40)};

  ${(props) =>
    !props.isPage &&
    css`
      background-color: ${variables.colors.lighterGray};
    `}

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(34)} ${rem(34)} ${rem(40)} ${rem(34)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    padding: ${rem(34)} ${rem(40)} ${rem(40)} ${rem(40)};
  }
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${rem(27)};
  padding: 0 ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: 0;
  }
`;
