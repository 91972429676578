import { StyledSelectBoxIcon, StyledSelectBoxText } from './Select.styles';
import { SizeType, TextType } from '@atoms/Text/Text';
import AccordionDown from '@icons/accordion-down_small.svg';

interface Props {
  currentSelection: OptionType;
  type?: TextType;
  size?: SizeType;
}

const SelectBox = ({ currentSelection, type = 'body', size = 'medium' }: Props) => {
  return (
    <>
      <StyledSelectBoxText color="black" type={type} size={size} title={currentSelection.label}>
        {currentSelection.label}
      </StyledSelectBoxText>
      <StyledSelectBoxIcon svg={AccordionDown} size={12} />
    </>
  );
};

export default SelectBox;
