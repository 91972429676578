import pushGTMEvent, { EcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackImageGridComponent = (name: string) => {
  pushGTMEvent({
    event: EcommerceEvent.SELECT_PROMOTION,
    ecommerce: {
      source: 'ecommerce',
      currency: 'SEK',
      promotion_id: name,
      creative_name: 'image_grid_component',
      creative_slot: '',
    },
  });
};
