import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Icon from '@atoms/Icon/Icon';
import variables from '@styles/variables';

export const StyledIcon = styled(Icon)`
  transform: rotate(-90deg);
  margin: 0 ${rem(8)};
`;

export const StyledBreadCrumbWrapper = styled.div`
  margin-top: ${rem(0)};
  margin-bottom: ${rem(28)};

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-bottom: ${rem(40)};
  }
`;

export const StyledBreadCrumbText = styled.span<{ lastItem?: boolean }>`
  font-size: ${rem(13)};

  ${(props) =>
    props.lastItem &&
    css`
      font-family: ${variables.fonts.fontGothamBook};
      color: ${variables.colors.darkerGray};
    `}

  ${(props) =>
    !props.lastItem &&
    css`
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: text-bottom;
      max-width: ${rem(97)};
    `}
`;
