import React, { ComponentProps } from 'react';
import { AddProductQueueProvider } from '@hooks/useAddProductQueue';
import { SnackbarProvider } from '@hooks/useSnackbar';
import { UserAgentProvider } from '@hooks/useUserAgent';
import SymplifyContextProvider from '@utility/ABTest/Symplify/SymplifyContextProvider';
import { StoreProvider } from './StoreProvider';

const AppContextProvider = ({ children }: ComponentProps<any>) => {
  return (
    <StoreProvider>
      <SymplifyContextProvider>
        <SnackbarProvider>
          <UserAgentProvider>
            <AddProductQueueProvider>{children}</AddProductQueueProvider>
          </UserAgentProvider>
        </SnackbarProvider>
      </SymplifyContextProvider>
    </StoreProvider>
  );
};

export default AppContextProvider;
