import styled from 'styled-components';
import Config from '@config';
import { rem } from 'polished';

const StyledGridRow = styled.div<{ noGap: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${(props) => (props.noGap ? rem(0) : rem(20))};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-columns: repeat(12, 1fr);
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    grid-gap: ${(props) => (props.noGap ? rem(0) : rem(24))};
  }
`;
export default StyledGridRow;
