import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { PaymentMode } from '@molecules/PaymentModes/PaymentModes';

const trackSelectedPaymentMode = (mode: PaymentType) => {
  let action = '';

  switch (mode) {
    case PaymentMode.KLARNA:
      action = 'payment_option_Klarna';
      break;
    case PaymentMode.PSPPAYEXALL:
    case PaymentMode.PAYEX:
      action = 'payment_option_card_selected';
      break;
    default:
      break;
  }

  if (action) {
    pushGTMEvent({
      event: 'Track',
      category: 'checkout',
      action,
      label: '',
      value: 0,
    });
  }
};

export default trackSelectedPaymentMode;
