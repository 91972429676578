import useSWR from 'swr';
import { getActiveStore } from '@api/interfaces/storeApi';
import useCustomer from '@hooks/useCustomer';

export const getActiveStoreEndpoint = '/rest/store/active';

const useStore = () => {
  const { customer } = useCustomer();

  // wait with fetching until customer has been fetched
  const shouldFetch = Boolean(customer);

  const key = { endpoint: getActiveStoreEndpoint, customerId: customer?.uid };
  const { data: store, isLoading, mutate: refreshStore } = useSWR(shouldFetch ? key : null, getActiveStore);

  return {
    store,
    refreshStore,
    isLoading,
  };
};

export default useStore;
