import styled from 'styled-components';
import InputField from '@atoms/InputField/InputField';
import Heading from '@atoms/Heading/Heading';
import { rem } from 'polished';

export const StyledCreateForm = styled.div`
  text-align: left;
`;

export const StyledFieldsWrapper = styled.div`
  display: flex;
  gap: ${rem(12)};
`;
export const StyledHeading = styled(Heading)`
  line-height: 1;
`;

export const StyledInputField = styled(InputField)`
  flex: 1 1 auto;
  min-width: 0;
`;
