import InputField from '@atoms/InputField/InputField';
import RoundButton from '@atoms/RoundButton/RoundButton';
import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import { saytToolbarPaddings } from '@organisms/SearchAsYouType/SearchAsYouTypeResults/SearchAsYouTypeResults.styles';
import Config from '@config';

export const StyledSearch = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  caret-color: ${variables.colors.highlight};
  flex: 1;
  // Styles below are to avoid input field flicker when SAYT A/B test is running,
  // by setting search to the same width as SAYT
  margin-right: auto;

  ${Config.MEDIA.FROM_DESKTOP} {
    max-width: ${rem(900 + saytToolbarPaddings.horizontal * 2)};
  }

  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    max-width: ${rem(1190 + saytToolbarPaddings.horizontal * 2)};
  }
`;

export const StyledSearchButton = styled(RoundButton)<{ isSearchOpen: boolean }>`
  z-index: ${variables.zIndex[1]};

  > span {
    pointer-events: none;
  }

  ${(props) =>
    props.isSearchOpen &&
    css`
      margin-left: ${rem(10)};
    `}
`;

export const StyledSearchForm = styled.form`
  position: relative;
  flex: 1;
`;

export const StyledSearchButtonSubmit = styled(RoundButton)`
  position: absolute;
  right: ${rem(4)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  > span {
    pointer-events: none;
  }
`;

export const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${variables.colors.white};
  border-radius: ${rem(22)};
`;

export const StyledSearchField = styled(InputField)`
  height: ${rem(44)} !important;
  border: 0 none;
  padding-left: ${rem(20)};
  background-color: transparent;

  &:focus {
    border: none;
  }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const scaleOut = keyframes`
  0% {
    transform: scale(1);
    visibility: visible;
  }
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    transform: scale(0);
  }
`;

export const StyledSearchClearButton = styled.button<{ visible: boolean; hidden: boolean }>`
  position: absolute;
  right: ${rem(40)};
  top: ${rem(10)};
  padding: ${rem(5)};
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-left: ${rem(-66)};
  z-index: 1;

  ${(props) =>
    props.hidden &&
    css`
      animation: ${scaleOut} 200ms ease;
      visibility: hidden;
    `}

  ${(props) =>
    props.visible &&
    css`
      animation: ${scaleIn} 200ms ease;
      visibility: visible;
    `}
`;
