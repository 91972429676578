import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import { useSelector } from 'react-redux';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';

export const useAmountOfItemsPerSlide = () => {
  const sideMenuIsOpen = useSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useSelector(selectMiniCartPreviewIsOpen);

  const fromTablet = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_PORTRAIT,
  });
  const fromTabletLandscape = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_TABLET_LANDSCAPE,
  });
  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });
  const fromDesktopLarge = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_LARGE,
  });

  const fromXLargeDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP_XLARGE,
  });

  /* TODO B2C-27024 Temporary solution, remove when rewrite grid solution */
  const fromDesktopCustomMedium = useMediaQuery({
    query: '(min-width: 1374px)',
  });
  const fromDesktopCustomLarge = useMediaQuery({
    query: '(min-width: 1626px)',
  });

  if (fromXLargeDesktop) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 6;
    return 7;
  }

  if (fromDesktopCustomLarge) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 5;
    if (sideMenuIsOpen || isCartPreviewOpen) return 5;
    return 6;
  }

  if (fromDesktopLarge) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 4;
    if (sideMenuIsOpen || isCartPreviewOpen) return 5;
    return 6;
  }

  if (fromDesktopCustomMedium) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 4;
    if (sideMenuIsOpen || isCartPreviewOpen) return 4;
    return 4;
  }

  if (fromDesktop) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 3;
    if (sideMenuIsOpen || isCartPreviewOpen) return 4;
    return 5;
  }

  if (fromTabletLandscape) return 4;
  if (fromTablet) return 3;
  return 2;
};
