/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodPersonalElementViewModel } from '../dto';
/**
 * PersonalElementsApi - axios parameter creator
 * @export
 */
export const PersonalElementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of personal elements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfPersonalElementsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/personalElementList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get personal element
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalElementUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/personalElement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalElementsApi - functional programming interface
 * @export
 */
export const PersonalElementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalElementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of personal elements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListOfPersonalElementsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodPersonalElementViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfPersonalElementsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get personal element
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalElementUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPersonalElementViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalElementUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonalElementsApi - factory interface
 * @export
 */
export const PersonalElementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalElementsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of personal elements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfPersonalElementsUsingGET(options?: any): AxiosPromise<Array<AxfoodPersonalElementViewModel>> {
            return localVarFp.getListOfPersonalElementsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get personal element
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalElementUsingGET(options?: any): AxiosPromise<AxfoodPersonalElementViewModel> {
            return localVarFp.getPersonalElementUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonalElementsApi - object-oriented interface
 * @export
 * @class PersonalElementsApi
 * @extends {BaseAPI}
 */
export class PersonalElementsApi extends BaseAPI {
    /**
     * 
     * @summary Get list of personal elements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalElementsApi
     */
    public getListOfPersonalElementsUsingGET(options?: AxiosRequestConfig) {
        return PersonalElementsApiFp(this.configuration).getListOfPersonalElementsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get personal element
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalElementsApi
     */
    public getPersonalElementUsingGET(options?: AxiosRequestConfig) {
        return PersonalElementsApiFp(this.configuration).getPersonalElementUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}
