import styled from 'styled-components';
import { rem } from 'polished';
import { SkeletonRow } from '@atoms/Skeleton/Skeleton.styles';

export const SkeletonWrapper = styled.div`
  width: 100%;
  height: ${rem(310)};
  &:first-child {
    ${SkeletonRow} {
      margin-top: 0;
    }
  }
`;
