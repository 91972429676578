import { RefObject, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useDocumentScroll from '@helpers/useDocumentScroll';
import MenuButton from '@atoms/MenuButton/MenuButton';
import Search from '@organisms/Search/Search';
import useUsabillaOverride from '@hooks/useUsabillaOverride';
import Config from '@config';
import { StyledToolbar } from '@components/organisms/Toolbar/Toolbar.styles';
import { selectStickyToolbar, selectToolbarSearchIsExpanded, setStickyToolbar } from '@slices/toolbarSlice';
import SearchAsYouType from '@organisms/SearchAsYouType/SearchAsYouType';
import useFeatureToggle from '@hooks/useFeatureToggle';
import Variant from '@utility/ABTest/Variant';
import MiniCart from '@molecules/MiniCart/MiniCart';
import useResponsive from '@hooks/useResponsive';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { selectDeliveryPicker } from '@slices/deliveryPickerSlice';
import SymplifyExperiment from '@utility/ABTest/Symplify/SymplifyExperiment';
import dynamic from 'next/dynamic';

interface Props {
  headerRef: RefObject<HTMLDivElement>;
}

const DynamicDeliveryPicker = dynamic(
  () => import('@organisms/DeliveryPicker/DeliveryPicker' /* webpackChunkName 'DeliveryPicker' */)
);

const Toolbar = ({ headerRef }: Props) => {
  const { isEnabled: searchAsYouTypeEnabled } = useFeatureToggle(Config.FEATURE_TOGGLES.searchAsYouType);
  const deliveryPicker = useAppSelector(selectDeliveryPicker);

  const [hideToolbar, setHideToolbar] = useState(false);
  const [aboveToolbarHeight, setAboveToolbarHeight] = useState(0);

  const downScrollDistanceRef = useRef(0);
  const upScrollDistanceRef = useRef(0);
  const toolbarRef = useRef<HTMLDivElement>(null);

  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSearchExpanded = useAppSelector(selectToolbarSearchIsExpanded);
  const stickyToolbar: boolean = useAppSelector(selectStickyToolbar);

  const { isMobile, fromDesktop } = useResponsive();
  const applyUsabillaFix = useUsabillaOverride();
  const dispatch = useAppDispatch();

  const scrollThreshold = 60;

  useResizeObserver(headerRef, (entry) => {
    setAboveToolbarHeight(entry.contentRect.height);
  });

  useDocumentScroll((scrollData) => {
    if (!isMobile) return;

    const { previousScrollTop, currentScrollTop } = scrollData;
    const scrollDistance = Math.abs(previousScrollTop - currentScrollTop);
    const scrolledPastToolbar =
      currentScrollTop > aboveToolbarHeight + (toolbarRef.current ? toolbarRef.current.offsetHeight : 0);

    if (!scrolledPastToolbar) {
      setHideToolbar(false);
    }

    if (currentScrollTop > previousScrollTop) {
      // Scrolling down
      upScrollDistanceRef.current = 0;
      downScrollDistanceRef.current += scrollDistance;
      if (!hideToolbar && downScrollDistanceRef.current + scrollDistance > scrollThreshold) {
        setHideToolbar(scrolledPastToolbar && true);
      }
      dispatch(setStickyToolbar(false));
    } else {
      // Scrolling up
      if (scrolledPastToolbar) {
        dispatch(setStickyToolbar(true));
      }
      downScrollDistanceRef.current = 0;
      upScrollDistanceRef.current += scrollDistance;
      if (hideToolbar && upScrollDistanceRef.current + scrollDistance > scrollThreshold) {
        setHideToolbar(false);
      }
    }
  });

  return (
    <StyledToolbar
      hidden={isMobile && hideToolbar && !isSearchExpanded}
      sticky={(isMobile && stickyToolbar) || !isMobile}
      usabillaFix={applyUsabillaFix}
      adjustUp={isMobile && isSearchExpanded && !deliveryPicker.isOpen && headerRef.current?.clientHeight}
      adjustZIndex={isCartPreviewOpen && !isMobile}
      ref={toolbarRef}
      headerHeight={headerRef.current?.clientHeight}
      adjustForSAYT={isMobile && isSearchExpanded}
    >
      {(isMobile ? !isSearchExpanded : true) && <MenuButton />}

      {searchAsYouTypeEnabled ? (
        <SymplifyExperiment testName="SearchAsYouType" defaultVariant="Original">
          <Variant variantName="Original">
            <Search />
          </Variant>
          <Variant variantName="SAYT">
            <SearchAsYouType />
          </Variant>
        </SymplifyExperiment>
      ) : (
        <Search />
      )}

      {fromDesktop && <DynamicDeliveryPicker />}

      {fromDesktop && <MiniCart />}
    </StyledToolbar>
  );
};

export default Toolbar;
