import { ReactNode } from 'react';
import { StyledAccordionContent } from './Accordion.styles';

interface Props {
  children: ReactNode;
}

const AccordionContent = ({ children }: Props) => {
  return <StyledAccordionContent>{children}</StyledAccordionContent>;
};

export default AccordionContent;
