import {
  StyledCategorySuggestion,
  StyledCategorySuggestions,
  StyledSuggestionsHeading,
} from '@organisms/SearchAsYouType/SearchAsYouTypeResults/CategorySuggestions/CategorySuggestions.styles';
import { CategoryBreadCrumbsData } from '@occ/api-client';
import useTranslation from 'next-translate/useTranslation';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import { trackCategorySuggestionClick } from '@helpers/analyticsHelpers/trackSearchAsYouType';
import { setToolbarSearchIsExpanded } from '@slices/toolbarSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';

interface CategorySuggestionsProps {
  navigationCategories: Array<CategoryBreadCrumbsData>;
}

const CategorySuggestions = ({ navigationCategories }: CategorySuggestionsProps) => {
  const { t } = useTranslation('search');
  const dispatch = useAppDispatch();

  return (
    <StyledCategorySuggestions data-testid="category-suggestions">
      <StyledSuggestionsHeading type="subhead">{t('asYouType->suggestionsHeading')}</StyledSuggestionsHeading>
      <ul>
        {navigationCategories.map((breadcrumb) => (
          <StyledCategorySuggestion key={breadcrumb.categoryCode}>
            <Link
              href={`${paths.CATEGORY}/${breadcrumb.url}`}
              onClick={() => {
                dispatch(setToolbarSearchIsExpanded(false));
                trackCategorySuggestionClick(breadcrumb.name || '');
              }}
            >
              {breadcrumb.name}
            </Link>
          </StyledCategorySuggestion>
        ))}
      </ul>
    </StyledCategorySuggestions>
  );
};

export default CategorySuggestions;
