import type { AxfoodBasicProductViewModel, AxfoodOfflineProductViewModel } from '@occ/api-client';
import { isOfflineProduct } from '@helpers/typeGuards/product';

const getProductLabelSplashType = (product: AxfoodBasicProductViewModel | AxfoodOfflineProductViewModel) => {
  if (isOfflineProduct(product)) {
    return null;
  }
  switch (true) {
    case product.bargainProduct:
      return 'bargain';
    case product.isDrugProduct || product.nicotineMedicalProduct:
      return 'medical';
    case product.newsSplashProduct:
      return 'news';
    case product.labels?.includes('age_restricted_18'):
      return 'age_18';
    default:
      return null;
  }
};

export default getProductLabelSplashType;
