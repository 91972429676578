import Heading from '@atoms/Heading/Heading';
import styled from 'styled-components';
import { rem } from 'polished';

export const StyledComponentHeading = styled(Heading)`
  margin-bottom: ${rem(16)};
`;

export const StyledComponentSection = styled.div`
  padding-top: ${rem(10)};
`;
