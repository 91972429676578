import Portal from '@atoms/Portal/Portal';
import { StyledTakeoverInnerWrapper } from '@molecules/Takeover/Takeover.styles';

interface Props {
  children: JSX.Element;
  isVisible: boolean;
}

const Takeover = ({ children, isVisible }: Props) => {
  return isVisible ? (
    <Portal selector="takeover-root">
      <StyledTakeoverInnerWrapper>{children}</StyledTakeoverInnerWrapper>
    </Portal>
  ) : (
    children
  );
};

export default Takeover;
