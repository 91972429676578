import styled from 'styled-components';
import { rem } from 'polished';
import Text from '@atoms/Text/Text';
import Config from '@config';

export const StyledCategorySuggestions = styled.div`
  margin-right: ${rem(60)};
  ${Config.MEDIA.FROM_DESKTOP} {
    margin-right: ${rem(60)};
  }
`;

export const StyledSuggestionsHeading = styled(Text)`
  margin-bottom: ${rem(10)};
`;
export const StyledCategorySuggestion = styled.li`
  margin-bottom: ${rem(10)};
  font-size: ${rem(15)};
`;
