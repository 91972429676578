import Image from 'next/legacy/image';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import slugify from '@helpers/slugify';
import Link from '@atoms/Link/Link';
import ProductQuantityInputField from '@molecules/ProductQuantityInputField/ProductQuantityInputField';
import {
  StyledAccent,
  StyledProductListItem,
  StyledPromotion,
  StyledSavings,
  StyledText,
  StyledTextWrapper,
  StyledWrapper,
} from './ProductListItem.styles';
import type {
  AxfoodBasicProductViewModel,
  AxfoodCartProductViewModel,
  AxfoodProductDetailsViewModel,
} from '@occ/api-client';
import { isCartProduct, isOnlineProduct } from '@helpers/typeGuards/product';
import { useProductImage } from '@hooks/useProductImage';

interface Props {
  product: AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel | AxfoodCartProductViewModel;
  quantity?: number;
  quantityCallback?: (quantity: number) => void;
  className?: string;
  clickable?: boolean;
  variant?: 'addToList' | 'default';
}

const ProductListItem = ({
  product,
  quantity,
  quantityCallback,
  className,
  clickable = true,
  variant = 'default',
}: Props) => {
  const { productImageURL, productImageAltText, onImageLoadError } = useProductImage(product);
  const router = useRouter();
  const { t } = useTranslation('cartpreview');
  const url = `${slugify(product.name)}-${product.code}`;
  const as = `/produkt/${encodeURI(url)}`;

  const urlParams = { ...router.query, name: encodeURIComponent(url), productCode: product.code, showInModal: true };

  const generateContentWithLink = (content: JSX.Element) => {
    if (clickable) {
      return (
        <Link href={{ pathname: router.route, query: urlParams }} scroll={false} asProp={as} internal shallow>
          {content}
        </Link>
      );
    }

    return content;
  };

  return (
    <StyledProductListItem className={className}>
      <StyledWrapper>
        {generateContentWithLink(
          <Image
            src={productImageURL}
            alt={productImageAltText}
            width={variant === 'addToList' ? 50 : 40}
            height={variant === 'addToList' ? 50 : 40}
            onError={onImageLoadError}
            unoptimized
          />
        )}

        <StyledTextWrapper variant={variant}>
          {generateContentWithLink(
            <StyledText
              type={variant === 'addToList' ? 'body' : 'detail'}
              size={variant === 'addToList' ? 'medium' : 'small'}
            >
              {product.name}
            </StyledText>
          )}
          <StyledText type={variant === 'addToList' ? 'body' : 'detail'} size="small" color="gray">
            {product.productLine2}
          </StyledText>
          {isCartProduct(product) && product.totalDeposit && (
            <StyledText type="detail" size="small" color="gray">
              {product.totalDeposit} pant
            </StyledText>
          )}
          {isOnlineProduct(product) &&
            isCartProduct(product) &&
            ((product.appliedPromotions?.length ?? 0) > 0 ? (
              <StyledText type="price" color="red" size="xsmall">
                {product.totalDiscountedPriceWithDeposit}
              </StyledText>
            ) : (
              <StyledText type="price" size="xsmall">
                {product.totalPrice}
              </StyledText>
            ))}
        </StyledTextWrapper>
        <ProductQuantityInputField
          product={product}
          variant={variant === 'addToList' ? 'cart' : 'minicart'}
          quantity={quantity}
          quantityCallback={quantityCallback}
        />
      </StyledWrapper>

      {product.nicotineMedicalProduct ? (
        <StyledPromotion>
          <StyledSavings>{t('cart->nicotineLabel')}</StyledSavings>
        </StyledPromotion>
      ) : (
        isCartProduct(product) &&
        product.promotionCartLabel &&
        product?.potentialPromotions?.length > 0 && (
          <StyledPromotion>
            <StyledAccent>{product.potentialPromotions[0].cartLabelFormatted}</StyledAccent>
            <StyledSavings>{product.promotionCartLabel}</StyledSavings>
          </StyledPromotion>
        )
      )}
    </StyledProductListItem>
  );
};

export default ProductListItem;
