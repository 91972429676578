import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface LoginState {
  isLoggingIn: boolean;
}

const initialState: LoginState = {
  isLoggingIn: false,
};

export const loginSlice = createAppSlice({
  name: 'login',
  initialState,
  reducers: (create) => ({
    setIsLoggingIn: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isLoggingIn = action.payload;
    }),
  }),
  selectors: {
    selectLogin: (login) => login,
    selectIsLoggingIn: (login) => login.isLoggingIn,
  },
});

// Selectors
export const { selectLogin, selectIsLoggingIn } = loginSlice.selectors;

// Actions
export const { setIsLoggingIn } = loginSlice.actions;
