import React, { ReactNode } from 'react';
import ABTestContext from './ABTestContext';

interface VariantProps {
  variantName: string;
  children: ReactNode;
}

const Variant = ({ variantName, children }: VariantProps) => {
  return <ABTestContext.Consumer>{(value) => (value === variantName ? children : null)}</ABTestContext.Consumer>;
};

export default Variant;
