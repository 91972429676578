import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { SpinnerSizeType } from '@molecules/Spinner/Spinner';

export const StyledSpinner = styled.span<{ size: SpinnerSizeType }>`
  display: inline-block;
  align-items: center;
  justify-content: center;

  ${(props) =>
    css`
      width: ${rem(props.size)};
      height: ${rem(props.size)};
    `}
`;
