import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledMarginWrapper = styled.div`
  margin-bottom: ${rem(40)};

  &:only-child {
    height: 100%;
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-bottom: ${rem(60)};
  }
`;
export const textStyling = css`
  p,
  div {
    line-height: ${rem(24)};
    font-size: ${rem(15)};
  }

  strong,
  b {
    font-family: ${variables.fonts.fontGothamMedium};
    font-weight: bold;
  }

  ul {
    list-style: initial;
    margin: ${rem(20)} 0;
    padding-left: ${rem(15)};
    font-size: ${rem(15)};

    li {
      padding: 0 0 ${rem(7)} ${rem(10)};
    }
  }
`;

export const imageStyling = css`
  .mobile-content {
    display: none;

    ${Config.MEDIA.IS_MOBILE} {
      display: block;
    }
  }

  .tablet-content {
    display: none;

    ${Config.MEDIA.IS_TABLET} {
      display: block;
    }
  }

  .desktop-content {
    display: none;

    ${Config.MEDIA.FROM_DESKTOP} {
      display: block;
    }
  }
`;

export const iconStyling = css`
  .before {
    &.marker-willys:before {
      content: url(/icons/MARKER_WILLYS.svg);
      width: ${rem(25)};
      height: ${rem(38)};
    }

    &.marker-willys-hemma:before {
      content: url(/icons/MARKER_WILLYS_HOME.svg);
      width: ${rem(25)};
      height: ${rem(38)};
    }
  }

  .after {
    &.marker-willys:after {
      content: url(/icons/MARKER_WILLYS.svg);
      width: ${rem(25)};
      height: ${rem(38)};
    }

    &.marker-willys-hemma:after {
      content: url(/icons/MARKER_WILLYS_HOME.svg);
      width: ${rem(25)};
      height: ${rem(38)};
    }
  }

  .icon-right {
    right: 0;
    top: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
`;
