import { StyledAmount, StyledButton, StyledCloseIcon, StyledText } from './Tile.styles';
import CloseSmall from '@icons/close_small.svg';

export type Props = {
  name: string;
  selected: boolean;
  count?: number;
};

const Tile = ({ name, selected, count }: Props) => {
  return (
    <StyledButton theme="tile" isSelected={selected}>
      <StyledText>
        <span>{name}</span>
        {count && <StyledAmount>{count}</StyledAmount>}
      </StyledText>
      {!count && <StyledCloseIcon svg={CloseSmall} size={9} />}
    </StyledButton>
  );
};

export default Tile;
