/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AutocompleteResultData } from '../dto';
// @ts-ignore
import { AxfoodBasicProductViewModel } from '../dto';
// @ts-ignore
import { AxfoodOfflineProductDetailsViewModel } from '../dto';
// @ts-ignore
import { AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel } from '../dto';
// @ts-ignore
import { AxfoodProductDetailSectionViewModel } from '../dto';
// @ts-ignore
import { AxfoodProductDetailsViewModel } from '../dto';
// @ts-ignore
import { AxfoodSearchResultViewModel } from '../dto';
// @ts-ignore
import { FacetRefinementOfSearchStateData } from '../dto';
// @ts-ignore
import { Loop54SearchWrapperData } from '../dto';
// @ts-ignore
import { ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel } from '../dto';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get autocomplete suggestions
         * @param {string} componentUid componentUid
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutocompleteSuggestionsUsingGET: async (componentUid: string, term: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentUid' is not null or undefined
            assertParamExists('getAutocompleteSuggestionsUsingGET', 'componentUid', componentUid)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('getAutocompleteSuggestionsUsingGET', 'term', term)
            const localVarPath = `/search/autocomplete/{componentUid}`
                .replace(`{${"componentUid"}}`, encodeURIComponent(String(componentUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facets for product
         * @param {string} q q
         * @param {number} [page] page
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacetsUsingGET: async (q: string, page?: number, show?: 'Page' | 'All', sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getFacetsUsingGET', 'q', q)
            const localVarPath = `/search/facets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search results for products and related navigation categories for as you type and autoComplete results
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiSearchResultsAsYouTypeUsingGET: async (q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getMultiSearchResultsAsYouTypeUsingGET', 'q', q)
            const localVarPath = `/search/multisearchAsutype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search results for products and related navigation categories for as you type and autoComplete results
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiSearchResultsUsingGET: async (q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getMultiSearchResultsUsingGET', 'q', q)
            const localVarPath = `/search/multisearchComplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product recommendations
         * @param {string} productCode productCode
         * @param {string} displayType displayType
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRecommendationUsingGET: async (productCode: string, displayType: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getProductRecommendationUsingGET', 'productCode', productCode)
            // verify required parameter 'displayType' is not null or undefined
            assertParamExists('getProductRecommendationUsingGET', 'displayType', displayType)
            const localVarPath = `/axfood/rest/products/{productCode}/{displayType}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"displayType"}}`, encodeURIComponent(String(displayType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offline product details
         * @param {string} storeId storeId
         * @param {string} campaignCode campaignCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET: async (storeId: string, campaignCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getProductUsingGET', 'storeId', storeId)
            // verify required parameter 'campaignCode' is not null or undefined
            assertParamExists('getProductUsingGET', 'campaignCode', campaignCode)
            const localVarPath = `/axfood/rest/c/{storeId}/{campaignCode}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"campaignCode"}}`, encodeURIComponent(String(campaignCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET1: async (productCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getProductUsingGET1', 'productCode', productCode)
            const localVarPath = `/axfood/rest/p/{productCode}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product detail in Sections
         * @param {string} productCode productCode
         * @param {string} sectionName sectionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductV2UsingGET: async (productCode: string, sectionName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('getProductV2UsingGET', 'productCode', productCode)
            // verify required parameter 'sectionName' is not null or undefined
            assertParamExists('getProductV2UsingGET', 'sectionName', sectionName)
            const localVarPath = `/axfood/rest/p/{productCode}/section/{sectionName}`
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)))
                .replace(`{${"sectionName"}}`, encodeURIComponent(String(sectionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get related search results for products
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedResultsUsingGET: async (q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/related-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (directSearch !== undefined) {
                localVarQueryParameter['directSearch'] = directSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get results for products
         * @param {string} q q
         * @param {number} [page] page
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsUsingGET1: async (q: string, page?: number, show?: 'Page' | 'All', sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getResultsUsingGET1', 'q', q)
            const localVarPath = `/search/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clean search results for products
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchCleanResultsUsingGET: async (q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getSearchCleanResultsUsingGET', 'q', q)
            const localVarPath = `/search/clean`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (directSearch !== undefined) {
                localVarQueryParameter['directSearch'] = directSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search results for products for as you type
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsAsYouTypeUsingGET: async (q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getSearchResultsAsYouTypeUsingGET', 'q', q)
            const localVarPath = `/search/asutype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search results for products
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET1: async (q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (directSearch !== undefined) {
                localVarQueryParameter['directSearch'] = directSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get autocomplete suggestions
         * @param {string} componentUid componentUid
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAutocompleteSuggestionsUsingGET(componentUid: string, term: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompleteResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAutocompleteSuggestionsUsingGET(componentUid, term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get facets for product
         * @param {string} q q
         * @param {number} [page] page
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacetsUsingGET(q: string, page?: number, show?: 'Page' | 'All', sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacetRefinementOfSearchStateData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacetsUsingGET(q, page, show, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search results for products and related navigation categories for as you type and autoComplete results
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMultiSearchResultsAsYouTypeUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loop54SearchWrapperData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMultiSearchResultsAsYouTypeUsingGET(q, page, size, show, sort, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search results for products and related navigation categories for as you type and autoComplete results
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMultiSearchResultsUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loop54SearchWrapperData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMultiSearchResultsUsingGET(q, page, size, show, sort, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product recommendations
         * @param {string} productCode productCode
         * @param {string} displayType displayType
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductRecommendationUsingGET(productCode: string, displayType: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductRecommendationUsingGET(productCode, displayType, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offline product details
         * @param {string} storeId storeId
         * @param {string} campaignCode campaignCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductUsingGET(storeId: string, campaignCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodOfflineProductDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductUsingGET(storeId, campaignCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product details
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductUsingGET1(productCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodProductDetailsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductUsingGET1(productCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product detail in Sections
         * @param {string} productCode productCode
         * @param {string} sectionName sectionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductV2UsingGET(productCode: string, sectionName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodProductDetailSectionViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductV2UsingGET(productCode, sectionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get related search results for products
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedResultsUsingGET(q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodBasicProductViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedResultsUsingGET(q, page, size, show, sort, directSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get results for products
         * @param {string} q q
         * @param {number} [page] page
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResultsUsingGET1(q: string, page?: number, show?: 'Page' | 'All', sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodSearchResultViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResultsUsingGET1(q, page, show, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get clean search results for products
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchCleanResultsUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchCleanResultsUsingGET(q, page, size, show, sort, directSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search results for products for as you type
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchResultsAsYouTypeUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchResultsAsYouTypeUsingGET(q, page, size, show, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search results for products
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchResultsUsingGET1(q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchResultsUsingGET1(q, page, size, show, sort, directSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Get autocomplete suggestions
         * @param {string} componentUid componentUid
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutocompleteSuggestionsUsingGET(componentUid: string, term: string, options?: any): AxiosPromise<AutocompleteResultData> {
            return localVarFp.getAutocompleteSuggestionsUsingGET(componentUid, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facets for product
         * @param {string} q q
         * @param {number} [page] page
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacetsUsingGET(q: string, page?: number, show?: 'Page' | 'All', sort?: string, options?: any): AxiosPromise<FacetRefinementOfSearchStateData> {
            return localVarFp.getFacetsUsingGET(q, page, show, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search results for products and related navigation categories for as you type and autoComplete results
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiSearchResultsAsYouTypeUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options?: any): AxiosPromise<Loop54SearchWrapperData> {
            return localVarFp.getMultiSearchResultsAsYouTypeUsingGET(q, page, size, show, sort, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search results for products and related navigation categories for as you type and autoComplete results
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiSearchResultsUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options?: any): AxiosPromise<Loop54SearchWrapperData> {
            return localVarFp.getMultiSearchResultsUsingGET(q, page, size, show, sort, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product recommendations
         * @param {string} productCode productCode
         * @param {string} displayType displayType
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRecommendationUsingGET(productCode: string, displayType: string, page?: number, pageSize?: number, options?: any): AxiosPromise<AxfoodPaginationViewModelOfAxfoodProductDetailsViewModel> {
            return localVarFp.getProductRecommendationUsingGET(productCode, displayType, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offline product details
         * @param {string} storeId storeId
         * @param {string} campaignCode campaignCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET(storeId: string, campaignCode: string, options?: any): AxiosPromise<AxfoodOfflineProductDetailsViewModel> {
            return localVarFp.getProductUsingGET(storeId, campaignCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product details
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET1(productCode: string, options?: any): AxiosPromise<AxfoodProductDetailsViewModel> {
            return localVarFp.getProductUsingGET1(productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product detail in Sections
         * @param {string} productCode productCode
         * @param {string} sectionName sectionName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductV2UsingGET(productCode: string, sectionName: string, options?: any): AxiosPromise<AxfoodProductDetailSectionViewModel> {
            return localVarFp.getProductV2UsingGET(productCode, sectionName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get related search results for products
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedResultsUsingGET(q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: any): AxiosPromise<Array<AxfoodBasicProductViewModel>> {
            return localVarFp.getRelatedResultsUsingGET(q, page, size, show, sort, directSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get results for products
         * @param {string} q q
         * @param {number} [page] page
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsUsingGET1(q: string, page?: number, show?: 'Page' | 'All', sort?: string, options?: any): AxiosPromise<AxfoodSearchResultViewModel> {
            return localVarFp.getResultsUsingGET1(q, page, show, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clean search results for products
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchCleanResultsUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: any): AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel> {
            return localVarFp.getSearchCleanResultsUsingGET(q, page, size, show, sort, directSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search results for products for as you type
         * @param {string} q q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsAsYouTypeUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, options?: any): AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel> {
            return localVarFp.getSearchResultsAsYouTypeUsingGET(q, page, size, show, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search results for products
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {'Page' | 'All'} [show] show
         * @param {string} [sort] sort
         * @param {boolean} [directSearch] directSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET1(q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: any): AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel> {
            return localVarFp.getSearchResultsUsingGET1(q, page, size, show, sort, directSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Get autocomplete suggestions
     * @param {string} componentUid componentUid
     * @param {string} term term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getAutocompleteSuggestionsUsingGET(componentUid: string, term: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getAutocompleteSuggestionsUsingGET(componentUid, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facets for product
     * @param {string} q q
     * @param {number} [page] page
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getFacetsUsingGET(q: string, page?: number, show?: 'Page' | 'All', sort?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getFacetsUsingGET(q, page, show, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search results for products and related navigation categories for as you type and autoComplete results
     * @param {string} q q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getMultiSearchResultsAsYouTypeUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getMultiSearchResultsAsYouTypeUsingGET(q, page, size, show, sort, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search results for products and related navigation categories for as you type and autoComplete results
     * @param {string} q q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {Array<'PRODUCTS' | 'RECIPES' | 'STORES'>} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getMultiSearchResultsUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, type?: Array<'PRODUCTS' | 'RECIPES' | 'STORES'>, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getMultiSearchResultsUsingGET(q, page, size, show, sort, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product recommendations
     * @param {string} productCode productCode
     * @param {string} displayType displayType
     * @param {number} [page] page
     * @param {number} [pageSize] pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductRecommendationUsingGET(productCode: string, displayType: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductRecommendationUsingGET(productCode, displayType, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offline product details
     * @param {string} storeId storeId
     * @param {string} campaignCode campaignCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductUsingGET(storeId: string, campaignCode: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductUsingGET(storeId, campaignCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product details
     * @param {string} productCode productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductUsingGET1(productCode: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductUsingGET1(productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product detail in Sections
     * @param {string} productCode productCode
     * @param {string} sectionName sectionName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductV2UsingGET(productCode: string, sectionName: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductV2UsingGET(productCode, sectionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get related search results for products
     * @param {string} [q] q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {boolean} [directSearch] directSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getRelatedResultsUsingGET(q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getRelatedResultsUsingGET(q, page, size, show, sort, directSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get results for products
     * @param {string} q q
     * @param {number} [page] page
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getResultsUsingGET1(q: string, page?: number, show?: 'Page' | 'All', sort?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getResultsUsingGET1(q, page, show, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clean search results for products
     * @param {string} q q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {boolean} [directSearch] directSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getSearchCleanResultsUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getSearchCleanResultsUsingGET(q, page, size, show, sort, directSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search results for products for as you type
     * @param {string} q q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getSearchResultsAsYouTypeUsingGET(q: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getSearchResultsAsYouTypeUsingGET(q, page, size, show, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search results for products
     * @param {string} [q] q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {'Page' | 'All'} [show] show
     * @param {string} [sort] sort
     * @param {boolean} [directSearch] directSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getSearchResultsUsingGET1(q?: string, page?: number, size?: number, show?: 'Page' | 'All', sort?: string, directSearch?: boolean, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getSearchResultsUsingGET1(q, page, size, show, sort, directSearch, options).then((request) => request(this.axios, this.basePath));
    }
}
