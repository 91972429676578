import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const event = 'Track';
const category = 'product_menu';
const label = '';
const value = 0;

export const trackToggleMenu = (isOpen: boolean) => {
  const actionType = isOpen ? 'opened' : 'closed';
  pushGTMEvent({
    event,
    category,
    action: `menu_${actionType}`,
    label,
    value,
  });
};

export const trackToggleCategory = (isExpend: boolean, categories: Array<NavigationNode>) => {
  const actionType = isExpend ? 'expanded' : 'minimized';
  if (Array.isArray(categories)) {
    const categoryPath = categories.map((c) => c.id).join('/');
    pushGTMEvent({
      event,
      category,
      action: `category_${actionType}`,
      label: categoryPath,
      value,
    });
  }
};

export const trackOpenCategory = (categories: Array<NavigationNode>) => {
  if (Array.isArray(categories)) {
    const categoryPath = categories.map((c) => c.id).join('/');
    pushGTMEvent({
      event,
      category,
      action: 'category_open',
      label: categoryPath,
      value,
    });
  }
};

export const trackOpenCmsLink = (linkName: string) => {
  pushGTMEvent({
    event,
    category,
    action: 'navigation',
    label: linkName,
    value,
  });
};
