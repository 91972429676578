import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import { LabelType } from '@atoms/Label/Label';

export const StyledLabel = styled.span<{ variant: LabelType }>`
  display: inline-flex;
  margin: ${rem(8)} 0;
  padding: ${rem(4)} ${rem(8)};
  border-radius: ${rem(2)};
  font-size: ${rem(10)};
  font-family: ${variables.fonts.fontGothamMedium};
  line-height: ${rem(14)};
  text-transform: uppercase;

  ${(props) =>
    props.variant === 'mixAndMatch' &&
    css`
      background-color: ${variables.colors.white};
      color: ${variables.colors.primary};
      border: solid ${rem(1)} ${variables.colors.mediumGray};
    `}

  ${(props) =>
    props.variant === 'news' &&
    css`
      background-color: ${variables.colors.error};
      color: ${variables.colors.white};
    `}

  ${(props) =>
    props.variant === 'mixed' &&
    css`
      background-color: ${variables.colors.lightGray};
      color: ${variables.colors.lighterBlack};
    `}
`;
