import { getFeatureToggles } from '@api/interfaces/featureApi';
import useSWR, { SWRConfiguration } from 'swr';
import Config from '@config';
import useCustomer from '@hooks/useCustomer';

const fetcher = async () => {
  const { data } = await getFeatureToggles();
  return data;
};

const options: SWRConfiguration = {
  refreshInterval: Config.TIMEOUT.defaultCacheInvalidateMs,
};

const useFeatureToggle = (featureName: string) => {
  const { customer } = useCustomer();
  const key = { endpoint: '/axfood/rest/feature' };
  const shouldFetch = Boolean(customer);
  const { data, error, isLoading } = useSWR(shouldFetch ? key : null, fetcher, options);

  const isEnabled = data?.find((feature) => feature.name === featureName)?.enabled || false;

  return { isEnabled, isError: error, isLoading };
};

export default useFeatureToggle;
