import type { AxfoodBasicProductViewModel, AxfoodProductDetailsViewModel } from '@occ/api-client';

const packageComponentHelper = () => {
  const getStartCol = (align: string, cols: number, size: number) => {
    let s = 1;
    if (align === 'RIGHT') {
      s = cols - size + 1;
    }
    if (align === 'CENTER') {
      if (size > 1) {
        s = Math.round(cols / size);
      } else {
        s = Math.round(cols / (size + 1));
      }
    }
    // Make sure s is not below zero, zero value will default to automatic start column calculation in GridCol.styles.ts
    // Negative value (which can occur) will result in a broken layout.
    s = Math.max(s, 0);
    return { s };
  };

  const getSpanSize = (component: ImageGridComponentType) => {
    let spanSize = { s: 2, m: 3, l: 3 };
    // if (component.typeCode === 'SmallGridPackageComponent') {}
    if (component?.typeCode === 'ImageGridComponent') {
      const size = parseInt(component.spanLength, 10);
      spanSize = { s: size > 2 ? 2 : size, m: size, l: size };
    }

    return spanSize;
  };

  const positionPackagesInList = (
    _items: AxfoodBasicProductViewModel[],
    packages: ImageGridComponentType[],
    mediaQueryKey: 's' | 'm' | 'l',
    itemsPerRow: number
  ) => {
    const itemsCopy: (AxfoodBasicProductViewModel | ImageGridComponentType)[] = [..._items];

    if (packages && packages.length) {
      for (let i = 0; i < packages.length; i += 1) {
        const currentPackage = packages[i];
        let packageIndex = parseInt(currentPackage.displayRow, 10) * itemsPerRow - itemsPerRow;

        if (i > 0) {
          const prevColSpanSize = getSpanSize(packages[i - 1] || 0)[mediaQueryKey];
          packageIndex -= prevColSpanSize - 1;
        }

        itemsCopy.splice(packageIndex, 0, currentPackage);
      }
    }
    return itemsCopy;
  };

  const alignImageInRelationToProduct = (pos: 'LEFT' | 'RIGHT') => {
    if (pos === 'LEFT') {
      return { s: 1 };
    }

    return { s: 2 };
  };

  return {
    getSpanSize,
    getStartCol,
    positionPackagesInList,
    alignImageInRelationToProduct,
  };
};

export default packageComponentHelper;
