export const cmsAlignToFlexAlign = (textAlign?: HorizontalBannerType['textAlign']) => {
  switch (textAlign?.toLocaleUpperCase()) {
    case 'LEFT':
      return 'flex-start';
    case 'RIGHT':
      return 'flex-end';
    case 'CENTER':
      return 'center';
    default:
      return undefined;
  }
};

export const cmsAlignToTextAlign = (textAlign?: HorizontalBannerType['textAlign']) => {
  switch (textAlign?.toLocaleUpperCase()) {
    case 'LEFT':
      return 'left';
    case 'RIGHT':
      return 'right';
    case 'CENTER':
      return 'center';
    default:
      return undefined;
  }
};

export const cmsAlignToSectionAlign = (sectionAlign?: HorizontalBannerType['buttonPosition']) => {
  switch (sectionAlign?.toLocaleUpperCase()) {
    case 'TOP':
      return 'flex-start';
    case 'BOTTOM':
      return 'flex-end';
    case 'CENTER':
      return 'center';
    default:
      return undefined;
  }
};
