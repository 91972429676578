import { useEffect, useState } from 'react';
import GridContainer from '@organisms/GridContainer/GridContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Tooltip from '@molecules/Tooltip/Tooltip';
import useTranslation from 'next-translate/useTranslation';
import {
  SearchResultWrapper,
  StyledButton,
  StyledButtonWrapper,
  StyledMultiSearchWrapper,
  StyledTextArea,
  StyledTextAreaWrapper,
  StyledTitle,
} from './MultiSearchComponent.styles';
import MultiSearchResult from '@organisms/MultiSearchComponent/MultiSearchResult';
import { trackSearchWords } from '@helpers/analyticsHelpers/trackMultiSearch';
import useResponsive from '@hooks/useResponsive';

export enum MULTI_SEARCH {
  TEXTAREA_HEIGHT_MIN = 100,
  AMOUNT_OF_BEAMS_PER_PAGE = 5,
  AMOUNT_OF_SLIDES_FIRST_LOAD = 20,
}

const MultiSearchComponent = () => {
  const { t } = useTranslation('multiSearch');
  const [searchValue, setSearchValue] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const [textAreaHeight, setTextAreaHeight] = useState(MULTI_SEARCH.TEXTAREA_HEIGHT_MIN);
  const [searchWordsArray, setSearchWordsArray] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { isMobile } = useResponsive();

  const textAreaOnChangeHandler = (e: any) => {
    setSearchValue(e?.currentTarget?.value);
    setTextAreaHeight(e?.target?.scrollHeight);
  };

  const onSubmitHandler = async () => {
    setCurrentPage(1);
    const searchWords = searchValue.split(/, +|\n+/);
    setSearchWordsArray(searchWords.filter(Boolean));
    trackSearchWords(searchWords.join());
  };

  useEffect(() => {
    if (localStorage.getItem('hideMultiSearchTooltip') === 'true') setTooltipOpen(false);
    else localStorage.setItem('hideMultiSearchTooltip', 'true');
  }, []);

  return (
    <GridContainer noPadding>
      <StyledMultiSearchWrapper>
        <StyledTitle type="body">{t('multiSearch:title')}</StyledTitle>

        <StyledTextAreaWrapper>
          <StyledTextArea
            resize={false}
            name="search"
            label={t('multiSearch:textArea->label')}
            labelTooltip={t('multiSearch:textArea->tooltip')}
            placeholder={t('multiSearch:textArea->placeholder')}
            value={searchValue}
            onChange={textAreaOnChangeHandler}
            height={textAreaHeight}
          />
          {tooltipOpen && (
            <Tooltip variant="medium" position="bottom" align="center" onCloseHandler={() => setTooltipOpen(false)}>
              {t('tooltip')}
            </Tooltip>
          )}
        </StyledTextAreaWrapper>

        <StyledButtonWrapper>
          {searchValue && (
            <StyledButton
              onClick={() => {
                setSearchValue('');
                setTextAreaHeight(MULTI_SEARCH.TEXTAREA_HEIGHT_MIN);
              }}
              theme="secondary"
            >
              {t('multiSearch:button->clear')}
            </StyledButton>
          )}
          <StyledButton disabled={searchValue.length === 0} onClick={onSubmitHandler}>
            {isMobile ? t('multiSearch:button->searchMobile') : t('multiSearch:button->search')}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledMultiSearchWrapper>

      {searchWordsArray.length > 0 && (
        <InfiniteScroll
          dataLength={currentPage * MULTI_SEARCH.AMOUNT_OF_BEAMS_PER_PAGE}
          next={() => setCurrentPage(currentPage + 1)}
          hasMore={currentPage * MULTI_SEARCH.AMOUNT_OF_BEAMS_PER_PAGE < searchWordsArray.length}
          style={{ overflow: undefined }}
          loader={undefined}
        >
          <SearchResultWrapper>
            {searchWordsArray
              .slice(0, currentPage * MULTI_SEARCH.AMOUNT_OF_BEAMS_PER_PAGE)
              .map((searchWord: any, index) => {
                return (
                  <MultiSearchResult
                    searchWord={searchWord}
                    searchWordsArray={searchWordsArray}
                    setSearchWordsArray={setSearchWordsArray}
                    key={searchWord}
                    index={index}
                  />
                );
              })}
          </SearchResultWrapper>
        </InfiniteScroll>
      )}
    </GridContainer>
  );
};

export default MultiSearchComponent;
