import styled from 'styled-components';
import { rem } from 'polished';
import Link from '@atoms/Link/Link';
import WillysLogo from '@icons/non-standard/WILLYS-LOGO.svg';
import variables from '@styles/variables';
import Config from '@config';

export const StyledWillysLogo = styled(WillysLogo)`
  &,
  svg {
    width: auto;
    height: ${rem(20)};

    ${Config.MEDIA.HIGH_CONTRAST} {
      path {
        fill: ${variables.colors.white} !important;
      }
    }
  }
`;

export const StyledLogoLink = styled(Link)`
  display: flex;
`;
