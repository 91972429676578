import styled from 'styled-components';
import { rem } from 'polished';
import Text from '@atoms/Text/Text';

export const StyledAnimalInfo = styled.div`
  &:not(:first-child) {
    margin-top: ${rem(10)};
  }
`;

export const StyledHeader = styled(Text)`
  margin-top: ${rem(6)};
  margin-bottom: ${rem(6)};
`;
