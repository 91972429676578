import React from 'react';
import Link from '@atoms/Link/Link';
import { StyledButton, StyledIcon } from './CMSPartnerLinkComponent.styles';
import ArrowRight from '@icons/arrow-right.svg';

interface Props {
  data: CMSPartnerLinkType;
}

const buttonTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CHECKOUT: 'secondary',
};

const CMSPartnerLinkComponent = ({ data }: Props) => {
  return (
    <Link href={data.url} external={data.external === 'true'} target={data.target === 'newWindow' ? '_blank' : ''}>
      <StyledButton
        theme={buttonTypes[data.buttonType as 'PRIMARY' | 'SECONDARY' | 'CHECKOUT'] as ButtonTheme}
        alignment={data.textAlign}
      >
        {data.linkName}
        {data.buttonType === 'CHECKOUT' && <StyledIcon svg={ArrowRight} size={12} color="lighterBlack" />}
      </StyledButton>
    </Link>
  );
};

export default CMSPartnerLinkComponent;
