import { ReactElement, Key } from 'react';
import { UrlObject } from 'url';

export interface TabContentProps {
  title: string;
  children: ReactElement | null;
  routeTo?: UrlObject;
  routeAs?: UrlObject;
  replace?: boolean;
  key: Key;
}
const TabContent = ({ children }: TabContentProps) => {
  return children;
};

export default TabContent;
