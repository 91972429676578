import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { StyledIconTooltip, StyledProductLabelSplash } from '@molecules/ProductLabelSplash/ProductLabelSplash.styles';
import React from 'react';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import Info from '@icons/info.svg';

export type LabelSplashType = 'mixmatch' | 'news' | 'medical' | 'age_18' | 'bargain' | 'segmented';

interface ProductLabelSplashProps {
  type: LabelSplashType;
  blockElement?: boolean;
  isPromotionUsed?: boolean;
}

const ProductLabelSplash = ({ type, blockElement = false, isPromotionUsed }: ProductLabelSplashProps) => {
  const { t } = useTranslation('product');
  return (
    <StyledProductLabelSplash type={type} blockElement={blockElement}>
      {t(`product->${type}->label`)}
      {type === 'segmented' && (
        <StyledIconTooltip
          variant="medium"
          iconProps={{ svg: Info, size: 12 }}
          content={
            <Trans
              i18nKey="product:product->tooltipPersonalText"
              components={[
                <Link key={1} href={paths.ARTICLE.PERSONALIZATION} variant="secondary">
                  {}
                </Link>,
              ]}
            />
          }
          position="bottom"
          openOnClick
          disabled={isPromotionUsed}
        />
      )}
    </StyledProductLabelSplash>
  );
};

export default ProductLabelSplash;
