import { ReactElement, Key, useEffect, useState } from 'react';
import { TabContentProps } from './TabContent';
import { StyledTab, StyledTabContainer } from './Tabs.styles';

export type VariantType = 'rounded' | 'box' | 'product';

interface Props {
  children: ReactElement<TabContentProps>[];
  activeTab?: string;
  onTabChange?: (x: Key) => void | undefined;
  variant?: VariantType;
  hideTabs?: boolean;
  slideIndex?: number;
  className?: string;
}

const Tabs = ({ activeTab, children, onTabChange, variant = 'box', slideIndex = 0, className, hideTabs }: Props) => {
  const firstTab = activeTab || children[0]?.key;
  const [internalActiveTab, setInternalActiveTab] = useState(firstTab);

  const setCurrentTab = (key: Key) => {
    if (onTabChange) onTabChange(key);
    // @ts-ignore
    setInternalActiveTab(key);
  };

  const filteredContent: any = children.filter((x: any) => {
    if (x) {
      return (x.key as string)?.toLowerCase() === (internalActiveTab as string).toLowerCase();
    }
  });

  const currentContent: ReactElement<TabContentProps>[] = filteredContent[0] && filteredContent[0].props.children;

  useEffect(() => {
    if (activeTab) setInternalActiveTab(activeTab);
  }, [activeTab]);

  return (
    <>
      {!hideTabs && (
        <StyledTabContainer className={className} last={slideIndex === children.length} variant={variant} role="tablist">
          {children?.map((child) => {
            if (child) {
              const { title, routeTo, routeAs, replace } = child?.props;
              const { key } = child;
              const isActive = key === internalActiveTab;

              return (
                <StyledTab
                  routeTo={routeTo}
                  routeAs={routeAs}
                  replace={replace || false}
                  onClick={() => setCurrentTab(key as 'b2c' | 'b2b' | 'bankId')}
                  title={title}
                  key={title}
                  tabkey={key as Key}
                  active={isActive}
                  isActive={isActive}
                  variant={variant}
                  slideIndex={slideIndex}
                  setLeftMargin={slideIndex > 1 && slideIndex !== children.length}
                />
              );
            }
          })}
        </StyledTabContainer>
      )}
      <>{currentContent}</>
    </>
  );
};

export default Tabs;
