import React, { forwardRef, KeyboardEvent, ReactNode, RefObject } from 'react';
import KEYS from '@helpers/keys';
import { StyledAutoComplete, StyledAutoCompleteList, StyledAutoCompleteListItem } from './Autocomplete.styles';

interface Props {
  inputRef?: RefObject<HTMLInputElement>;
  searchTerm: string;
  suggestions: Array<any>; // TODO check how this can be done with multiple different components.
  suggestionIndex?: number;
  className?: string;
  ListComponent: any; // TODO check how this can be done with multiple different components.
  listComponentOnClick?: (...args: Array<any>) => any;
  listWrapper?: (children: ReactNode) => ReactNode;
  rounded?: boolean;
  emptySlot?: ReactNode;
  isOrderReopened?: boolean;
  selectedStore?: string;
  scrollable?: boolean;
  getKeyFunction?: (suggestionObject: any) => string; // TODO check how this can be done with multiple different components.
  showSuggestions?: (show: boolean) => void;
  setSearchTerm?: (searchTerm: string) => void;
  setSuggestionIndex?: (suggestionIndex: number) => void;
  hoverable?: boolean;
  focusable?: boolean;
  id?: string;
  showAsCards?: boolean;
}

const Autocomplete = forwardRef<HTMLDivElement, Props>(
  (
    {
      inputRef,
      searchTerm,
      suggestions,
      suggestionIndex,
      className,
      ListComponent,
      listComponentOnClick,
      rounded,
      emptySlot = null,
      isOrderReopened = false,
      selectedStore = '',
      scrollable = false,
      hoverable = true,
      focusable = true,
      getKeyFunction,
      showSuggestions,
      setSearchTerm,
      setSuggestionIndex,
      listWrapper,
      id,
      showAsCards = false,
    }: Props,
    ref
  ) => {
    const onKeyDown = (e: KeyboardEvent<HTMLUListElement>) => {
      // Workaround to stop event bubbling from child component productlistitem
      const target = e?.target as any;
      if (target?.name === 'quantity') return false;

      e.preventDefault();
      e.stopPropagation();

      switch (e.key) {
        case KEYS.TAB:
        case KEYS.ESCAPE:
          inputRef?.current?.focus();
          break;
        case KEYS.ENTER:
          (e.currentTarget.children[0] as HTMLButtonElement)?.click();
          if (inputRef?.current?.closest('form')?.querySelector<HTMLButtonElement>('button[type="submit"]:disabled')) {
            setTimeout(
              () =>
                inputRef?.current?.closest('form')?.querySelector<HTMLButtonElement>('button[type="submit"]')?.focus(),
              1
            );
          } else {
            inputRef?.current?.closest('form')?.querySelector<HTMLButtonElement>('button[type="submit"]')?.focus();
          }

          break;
        case KEYS.DOWN:
          (e.currentTarget.nextSibling as HTMLOptionElement)?.focus();
          break;
        case KEYS.UP:
          if (e.currentTarget.previousSibling) {
            (e.currentTarget.previousSibling as HTMLOptionElement)?.focus();
          } else {
            inputRef?.current?.focus();
          }
          break;
        case KEYS.PAGE_UP:
        case KEYS.HOME:
          (e.currentTarget.parentNode?.firstChild as HTMLOptionElement)?.focus();
          break;
        case KEYS.PAGE_DOWN:
        case KEYS.END:
          (e.currentTarget.parentNode?.lastChild as HTMLOptionElement)?.focus();
          break;
        default:
          inputRef?.current?.focus();
          break;
      }
    };

    const list = (suggestions || []).map((suggestion, i) => (
      <StyledAutoCompleteListItem
        tabIndex={-1}
        hoverable={hoverable}
        focusable={focusable}
        key={
          getKeyFunction
            ? getKeyFunction(suggestion)
            : `autocomplete-suggestion-${suggestion?.postalcode || suggestion?.name || i}` // TODO use getKeyFunction everywhere
        }
        role="option"
        aria-selected={i === suggestionIndex}
        data-value={suggestion.term}
        onKeyDown={onKeyDown}
        showAsCards={showAsCards}
        isDisabled={isOrderReopened && !!suggestion?.franchise && selectedStore !== suggestion?.storeId}
      >
        <ListComponent
          suggestion={suggestion}
          searchTerm={searchTerm}
          index={i}
          listComponentOnClick={listComponentOnClick}
          isOrderReopened={isOrderReopened}
          selectedStore={selectedStore}
          onClick={() => {
            if (setSearchTerm) setSearchTerm(suggestion.term);
            if (showSuggestions) showSuggestions(false);
            if (setSuggestionIndex) setSuggestionIndex(-1);
          }}
        />
      </StyledAutoCompleteListItem>
    ));
    return (
      <StyledAutoComplete
        id={id}
        tabIndex={-1}
        ref={ref}
        rounded={rounded}
        scrollable={scrollable}
        className={className}
        showAsCards={showAsCards}
      >
        {(!suggestions || !suggestions.length) && emptySlot ? (
          emptySlot
        ) : (
          <StyledAutoCompleteList tabIndex={-1} role="listbox" data-testid="autocomplete-list">
            {listWrapper ? listWrapper(list) : list}
          </StyledAutoCompleteList>
        )}
      </StyledAutoComplete>
    );
  }
);
Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;
