import { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/useAppDispatch';
import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { StyledScrollTop } from './ScrollTop.styles';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import Icon from '@atoms/Icon/Icon';
import AccordionDown from '@icons/accordion-down_small.svg';

type Props = {
  productDetailsOpen: boolean;
};

const ScrollTop = ({ productDetailsOpen }: Props) => {
  const [initialShow, setInitialShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const isCartOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const fromTabletLandscape = useMediaQuery({ query: Config.BREAKPOINTS.FROM_TABLET_LANDSCAPE });

  useEffect(() => {
    const scrollHandler = () => {
      let aboveThreshold = window.pageYOffset > 60;
      if (productDetailsOpen) {
        const scrollTop = document?.getElementById('product-modal')?.scrollTop;
        aboveThreshold = scrollTop !== undefined && scrollTop > 0;
      }
      if (aboveThreshold) setInitialShow(true);
      setVisible(aboveThreshold);
    };

    scrollHandler();
    if (productDetailsOpen) document?.getElementById('product-modal')?.addEventListener('scroll', scrollHandler);
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
      if (productDetailsOpen) document?.getElementById('product-modal')?.addEventListener('scroll', scrollHandler);
    };
  }, [productDetailsOpen]);

  const scrollToTop = () => {
    const scrollObject: ScrollToOptions = { top: 0, behavior: 'smooth' };
    if (productDetailsOpen) {
      document.getElementById('product-modal')?.scrollTo(scrollObject);
    } else {
      window.scrollTo(scrollObject);

      pushGTMEvent({
        event: 'Track',
        category: 'footer',
        action: 'scrolled_to_top',
        label: '',
        value: 0,
      });
    }
  };

  return (
    <>
      {initialShow && (
        <StyledScrollTop
          onClick={scrollToTop}
          color={productDetailsOpen && fromTabletLandscape ? 'white' : 'black'}
          cartIsOpen={isCartOpen}
          productDetailOpen={productDetailsOpen}
          show={visible}
          hide={!visible}
        >
          <Icon svg={AccordionDown} size={12} color={productDetailsOpen && fromTabletLandscape ? 'black' : 'white'} />
        </StyledScrollTop>
      )}
    </>
  );
};

export default ScrollTop;
