import { ReactNode } from 'react';
import { StyledScreenReaderMessage } from '@atoms/ScreenReaderMessage/ScreenReaderMessage.styles';

interface Props {
  children: ReactNode;
  'data-testid'?: string;
}

const ScreenReaderMessage = ({ children, ...props }: Props) => {
  return (
    <StyledScreenReaderMessage data-testid={props['data-testid'] || 'screen-reader-message'} aria-hidden={false}>
      {children}
    </StyledScreenReaderMessage>
  );
};

export default ScreenReaderMessage;
