/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../dto';
// @ts-ignore
import { AxfoodPaginationViewModelOfAxfoodBasicProductViewModel } from '../dto';
// @ts-ignore
import { AxfoodPromotionSuggestionsViewModel } from '../dto';
// @ts-ignore
import { FacetSearchPageData } from '../dto';
// @ts-ignore
import { ProductPromotionDataV2Response } from '../dto';
// @ts-ignore
import { ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel } from '../dto';
// @ts-ignore
import { PromotionSearchPageDataOfSearchStateDataAndAxfoodOfflineProductViewModel } from '../dto';
/**
 * PromotionApi - axios parameter creator
 * @export
 */
export const PromotionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get mix promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {number} [onlineSize] Number of online promotions displayed in previous pages
         * @param {number} [offlineSize] Number of offline promotions displayed in previous pages
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMixPromotionsUsingGET: async (q: string, type?: string, page?: number, size?: number, onlineSize?: number, offlineSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getMixPromotionsUsingGET', 'q', q)
            const localVarPath = `/axfood/rest/promotionproduct/mix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (onlineSize !== undefined) {
                localVarQueryParameter['onlineSize'] = onlineSize;
            }

            if (offlineSize !== undefined) {
                localVarQueryParameter['offlineSize'] = offlineSize;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get online and offline campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [size] size
         * @param {number} [onlineSize] onlineSize
         * @param {number} [offlineSize] offlineSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMixedCampaignsUsingGET: async (q: string, type?: string, size?: number, onlineSize?: number, offlineSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getMixedCampaignsUsingGET', 'q', q)
            const localVarPath = `/search/campaigns/mix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (onlineSize !== undefined) {
                localVarQueryParameter['onlineSize'] = onlineSize;
            }

            if (offlineSize !== undefined) {
                localVarQueryParameter['offlineSize'] = offlineSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get promoted previews
         * @param {string} [q] q
         * @param {Array<string>} [fields] fields
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {number} [dateFrom] dateFrom
         * @param {number} [dateTo] dateTo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCampaignsPreviewUsingGET: async (q?: string, fields?: Array<string>, page?: number, size?: number, dateFrom?: number, dateTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/campaigns/preview/offline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offline campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCampaignsUsingGET: async (q: string, type?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getOfflineCampaignsUsingGET', 'q', q)
            const localVarPath = `/search/campaigns/offline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offline promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflinePromotionsUsingGET: async (q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getOfflinePromotionsUsingGET', 'q', q)
            const localVarPath = `/axfood/rest/promotionproduct/offline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get online campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCampaignsUsingGET: async (q: string, type?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getOnlineCampaignsUsingGET', 'q', q)
            const localVarPath = `/search/campaigns/online`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get online promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlinePromotionsUsingGET: async (q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getOnlinePromotionsUsingGET', 'q', q)
            const localVarPath = `/axfood/rest/promotionproduct/online`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for given promotion code and store excluding products in parameter
         * @param {string} promotionCode The promotion code
         * @param {string} storeId The search query
         * @param {Array<string>} [excludeProducts] List of excluded products
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsFromPromotionUsingGET: async (promotionCode: string, storeId: string, excludeProducts?: Array<string>, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionCode' is not null or undefined
            assertParamExists('getProductsFromPromotionUsingGET', 'promotionCode', promotionCode)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getProductsFromPromotionUsingGET', 'storeId', storeId)
            const localVarPath = `/axfood/rest/promotionproduct/{promotionCode}/products`
                .replace(`{${"promotionCode"}}`, encodeURIComponent(String(promotionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (excludeProducts) {
                localVarQueryParameter['excludeProducts'] = excludeProducts;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get promoted products
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotedProductsUsingGET: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get promotion products
         * @param {string} promotionCode promotionCode
         * @param {Array<string>} [excludeProducts] excludeProducts
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionProductsUsingGET: async (promotionCode: string, excludeProducts?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionCode' is not null or undefined
            assertParamExists('getPromotionProductsUsingGET', 'promotionCode', promotionCode)
            const localVarPath = `/axfood/rest/promotions/{promotionCode}/products`
                .replace(`{${"promotionCode"}}`, encodeURIComponent(String(promotionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (excludeProducts) {
                localVarQueryParameter['excludeProducts'] = excludeProducts;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get smart suggestion promotions
         * @param {string} storeId storeId
         * @param {string} promotionComponentUID promotionComponentUID
         * @param {boolean} [offline] offline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmartSuggestionComponentsUsingGET: async (storeId: string, promotionComponentUID: string, offline?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getSmartSuggestionComponentsUsingGET', 'storeId', storeId)
            // verify required parameter 'promotionComponentUID' is not null or undefined
            assertParamExists('getSmartSuggestionComponentsUsingGET', 'promotionComponentUID', promotionComponentUID)
            const localVarPath = `/axfood/rest/smart-suggestions/{storeId}/promotion-component/{promotionComponentUID}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"promotionComponentUID"}}`, encodeURIComponent(String(promotionComponentUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (offline !== undefined) {
                localVarQueryParameter['offline'] = offline;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionApi - functional programming interface
 * @export
 */
export const PromotionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get mix promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {number} [onlineSize] Number of online promotions displayed in previous pages
         * @param {number} [offlineSize] Number of offline promotions displayed in previous pages
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMixPromotionsUsingGET(q: string, type?: string, page?: number, size?: number, onlineSize?: number, offlineSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPromotionDataV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMixPromotionsUsingGET(q, type, page, size, onlineSize, offlineSize, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get online and offline campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [size] size
         * @param {number} [onlineSize] onlineSize
         * @param {number} [offlineSize] offlineSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMixedCampaignsUsingGET(q: string, type?: string, size?: number, onlineSize?: number, offlineSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacetSearchPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMixedCampaignsUsingGET(q, type, size, onlineSize, offlineSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get promoted previews
         * @param {string} [q] q
         * @param {Array<string>} [fields] fields
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {number} [dateFrom] dateFrom
         * @param {number} [dateTo] dateTo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfflineCampaignsPreviewUsingGET(q?: string, fields?: Array<string>, page?: number, size?: number, dateFrom?: number, dateTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionSearchPageDataOfSearchStateDataAndAxfoodOfflineProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfflineCampaignsPreviewUsingGET(q, fields, page, size, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offline campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfflineCampaignsUsingGET(q: string, type?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacetSearchPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfflineCampaignsUsingGET(q, type, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offline promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfflinePromotionsUsingGET(q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPromotionDataV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfflinePromotionsUsingGET(q, type, page, size, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get online campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnlineCampaignsUsingGET(q: string, type?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnlineCampaignsUsingGET(q, type, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get online promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnlinePromotionsUsingGET(q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPromotionDataV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnlinePromotionsUsingGET(q, type, page, size, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for given promotion code and store excluding products in parameter
         * @param {string} promotionCode The promotion code
         * @param {string} storeId The search query
         * @param {Array<string>} [excludeProducts] List of excluded products
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsFromPromotionUsingGET(promotionCode: string, storeId: string, excludeProducts?: Array<string>, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPromotionDataV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsFromPromotionUsingGET(promotionCode, storeId, excludeProducts, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get promoted products
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotedProductsUsingGET(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotedProductsUsingGET(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get promotion products
         * @param {string} promotionCode promotionCode
         * @param {Array<string>} [excludeProducts] excludeProducts
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionProductsUsingGET(promotionCode: string, excludeProducts?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPaginationViewModelOfAxfoodBasicProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionProductsUsingGET(promotionCode, excludeProducts, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get smart suggestion promotions
         * @param {string} storeId storeId
         * @param {string} promotionComponentUID promotionComponentUID
         * @param {boolean} [offline] offline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSmartSuggestionComponentsUsingGET(storeId: string, promotionComponentUID: string, offline?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodPromotionSuggestionsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSmartSuggestionComponentsUsingGET(storeId, promotionComponentUID, offline, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionApi - factory interface
 * @export
 */
export const PromotionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get mix promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {number} [onlineSize] Number of online promotions displayed in previous pages
         * @param {number} [offlineSize] Number of offline promotions displayed in previous pages
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMixPromotionsUsingGET(q: string, type?: string, page?: number, size?: number, onlineSize?: number, offlineSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ProductPromotionDataV2Response> {
            return localVarFp.getMixPromotionsUsingGET(q, type, page, size, onlineSize, offlineSize, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get online and offline campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [size] size
         * @param {number} [onlineSize] onlineSize
         * @param {number} [offlineSize] offlineSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMixedCampaignsUsingGET(q: string, type?: string, size?: number, onlineSize?: number, offlineSize?: number, options?: any): AxiosPromise<FacetSearchPageData> {
            return localVarFp.getMixedCampaignsUsingGET(q, type, size, onlineSize, offlineSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get promoted previews
         * @param {string} [q] q
         * @param {Array<string>} [fields] fields
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {number} [dateFrom] dateFrom
         * @param {number} [dateTo] dateTo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCampaignsPreviewUsingGET(q?: string, fields?: Array<string>, page?: number, size?: number, dateFrom?: number, dateTo?: number, options?: any): AxiosPromise<PromotionSearchPageDataOfSearchStateDataAndAxfoodOfflineProductViewModel> {
            return localVarFp.getOfflineCampaignsPreviewUsingGET(q, fields, page, size, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offline campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflineCampaignsUsingGET(q: string, type?: string, page?: number, size?: number, options?: any): AxiosPromise<FacetSearchPageData> {
            return localVarFp.getOfflineCampaignsUsingGET(q, type, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offline promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfflinePromotionsUsingGET(q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ProductPromotionDataV2Response> {
            return localVarFp.getOfflinePromotionsUsingGET(q, type, page, size, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get online campaigns
         * @param {string} q q
         * @param {string} [type] type
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineCampaignsUsingGET(q: string, type?: string, page?: number, size?: number, options?: any): AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel> {
            return localVarFp.getOnlineCampaignsUsingGET(q, type, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get online promotions
         * @param {string} q The search query
         * @param {string} [type] The customer type
         * @param {number} [page] Current page to start at
         * @param {number} [size] Size of each page
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlinePromotionsUsingGET(q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ProductPromotionDataV2Response> {
            return localVarFp.getOnlinePromotionsUsingGET(q, type, page, size, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for given promotion code and store excluding products in parameter
         * @param {string} promotionCode The promotion code
         * @param {string} storeId The search query
         * @param {Array<string>} [excludeProducts] List of excluded products
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsFromPromotionUsingGET(promotionCode: string, storeId: string, excludeProducts?: Array<string>, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ProductPromotionDataV2Response> {
            return localVarFp.getProductsFromPromotionUsingGET(promotionCode, storeId, excludeProducts, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get promoted products
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotedProductsUsingGET(page?: number, size?: number, options?: any): AxiosPromise<ProductSearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModel> {
            return localVarFp.getPromotedProductsUsingGET(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get promotion products
         * @param {string} promotionCode promotionCode
         * @param {Array<string>} [excludeProducts] excludeProducts
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionProductsUsingGET(promotionCode: string, excludeProducts?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<AxfoodPaginationViewModelOfAxfoodBasicProductViewModel> {
            return localVarFp.getPromotionProductsUsingGET(promotionCode, excludeProducts, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get smart suggestion promotions
         * @param {string} storeId storeId
         * @param {string} promotionComponentUID promotionComponentUID
         * @param {boolean} [offline] offline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmartSuggestionComponentsUsingGET(storeId: string, promotionComponentUID: string, offline?: boolean, options?: any): AxiosPromise<AxfoodPromotionSuggestionsViewModel> {
            return localVarFp.getSmartSuggestionComponentsUsingGET(storeId, promotionComponentUID, offline, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionApi - object-oriented interface
 * @export
 * @class PromotionApi
 * @extends {BaseAPI}
 */
export class PromotionApi extends BaseAPI {
    /**
     * 
     * @summary Get mix promotions
     * @param {string} q The search query
     * @param {string} [type] The customer type
     * @param {number} [page] Current page to start at
     * @param {number} [size] Size of each page
     * @param {number} [onlineSize] Number of online promotions displayed in previous pages
     * @param {number} [offlineSize] Number of offline promotions displayed in previous pages
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getMixPromotionsUsingGET(q: string, type?: string, page?: number, size?: number, onlineSize?: number, offlineSize?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getMixPromotionsUsingGET(q, type, page, size, onlineSize, offlineSize, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get online and offline campaigns
     * @param {string} q q
     * @param {string} [type] type
     * @param {number} [size] size
     * @param {number} [onlineSize] onlineSize
     * @param {number} [offlineSize] offlineSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getMixedCampaignsUsingGET(q: string, type?: string, size?: number, onlineSize?: number, offlineSize?: number, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getMixedCampaignsUsingGET(q, type, size, onlineSize, offlineSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get promoted previews
     * @param {string} [q] q
     * @param {Array<string>} [fields] fields
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {number} [dateFrom] dateFrom
     * @param {number} [dateTo] dateTo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getOfflineCampaignsPreviewUsingGET(q?: string, fields?: Array<string>, page?: number, size?: number, dateFrom?: number, dateTo?: number, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getOfflineCampaignsPreviewUsingGET(q, fields, page, size, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offline campaigns
     * @param {string} q q
     * @param {string} [type] type
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getOfflineCampaignsUsingGET(q: string, type?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getOfflineCampaignsUsingGET(q, type, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offline promotions
     * @param {string} q The search query
     * @param {string} [type] The customer type
     * @param {number} [page] Current page to start at
     * @param {number} [size] Size of each page
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getOfflinePromotionsUsingGET(q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getOfflinePromotionsUsingGET(q, type, page, size, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get online campaigns
     * @param {string} q q
     * @param {string} [type] type
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getOnlineCampaignsUsingGET(q: string, type?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getOnlineCampaignsUsingGET(q, type, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get online promotions
     * @param {string} q The search query
     * @param {string} [type] The customer type
     * @param {number} [page] Current page to start at
     * @param {number} [size] Size of each page
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getOnlinePromotionsUsingGET(q: string, type?: string, page?: number, size?: number, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getOnlinePromotionsUsingGET(q, type, page, size, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for given promotion code and store excluding products in parameter
     * @param {string} promotionCode The promotion code
     * @param {string} storeId The search query
     * @param {Array<string>} [excludeProducts] List of excluded products
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration. This is the list of fields that should be returned in the response body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getProductsFromPromotionUsingGET(promotionCode: string, storeId: string, excludeProducts?: Array<string>, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getProductsFromPromotionUsingGET(promotionCode, storeId, excludeProducts, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get promoted products
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getPromotedProductsUsingGET(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getPromotedProductsUsingGET(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get promotion products
     * @param {string} promotionCode promotionCode
     * @param {Array<string>} [excludeProducts] excludeProducts
     * @param {number} [page] page
     * @param {number} [pageSize] pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getPromotionProductsUsingGET(promotionCode: string, excludeProducts?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getPromotionProductsUsingGET(promotionCode, excludeProducts, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get smart suggestion promotions
     * @param {string} storeId storeId
     * @param {string} promotionComponentUID promotionComponentUID
     * @param {boolean} [offline] offline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public getSmartSuggestionComponentsUsingGET(storeId: string, promotionComponentUID: string, offline?: boolean, options?: AxiosRequestConfig) {
        return PromotionApiFp(this.configuration).getSmartSuggestionComponentsUsingGET(storeId, promotionComponentUID, offline, options).then((request) => request(this.axios, this.basePath));
    }
}
