import variables from '@styles/variables';
import { createGlobalStyle, css } from 'styled-components';
import { rem } from 'polished';

let isWindows = false;
if (typeof navigator !== 'undefined') isWindows = navigator.appVersion.indexOf('Win') > -1;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    outline-color: ${variables.colors.highlight};
    outline-width: ${rem(2)};
    outline-offset: ${rem(2)};
  }

  body {
    color: ${variables.colors.lighterBlack};
    margin: 0;
    padding: 0;
    font-family: ${variables.fonts.fontGothamBook};
    scroll-behavior: smooth;
    background-color: ${variables.colors.white};
    overscroll-behavior-x: none;
    overscroll-behavior-y: auto;

    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
  
  html {
    height: -webkit-fill-available;
  }

  p {
    font-family: ${variables.fonts.fontGothamBook};
  }

  a {
    font-family: ${variables.fonts.fontGothamMedium};
    color: ${variables.colors.highlight};
    text-decoration: none;

    &:visited {
      color: ${variables.colors.highlight};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: ${variables.colors.highlightHover};
      }
    }
  }

  h1 {
    font-family: ${variables.fonts.fontGothamSerifBold};
    font-size: 2.5rem;
    line-height: 1.5;
  }

  h2 {
    font-family: ${variables.fonts.fontGothamSerifBold};
    line-height: 1.5;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    touch-action: manipulation;
    color: inherit;
  }

  button,
  a,
  [role="listbox"] {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  ${
    isWindows &&
    css`
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: ${rem(8)};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: ${rem(8)};
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 ${rem(1)} rgba(255, 255, 255, 0.5);
      }
    `
  }

  :root {
    --doc-height: 100%;
  }
`;

export default GlobalStyle;
