import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Select from '@molecules/Select/Select';
import Text from '@atoms/Text/Text';

export const FilterAndSortWrapper = styled.div<{ noMargin: boolean }>`
  position: relative;
  display: flex;
  gap: ${rem(12)};
  align-items: center;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(16)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-bottom: ${rem(24)};
    gap: unset;
    justify-content: space-between;
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(28)};
  }
`;

export const SelectWrapper = styled(Select)`
  flex: 1;
  max-width: 50%;
  margin: 0;

  ${Config.MEDIA.IS_MOBILE} {
    flex: 1 0 0;
    min-width: 0;
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${rem(172)};
    min-width: ${rem(162)};
  }
`;
SelectWrapper.displayName = 'SelectWrapper';

export const StyledAboutThisSearch = styled.div`
  padding: 0 ${rem(6)} ${rem(16)} 0;
  display: flex;
  justify-content: flex-end;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(-12)};
    padding: 0 ${rem(6)} ${rem(12)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-top: ${rem(-16)};
  }
`;

export const StyledNumberOfResults = styled(Text)`
  flex-grow: 1;
`;
