import { Key } from 'react';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackTabChangeProductDetails = (key: Key) => {
  const index = Number(key) - 1;
  const tabs = ['product_information', 'table_of_contents', 'other'];

  pushGTMEvent({
    event: 'Track',
    category: 'product_detail',
    action: `toggled_to_${tabs[index]}`,
    label: '',
    value: 0,
  });
};

export default trackTabChangeProductDetails;
