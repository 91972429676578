import SnackBar, { TIconSnackbar } from '@atoms/SnackBar/SnackBar';
import { createContext, ReactElement, useCallback, useContext, useState } from 'react';

type SnackbarType = {
  text: string;
  bottomPosition?: number;
  icon: TIconSnackbar;
  callback?: () => void;
  link?: string;
  linkText?: string;
  onClick?: () => void;
};

// Context
const SnackbarContext = createContext<(snack: SnackbarType | null) => void>(() => {
  /* no op */
});

export interface SnackbarProviderProps {
  children: ReactElement;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snack, setSnack] = useState<SnackbarType | null>(null);
  const [show, setShow] = useState(false);

  const addSnack = useCallback((s: SnackbarType | null) => {
    setSnack(s);
    setShow(true);
  }, []);

  return (
    <SnackbarContext.Provider value={addSnack}>
      {children}
      {show && snack ? (
        <SnackBar
          bottomPosition={snack?.bottomPosition}
          text={snack?.text}
          show={setShow}
          callback={snack?.callback}
          icon={snack?.icon}
          link={snack?.link}
          linkText={snack?.linkText}
          onClick={snack?.onClick}
        />
      ) : null}
    </SnackbarContext.Provider>
  );
};

const useSnackBar = () => useContext(SnackbarContext);

export default useSnackBar;
