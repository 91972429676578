import { StyledNoResultWrapper } from '@organisms/MultiSearchComponent/MultiSearchComponent.styles';
import Heading from '@atoms/Heading/Heading';
import Icon from '@atoms/Icon/Icon';
import Text from '@atoms/Text/Text';
import { useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Edit from '@icons/edit.svg';

interface Props {
  noResult: any;
  noResultOnFocusHandler: any;
  noResultOnBlurHandler: any;
  noResultOnKeyDownHandler: any;
}

const MultiSearchNoResult = ({
  noResult,
  noResultOnKeyDownHandler,
  noResultOnFocusHandler,
  noResultOnBlurHandler,
}: Props) => {
  const { t } = useTranslation('multiSearch');
  const [showEditIcon, setShowEditIcon] = useState(true);
  const headingRef = useRef<HTMLHeadingElement>(null);

  const selectElementContents = (el: any) => {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  };

  return (
    <div>
      <StyledNoResultWrapper>
        <Heading
          variant="h2"
          size="small"
          contentEditable={true}
          onFocus={noResultOnFocusHandler}
          onBlur={(e) => {
            noResultOnBlurHandler(e);
            setShowEditIcon(true);
          }}
          onKeyDown={noResultOnKeyDownHandler}
          headingRef={headingRef}
          spellCheck={false}
        >
          {noResult.freeTextSearch}
        </Heading>
        {showEditIcon && (
          <Icon
            onClick={() => {
              const el = headingRef.current;
              selectElementContents(el);
              setShowEditIcon(false);
            }}
            svg={Edit}
            size={16}
          />
        )}
      </StyledNoResultWrapper>
      <Text type="body">{t('noresult')}</Text>
    </div>
  );
};

export default MultiSearchNoResult;
