import { ReactNode } from 'react';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import GridContainer from '@organisms/GridContainer/GridContainer';
import NavigationBreadcrumbComponent from '@molecules/NavigationBreadcrumbComponent/NavigationBreadcrumbComponent';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import {
  StyledContentWrapper,
  StyledMainSlot,
  StyledRightContentWrapper,
  StyledSlot,
} from './WillysDynamicMasterTemplate.style';
import collectSlots from '@api/interfaces/collectSlots';
import useSmartEdit from '@hooks/useSmartEdit';
import type { PageWithSlots } from '@api/interfaces/pageApi';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  dynamicMasterSlots: string[];
}

const WillysDynamicMasterTemplate = ({ cmsPage, children, dynamicMasterSlots }: Props) => {
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const { TopWide, TopRightContent, MainContent, RightContent, BottomWide } = collectSlots(
    cmsPage.slots,
    dynamicMasterSlots
  );
  const hasRightContent = TopRightContent?.component?.length > 0 || RightContent?.component?.length > 0;
  const { breadcrumbs = [] } = cmsPage;
  const breadCrumbDataArray = Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs];
  const { isSmartEditEnabled } = useSmartEdit();

  return (
    <>
      {(isSmartEditEnabled || TopWide?.component?.length > 0) && (
        <StyledSlot slotData={TopWide} data-testid="topWide-slot">
          {TopWide?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </StyledSlot>
      )}
      {children && children}
      <GridContainer addPadding={sideMenuIsOpen || isCartPreviewOpen}>
        <StyledContentWrapper>
          {(isSmartEditEnabled || MainContent?.component?.length > 0) && (
            <StyledMainSlot
              slotData={MainContent}
              data-testid="mainContent-slot"
              hasRightContent={hasRightContent}
              addMarginTop={!TopWide?.component?.length}
            >
              {breadCrumbDataArray.length > 0 && <NavigationBreadcrumbComponent data={breadCrumbDataArray} />}
              {MainContent?.component?.map((child) => (
                <CmsComponent key={child.uuid} component={child} />
              ))}
            </StyledMainSlot>
          )}

          {hasRightContent && (
            <StyledRightContentWrapper
              hideSlot={sideMenuIsOpen && isCartPreviewOpen}
              addMarginTop={!TopWide?.component?.length || isSmartEditEnabled}
            >
              {(isSmartEditEnabled || TopRightContent?.component?.length > 0) && (
                <StyledSlot slotData={TopRightContent} data-testid="topRight-slot">
                  {TopRightContent?.component?.map((child) => (
                    <CmsComponent key={child.uuid} component={child} />
                  ))}
                </StyledSlot>
              )}

              {(isSmartEditEnabled || RightContent?.component?.length > 0) && (
                <StyledSlot slotData={RightContent} data-testid="rightContent-slot">
                  {RightContent?.component?.map((child) => (
                    <CmsComponent key={child.uuid} component={child} />
                  ))}
                </StyledSlot>
              )}
            </StyledRightContentWrapper>
          )}
        </StyledContentWrapper>
      </GridContainer>
      {(isSmartEditEnabled || BottomWide?.component?.length > 0) && (
        <StyledSlot slotData={BottomWide} data-testid="bottomWide-slot">
          {BottomWide?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </StyledSlot>
      )}
    </>
  );
};

export default WillysDynamicMasterTemplate;
