import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import Button from '@atoms/Button/Button';
import Config from '@config';
import Icon from '@atoms/Icon/Icon';

const desktopWidth = 420;

export const sizes = {
  headerHeight: 100,
  footerHeight: 91,
};

export const StyledProductListFilterWrapper = styled.div`
  width: 100vw;
  height: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(420)};
  }
`;

export const StyledProductListFilter = styled.div<{ $loading?: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  ${Config.MEDIA.IS_MOBILE} {
    flex: 1 0 0;
    min-width: 0;
  }

  ${(props) =>
    props.$loading &&
    css`
      pointer-events: none;
    `}
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex: unset;
  }
`;

export const StyledProductListFilterTakeoverHeader = styled.div`
  padding: ${rem(20)};
  display: flex;
  align-items: center;
  height: ${rem(sizes.headerHeight)};
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: 100%;
  }
`;

export const StyledProductListFilterTakeoverBody = styled.div`
  max-height: calc(100% - ${rem(sizes.headerHeight + sizes.footerHeight)});
  overflow-y: auto;
`;

export const StyledProductListFilterTakeoverFooter = styled.div`
  box-shadow: 0 ${rem(-4)} ${rem(30)} 0 ${rgba(variables.colors.black, 0.1)};
  padding: ${rem(20)};
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: ${variables.colors.white};
  width: 100%;
  height: ${sizes.footerHeight};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(desktopWidth)};
  }
`;

export const StyledProductListFilterTakeoverButton = styled(Button)`
  flex: 1;

  &:first-child {
    margin-right: ${rem(20)};
  }
`;

export const StyledOpenFilterButton = styled(Button)<{ isPromotionPage: boolean }>`
  width: 100%;

  ${(props) =>
    props.isPromotionPage &&
    css`
      width: auto;
    `}
`;

export const StyledCloseIcon = styled(Icon)`
  position: absolute;
  top: ${rem(20)};
  right: ${rem(20)};
`;
