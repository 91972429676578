import { Key, useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import Config from '@config';
import trackTabChange from '@helpers/analyticsHelpers/trackTabChangeProductDetails';
import TabContent from '@molecules/Tabs/TabContent';
import ProductTableOfContents from '@molecules/ProductTableOfContents/ProductTableOfContents';
import ProductTableOfOther from '@molecules/ProductTableOfOther/ProductTableOfOther';
import ExpandableContainer from '@molecules/ExpandableContainer/ExpandableContainer';
import ProductTableOfInformation from '@molecules/ProductTableOfInformation/ProductTableOfInformation';
import { useRouter } from 'next/router';
import { StyledSwipeableSection, StyledSwipeableSectionTabs } from './ProductDetailsTabs.styles';
import useResponsive from '@hooks/useResponsive';

type Props = {
  data: any;
};

const ProductDetailsTabs = ({ data }: Props) => {
  const { query } = useRouter();
  const { t } = useTranslation('product');
  const [activeTab, setActiveTab] = useState('1');
  const [initiallyExpanded, setInitiallyExpanded] = useState(false);
  const { isMobile } = useResponsive();

  const expandableMaxHeight = 150;

  const onTabChange = (key: Key) => {
    trackTabChange(key);
    setActiveTab(String(key));
  };

  const swipeHandlers = useSwipeable({
    onSwiped: (e) => {
      if (isMobile) {
        if (e.dir === 'Left') {
          if (+activeTab + 1 > 3) return;
          setActiveTab((+activeTab + 1).toString());
        }
        if (e.dir === 'Right') {
          if (+activeTab - 1 < 1) return;
          setActiveTab((+activeTab - 1).toString());
        }
      }
    },
  });

  useEffect(() => {
    if (query?.tab && typeof query.tab === 'string' && ['1', '2', '3'].includes(query.tab)) {
      setActiveTab(query.tab);
      setInitiallyExpanded(true);
    } else {
      setActiveTab('1');
    }
  }, [data, query]);

  return (
    <StyledSwipeableSection {...swipeHandlers}>
      <StyledSwipeableSectionTabs
        variant="product"
        activeTab={activeTab}
        onTabChange={onTabChange}
        slideIndex={isMobile ? +activeTab : 0}
      >
        <TabContent key="1" title={t('product->tabs->product_information')}>
          <ExpandableContainer
            key="1"
            initiallyExpanded={data.medicineType !== '' || initiallyExpanded}
            maxHeight={expandableMaxHeight}
            meta="product_information"
          >
            <ProductTableOfInformation data={data} />
          </ExpandableContainer>
        </TabContent>

        {(data?.ingredients || data?.animalData || data?.nutrientComponents.length > 0) && (
          <TabContent key="2" title={t('product->tabs->table_of_contents')}>
            <ExpandableContainer
              key="2"
              maxHeight={expandableMaxHeight}
              meta="table_of_contents"
              initiallyExpanded={initiallyExpanded}
            >
              <ProductTableOfContents product={data} />
            </ExpandableContainer>
          </TabContent>
        )}

        <TabContent key="3" title={t('product->tabs->other')}>
          <ExpandableContainer
            key="3"
            initiallyExpanded={initiallyExpanded}
            maxHeight={expandableMaxHeight}
            meta="other"
          >
            <ProductTableOfOther data={data} />
          </ExpandableContainer>
        </TabContent>
      </StyledSwipeableSectionTabs>
    </StyledSwipeableSection>
  );
};

export default ProductDetailsTabs;
