import type { WishlistEntryForm, WishlistForm } from '@occ/api-client';
import { WishListSortOrder } from '@molecules/WishList/WishList';
import { AxfoodApi } from '@occ/api-client';

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

export enum WishlistsSortOrder {
  LAST_UPDATED_DESC = 'LAST_UPDATED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export const getUserWishlists = async (sorting = WishlistsSortOrder.LAST_UPDATED_DESC) => {
  const response = await axfoodApi.getUserWishListsUsingGet({ basic: true, sorting });
  return response.data;
};

export const createNewWishList = (form: WishlistForm) => axfoodApi.createWishlistUsingPost(form);

export const getUserWishList = (id: string) => axfoodApi.getUserWishlistUsingGet(id);

export const updateWishList = (id: string, wishListForm: WishlistForm) =>
  axfoodApi.updateWishlistUsingPost(id, wishListForm);

export const wishListAddToCart = (id: string, wishListForm: WishlistForm) =>
  axfoodApi.addProductsToCartUsingPost(id, wishListForm);

export const deleteWishList = (id: string) => axfoodApi.deleteUserWishListUsingDelete(id);

export const deleteMultipleWishLists = (wishListIds: string[]) => axfoodApi.deleteUserWishListsUsingDelete(wishListIds);

export const updateWishListSorting = (id: string, sorting: WishListSortOrder) =>
  axfoodApi.updateWishlistSortingUsingPut(id, { sorting });

export const checkEntriesInWishList = (id: string, wishlistForm: WishlistForm) =>
  axfoodApi.checkEntriesInUserWishlistUsingPut(id, wishlistForm);

export const getWishListEntriesStatus = (wishlistForm: WishlistForm, signal: AbortSignal) =>
  axfoodApi.validateWishlistEntriesUsingPost(wishlistForm, { checkStock: true }, { signal });

export const removeEntriesFromWishList = (id: string, identifiers: string[]) =>
  axfoodApi.removeEntriesFromUserWishlistUsingDelete(id, identifiers);

export const getListStatus = (listID: string) => axfoodApi.validateOrderEntriesUsingGet1(listID, { checkStock: true });

export const addToUserWishlist = async (id: string, productData: Array<WishlistEntryForm>) => {
  const form = makeForm(productData);
  const response = await axfoodApi.addProductsToUserWishlistUsingPost(id, form);
  return response.data;
};

export const shareWishList = (id: string) => axfoodApi.shareWishlistUsingPost(id);

export const getSharedWishList = async (id: string) => {
  const response = await axfoodApi.getSharedWishlistUsingGet(id);
  return response.data;
};

const makeForm = (productData: Array<WishlistEntryForm>, listName?: string) => {
  const entries = productData.map((p) => {
    const { entryType, pickUnit, productCode, promotionCode, quantity } = p;
    if (entryType === 'PROMOTION') {
      return { entryType, pickUnit, productCode, promotionCode, quantity };
    }
    return { entryType, pickUnit, productCode, quantity };
  });
  return listName ? { name: listName, entries } : { entries };
};

export const createUserWishlist = async (listName: string, productData: Array<WishlistEntryForm>) => {
  const form = makeForm(productData, listName);
  const response = await axfoodApi.createWishlistUsingPost(form);
  return response.data;
};
