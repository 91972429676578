import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import { LabelSplashType } from '@molecules/ProductLabelSplash/ProductLabelSplash';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';

export const StyledProductLabelSplash = styled.span<{
  type: LabelSplashType;
  blockElement?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${rem(5)};

  ${(props) =>
    props.blockElement &&
    css`
      margin-bottom: ${rem(14)};
      display: inline-block;
    `}

  ${(props) =>
    !props.blockElement &&
    css`
      position: absolute;
      bottom: 0;
      left: ${rem(12)};
    `}

  height: ${rem(20)};
  padding: ${rem(4)} ${rem(8)};
  font-size: ${rem(10)};
  line-height: ${rem(11)};
  font-family: ${variables.fonts.fontGothamMedium};
  border-radius: ${rem(2)};
  text-transform: uppercase;

  ${(props) =>
    (props.type === 'mixmatch' || props.type === 'medical' || props.type === 'bargain') &&
    css`
      background-color: ${variables.colors.white};
      border: solid ${rem(1)} ${variables.colors.mediumGray};
      color: ${props.type === 'mixmatch' ? variables.colors.primary : variables.colors.lighterBlack};
    `}

  ${(props) =>
    props.type === 'news' &&
    css`
      background-color: ${variables.colors.error};
      color: ${variables.colors.white};
    `}

  ${(props) =>
    props.type === 'age_18' &&
    css`
      background-color: ${variables.colors.plus};
      border: solid ${rem(1)} ${variables.colors.primary};
      color: ${variables.colors.lighterBlack};
    `}

  ${(props) =>
    props.type === 'segmented' &&
    css`
      background-color: ${variables.colors.plus};
      color: ${variables.colors.lighterBlack};
    `}
`;

export const StyledIconTooltip = styled(IconTooltip)`
  position: static;
  margin: 0;
`;
