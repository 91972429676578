import styled, { css } from 'styled-components';
import Icon from '@atoms/Icon/Icon';
import { rem, rgba } from 'polished';
import variables from '@styles/variables';

export const StyledContainer = styled.span`
  display: inline-block;
  position: relative;
  left: ${rem(6)};
  top: ${rem(3)};
  margin-top: ${rem(-3)};
  z-index: 1;
`;

export const StyledContent = styled.span`
  position: relative;
  display: flex;
`;

export const StyledIcon = styled(Icon)<{ disabled?: boolean }>`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;
