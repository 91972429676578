import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import Button from '@atoms/Button/Button';

export const StyledAlert = styled.div<{ theme: messageTheme; closeable: boolean }>`
  width: 100%;
  line-height: ${rem(20)};
  font-size: ${rem(13)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(18)} ${rem(20)};
  min-height: ${rem(56)};
  pointer-events: auto;
  text-align: center;

  p {
    margin: 0;
  }

  ${(props) =>
    props.closeable &&
    css`
      padding: ${rem(18)} ${rem(36)} ${rem(18)} ${rem(20)};
    `}

  ${(props) =>
    props.theme === 'cookie' &&
    css`
      background: ${variables.colors.black};
      color: ${variables.colors.white};
      padding: ${rem(18)} ${rem(36)} ${rem(18)} ${rem(24)};
      min-height: ${rem(96)};
      text-align: left;
    `}

  ${(props) =>
    props.theme === 'global' &&
    css`
      background: ${variables.colors.black};
      color: ${variables.colors.white};
      padding: ${rem(6)} ${rem(20)};
      min-height: ${rem(32)};
    `}

  ${(props) =>
    props.theme === 'info' &&
    css`
      background: ${rgba(variables.colors.highlight, 0.05)};
      color: ${variables.colors.black};
    `}

  ${(props) =>
    props.theme === 'permanent' &&
    css`
      background: ${variables.colors.highlightBg};
      color: ${variables.colors.lighterBlack};
    `}
`;

export const StyledButton = styled(Button)<{ variant: messageTheme }>`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};

  ${(props) =>
    props.variant === 'global' &&
    css`
      top: ${rem(8)};
      right: ${rem(8)};
    `}
`;
