import { ReactNode, RefObject } from 'react';
import { StyledH1, StyledH2, StyledH3 } from './Heading.styles';

interface Props {
  variant: HeadingType;
  color?: HeadingColors;
  size?: string;
  children?: ReactNode;
  label?: boolean;
  className?: string;
  testId?: string;
  contentEditable?: boolean;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  headingRef?: RefObject<HTMLHeadingElement>;
  spellCheck?: boolean;
}

const Heading = ({
  variant,
  color = 'black',
  size,
  children,
  label = false,
  className,
  testId,
  contentEditable,
  onFocus,
  onBlur,
  onKeyDown,
  headingRef,
  spellCheck,
}: Props) => {
  const getHtmlElement = () => {
    switch (variant) {
      case 'h1':
        return (
          <StyledH1
            className={className}
            isLabel={label}
            size={size}
            color={color}
            data-testid={testId}
            contentEditable={contentEditable}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            ref={headingRef}
            spellCheck={spellCheck}
            suppressContentEditableWarning
          >
            {children}
          </StyledH1>
        );
      case 'h2':
        return (
          <StyledH2
            className={className}
            isLabel={label}
            size={size}
            color={color}
            data-testid={testId}
            contentEditable={contentEditable}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            ref={headingRef}
            spellCheck={spellCheck}
            suppressContentEditableWarning
          >
            {children}
          </StyledH2>
        );
      case 'h3':
        return (
          <StyledH3
            className={className}
            isLabel={label}
            color={color}
            data-testid={testId}
            contentEditable={contentEditable}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            ref={headingRef}
            spellCheck={spellCheck}
            suppressContentEditableWarning
          >
            {children}
          </StyledH3>
        );
      default:
        return (
          <StyledH1
            className={className}
            isLabel={label}
            size={size}
            color={color}
            data-testid={testId}
            contentEditable={contentEditable}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            ref={headingRef}
            spellCheck={spellCheck}
            suppressContentEditableWarning
          >
            {children}
          </StyledH1>
        );
    }
  };

  return <>{getHtmlElement()}</>;
};

export default Heading;
