/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodExternalAllVoucherViewModel } from '../dto';
// @ts-ignore
import { AxfoodExternalVoucherViewModel } from '../dto';
/**
 * VoucherApi - axios parameter creator
 * @export
 */
export const VoucherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate external voucher
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateExternalVoucherUsingPUT: async (voucherCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherCode' is not null or undefined
            assertParamExists('activateExternalVoucherUsingPUT', 'voucherCode', voucherCode)
            const localVarPath = `/axfood/rest/externalvoucher/activate/{voucherCode}`
                .replace(`{${"voucherCode"}}`, encodeURIComponent(String(voucherCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all external vouchers
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllExternalVouchersOnlineAndOfflineUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/allexternalvouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all external vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalVoucherCountUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/externalvoucher/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get external voucher for club info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalVoucherForClubInfoUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/externalvoucher/clubinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get external vouchers
         * @param {boolean} [isOnline] isOnline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalVoucherUsingGET: async (isOnline?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/externalvoucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (isOnline !== undefined) {
                localVarQueryParameter['isOnline'] = isOnline;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invalid external voucher
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidExternalVoucherUsingGET: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getInvalidExternalVoucherUsingGET', 'orderId', orderId)
            const localVarPath = `/axfood/rest/externalvoucher/invalid/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoucherApi - functional programming interface
 * @export
 */
export const VoucherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoucherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate external voucher
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateExternalVoucherUsingPUT(voucherCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateExternalVoucherUsingPUT(voucherCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all external vouchers
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllExternalVouchersOnlineAndOfflineUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodExternalAllVoucherViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllExternalVouchersOnlineAndOfflineUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all external vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalVoucherCountUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalVoucherCountUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get external voucher for club info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalVoucherForClubInfoUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodExternalVoucherViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalVoucherForClubInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get external vouchers
         * @param {boolean} [isOnline] isOnline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalVoucherUsingGET(isOnline?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodExternalVoucherViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalVoucherUsingGET(isOnline, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get invalid external voucher
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvalidExternalVoucherUsingGET(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvalidExternalVoucherUsingGET(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoucherApi - factory interface
 * @export
 */
export const VoucherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoucherApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate external voucher
         * @param {string} voucherCode voucherCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateExternalVoucherUsingPUT(voucherCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.activateExternalVoucherUsingPUT(voucherCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all external vouchers
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllExternalVouchersOnlineAndOfflineUsingGET(options?: any): AxiosPromise<AxfoodExternalAllVoucherViewModel> {
            return localVarFp.getAllExternalVouchersOnlineAndOfflineUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all external vouchers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalVoucherCountUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.getExternalVoucherCountUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get external voucher for club info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalVoucherForClubInfoUsingGET(options?: any): AxiosPromise<AxfoodExternalVoucherViewModel> {
            return localVarFp.getExternalVoucherForClubInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get external vouchers
         * @param {boolean} [isOnline] isOnline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalVoucherUsingGET(isOnline?: boolean, options?: any): AxiosPromise<AxfoodExternalVoucherViewModel> {
            return localVarFp.getExternalVoucherUsingGET(isOnline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invalid external voucher
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidExternalVoucherUsingGET(orderId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getInvalidExternalVoucherUsingGET(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoucherApi - object-oriented interface
 * @export
 * @class VoucherApi
 * @extends {BaseAPI}
 */
export class VoucherApi extends BaseAPI {
    /**
     * 
     * @summary Activate external voucher
     * @param {string} voucherCode voucherCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherApi
     */
    public activateExternalVoucherUsingPUT(voucherCode: string, options?: AxiosRequestConfig) {
        return VoucherApiFp(this.configuration).activateExternalVoucherUsingPUT(voucherCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all external vouchers
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VoucherApi
     */
    public getAllExternalVouchersOnlineAndOfflineUsingGET(options?: AxiosRequestConfig) {
        return VoucherApiFp(this.configuration).getAllExternalVouchersOnlineAndOfflineUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all external vouchers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherApi
     */
    public getExternalVoucherCountUsingGET(options?: AxiosRequestConfig) {
        return VoucherApiFp(this.configuration).getExternalVoucherCountUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get external voucher for club info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherApi
     */
    public getExternalVoucherForClubInfoUsingGET(options?: AxiosRequestConfig) {
        return VoucherApiFp(this.configuration).getExternalVoucherForClubInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get external vouchers
     * @param {boolean} [isOnline] isOnline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherApi
     */
    public getExternalVoucherUsingGET(isOnline?: boolean, options?: AxiosRequestConfig) {
        return VoucherApiFp(this.configuration).getExternalVoucherUsingGET(isOnline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invalid external voucher
     * @param {string} orderId orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherApi
     */
    public getInvalidExternalVoucherUsingGET(orderId: string, options?: AxiosRequestConfig) {
        return VoucherApiFp(this.configuration).getInvalidExternalVoucherUsingGET(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}
