import { SearchApi } from '@occ/api-client';

const searchApi = new SearchApi({
  baseUrl: '',
});

export const getSearchAutocomplete = (query: string) =>
  searchApi.getAutocompleteSuggestionsUsingGet('SearchBox', { term: decodeURIComponent(query) }, { format: 'json' });

interface GetSearchResultsParams {
  q: string;
  size?: number;
  page?: number;
  sort?: string;
}

export const getRelatedSearch = ({ q, size, page, sort }: GetSearchResultsParams) => {
  return searchApi.getRelatedResultsUsingGet(
    {
      q,
      page,
      size,
      sort,
    },
    { format: 'json' }
  );
};
