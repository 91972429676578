import { ReactElement, cloneElement, useEffect, useState } from 'react';

interface Props {
  children: Array<ReactElement | null | ''>;
  currentOpened?: string | null;
  customClickHandler?: (accordionKey: string) => void;
}

const AccordionGroup = ({ currentOpened, children, customClickHandler }: Props) => {
  const [internalActiveAccordion, setInternalActiveAccordion] = useState<string | null>(null);

  const setCurrentOpenAccordion = (accordionKey: string) => {
    if (customClickHandler) {
      customClickHandler(accordionKey);
    } else {
      setInternalActiveAccordion(accordionKey === internalActiveAccordion ? null : accordionKey);
    }
  };

  useEffect(() => {
    if (currentOpened !== undefined) setInternalActiveAccordion(currentOpened);
  }, [currentOpened]);

  return (
    <>
      {children?.map((child) => {
        if (!child) return null;
        return cloneElement(child, {
          customClickHandler: setCurrentOpenAccordion,
          isOpen: child.props.accordionKey === internalActiveAccordion,
        });
      })}
    </>
  );
};

export default AccordionGroup;
