import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackFilteredOnYear = (option: OptionType) => {
  pushGTMEvent({
    event: 'Track',
    category: 'news',
    action: 'news_filtered_on_year',
    label: `${option.value}`,
    value: 0,
  });
};

export const trackShowMore = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'news',
    action: 'show_more_news',
    label: '',
    value: 0,
  });
};
