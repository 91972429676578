import Tile from '@atoms/Tile/Tile';
import Link from '@atoms/Link/Link';

import useCustomRouter from '@hooks/useCustomRouter';
import paths from '@constants/paths';
import webToAppApi from '@api/web-to-app';
import { StyledActiveFilters, StyledTileContainer } from './SelectedFilters.styles';
import type { BreadcrumbDataOfSearchStateData } from '@occ/api-client';

interface Props {
  breadcrumbs: Array<BreadcrumbDataOfSearchStateData>;
}

export interface BreadcrumbDataWithFaceValueName extends BreadcrumbDataOfSearchStateData {
  facetValueName: string;
}

const SelectedFilters = ({ breadcrumbs }: Props) => {
  const router = useCustomRouter();

  const notifyApp = (filterValue: string) => {
    if (router.pathname === paths.OFFERS) {
      webToAppApi.eventOffersFiltered(filterValue);
    }
  };

  return (
    <StyledActiveFilters>
      <StyledTileContainer>
        {breadcrumbs
          .filter((breadcrumb): breadcrumb is BreadcrumbDataWithFaceValueName => breadcrumb.facetValueName !== undefined)
          .map((breadcrumb) => {
            /*
            TODO: Refactor to only use breadcrumb?.removeQuery?.query?.value
            This code is required because the value property contains a lot of Solr-specific
            internals (boost rules etc.) that we do not want in the URL.
            Temporary solution is to use breadcrumb?.removeQuery?.url (only for /erbjudanden) but it contains
            the full categoryPath so it cannot be used for the category page.
           */

            let filterQuery = breadcrumb?.removeQuery?.query?.value;
            if (router.pathname?.startsWith('/erbjudanden') && breadcrumb?.removeQuery?.url) {
              filterQuery = decodeURIComponent(breadcrumb.removeQuery.url);
            }
            return (
              <Link
                internal
                scroll={false}
                href={{ pathname: router.pathname, query: { ...router.query, q: filterQuery } }}
                onClick={() => notifyApp(breadcrumb.facetValueName)}
                key={breadcrumb.facetValueCode}
              >
                <Tile selected={false} name={breadcrumb.facetValueName} />
              </Link>
            );
          })}
      </StyledTileContainer>
    </StyledActiveFilters>
  );
};

export default SelectedFilters;
