import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackCategorySuggestionClick = (categoryName: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'continued_to_category',
    label: categoryName,
    value: 0,
  });
};

export const trackSearchTermSuggestionClick = (suggestion: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'suggested_keyword_selected',
    label: suggestion,
    value: 0,
  });
};

export const trackEmptyResultShown = (searchTerm: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'empty_search_result_shown',
    label: searchTerm,
    value: 0,
  });
};

export const trackEditorialSuggestion = (searchTerm: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'editor_suggestion_shown',
    label: searchTerm,
    value: 0,
  });
};

export const trackEditorialSuggestionClick = (searchTerm: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'continued_to_editor_suggestion',
    label: searchTerm,
    value: 0,
  });
};

export const trackSuggestionGoToAllProductsClick = (searchTerm: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'continued_to_full_search',
    label: searchTerm,
    value: 0,
  });
};

export const trackSearch = (searchTerm: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'search_as_you_type',
    action: 'search',
    label: searchTerm,
    value: 0,
  });
};
