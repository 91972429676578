import useTranslation from 'next-translate/useTranslation';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import {
  StyledUspWrapper,
  StyledUspItem,
  StyledUspLink,
  StyledUspText,
  StyledUspNewsText,
  StyledUspIcon,
  StyledUspItemWrapper,
} from './Usps.styles';
import useCmsComponents from '@hooks/useCmsComponents';
import type { ComponentAdaptedData } from '@occ/api-client';
import useResponsive from '@hooks/useResponsive';
import Checkmark from '@icons/checkmark.svg';

interface Props {
  data: { contentUsps: string };
}

interface UspComponentAdaptedData extends ComponentAdaptedData {
  contentUspType: string;
  contentUspText: string;
  uspLinkUrl?: string;
  uspLinkTarget?: string;
}

const Usps = ({ data }: Props) => {
  const { t } = useTranslation('common');
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSideNavMenuOpen = useAppSelector(selectSideNavMenuIsOpen);

  const { isMobile, fromDesktop } = useResponsive();

  const sliceValue = fromDesktop && (!isCartPreviewOpen || !isSideNavMenuOpen) ? 4 : 3;
  const { data: uspsItem } = useCmsComponents(data.contentUsps);

  const uspItemHtml = (item: UspComponentAdaptedData, hasLink: boolean) => {
    return (
      <>
        {item.contentUspType === 'NEWS' && <StyledUspNewsText>{t('usps->news')}</StyledUspNewsText>}
        {item.contentUspType === 'CHECKBOX' && (
          <StyledUspIcon svg={Checkmark} size={12} color="primary" data-testid="checkbox-icon" hasLink={hasLink} />
        )}
        <StyledUspText>{item.contentUspText}</StyledUspText>
      </>
    );
  };

  const trackLinkClick = (uspText: string) => {
    pushGTMEvent({
      event: 'Track',
      category: 'usp_bar',
      action: 'navigation',
      label: uspText,
      value: 0,
    });
  };

  const showUsps = !isMobile;

  return (
    <>
      {showUsps && (
        <StyledUspWrapper>
          {(uspsItem?.component ?? [])
            .filter((item): item is UspComponentAdaptedData => 'contentUspType' in item)
            .slice(0, sliceValue)
            .map((child, index: number) => (
              <StyledUspItem data-testid="usp-item" key={`${index}-${child.uuid}`}>
                {child?.uspLinkUrl ? (
                  <StyledUspLink
                    variant="usp"
                    href={child.uspLinkUrl}
                    onClick={() => trackLinkClick(child.contentUspText)}
                    target={child?.uspLinkTarget === 'true' ? '_blank' : ''}
                  >
                    <span>{uspItemHtml(child, true)}</span>
                  </StyledUspLink>
                ) : (
                  <StyledUspItemWrapper>{uspItemHtml(child, false)}</StyledUspItemWrapper>
                )}
              </StyledUspItem>
            ))}
        </StyledUspWrapper>
      )}
    </>
  );
};

export default Usps;
