import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import InputField from '@atoms/InputField/InputField';
import { SizeTypes, ThemeTypes } from '@molecules/AddToCart/AddToCart';
import {
  PrimaryButton,
  SecondaryButton,
  ShowHideAnimation,
  WishListButton,
} from '@molecules/AddToCart/AddToCartButton.styles';
import {
  PrimaryInputFieldStyle,
  SecondaryInputFieldStyle,
  WishlistInputFieldStyle,
} from '@molecules/AddToCart/AddToCartInputField.styles';
import {
  PrimaryWrapperStyle,
  SecondaryWrapperStyle,
  WishlistWrapperStyle,
} from '@molecules/AddToCart/AddToCartWrapper.styles';
import Button from '@atoms/Button/Button';

export const StyledOutOfStock = styled.div<{ theme: ThemeTypes }>`
  display: flex;
  align-items: center;
  border-radius: ${rem(22)};
  padding: ${rem(4)};
  height: ${rem(44)};
  justify-content: center;
  text-align: center;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(15)};
  color: ${variables.colors.darkerGray};

  ${(props) =>
    props.theme === 'primary' &&
    css`
      background-color: ${variables.colors.lighterGray};
    `}
`;

export const StyledAddToCart = styled.div<{ disabled: boolean; hasItems: boolean; theme: ThemeTypes; size: SizeTypes }>`
  ${(props) =>
    props.theme === 'primary' &&
    css`
      ${PrimaryWrapperStyle};
    `}

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      ${SecondaryWrapperStyle};
    `}

  ${(props) =>
    props.theme === 'wishlist' &&
    css`
      ${WishlistWrapperStyle};
    `}
`;

export const StyledAddToCartButton = styled.button<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size: SizeTypes;
  hide: boolean;
}>`
  ${(props) =>
    props.theme === 'primary' &&
    css`
      ${PrimaryButton};
    `}

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      ${SecondaryButton};
    `}

  ${(props) =>
    props.theme === 'wishlist' &&
    css`
      ${WishListButton};
    `}
`;
export const StyledAddToCartButtonConfirm = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ${variables.fonts.fontGothamMedium};
`;

export const StyledInputFieldWrapper = styled.div`
  flex: 1;
  ${ShowHideAnimation}
`;
export const StyledAddToCartInputField = styled(InputField)<{
  hasItems: boolean;
  isActive: boolean;
  theme: ThemeTypes;
  size?: SizeTypes;
}>`
  ${(props) =>
    props.theme === 'primary' &&
    css`
      ${PrimaryInputFieldStyle};
    `}

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      ${SecondaryInputFieldStyle};
    `}

  ${(props) =>
    props.theme === 'wishlist' &&
    css`
      ${WishlistInputFieldStyle};
    `}
`;

export const StyledAddToCartBuyButton = styled(Button)`
  height: ${rem(28)};
  width: 100%;
`;
