import styled from 'styled-components';
import Config from '@config';

export const StyledColumnComponent = styled.div<{ widthPercent: string }>`
  flex-grow: 0;
  min-width: 0;
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-width: ${(props) => props.widthPercent};
    flex-basis: ${(props) => props.widthPercent};
  }
`;
