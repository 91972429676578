import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import NextLink from 'next/link';
import useCustomRouter, { reserveQueries } from '@hooks/useCustomRouter';
import { UrlObject } from 'url';
import useUserAgent from '@hooks/useUserAgent';
import { StyledLink } from './Link.styles';

type UrlType = string | UrlObject; // TODO: is it possible to convert to a global type?

interface Props {
  children: ReactNode;
  href: UrlType;
  asProp?: UrlType;
  passHref?: boolean;
  prefetch?: boolean;
  replace?: boolean;
  scroll?: boolean;
  variant?: LinkVariant;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  customStyle?: CSSProperties;
  internal?: boolean;
  external?: boolean;
  itemscope?: boolean;
  itemtype?: string;
  itemprop?: string;
  shallow?: boolean;
  target?: string;
  rel?: string;
  role?: React.AriaRole;
  nativeAppMethodOverride?: 'replace' | 'push';
  'data-testid'?: string;
  ariaSelected?: boolean;
}

const Link = ({
  variant,
  asProp,
  passHref = true,
  replace = false,
  scroll = true,
  prefetch,
  href = '/',
  children,
  className,
  onClick,
  customStyle,
  internal,
  external,
  itemscope,
  itemtype,
  itemprop,
  shallow,
  target,
  rel,
  role,
  ariaSelected,
  nativeAppMethodOverride,
  ...props
}: Props) => {
  const [replaceState, setReplaceState] = useState(replace);
  const { isNativeApp } = useUserAgent();

  useEffect(() => {
    if (isNativeApp === undefined) return;
    // Allow overriding replace/push functionality in native app environment
    if (isNativeApp && nativeAppMethodOverride === 'replace') {
      setReplaceState(true);
    }
  }, [isNativeApp, replaceState, nativeAppMethodOverride]);

  const router = useCustomRouter();

  const link = !external ? reserveQueries(href, router.query) : href;
  const asArg = asProp ? reserveQueries(asProp, router.query) : null;

  return (
    <NextLink
      href={link as UrlType}
      as={asArg as UrlType}
      passHref={passHref}
      prefetch={prefetch}
      replace={replaceState}
      scroll={scroll}
      shallow={shallow}
      legacyBehavior
    >
      <StyledLink
        variant={variant}
        itemScope={itemscope}
        itemProp={itemprop}
        itemType={itemtype}
        style={customStyle}
        onClick={(e) => (onClick ? onClick(e) : null)}
        className={className}
        target={target}
        rel={rel}
        data-testid={props['data-testid']}
        role={role}
        aria-selected={ariaSelected}
        {...props}
      >
        {children}
      </StyledLink>
    </NextLink>
  );
};

export default Link;
