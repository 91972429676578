import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';

export const StyledTextArea = styled.textarea<{ variant: string; resize: boolean; isDirty: boolean }>`
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.lighterBlack};
  &:disabled {
    color: ${variables.colors.lighterBlack};
    -webkit-text-fill-color: ${variables.colors.lighterBlack};
    opacity: 0.6;
  }
  &:read-only {
    background-color: ${variables.colors.lighterGray};
    color: ${variables.colors.darkerGray};
    -webkit-text-fill-color: ${variables.colors.darkerGray};
    opacity: 1;
  }
  width: 100%;
  font-size: ${rem(15)};
  line-height: 1.5;
  border: ${rem(1)} solid ${variables.colors.darkGray};
  border-radius: ${rem(2)};
  padding: ${rem(15)} ${rem(15)};
  appearance: none;

  &:focus {
    border: ${rem(1)} solid ${variables.colors.highlight};
  }

  &::placeholder {
    color: ${variables.colors.darkerGray};
  }

  ${(props) =>
    props.isDirty &&
    css`
      &:invalid {
        border-color: ${variables.colors.primaryDark} !important;
      }
    `}

  ${(props) =>
    props.resize === false &&
    css`
      resize: none;
    `}
`;

export const StyledTextAreaLabelWrapper = styled.div`
  display: flex;
`;

export const StyledTextAreaLabel = styled.label`
  height: ${rem(16)};
  margin: 0 0 ${rem(8)} 0;
  font-family: ${variables.fonts.fontGothamMedium};
  color: ${variables.colors.black};
  font-size: ${rem(13)};
  font-weight: 500;
  text-transform: uppercase;
  display: block;
`;
