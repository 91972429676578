import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackShowMoreProductInfo = (tab: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'product_detail',
    action: `${tab}_show_more_information`,
    label: '',
    value: 0,
  });
};

export default trackShowMoreProductInfo;
