import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

export const keyframesShow = keyframes`
from {
  opacity: 0;

}
to {
  opacity: 1;
}
`;

export const keyframesHide = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

export const FadeAnimation = styled.div<{ show: boolean; extraCss: string }>`
  ${(props) =>
    props.show
      ? css`
          opacity: 0;
          animation: ${keyframesShow} ease-out 0.15s forwards;
        `
      : css`
          opacity: 1;
          animation: ${keyframesHide} ease-out 0.15s forwards;
        `};
  ${(props) => props.extraCss}
`;

export interface FadeProps {
  show: boolean;
  children: ReactNode;
  onStart?: (show: boolean, ref: RefObject<HTMLDivElement>) => void;
  onEnd?: (show: boolean, ref: RefObject<HTMLDivElement | undefined>) => void;
  as: 'ul' | 'li' | 'div' | 'a' | 'button';
  extraCss: string;
  role: string;
}

const Fade = ({ as = 'div', show, children, onStart, onEnd, extraCss, role }: FadeProps) => {
  const [shouldRender, setRender] = useState(show);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
    if (onEnd) onEnd(show, ref);
  };

  const onAnimationStart = () => {
    if (onStart) onStart(show, ref);
  };

  return shouldRender ? (
    <FadeAnimation
      as={as}
      show={show}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}
      role={role}
      extraCss={extraCss}
      ref={ref}
    >
      {children}
    </FadeAnimation>
  ) : null;
};

export default Fade;
