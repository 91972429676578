import { ReactNode, RefObject, useEffect, useRef } from 'react';

type OutsideClickWatcherTypes = {
  children: ReactNode;
  className?: string;
  clickHandler: (e: PointerEvent) => void;
};

function useOutsideClickWatcher(ref: RefObject<HTMLDivElement>, clickHandler: OutsideClickWatcherTypes['clickHandler']) {
  useEffect(() => {
    function handleClickOutside(event: PointerEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        clickHandler(event);
      }
    }

    // Bind the event listener
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [ref, clickHandler]);
}

function OutsideClickWatcher({ children, className, clickHandler }: OutsideClickWatcherTypes) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickWatcher(wrapperRef, clickHandler);

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  );
}

export default OutsideClickWatcher;
