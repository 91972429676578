import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';

import { StyledAnimalInfo, StyledHeader } from './ProductTableOfContents.styles';
import { AxfoodProductDetailsViewModel, ProductInfoNutrientComponentData } from '@occ/api-client';
import { Fragment } from 'react';
import sortNutrientComponents from '@helpers/sortNutrientComponents';

interface Props {
  product: AxfoodProductDetailsViewModel;
}

const ProductTableOfContents = ({ product }: Props) => {
  const { t } = useTranslation('product');

  const sortedNutrientComponents = sortNutrientComponents(product.nutrientComponents);

  return (
    <>
      {!product.animalData && (product.ingredients || sortedNutrientComponents.length > 0) && (
        <>
          {sortedNutrientComponents.length > 0 ? (
            sortedNutrientComponents.map((nutrientComponent: ProductInfoNutrientComponentData) => (
              <Fragment key={nutrientComponent.sequenceNumber}>
                <StyledHeader type="label" size="small">
                  {nutrientComponent.description}
                </StyledHeader>
                <Text type="body">{nutrientComponent?.ingredientStatement}</Text>
              </Fragment>
            ))
          ) : (
            <>
              <StyledHeader type="label" size="small">
                {t('product->contents')}
              </StyledHeader>
              <Text type="body">{product.ingredients}</Text>
            </>
          )}
        </>
      )}

      {product.animalData && (
        <>
          {product.animalData?.compositionStatement && (
            <StyledAnimalInfo>
              <Heading variant="h3" label>
                {t('product->animalData->compositionStatement')}
              </Heading>
              <Text type="body">{product.animalData.compositionStatement}</Text>
            </StyledAnimalInfo>
          )}
          {product.animalData?.constituentStatement && (
            <StyledAnimalInfo>
              <Heading variant="h3" label>
                {t('product->animalData->constituentStatement')}
              </Heading>
              <Text type="body">{product.animalData.constituentStatement}</Text>
            </StyledAnimalInfo>
          )}
          {product.animalData?.additiveStatement && (
            <StyledAnimalInfo>
              <Heading variant="h3" label>
                {t('product->animalData->additiveStatement')}
              </Heading>
              <Text type="body">{product.animalData.additiveStatement}</Text>
            </StyledAnimalInfo>
          )}
        </>
      )}
    </>
  );
};

export default ProductTableOfContents;
