import { ErrorInfo, ReactNode, useMemo } from 'react';
import HorizontalBannerComponent from '@molecules/HorizontalBannerComponent/HorizontalBannerComponent';
import ImageGridComponent from '@molecules/ImageGridComponent/ImageGridComponent';
import MultiColumnContainerComponent from '@molecules/MultiColumnContainerComponent/MultiColumnContainerComponent';
import ProductBeam from '@molecules/ProductBeam/ProductBeam';
import SmallGridPackageComponent from '@molecules/SmallGridPackageComponent/SmallGridPackageComponent';
import TrackedHorizontalBannerComponent from '@molecules/HorizontalBannerComponent/TrackedHorizontalBannerComponent';
import TrackedTwoTilesComponent from '@molecules/TwoTilesComponent/TrackedTwoTilesComponent';
import TwoTilesComponent from '@molecules/TwoTilesComponent/TwoTilesComponent';
import Usps from '@molecules/Usps/Usps';
import ErrorBoundary from '@utility/ErrorBoundary/ErrorBoundary';
import logger from '@logger';
import VerticalBannerComponent from '@organisms/VerticalBannerComponent/VerticalBannerComponent';
import CMSPartnerLinkComponent from '@molecules/CMSPartnerLinkComponent/CMSPartnerLinkComponent';
import ArticleNavigationMenu from '@organisms/ArticleNavigationMenu/ArticleNavigationMenu';
import CMSParagraphComponent from '@organisms/CMSParagraphComponent/CMSParagraphComponent';
import MultiSearchComponent from '@organisms/MultiSearchComponent/MultiSearchComponent';
import NewsComponent from '@molecules/NewsComponent/NewsComponent';
import NewsComponentDetail from '@molecules/NewsComponentDetail/NewsComponentDetail';
import StoreInfoComponent from '@molecules/StoreInfoComponent/StoreInfoComponent';
import StoreFinder from '@organisms/StoreFinder/StoreFinder';
import { StyledMarginWrapper } from '@organisms/CmsComponent/CmsComponent.styles';
import useSmartEdit from '@hooks/useSmartEdit';
import CategoryProductBannerComponent from '@organisms/CategoryProductBannerComponent/CategoryProductBannerComponent';
import BuyAllProductsComponent from '@organisms/BuyAllProductsComponent/BuyAllProductsComponent';
import dynamic from 'next/dynamic';
import GlobalMessage from '@molecules/GlobalMessage/GlobalMessage';
import ProductDetails from '@organisms/ProductDetails/ProductDetails';
import DynamicFaqComponent from '@organisms/FaqComponent/DynamicFaqComponent';

interface Props {
  component: any;
  context?: SlotContextType;
}

const DynamicContactUsForm = dynamic(() => import('@organisms/ContactUsForm/ContactUsForm'));
const DynamicPersonalElements = dynamic(() => import('@organisms/PersonalElements/PersonalElements'));

const wrapWithMargin = (children: ReactNode) => <StyledMarginWrapper>{children}</StyledMarginWrapper>;

const componentErrorBoundaryHandler = (error: Error, info: ErrorInfo) => {
  logger.error({
    error: 'Error boundary caught error',
    message: error.message,
    ...info,
  });

  // TODO: Log to GA (https://axfood.atlassian.net/browse/B2C-16720)
};

const CmsComponent = ({ component, context }: Props) => {
  const { isSmartEditEnabled, catalogVersionUuid: smartEditCv } = useSmartEdit();

  const getCMSComponentTemplate = () => {
    const componentLiteral = {
      HorizontalBannerComponent: context
        ? wrapWithMargin(<TrackedHorizontalBannerComponent data={component} context={context} />)
        : wrapWithMargin(<HorizontalBannerComponent data={component} />),
      MultiColumnContainerComponent: <MultiColumnContainerComponent data={component} context={context} />,
      VerticalBannerComponent: wrapWithMargin(<VerticalBannerComponent data={component} />),
      CMSPartnerLinkComponent: wrapWithMargin(<CMSPartnerLinkComponent data={component} />),
      AxfoodProductBannerComponent: wrapWithMargin(<ProductBeam data={component} />),
      ArticleNavigationMenuComponent: <ArticleNavigationMenu articleUid={component.uid} />,
      ImageGridComponent: <ImageGridComponent data={component} />,
      SmallGridPackageComponent: <SmallGridPackageComponent data={component} />,
      AxfoodContentUspCmsComponent: <Usps data={component} />,
      TwoTilesButtonAndProductBannerComponent: context
        ? wrapWithMargin(<TrackedTwoTilesComponent data={component} context={context} />)
        : wrapWithMargin(<TwoTilesComponent data={component} />),
      AxfoodCustomerServiceContactComponent: wrapWithMargin(<DynamicContactUsForm />),
      CMSParagraphComponent: wrapWithMargin(<CMSParagraphComponent data={component} />),
      StoreCMSParagraphComponent: wrapWithMargin(<CMSParagraphComponent data={component} isStorePage />),
      AxfoodNewsComponent: <NewsComponent data={component} />,
      NewsItemContentPlaceHolder: <NewsComponentDetail />,
      StoreInfoComponent: <StoreInfoComponent data={component} />,
      SearchStoreMapComponent: wrapWithMargin(<StoreFinder />),
      PersonalElementPlaceHolder: wrapWithMargin(<DynamicPersonalElements cmsData={component} />),
      AxfoodFAQComponent: wrapWithMargin(<DynamicFaqComponent />),
      AxfoodMultiSearchComponent: wrapWithMargin(<MultiSearchComponent />),
      CategoryProductsComponent: <CategoryProductBannerComponent data={component} />,
      AxfoodBuyAllProductsComponent: wrapWithMargin(<BuyAllProductsComponent data={component} />),
      AxfoodMostBoughtWithSearchProductBannerComponent: wrapWithMargin(<ProductBeam data={component} displayFilters />),
      AxfoodGlobalMessageComponent: <GlobalMessage data={component} />,
      ProductDetailsComponent: <ProductDetails isPage />,
    };

    return componentLiteral[component.typeCode as keyof typeof componentLiteral] || null;
  };

  const SmartEdit = ({ children }: { children: ReactNode }) => {
    return isSmartEditEnabled &&
      component.typeCode !== 'SmallGridPackageComponent' &&
      component.typeCode !== 'MediumGridPackageComponent' &&
      component.typeCode !== 'ImageGridComponent' ? (
      <div
        className="smartEditComponent"
        data-smartedit-component-type={component.typeCode}
        data-smartedit-component-uuid={component.uuid}
        data-smartedit-component-id={component.uid}
        data-smartedit-catalog-version-uuid={smartEditCv}
      >
        {children}
      </div>
    ) : (
      <>{children}</>
    );
  };

  // Make sure to use memoized version as context object is recreated each template re-render,
  // so comparing the context object by reference causes unnecessary re-renders
  return useMemo(() => {
    return (
      <ErrorBoundary errorHandler={componentErrorBoundaryHandler}>
        <SmartEdit>{getCMSComponentTemplate()}</SmartEdit>
      </ErrorBoundary>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.slotPosition, context?.slotName]);
};

export default CmsComponent;
