import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackSort = (category: string, label: string) =>
  pushGTMEvent({
    event: 'Track',
    category,
    action: 'sorted_products',
    label,
    value: 0,
  });

export default trackSort;
