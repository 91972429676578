import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@slices/createAppSlice';

interface SlideInSectionState {
  isOpen: boolean;
}

const initialState: SlideInSectionState = {
  isOpen: false,
};

export const slideInSectionSlice = createAppSlice({
  name: 'slideInSection',
  initialState,
  reducers: (create) => ({
    toggleSlideInSection: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }),
  }),
  selectors: {
    selectSlideInSectionIsOpen: (slideInSection) => slideInSection.isOpen,
  },
});

// Actions
export const { toggleSlideInSection } = slideInSectionSlice.actions;

// Selectors
export const { selectSlideInSectionIsOpen } = slideInSectionSlice.selectors;
