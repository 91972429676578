import styled from 'styled-components';
import { rem } from 'polished';
import Link from '@atoms/Link/Link';
import Config from '@config';

export const StyledSubCategoryLinksWrapper = styled.div`
  display: flex;
  gap: ${rem(6)};
  overflow-x: auto;
  margin-top: ${rem(16)};

  ${Config.MEDIA.IS_MOBILE} {
    margin-left: ${rem(-20)};
    margin-right: ${rem(-20)};
  }

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSubCategoryLink = styled(Link)`
  ${Config.MEDIA.IS_MOBILE} {
    &:first-child {
      margin-left: ${rem(20)};
    }

    &:last-child {
      margin-right: ${rem(20)};
    }
  }
`;
