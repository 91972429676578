import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  errorHandler: (error: Error, errorInfo: ErrorInfo) => void;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { errorHandler } = this.props;
    this.setState({ hasError: true });
    errorHandler(error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      if (fallback) {
        return fallback;
      }
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
