import React from 'react';
import { SkeletonWrapper, StyledSkeleton } from './TwoTilesComponent.skeleton.styles';

const TwoTilesComponentSkeleton = () => {
  return (
    <SkeletonWrapper>
      <StyledSkeleton type="rect" height={340} />
      <StyledSkeleton type="rect" height={340} />
    </SkeletonWrapper>
  );
};

export default TwoTilesComponentSkeleton;
