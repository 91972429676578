import Button from '@atoms/Button/Button';
import {
  StyledButtonLink,
  StyledHeading,
  StyledInnerWrapper,
  StyledTitleAndButtonWrapper,
} from './GridComponentTitleAndButton.styles';
import { trackImageGridComponent } from '@helpers/analyticsHelpers/trackImageGridComponent';

interface Props {
  title?: string;
  titleColor?: string;
  buttonTitlePosition?: 'TOP' | 'CENTER' | 'BOTTOM';
  buttonColor?: string;
  buttonTextColor?: string;
  buttonText?: string;
  enableButton?: boolean;
  imageLinkUrl: string;
  imageTarget: string;
  testId: string;
}

const GridComponentTitleAndButton = ({
  title,
  enableButton,
  buttonTitlePosition,
  titleColor,
  imageLinkUrl,
  imageTarget,
  buttonText,
  buttonTextColor,
  buttonColor,
  testId,
}: Props) => {
  const contentAlignment =
    buttonTitlePosition === 'TOP' ? 'flex-start' : buttonTitlePosition === 'BOTTOM' ? 'flex-end' : 'center';

  return title || enableButton ? (
    <StyledTitleAndButtonWrapper align={contentAlignment}>
      <StyledInnerWrapper>
        {title && (
          <StyledHeading variant="h3" color={titleColor as HeadingColors} testId={`${testId}Title`}>
            {title}
          </StyledHeading>
        )}

        {imageLinkUrl && enableButton && (
          <StyledButtonLink
            data-testid="grid-link"
            href={imageLinkUrl}
            target={imageTarget === 'newWindow' ? '_blank' : '_self'}
            onClick={() => trackImageGridComponent(title || '')}
          >
            <Button
              background={buttonColor}
              color={buttonTextColor}
              title={buttonText}
              size="small"
              data-testid={`${testId}Button`}
            >
              {buttonText}
            </Button>
          </StyledButtonLink>
        )}
      </StyledInnerWrapper>
    </StyledTitleAndButtonWrapper>
  ) : null;
};

export default GridComponentTitleAndButton;
