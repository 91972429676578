import useTranslation from 'next-translate/useTranslation';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import Link from '@atoms/Link/Link';
import Table from '@atoms/Table/Table';
import {
  StyledEnergyDeclarationImage,
  StyledGhsLabels,
  StyledGhsSymbols,
  StyledGhsWrapper,
  StyledInfoBlock,
  StyledGhsIcon,
} from './ProductTableOfOther.styles';
import type {
  AxfoodProductDetailsViewModel,
  BatteryInformationData,
  FeedingData,
  FeedingDetailsData,
  FeedingFrequencyData,
} from '@occ/api-client';
import config from '@config';

const GHS_SIGNAL_WORDS = ['danger', 'not_applicable', 'warning'];

type Props = {
  data: AxfoodProductDetailsViewModel;
};

const ProductTableOfOther = ({ data }: Props) => {
  const { t } = useTranslation('product');
  const precautionaryStatements = data.hazards?.length
    ? data.hazards.concat(data.precautionaryStatements)
    : data.precautionaryStatements;

  const renderInfoBlock = (title: string, details: string[]) => {
    return (
      <StyledInfoBlock>
        <Heading variant="h3" label>
          {title}
        </Heading>

        {details &&
          details?.map((detail, index) => (
            <Text key={detail.replaceAll(' ', '')} type="body">
              {detail}
            </Text>
          ))}
      </StyledInfoBlock>
    );
  };

  const getFeedingDetailString = (feedingDetails: FeedingDetailsData[] = [], feedingFrequencyId = 0) =>
    feedingDetails
      .find((detail) => detail.feedingFrequencyId === feedingFrequencyId)
      ?.amount?.map((unit) => unit)
      .join(', ');

  return (
    <>
      {data.labels && data.labels.length > 0 && (
        <StyledInfoBlock>
          <StyledGhsWrapper>
            <StyledGhsSymbols>
              {data.labels.map(
                (item: string, index) =>
                  config.GHS_SYMBOLS.includes(item) && (
                    <StyledGhsIcon key={`${item}-${index}`} src={`/icons/ghs/${item}.svg`} />
                  )
              )}
            </StyledGhsSymbols>
            <StyledGhsLabels>
              {data.labels.map(
                (item: string, index) =>
                  GHS_SIGNAL_WORDS.includes(item) && (
                    <Text key={`${item}-${index}`} type="label">
                      {t(`product->${item}`)}
                    </Text>
                  )
              )}
            </StyledGhsLabels>
          </StyledGhsWrapper>
        </StyledInfoBlock>
      )}

      {precautionaryStatements?.length > 0 &&
        renderInfoBlock(t('product->precautionaryStatement'), precautionaryStatements)}

      {data.dietTypeInformation && renderInfoBlock(t('product->dietTypeInformation'), [data.dietTypeInformation])}

      {data.consumerStorageInstructions &&
        renderInfoBlock(t('product->consumerStorageInstructions'), [data.consumerStorageInstructions])}

      {data.consumerUsageInstructions &&
        renderInfoBlock(t('product->consumerUsageInstructions'), [data.consumerUsageInstructions])}

      {data.preparationDescription &&
        renderInfoBlock(t('product->preparationDescription'), [data.preparationDescription])}

      {data.maxStorageTemperature && renderInfoBlock(t('product->maxStorageTemprature'), [data.maxStorageTemperature])}

      {data.minStorageTemperature && renderInfoBlock(t('product->minStorageTemprature'), [data.minStorageTemperature])}

      {(data.areBatteriesIncluded || data.areBatteriesRequired || data.batteryInformation?.length > 0) && (
        <StyledInfoBlock>
          <Heading variant="h3" label>
            {t('product->batteryInformation')}
          </Heading>
          {data.areBatteriesIncluded !== null && (
            <Text type="body">
              {`${t('product->areBatteriesIncluded')} ${
                data.areBatteriesIncluded ? t('product->batteryTrue') : t('product->batteryFalse')
              }`}
            </Text>
          )}
          {data.areBatteriesRequired !== null && (
            <Text type="body">
              {`${t('product->areBatteriesRequired')} ${
                data.areBatteriesRequired ? t('product->batteryTrue') : t('product->batteryFalse')
              }`}
            </Text>
          )}

          {data.batteryInformation?.length > 0 &&
            data.batteryInformation.map((item: BatteryInformationData, index) => (
              <Text key={`${item}-${index}`} type="body">
                {`${item.batteriesQuantityRequired || ''} ${item.batteryType || ''} ${
                  item.batteryTechnologyTypeCode || ''
                } ${item.batteryWeight || ''} ${item.batteryQuantityBuiltIn || ''} ${
                  item.batteryQuantityBuiltIn ? t('product->batteryQuantityBuiltIn') : ''
                }`}
              </Text>
            ))}
        </StyledInfoBlock>
      )}

      {/*{data.isDrugProduct && renderInfoBlock(t('product->drugInformation'), [data.drugInformation])}*/}

      {/*{data.isDrugProduct && renderInfoBlock(t('product->drugIllumination'), [data.drugIllumination])}*/}

      {(data.foodContactName || data.foodContactAddress) && (
        <StyledInfoBlock>
          <Heading variant="h3" label>
            {t('product->contact')}
          </Heading>
          {data.foodContactName && <Text type="body">{data.foodContactName}</Text>}
          {data.foodContactAddress && <Text type="body">{data.foodContactAddress}</Text>}
        </StyledInfoBlock>
      )}

      {data.regulatoryAct && renderInfoBlock(t('product->regulatoryAct'), [data.regulatoryAct])}

      {data.compulsoryAdditiveLabelInformation &&
        renderInfoBlock(t('product->compulsoryAdditiveLabelInformation'), [data.compulsoryAdditiveLabelInformation])}

      {data.code && renderInfoBlock(t('product->articleNumber'), [data.code])}

      {data.animalData && (data.animalData?.feedingInstructions || (data.animalData.feedingData?.length ?? 0) > 0) && (
        <StyledInfoBlock>
          {data.animalData?.feedingInstructions && (
            <div>
              <Heading variant="h3" label>
                {t('product->feedingInstructions')}
              </Heading>
              <Text type="body">{data.animalData?.feedingInstructions}</Text>
            </div>
          )}

          {data.animalData?.feedingFrequencies && data.animalData?.feedingData && (
            <StyledInfoBlock>
              <Table>
                <thead>
                  <tr>
                    <th>{t('product->animalWeight')}</th>
                    <th>{t('product->animalAge')}</th>
                    {data.animalData?.feedingFrequencies?.map((item: FeedingFrequencyData, index) => (
                      <th key={`${item.value}-${index}`}>{item.value}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.animalData?.feedingData?.map((feedingDataPoint: FeedingData, index) => (
                    <tr key={`${feedingDataPoint.lifeStage}-${index}`}>
                      <td>{feedingDataPoint.weight}</td>
                      <td>{feedingDataPoint.lifeStage}</td>
                      {data.animalData?.feedingFrequencies?.map((feedingFrequency: FeedingFrequencyData) => (
                        <td key={`${feedingFrequency.id}-${index}`}>
                          {getFeedingDetailString(feedingDataPoint.feedingDetails, feedingFrequency.id)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </StyledInfoBlock>
          )}
        </StyledInfoBlock>
      )}
      {data?.energyDeclaration && (
        <StyledInfoBlock data-testid="energyDeclaration">
          <Heading variant="h3" label>
            {t('product->energyDeclaration->heading')}
          </Heading>
          {data.energyDeclaration?.link && (
            <Text type="body">
              <Link href={data.energyDeclaration.link} data-testid="energyDeclaration_link" target="_blank">
                {t('product->energyDeclaration->linkText')}
              </Link>
            </Text>
          )}
          {data.energyDeclaration.image && (
            <StyledEnergyDeclarationImage
              alt={t('product->energyDeclaration->heading')}
              src={data.energyDeclaration.image}
              data-testid="energyDeclaration_image"
            />
          )}
        </StyledInfoBlock>
      )}
    </>
  );
};

export default ProductTableOfOther;
