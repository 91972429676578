import { RefObject } from 'react';
import Header from '@organisms/Header/Header';
import { StyledHeaderContainer } from './HeaderContainer.styles';
import useUserAgent from '@hooks/useUserAgent';
import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import dynamic from 'next/dynamic';

interface Props {
  messageSlot: ContentSlotWsDTOWithComponent;
  headerSlots: ContentSlotWsDTOWithComponent[];
  headerRef: RefObject<HTMLDivElement>;
  isCheckout?: boolean;
  isSharedWishList?: boolean;
}

const DynamicGlobalMessageContainer = dynamic(() => import('@molecules/HeaderContainer/GlobalMessageContainer'), {
  ssr: false,
});

const HeaderContainer = ({ messageSlot, headerSlots, headerRef, isCheckout, isSharedWishList }: Props) => {
  const { isWebOrNativeAndEcommerce = false, isNativeApp = false } = useUserAgent();
  const isNativeAppCheckout = isNativeApp && isCheckout;

  return (
    <StyledHeaderContainer hasHeader={!isNativeAppCheckout && isWebOrNativeAndEcommerce} ref={headerRef}>
      <DynamicGlobalMessageContainer messageSlot={messageSlot} />
      {headerSlots.length > 0 && isWebOrNativeAndEcommerce && !isNativeAppCheckout && (
        <Header slots={headerSlots} isCheckout={isCheckout} isSharedWishList={isSharedWishList} />
      )}
    </StyledHeaderContainer>
  );
};

export default HeaderContainer;
